import React from "react";
import { PageHeader, Card } from "antd";
import Form from "./Form";

function Edit({ session, loading, error, onSubmit, lexicons }) {
	return (
		<div>
			<PageHeader title="Edit Lexicon Session" />
			<Card>
				<Form
					lexicons={lexicons}
					apiError={error}
					loading={loading}
					onSubmit={onSubmit}
					initialValues={session}
				/>
			</Card>
		</div>
	);
}

export default Edit;
