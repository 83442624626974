import axios from "axios";
import { message } from "antd";

export const LEXICONS_LOAD = "LEXICONS_LOAD";
export const LEXICONS_LOAD_SUCCESS = "LEXICONS_LOAD_SUCCESS";
export const LEXICONS_LOAD_FAIL = "LEXICONS_LOAD_FAIL";
export const LEXICONS_UPDATE = "LEXICONS_UPDATE";
export const LEXICONS_DEPLOY = "LEXICONS_DEPLOY";
export const LEXICONS_DEPLOY_SUCCESS = "LEXICONS_DEPLOY_SUCCESS";
export const LEXICONS_DEPLOY_FAIL = "LEXICONS_DEPLOY_FAIL";

export const update = lexicon => ({
	type: LEXICONS_UPDATE,
	payload: lexicon
});

export const loadFail = e => dispatch => {
	message.error("An error occurred while fetching the list of lexicons.");

	dispatch({
		type: LEXICONS_LOAD_FAIL,
		payload: e
	});
};

export const loadSuccess = data => ({
	type: LEXICONS_LOAD_SUCCESS,
	payload: data
});

export function load() {
	return dispatch => {
		dispatch({ type: LEXICONS_LOAD });

		axios
			.get("/lexicons")
			.then(res => dispatch(loadSuccess(res.data)))
			.catch(e => dispatch(loadFail(e)));
	};
}

export const deploySuccess = data => ({
	type: LEXICONS_DEPLOY_SUCCESS,
	payload: data
});

export const deployFail = e => dispatch => {
	message.error("An error occurred while deploying lexicons.");

	dispatch({
		type: LEXICONS_DEPLOY_FAIL,
		payload: e
	});
};

export function deploy(values){
	return dispatch => {
		dispatch({ type: LEXICONS_DEPLOY });
		axios
			.post(`lexicons/bulk_deploy/`, {
				lexicons: values
			})
			.then(res => {
				message.success("Deployment successful");
				dispatch(deploySuccess(res.data));
			})
			.catch(e => dispatch(deployFail(e)));
	};
}

const initialState = {
	data: null,
	error: null,
	loading: false,
	deployLoading: false,
	deployError: null
};

export default function lexiconsReducer(state = initialState, action) {
	switch (action.type) {
		case LEXICONS_LOAD:
			return { ...initialState, loading: true };
		case LEXICONS_LOAD_SUCCESS:
			return { ...state, data: action.payload, loading: false };
		case LEXICONS_LOAD_FAIL:
			return { ...state, error: action.payload, loading: false };
		case LEXICONS_UPDATE:
			return {
				...state,
				data: state.data.map(r =>
					r.id === action.payload.id ? action.payload : r
				)
			};
		case LEXICONS_DEPLOY:
			return { ...state, deployLoading: true };
		case LEXICONS_DEPLOY_SUCCESS:
			return { ...state, deployLoading: false };
		case LEXICONS_DEPLOY_FAIL: 
			return { ...state, deployLoading: false };			
		default:
			return state;
	}
}
