import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { save, reset } from "../modules/create";
import { PropTypes } from "prop-types";
import Create from "../components/Create";

export class CreateContainer extends PureComponent {
	static propTypes = {
		reset: PropTypes.func.isRequired,
		loading: PropTypes.bool.isRequired,
		error: PropTypes.object,
	};

	componentDidMount() {
		this.props.reset();
	}

	render() {
		return <Create {...this.props} />;
	}
}

const mapStateToProps = (state) => ({
	...state.clusters.create,
});

const mapDispatchToProps = { save, reset };

export default connect(mapStateToProps, mapDispatchToProps)(CreateContainer);
