import React from "react";
import ApiError from "../../components/ApiError";
import { useList } from "./useList";
import BookWithStatus from "./BookWithStatus";

const SearchResults = ({ query }) => {
	const list = useList("dashboard-search", `/clusters/?search=${query}`);
   
	return (
		<>
			<h3 className="text-lg text-cool-gray-400">
				Search results for{" "}
				<span className="text-white font-medium">{query}</span>
			</h3>

			{list && list.error && <ApiError error={list.error} />}
			{list?.data?.results &&
				list.data.results.map((cluster) => (
					<BookWithStatus key={cluster.id} {...cluster} />
				))}
		</>
	);
};

export default SearchResults;
