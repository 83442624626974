import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { TextField, TextAreaField } from "redux-form-antd";
import { reduxForm, Field, change } from "redux-form";
import { Button, Icon, message } from "antd";
import { required, number } from "../../../../services/validations";
import ImportEpub from "./ImportEpub";
import SelectField from "redux-form-antd/lib/components/SelectField";
import axios from "axios";

const defaultNlpTypes = {
	anger: 0.1, 
	disgust: 0, 
	fear: 0, 
	happy: 0.5, 
	love: 0.7, 
	sad: 0, 
	surprise: 0 
};

export class Form extends Component {
	state = {
		showEpub: false,
		publisherOptions: null,
		voices: null,
		fixedNlpScore: false,
	};

	componentDidMount() {
		axios
			.get("publishers/")
			.then((res) =>
				this.setState({
					publisherOptions: [
						{ value: "0", label: "-- Choose publisher --" },
						...res.data.map((publisher) => ({
							value: publisher.id,
							label: publisher.title,
						})),
					],
				})
			)
			.catch((e) =>
				message.error("An error occurred while fetching publishers.")
			);

			axios.get(`portal/voices/`).then((res) =>
				this.setState({
					voices: [
						...res.data.map((v) => ({
							value: v.tts_voice,
							label: v.name_of_narrator,
						})),
					],
				})
			)
			.catch((e) =>
				message.error("An error occurred while fetching voices.")
			);
	}

	render() {
		const { showEpub } = this.state;
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit}>
				{showEpub && (
					<ImportEpub
						onCancel={() => this.setState({ showEpub: false })}
						onComplete={(text) => {
							this.props.dispatch(
								change("clusters-create", "text", text.trim())
							);
							this.setState({ showEpub: false });
						}}
					/>
				)}

				<Field
					name="title"
					label="Title"
					validate={required}
					component={TextField}
				/>

				<div className="flex">
					<div className="flex-1 mr-5">
						<Field name="isbn" label="ISBN" component={TextField} />
					</div>
					<div className="flex-1 ml-5">
						<Field
							name="publisher_id"
							loading={this.state.publisherOptions === null}
							disabled={this.state.publisherOptions === null}
							label="Publisher"
							options={this.state.publisherOptions}
							component={SelectField}
						/>
					</div>
				</div>

				<div className="flex">
					<div className="flex-1 mr-5">
						<Field
							name="tts_model"
							label="Model"
							validate={required}
							component={TextField}
						/>
					</div>
					<div className="flex-1 mr-5">
						<Field
							label="Voice"
							name="tts_voice"
							component={SelectField}
							disabled={this.state.voices === null}
							options={this.state.voices ?? []}
						/>
					</div>
					<div className="flex-1 mr-5">
						<Field
							name="tts_gst_factor"
							label="Narration Intensity"
							validate={number}
							component={TextField}
						/>
					</div>
					<div className="flex-1">
						<Field
							name="tts_gst_factor_quotes"
							label="Conversation Intensity"
							validate={number}
							component={TextField}
						/>
					</div>
				</div>

				<Field
					name="text"
					label="Text"
					validate={required}
					component={TextAreaField}
					rows={20}
				/>

				<Button
					size="small"
					style={{ marginBottom: 10, transform: "translateY(-20px)" }}
					onClick={() => this.setState({ showEpub: true })}
				>
					Import EPUB
				</Button>

				<div className="flex items-center">
					<Field
						name="include_titles_in_body"
						id="include_titles_in_the_body"
						label="Include titles in the body"
						type="checkbox"
						component="input"
					/>
    				<label 
						htmlFor="include_titles_in_the_body" 
						className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300">
							Include titles in the body
					</label>
				</div>

				<div className="flex items-center mt-5">
					<Field
						name="fixed_nlp_score"
						id="fixed_nlp_score"
						label="Fixed nlp score"
						type="checkbox"
						component="input"
						onChange={(e) => { 
							this.setState({ 
								fixedNlpScore: e.target.checked 
							})
						}}
					/>
    				<label 
						htmlFor="fixed_nlp_score" 
						className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300">
							Fixed nlp score
					</label>
				</div>

				{this.state.fixedNlpScore && (
					<div className="flex items-center mt-5 ml-5">
						{Object.keys(defaultNlpTypes).map((type) => (
							<div className="mr-5" key={type}>
								<label 
									htmlFor={`tts_nlp_type.${type}`} 
									className="block text-md font-medium text-gray-900">
										{type === "love" ? "excitement" : type}
								</label>
								<Field
									key={type}
									name={`tts_nlp_type.${type}`}
									type="number"
									min={0}
									max={1}
									step={0.01}
									component={TextField}
									size="small"
								/>
							</div>
						))}
					</div>
				)}		
				
				<div className="clearfix">
					<Button
						type="primary"
						size="large"
						className="float-right"
						htmlType="submit"
					>
						Continue
						<Icon type="right" />
					</Button>
				</div>
			</form>
		);
	}
}

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
	form: "clusters-create",
	initialValues: { 
		tts_nlp_type: {...defaultNlpTypes}, 
		include_titles_in_body: true, 
		publisher_id: 0, 
		tts_gst_factor: 0.4,
	},
})(Form);
