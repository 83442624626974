import React from "react";
import SearchContainer from "../routes/Lexicon/Search/containers/SearchContainer";
import { connect } from "react-redux";

export const LexiconLayout = ({ children, location }) => (
	<div className="h-screen flex overflow-hidden">
		{location.pathname === "/lexicon/sessions" && (
			<div className="flex flex-col w-80 p-3 bg-cool-gray-200">
				<SearchContainer />
			</div>
		)}
		<div className="flex flex-col w-0 flex-1 overflow-y-auto p-3">
			{children}
		</div>
	</div>
);

const mapStateToProps = (state) => state.location;

export default connect(mapStateToProps)(LexiconLayout);
