import React from "react";
import { Link } from "react-router";
import ClusterStatusBadge from "../../components/ClusterStatusBadge";

const BookWithStatus = ({ id, title, status }) => (
	<Link
		to={`/clusters/${id}`}
		className="flex rounded-lg bg-white p-4 mt-3 items-center hover:shadow-outline-blue hover:bg-cool-gray-50 hover:text-cool-gray-900"
	>
		<div className="flex-grow truncate text-base">{title}</div>
		<div className="w-36 text-right">
			<ClusterStatusBadge status={status} />
		</div>
	</Link>
);

export default BookWithStatus;
