import {
	applyMiddleware,
	compose,
	createStore as createReduxStore,
} from "redux";
import thunk from "redux-thunk";
import { browserHistory } from "react-router";
import makeRootReducer from "./reducers";
import { updateLocation } from "./location";
// import logger from "redux-logger";

const createStore = (initialState = {}) => {
	let middleware = [thunk];

	const enhancers = [];
	let composeEnhancers = compose;

	if (process.env.NODE_ENV === "development") {
		if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function") {
			composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
		}

		middleware = [...middleware]; // logger
	}

	// Store Instantiation and HMR Setup
	const store = createReduxStore(
		makeRootReducer(),
		initialState,
		composeEnhancers(applyMiddleware(...middleware), ...enhancers)
	);

	// To unsubscribe, invoke `store.unsubscribeHistory()` anytime
	store.unsubscribeHistory = browserHistory.listen(updateLocation(store));

	return store;
};

export default createStore;
