import React, { Component } from "react";
import { connect } from "react-redux";
import { load } from "../../modules/sessions";
import List from "../components/List";

class ListContainer extends Component {
	componentDidMount() {
		this.props.load();
	}

	render() {
		return <List {...this.props} />;
	}
}

const mapStateToProps = state => state.lexicon.sessions;

const mapDispatchToProps = {
	load
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
