import { message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../../../components/Card";
import Descriptions, {
	DescriptionsItem,
} from "../../../../components/Descriptions";
import PortalProjectSummary from "../Overview/PortalProjectSummary";
import Badge from "../../../../components/Badge";
import classNames from "classnames";
import MiniPlayer from "../../../../components/MiniPlayer";

const PortalDeliveryZone = () => {
	const editorNotesTimeout = useRef();
	const [issues, setIssues] = useState([]);
	const chapterTitles = useSelector((state) => state.clusters.detail.chapterTitles);
	const project = useSelector((state) => state.clusters.detail.portalProject);
	const [playingAudioId, setPlayingAudioId] = useState(null);

	useEffect(() => {
		if (project) 
			setIssues(project.issues?.map((issue) => {
				return {...issue, chapterTitle: chapterTitles[issue.chapter]}
			})
			.sort((a, b) => a.chapterTitle > b.chapterTitle ? 1 : -1))
	}, [project]);

	if (!chapterTitles || !project) return null;

	const patchIssue = (issue, payload) => {
		setIssues(
			issues.map((i) => (i.id === issue.id ? { ...i, ...payload } : i))
		);

		clearTimeout(editorNotesTimeout.current);
		editorNotesTimeout.current = setTimeout(() => {
			axios
				.patch(
					`portal/projects/${project.id}/issues/${issue.id}/`,
					payload
				)
				.then((res) => null)
				.catch(() => message.error("An error occurred."));
		}, 500);
	};

	const onPlayAudio = (id) => {
		if (id !== playingAudioId) {
			let audioEl = document.getElementById(playingAudioId);
			if (audioEl) {
				audioEl.pause();
			}
		}
		setPlayingAudioId(id);
	};

	return (
		<div className="container">
			<div className="flex -mx-2.5">
				<div className="w-1/3 px-2.5">
					<PortalProjectSummary data={project} />
				</div>
				<div className="w-2/3 px-2.5">
					{project.status === "finalizing_book" && (
						<Card className="mb-4" title="Customer Proofing Review">
							<Descriptions>
								{issues.map((issue) =>(
									<DescriptionsItem
										key={issue.id}
										label={
											<span>
												<a
													target="_blank"
													href={`/books/${issue.chapter}?issue_timestamp=${issue.time}`}
													className="mr-1 font-medium text-gray-900"
												>
													{issue.chapterTitle} [
													{issue.time}]
												</a>
												{issue.comment}
												<span className="font-medium text-gray-900">
													{" (Added by "}
													{issue.email ?? `guest`}
													{")"}
												</span>
											</span>
										}
									>
										{issue.approved ? (
											<Badge color="green">Approved</Badge>
										): (
											<Badge color="red">Unapproved</Badge>
										)}
									</DescriptionsItem>
								))}
							</Descriptions>
						</Card>
					)}
					<Card title="Customer Proofing Issues">
						<Descriptions>
							{issues.map((issue) => (
								<DescriptionsItem
									key={issue.id}
									style="row"
									label={
										<span
											className={classNames("",
												(issue.status === "resolved" ||
													issue.status ===
														"improved") &&
												"line-through"
											)}
										>
											<a
												target="_blank"
												href={`/books/${issue.chapter}?issue_timestamp=${issue.time}`}
												className="mr-1 font-medium text-gray-900"
											>
												{issue.chapterTitle} [
												{issue.time}]
											</a>
											{issue.comment}
											<span className="font-medium text-gray-900">
												{" (Added by "}
												{issue.email ?? `guest`}
												{")"}
											</span>
										</span>
									}
								>
									<div className="pt-3">
										{issue.audioDetails?.recording && (
											<Badge color="blue" className="mr-3">
												<div className="py-0.5">
													<span className="pr-2">
														Pronunciation
													</span>
													<MiniPlayer
														playerId={issue.id}
														src={issue.audioDetails.recording}
														onPlayAudio={onPlayAudio}
														playingAudioId={playingAudioId}
													/>
												</div>
												
											</Badge>
										)}
										<input
											placeholder="Editor notes"
											value={issue.editor_notes}
											className="form-input py-0.5 text-sm mr-3"
											onChange={(e) =>
												patchIssue(issue, {
													editor_notes:
														e.target.value,
												})
											}
										/>
										<select
											className="form-select py-0.5 text-sm"
											value={issue.status}
											onChange={(e) =>
												patchIssue(issue, {
													status: e.target.value,
												})
											}
										>
											<option value="">- Status -</option>
											<option value="resolved">
												Resolved
											</option>
											<option value="improved">
												Improved
											</option>
											<option value="unresolved">
												Unresolved
											</option>
										</select>
									</div>
								</DescriptionsItem>
							))}
						</Descriptions>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default PortalDeliveryZone;
