export const required = value => value && value.toString().trim() ? undefined : 'Required';
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(value) ? 'Invalid email address' : undefined;
export const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined;
export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined;
export const passwordConfirmation = (value, allValues) => value !== allValues.new_password ? `Passwords don't match` : undefined;
export const password = (value) => {
    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    if(value){
        if(value.length < 8) return "Must be at least 8 characters or more";
        if(!(uppercaseRegExp.test(value) || lowercaseRegExp.test(value))) return "Must be at least 1 character";
    }
    return undefined;
}