import React, { Component } from "react";

export class SentenceDetailsPlayer extends Component {
	audioEl = null;

	componentDidMount() {
		this.audioEl.addEventListener(
			"ended",
			() => this.props.onEndAudio && this.props.onEndAudio()
		);
		this.setupPlayer();
	}

	componentDidUpdate(oldProps) {
		if (oldProps.wav_url !== this.props.wav_url) {
			this.setupPlayer();
		}
	}

	setupPlayer() {
		this.audioEl.src = this.props.wav_url;

		if (this.props.autoPlay) {
			this.audioEl.play();
		}
	}

	render() {
		return (
			<div>
				<audio
					className="w-full h-8"
					preload="none"
					ref={(ref) => (this.audioEl = ref)}
					controls
				/>
			</div>
		);
	}
}

export default SentenceDetailsPlayer;
