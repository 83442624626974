import React from "react";
import classNames from "classnames";

const PageHeader = ({ title, subTitle, extra, footer }) => (
	<div
		className={classNames(
			"rounded-lg bg-white shadow mb-5",
			footer && "overflow-hidden"
		)}
	>
		<div className="p-4 sm:px-6">
			<div className="lg:flex lg:items-center lg:justify-between">
				<div className="flex-1 min-w-0">
					<h2 className="font-medium leading-7 text-gray-900 text-2xl">
						{title}
					</h2>
					<div className="mt-1 text-gray-500">{subTitle}</div>
				</div>
				<div className="mt-5 flex lg:mt-0 lg:ml-4">{extra}</div>
			</div>
		</div>
		{footer && (
			<div className="px-5 border-t border-gray-200 bg-gray-50">
				{footer}
			</div>
		)}
	</div>
);

export default PageHeader;
