import ukPhonemes from "./uk";
import usPhonemes from "./us";
import frPhonemes from "./fr";
import dePhonemes from "./de";
import esEsPhonemes from "./es-es";
import esMxPhonemes from "./es-mx";
import trPhonemes from "./tr";

export const alphabets = {
	ipa: { stress: { pos: "prefix", primary: "ˈ", secondary: "ˌ" } },
	deepzen: {
		stress: { pos: "suffix", primary: '"', secondary: "%" },
	},
	combilex: {
		stress: { pos: "suffix", zero: "0", primary: "1", secondary: "2" },
	},
};

class MyRegExp extends RegExp {
	[Symbol.matchAll](str) {
		const result = RegExp.prototype[Symbol.matchAll].call(this, str);
		if (!result) {
			return null;
		}
		return Array.from(result);
	}
}

function generateRegExp(alphabet, phonemes, key) {
	let phones = [],
		pattern = "";

	phonemes
		.sort((a, b) => b[key].length - a[key].length)
		.forEach((row) => {
			phones.push(
				row[key] === "?" ? "\\?" : row[key].replace("^", "\\^")
			);
		});

	const stressPattern = `(?<stress>[${alphabet.stress?.zero ?? ""}${
		alphabet.stress.primary
	}${alphabet.stress.secondary}])?`;

	pattern = `${
		alphabet.stress.pos === "prefix" ? stressPattern : ""
	}(?<phoneme>${phones.join("|")})${
		alphabet.stress.pos === "suffix" ? stressPattern : ""
	}`;

	return new MyRegExp(pattern, "g");
}

const generateLexicon = (phonemes) => {
	let parsePatterns = {};

	Object.keys(alphabets).forEach((alphabet) => {
		parsePatterns[alphabet] = generateRegExp(
			alphabets[alphabet],
			phonemes,
			alphabet
		);
	});

	return { phonemes, parsePatterns };
};

export const lexicons = {
	uk: generateLexicon(ukPhonemes),
	us: generateLexicon(usPhonemes),
	fr: generateLexicon(frPhonemes),
	de: generateLexicon(dePhonemes),
	es_es: generateLexicon(esEsPhonemes),
	es_mx: generateLexicon(esMxPhonemes),
	tr: generateLexicon(trPhonemes)
};

export function parseCombilex(transcription) {
	const re = new RegExp(`^(\\(?[^\\(\\)]+\\)?)? \\((.*)\\)$`, "im");
	const matches = transcription.match(re);

	return [matches[1], matches[2]];
}

export function parseTranscription(language, alphabet, str) {
	const lexicon = lexicons[language];
	const pattern = lexicon.parsePatterns[alphabet];

	const validMatches = str.matchAll(pattern).map((match) => {
		const entry = lexicon.phonemes.find(
			(row) => row[alphabet] === match.groups.phoneme
		);

		return {
			entry,
			phoneme: match.groups.phoneme,
			stress: match.groups.stress,
			stressLevel:
				match.groups.stress === alphabets[alphabet].stress.primary
					? "primary"
					: match.groups.stress ===
					  alphabets[alphabet].stress.secondary
					? "secondary"
					: null,
			startIndex: match.index,
			endIndex: match.index + match[0].length,
			valid: true,
		};
	});

	let matches = [];
	for (let i = 0; i < validMatches.length; i++) {
		const match = validMatches[i];
		const nextMatch = validMatches[i + 1];

		// First entry is illegal
		if (i === 0 && match.startIndex > 0) {
			matches.push({
				phoneme: str.substring(0, match.startIndex),
				stress: undefined,
				startIndex: 0,
				endIndex: match.startIndex,
				valid: false,
			});
		}

		matches.push(match);

		if (nextMatch && nextMatch.startIndex - match.endIndex > 0) {
			matches.push({
				startIndex: match.endIndex,
				endIndex: nextMatch.startIndex,
				phoneme: str.substring(match.endIndex, nextMatch.startIndex),
				stress: undefined,
				valid: false,
			});
		} else if (!nextMatch && str.length - match.endIndex > 0) {
			matches.push({
				startIndex: match.endIndex,
				endIndex: str.length,
				phoneme: str.substring(match.endIndex),
				stress: undefined,
				valid: false,
			});
		}
	}

	// Completely illegal entry
	if (matches.length === 0 && str.length > 0) {
		matches.push({
			startIndex: 0,
			endIndex: str.length,
			phoneme: str,
			stress: undefined,
			valid: false,
		});
	}

	return matches.filter((m) => m.phoneme.trim().length > 0);
}

export function languageFromLexiconName(title) {
	if (title?.endsWith("-us")) {
		return "us";
	} else if (title?.endsWith("-fr")) {
		return "fr";
	} else if (title?.endsWith("-de")) {
		return "de";
	} else if (title?.endsWith("-es-es") 
		|| title?.endsWith("-es_es")
		){
		return "es_es";
	} else if (title?.endsWith("-es-mx") 
		|| title?.endsWith("-es_mx")){
		return "es_mx";
	} else if (title?.endsWith("-tr")){
		return "tr";
	}else {
		return "uk";
	}
}

export function convertTranscription(language, from, to, matches) {
	const lexicon = lexicons[language];
	const toAlphabet = alphabets[to];

	return (
		matches
			// .filter((m) => m.valid)
			.map((m) => {
				const phoneme = lexicon.phonemes.find(
					(p) => p[from] === m.phoneme
				);

				const stress =
					m.stressLevel === "primary"
						? toAlphabet.stress.primary
						: m.stressLevel === "secondary"
						? toAlphabet.stress.secondary
						: phoneme?.type === "vowel"
						? (language === "uk" || language === "us"
								? toAlphabet.stress.zero
								: undefined) ?? ""
						: "";

				return `${toAlphabet.stress.pos === "prefix" ? stress : ""}${
					phoneme?.[to] ?? m.phoneme
				}${toAlphabet.stress.pos === "suffix" ? stress : ""}`;
			})
			.join(" ")
	);
}
