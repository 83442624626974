import React from "react";
import classNames from "classnames";

const SliderField = ({ label, input, meta, className, ...otherProps }) => (
	<div className="relative focus-within:z-10 flex-grow">
		{label && (
			<label
				className={classNames("text-gray-500 font-medium mb-0.5 block")}
			>
				{label}
			</label>
		)}
		<input
			type="range"
			className={classNames(
				"block bg-white outline-none",
				meta.touched && meta.error
					? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
					: "focus:shadow-outline-blue",
				className
			)}
			{...input}
			{...otherProps}
		/>
	</div>
);

export default SliderField;
