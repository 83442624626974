export function secondsToHms(d) {
    d = Number(d);

    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    return ('0' + h).slice(-2) + ":" + ('0' + m).slice(-2) + ":" + ('0' + s).slice(-2);
}

export function timestampToDuration(t) {
    try {
        var timeArr = t.split(":");
        var h = timeArr[0] * 3600;
        var m = timeArr[1] * 60;
        var s = timeArr[2] * 1;
        return h + m + s;    
        
    } catch (error) {
        return null;
    }
}