import React from "react";
import PropTypes from "prop-types";
import ApiError from "../../../../../components/ApiError";
import Results from "./Results";
import { Link } from "react-router";
import Loading from "../../../../../components/Loading";
import Button from "../../../../../components/Button";
import PageHeader from "../../../../../components/PageHeader";
import PlusIcon from "../../../../../components/Icons/Plus";

function List({ loading, error, ids }) {
	return (
		<div className="container">
			<PageHeader
				title="Lexicon Sessions"
				subTitle="Analyzed texts to detect out-of-vocabulary words."
				extra={[
					<Link to="/lexicon/sessions/create">
						<Button type="primary" size="large" icon={PlusIcon}>
							New session
						</Button>
					</Link>,
				]}
			/>

			{loading && <Loading />}
			{error && <ApiError error={error} />}
			{!loading && ids && <Results ids={ids} />}
		</div>
	);
}

List.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	ids: PropTypes.array,
};

export default List;
