import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/Login";
import { login } from "../../modules/auth";
import { browserHistory } from "react-router";

export class LoginContainer extends Component {
	componentDidMount() {
		if (this.props.user !== null) {
			browserHistory.push("/");
		}
	}

	componentDidUpdate() {
		if (this.props.user !== null) {
			browserHistory.push("/");
		}
	}

	render() {
		return <Login {...this.props} />;
	}
}

const mapStateToProps = state => ({
	loading: state.auth.loginLoading,
	error: state.auth.loginError,
	user: state.auth.user
});

const mapDispatchToProps = { login };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LoginContainer);
