import React from "react";
import { PropTypes } from "prop-types";
import Form from "./Form";
import logo from "../../../../assets/logo-icon.png";

export function Login({ login, loading, error }) {
	return (
		<div className="flex h-screen justify-center items-center">
			<div className="sm:w-3/12">
				<div className="flex justify-center mb-6">
					<img src={logo} alt="DeepZen Logo" className="h-24" />
				</div>

				<div className="p-7 shadow-md rounded-md bg-white">
					<Form loading={loading} apiError={error} onSubmit={login} />
				</div>
			</div>
		</div>
	);
}

Login.propTypes = {
	login: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
};

export default Login;
