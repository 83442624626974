import axios from "axios";

export const INSERT_SENTENCE = "INSERT_SENTENCE";
export const INSERT_SENTENCE_SUCCESS = "INSERT_SENTENCE_SUCCESS";
export const INSERT_SENTENCE_FAIL = "INSERT_SENTENCE_FAIL";
export const INSERT_SENTENCE_TOGGLE = "INSERT_SENTENCE_TOGGLE";

const insertSentenceSuccess = (data) => ({ type: INSERT_SENTENCE_SUCCESS });
const insertSentenceFail = (e) => ({ type: INSERT_SENTENCE_FAIL, payload: e });

export function insert(sentenceId, oldText, newText) {
	return (dispatch) => {
		dispatch({ type: INSERT_SENTENCE, payload: sentenceId });

		axios
			.post(`sentences/${sentenceId}/insert/`, {
				old_text: oldText,
				new_text: newText,
			})
			.then((a) => dispatch(insertSentenceSuccess()))
			.catch((e) => dispatch(insertSentenceFail(e)));
	};
}

export const toggle = () => ({ type: INSERT_SENTENCE_TOGGLE });

const initialState = {
	show: false,
	loading: false,
	error: null,
};

export default function insertSentenceReducer(state = initialState, action) {
	switch (action.type) {
		case INSERT_SENTENCE:
			return { ...state, loading: true };
		case INSERT_SENTENCE_SUCCESS:
			return { ...state, show: false, loading: false };
		case INSERT_SENTENCE_FAIL:
			return { ...state, loading: false };
		case INSERT_SENTENCE_TOGGLE:
			return { ...state, show: !state.show };
		default:
			return state;
	}
}
