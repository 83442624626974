import React from "react";
import SentenceSummaryContainer from "../../containers/MultiSentence/SentenceSummaryContainer";
import ResynthesizeContainer from "../../containers/MultiSentence/ResynthesizeContainer";

export function MultiSentenceDetails({ ids }) {
	return (
		<div className="sentence-details sentence-details--multi">
			<div className="sentence-details__title">[{ ids.length }] sentence being edited</div>
			{ids.map(id => <SentenceSummaryContainer key={id} id={id} />)}

			<ResynthesizeContainer />
		</div>
	);
}

export default MultiSentenceDetails;