import { combineReducers } from "redux";
import locationReducer from "./location";
import clustersReducer from "../routes/Clusters/modules/clusters";
import editorReducer from "../routes/Editor/modules/editor";
import nlpReducer from "../routes/Editor/modules/nlp";
import authReducer from "../routes/Auth/modules/auth";
import sentenceDetailsReducer from "../routes/Editor/modules/sentenceDetails";
import queueReducer from "../routes/Editor/modules/queue";
import cloneReducer from "../routes/Editor/modules/clone";
import reloadReducer from "../routes/Editor/modules/reload";
import insertSentenceReducer from "../routes/Editor/modules/insertSentence";
import synthesizeReducer from "../routes/Editor/modules/synthesize";
import searchReducer from "../routes/Clusters/Detail/Search/modules/search";
import assignmentReducer from "../modules/assignment";
import lexiconReducer from "../routes/Lexicon/modules/lexicon";
import epubEditorReducer from "../routes/Clusters/EpubEditor/modules/epubEditor";
import timeTrackerEditorReducer from "../modules/timeTrackerEditor";
import timeTrackerLexiconReducer from "../modules/timeTrackerLexicon";
import apiStatsReducer from "../routes/ApiStats/modules/apiStats";
import { reducer as formReducer } from "redux-form";

export const makeRootReducer = (asyncReducers) => {
	return combineReducers({
		location: locationReducer,
		auth: authReducer,
		clusters: clustersReducer,
		editor: editorReducer,
		nlp: nlpReducer,
		sentenceDetails: sentenceDetailsReducer,
		form: formReducer,
		queue: queueReducer,
		search: searchReducer,
		clone: cloneReducer,
		reload: reloadReducer,
		insertSentence: insertSentenceReducer,
		synthesize: synthesizeReducer,
		assignment: assignmentReducer,
		lexicon: lexiconReducer,
		timeTrackerEditor: timeTrackerEditorReducer,
		timeTrackerLexicon: timeTrackerLexiconReducer,
		epubEditor: epubEditorReducer,
		apiStats: apiStatsReducer,
		...asyncReducers,
	});
};

export const injectReducer = (store, { key, reducer }) => {
	if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

	store.asyncReducers[key] = reducer;
	store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
