import React from "react";
import { reduxForm, Field, FieldArray, formValueSelector } from "redux-form";
import ApiError from "../../../../components/ApiError";
import { TextField, SelectField } from "redux-form-antd";
import Button from "../../../../components/Button";
import TranscriptionInput from "../../../../components/TranscriptionInput";
import { useSelector } from "react-redux";
import { languageFromLexiconName } from "../../../../services/lexicons";

export const validateLexiconEntry = (value) => {
	if (value === undefined) return undefined;

	const openParan = value.match(/\(/g);
	const closeParan = value.match(/\)/g);
	const openParanCount = openParan === null ? 0 : openParan.length;
	const closeParanCount = closeParan === null ? 0 : closeParan.length;

	if (openParanCount !== closeParanCount) {
		return "Parentheses count does not match.";
	}

	if (openParanCount > 2) {
		return "Old formatting is not allowed.";
	}
};

const RenderField = ({ options, type, fields, meta, hasUserFullEditingAccess }) => {
	const formValues = useSelector(
		(state) => state.form[meta.form]?.values?.values
	);

	const isFormDisabled = (index) => !hasUserFullEditingAccess && formValues?.[index]?.lexicon_id.indexOf("main") > -1; 
	
	return (
		<div>
			{fields.map((field, index) => (
				<div className="flex space-x-4" key={index}>
					<div
						className="w-1/4"
						style={{
							display: type === "create" ? "block" : "none",
						}}
					>
						<Field
							name={`${field}.token`}
							component={TextField}
							placeholder="Token"
							disabled={isFormDisabled(index)}
						/>
					</div>
					<div className={type === "create" ? "w-1/2" : "w-3/4"}>
						<Field
							name={`${field}.value`}
							component={TranscriptionInput}
							style={{ fontFamily: "monospace" }}
							placeholder="Lexicon value... (optional)"
							validate={validateLexiconEntry}
							language={languageFromLexiconName(
								formValues?.[index]?.lexicon_id ?? ""
							)}
							isFormDisabled={isFormDisabled(index)}
						/>
					</div>
					<div className="w-1/4">
						<Field
							name={`${field}.lexicon_id`}
							options={options}
							component={SelectField}
							validate={null}
							disabled={isFormDisabled(index)}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

export class Form extends React.PureComponent {
	render() {
		const { type, loading, handleSubmit, apiError, lexicons, hasUserFullEditingAccess } = this.props;

		const options = lexicons
			? lexicons.map((lexicon) => ({
					label: lexicon.id,
					value: lexicon.id,
					disabled: (!hasUserFullEditingAccess && lexicon.id.indexOf("main") > -1)
			  }))
			: [];

		return (
			<form className="lexicon-edit" onSubmit={handleSubmit}>
				{apiError && <ApiError error={apiError} />}

				<FieldArray
					name="values"
					component={RenderField}
					type={type}
					options={options}
					hasUserFullEditingAccess={hasUserFullEditingAccess}
				/>

				<Button
					block
					size="large"
					type="primary"
					htmlType="submit"
					loading={loading}
					className="mt-2"
				>
					Save & Deploy
				</Button>
			</form>
		);
	}
}

export default reduxForm({
	form: "lexicon-edit",
	enableReinitialize: true,
})(Form);
