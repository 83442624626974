import React, { useState } from "react";
import Transition from "./Transition.js";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

function Dropdown({ children, content, wrapperClassName, width }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div
			className={classNames(
				"relative inline-block z-10",
				wrapperClassName
			)}
		>
			<div
				style={{ pointerEvents: isOpen ? "none" : "auto" }}
				onClick={() => setIsOpen(true)}
			>
				{children}
			</div>

			<Transition
				show={isOpen}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
					<div
						class={classNames(
							"absolute right-0 mt-2 rounded-md shadow-lg",
						)}
						style={width ? {width: 4 * width}: {width: 4 * 48}}
					>
						{content}
					</div>
				</OutsideClickHandler>
			</Transition>
		</div>
	);
}

export default Dropdown;
