import React from "react";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../../components/ApiError";
import TextField from "redux-form-antd/lib/components/TextField";
import { required } from "../../../../../services/validations";
import { Button, Input, Select } from "antd";
import SelectField from "redux-form-antd/lib/components/SelectField";

const Form = ({ handleSubmit, models, loading, apiError }) => (
	<form onSubmit={handleSubmit}>
		{apiError && <ApiError error={apiError} />}

		<div className="row">
			<div className="col-5">
				<Field
					name="model"
					component={SelectField}
					options={
						models
							? [
									{ label: "-- Model --", value: "" },
									...models.map(m => ({ label: m, value: m }))
							  ]
							: []
					}
					validate={[required]}
				/>
			</div>
			<div className="col-5">
				<Field
					component={TextField}
					name="word"
					placeholder="Predict"
					validate={[required]}
				/>
			</div>
			<div className="col-2" style={{ paddingTop: 3 }}>
				<Button
					block
					htmlType="submit"
					icon="search"
					type="primary"
					loading={loading}
				/>
			</div>
		</div>
	</form>
);

export default reduxForm({
	form: "lexicon-g2p-prediction"
})(Form);
