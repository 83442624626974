import React, { Component } from "react";
import { Spin, Collapse, Alert, Modal } from "antd";
import WordResults from "./WordResults";
import EditContainer from "../../../Edit/containers/EditContainer";
import SynthesizeForm from "./SynthesizeForm";
import SessionEditContainer from "../../Edit/containers/EditContainer";
import MissingWordContainer from "../../../MissingWord/containers/MissingWordContainer";
import PageHeader from "../../../../../components/PageHeader";
import Dropdown from "../../../../../components/Dropdown";
import DropdownMenu, {
	DropdownMenuItem,
} from "../../../../../components/DropdownMenu";
import Card from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import Badge from "../../../../../components/Badge";
import DotsVertical from "../../../../../components/Icons/DotsVertical";
import Adjustments from "../../../../../components/Icons/Adjustments";
import columns from "./columns";
import ColumnSelection from "./ColumnSelection";
import PortalProjectSummary from "./PortalProjectSummary";
import axios from "axios";
import { languageFromLexiconName } from "../../../../../services/lexicons";
import { isColumnActive } from "../modules/detail";
import TimeTrackerLexiconContainer from "../../../../../containers/TimeTrackerLexiconContainer";
import WorkLogLexiconSessionForm from "./WorkLogLexiconSessionForm";

const persistKey = "LEXICON_SESSION_SELECTED_COLUMNS";

class Detail extends Component {
	state = {
		showCreate: null,
		showEdit: null,
		showSynthesize: false,
		showReanalyze: false,
		showWorkLogDownload: false,
		portalProject: null,
		selectedColumns: localStorage.getItem(persistKey)
			? JSON.parse(localStorage.getItem(persistKey))
			: Object.keys(columns),
		playingAudioId: null,
	};

	componentDidMount() {
		if (this.props.session) {
			this.loadPortalProject();
		}
	}

	componentDidUpdate(oldProps) {
		if (this.props.session !== oldProps.session) {
			this.loadPortalProject();
		}
	}

	loadPortalProject() {
		this.setState({ portalProject: null });

		axios
			.get(`lexicon/sessions/${this.props.session.id}/portal/`)
			.then((res) => this.setState({ portalProject: res.data }))
			.catch(() => null);
	}

	render() {
		const { session, synthesize, reanalyze, archive, hasUserFullEditingAccess } = this.props;
		const {
			showEdit,
			showSynthesize,
			showReanalyze,
			showCreate,
			showWorkLogDownload,
			selectedColumns,
			portalProject,
			playingAudioId,
		} = this.state;

		if (!session) return <Spin />;
		
		const language = languageFromLexiconName(
			JSON.parse(session.lexicons)[0]
		);

		const filteredSelectedColumn = selectedColumns.filter((sc) => isColumnActive(language, sc));

		const extraMenu = (
			<DropdownMenu>
				<DropdownMenuItem onClick={() => archive(session.id)}>
					Archive
				</DropdownMenuItem>
				{hasUserFullEditingAccess && (
					<DropdownMenuItem onClick={() => this.setState({ showWorkLogDownload: true })}>
						Export work logs
					</DropdownMenuItem>
				)}
			</DropdownMenu>
		);

		const columnPreferences = (
			<Dropdown
				content={
					<ColumnSelection
						selectedColumns={filteredSelectedColumn}
						language={language}
						onChange={(selectedColumns) => {
							localStorage.setItem(
								persistKey,
								JSON.stringify(selectedColumns)
							);
							this.setState({ selectedColumns });
						}}
					/>
				}
			>
				<Button icon={Adjustments} className="-mt-1" />
			</Dropdown>
		);

		const onPlayAudio = (id) => {
			if (id !== playingAudioId) {
				let audioEl = document.getElementById(playingAudioId);
				if (audioEl) {
					audioEl.pause();
				}
			}
			this.setState({ playingAudioId: id });
		};

		return (
			<div>
				{showSynthesize && (
					<Modal
						footer={null}
						bodyStyle={{ padding: 0 }}
						visible
						onCancel={() =>
							this.setState({ showSynthesize: false })
						}
					>
						<SynthesizeForm
							onSubmit={(values) => {
								synthesize(session.id, values);
								this.setState({ showSynthesize: false });
							}}
						/>
					</Modal>
				)}

				{showWorkLogDownload && (
					<Modal
						footer={null}
						bodyStyle={{ padding: 0 }}
						visible
						onCancel={() =>
							this.setState({ showWorkLogDownload: false })
						}
					>
						<WorkLogLexiconSessionForm 
							id={session.id} 
							onClose={() => this.setState({ showWorkLogDownload: false })} 
						/>
					</Modal>
				)}

				{showReanalyze && (
					<Modal
						footer={null}
						width={"70%"}
						bodyStyle={{ padding: 0 }}
						visible
						onCancel={() => this.setState({ showReanalyze: false })}
					>
						<SessionEditContainer
							session={{
								...session,
								lexicons: JSON.parse(session.lexicons),
							}}
							onSubmit={(values) => {
								reanalyze(session.id, values);
								this.setState({ showReanalyze: false });
							}}
						/>
					</Modal>
				)}

				{showCreate !== null && (
					<MissingWordContainer
						language={language}
						onCancel={() => this.setState({ showCreate: null })}
						defaultOption={showCreate.defaultOption}
						{...showCreate.word}
					/>
				)}

				{showEdit !== null && (
					<Modal
						width={"70%"}
						footer={null}
						bodyStyle={{ padding: 0 }}
						visible
						onCancel={() => this.setState({ showEdit: null })}
					>
						<EditContainer token={showEdit} />
					</Modal>
				)}

				<PageHeader
					title={session.title}
					subTitle={[
						session.deleted_at && (
							<Badge key="archived" color="red">
								Archived
							</Badge>
						),
					].concat(
						JSON.parse(session.lexicons).map((lex) => (
							<Badge key={lex} className="mr-1">
								{lex}
							</Badge>
						))
					)}
					extra={[
						<Button
							key="scratch"
							className="mr-2"
							onClick={() =>
								this.setState({ showReanalyze: true })
							}
						>
							Start from scratch
						</Button>,
						<Button
							key="synthesize"
							className="mr-2"
							loading={session.synthesizing}
							onClick={() =>
								this.setState({
									showSynthesize: !showSynthesize,
								})
							}
						>
							Synthesize
						</Button>,
						<Dropdown key="extra" content={extraMenu}>
							<Button icon={DotsVertical} />
						</Dropdown>,
					]}
				/>

				{session && session.missing_words && portalProject && (
					<PortalProjectSummary
						session={session}
						data={portalProject}
						onStatusChange={() => this.loadPortalProject()}
					/>
				)}

				<Card>
					<Collapse>
						<Collapse.Panel header="Text" key="1">
							<p className="whitespace-pre-wrap">
								{session.text}
							</p>
						</Collapse.Panel>
					</Collapse>
				</Card>

				<br />

				{session.error && (
					<Alert type="error" message={session.error} />
				)}
				{!session.error && session.analyzing && (
					<div>
						<Spin /> Lexicon analysis is still running
					</div>
				)}

				{!session.error && !session.analyzing && (
					<div>
						<Card
							title="Missing Words"
							headerActions={columnPreferences}
						>
							<div className="overflow-x-auto">
								{session.missing_words === undefined && (
									<Spin />
								)}
								{session.missing_words && (
									<WordResults
										portalProject={
											portalProject &&
											(portalProject.status ===
												"editing_sample" ||
												portalProject.status ===
													"editing_book") &&
											portalProject
										}
										selectedColumns={filteredSelectedColumn}
										sessionId={session.id}
										language={language}
										editText="Add"
										onEdit={(word, defaultOption) =>
											this.setState({
												showCreate: {
													word,
													defaultOption,
												},
											})
										}
										data={session.missing_words}
										playingAudioId={playingAudioId}
										onPlayAudio={onPlayAudio}
									/>
								)}
							</div>
						</Card>

						<br />

						<Card
							title="Resolved Words"
							headerActions={columnPreferences}
						>
							<div className="overflow-x-auto">
								{session.resolved_words === undefined && (
									<Spin />
								)}
								{session.resolved_words && (
									<WordResults
										selectedColumns={filteredSelectedColumn}
										sessionId={session.id}
										language={language}
										onEdit={(word) =>
											this.setState({
												showEdit: word.token,
											})
										}
										data={session.resolved_words}
										playingAudioId={playingAudioId}
										onPlayAudio={onPlayAudio}
									/>
								)}
							</div>
						</Card>
					</div>
				)}

				<TimeTrackerLexiconContainer lexiconSessionId={session.id} />

			</div>
		);
	}
}

export default Detail;
