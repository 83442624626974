import React from "react";
import Form from "./Form";

export function InsertSentence({
	sentenceId,
	insert,
	loading,
	initialValues,
	toggle,
}) {
	return (
		<Form
			initialValues={initialValues}
			onSubmit={(values) =>
				insert(sentenceId, values.old_text, values.new_text)
			}
			onCancel={toggle}
			loading={loading}
		/>
	);
}

export default InsertSentence;
