import React from "react";
import Form from "./Form";

export function Reload({ bookId, reload, loading, initialValues, toggle }) {
	return (
		<Form
			initialValues={initialValues}
			onSubmit={(values) => reload(bookId, values)}
			onCancel={toggle}
			loading={loading}
		/>
	);
}

export default Reload;
