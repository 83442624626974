import React from "react";
import Badge from "./Badge";

export const statusLabels = {
	inprogress: "In progress",
	pending: "Pending",
	review: "In review",
	ready: "Ready",
};

const statusColors = {
	inprogress: "blue",
	pending: "red",
	review: "orange",
	ready: "green",
};

const ClusterStatusBadge = ({ status, size = "large" }) => (
	<Badge color={statusColors[status]} size={size}>
		{statusLabels[status]}
	</Badge>
);

export default ClusterStatusBadge;
