import React from "react";
import classNames from "classnames";

const Base =
	(Comp, svgProps = {}) =>
	({ size, className }) =>
		(
			<svg
				className={classNames(`inline`, className)}
				fill="currentColor"
				viewBox="0 0 20 20"
				{...svgProps}
				style={size ? {width: 4 * size, height: 4 * size} : {}}
			>
				<Comp />
			</svg>
		);

export default Base;
