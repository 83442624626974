import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import classNames from "classnames";

const DndBox = ({id, index, className, moveCard, type, children}) => {
    const ref = useRef(null);

    const [{ isDragging }, drag] = useDrag({
        type: type,
        item: {
            id: id,
            index: index
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    });

    const [{ handlerId }, drop] = useDrop({
        accept: type,
        collect: monitor => ({
            handlerId: monitor.getHandlerId()
        }),
        hover(item, monitor) {
            if (!ref.current) return;
            if (!monitor.isOver({ shallow: true })) return;
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;
            
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });

    drag(drop(ref));
   
    const opacity = isDragging ? 0 : 1;

    switch (type) {
       case "tableRow":
           return (
                <tr className={classNames("group", className)} ref={ref} style={{opacity}}>
                    {children}
                </tr>   
           );
           
        case "card":
            return (
                <div className={classNames("", className)} ref={ref} style={{opacity}}>
                    {children}
                </div>
            );
   
       default:
            return (
                <div className={classNames("", className)} ref={ref} style={{opacity}}>
                    {children}
                </div>
            );
    }

}

export default DndBox;
