import Layout from "../components/Layout";
import EditorRoute from "./Editor";
import AuthRoute from "./Auth";
import DashboardRoute from "./Dashboard";
import LexiconRoute from "./Lexicon";
import ApiStatsRoute from "./ApiStats";
import Epub from "./Epub";
import PortalPostProcess from "./PortalPostProcess";
import PasswordResetRoute from "./PasswordReset";
import Maintenance from "./Maintenance";

export const createRoutes = (store) => ({
	path: "/",
	component: Layout,
	childRoutes: [
		EditorRoute,
		AuthRoute,
		LexiconRoute,
		DashboardRoute,
		ApiStatsRoute,
		{
			path: "epub",
			component: Epub,
		},
		{
			path: "portal-post-processing/:id",
			component: PortalPostProcess,
		},
		PasswordResetRoute,
		Maintenance,
	],
});

export default createRoutes;
