import React from "react";
import DropdownMenu, {
	DropdownMenuItem,
} from "../../../../../components/DropdownMenu";
import columns from "./columns";
import { isColumnActive } from "../modules/detail";

const ColumnSelection = ({ selectedColumns, language, onChange }) => (
	<DropdownMenu>
		{Object.keys(columns).filter((c) => isColumnActive(language, c)).map((col) => {
			const checked = selectedColumns.indexOf(col) > -1;

			return (
				<DropdownMenuItem
					onClick={() =>
						onChange(
							(checked
								? selectedColumns.filter((c) => c !== col)
								: [...selectedColumns, col]
							).sort()
						)
					}
				>
					<input
						type="checkbox"
						className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
						checked={checked}
					/>
					{columns[col]}
				</DropdownMenuItem>
			);
		})}
	</DropdownMenu>
);

export default ColumnSelection;
