import axios from "axios";

export const DELETE_SENTENCE = "DELETE_SENTENCE";
export const DELETE_SENTENCE_SUCCESS = "DELETE_SENTENCE_SUCCESS";
export const DELETE_SENTENCE_FAIL = "DELETE_SENTENCE_FAIL";

const deleteSentenceSuccess = () => ({ type: DELETE_SENTENCE_SUCCESS });
const deleteSentenceFail = () => ({ type: DELETE_SENTENCE_FAIL });

export function deleteSentence(sentence) {
	return dispatch => {
		dispatch({ type: DELETE_SENTENCE, payload: sentence.id });

		axios
			.post(`sentences/${sentence.id}/delete/`)
			.then(a => dispatch(deleteSentenceSuccess()))
			.catch(e => dispatch(deleteSentenceFail(e)));
	};
}
