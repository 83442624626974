import React from "react";
import Form from "./Form";

export function Clone({
	bookId,
	clone,
	loading,
	initialValues,
	toggle,
	hasUserFullEditingAccess,
}) {
	return (
		<Form
			initialValues={initialValues}
			onSubmit={(values) => clone(bookId, values)}
			onCancel={toggle}
			loading={loading}
			hasUserFullEditingAccess={hasUserFullEditingAccess}
		/>
	);
}

export default Clone;
