import React, { Component } from "react";
import { logout } from "../modules/auth";
import { browserHistory } from "react-router";
import { connect } from "react-redux";

export class Logout extends Component {
	componentDidMount() {
		if (this.props.verified) {
			this.logout();
		}
	}

	componentDidUpdate(oldProps) {
		if (this.props.verified && !oldProps.verified) {
			this.logout();
		}
	}

	logout() {
		this.props.logout();
		browserHistory.push("/auth/login");
	}

	render() {
		return null;
	}
}

const mapStateToProps = (state) => ({
	verified: state.auth.verified,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
