import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { 
	load, 
	asyncSentenceUpdated, 
	asyncReloadBook, 
} from "../modules/editor";
import { addedJob, updatedJob, queueCountSelector } from "../modules/queue";
import { noteCreated, noteDeleted } from "../modules/notes";
import Editor from "../components/Editor";
import { withRouter } from "react-router";

export class EditorContainer extends PureComponent {
	static propTypes = {
		route: PropTypes.object.isRequired,
		router: PropTypes.object.isRequired,
		params: PropTypes.object.isRequired,
		load: PropTypes.func.isRequired,
		asyncReloadBook: PropTypes.func.isRequired,
		asyncSentenceUpdated: PropTypes.func.isRequired,
		addedJob: PropTypes.func.isRequired,
		updatedJob: PropTypes.func.isRequired,
		noteCreated: PropTypes.func.isRequired,
		noteDeleted: PropTypes.func.isRequired,
		queueCount: PropTypes.number.isRequired,
	};

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const selectedIssueTimestamp = urlParams.get("issue_timestamp");
		localStorage.setItem("selectedIssueTimestamp", selectedIssueTimestamp);

		// Prompt for navigation
		this.props.router.setRouteLeaveHook(
			this.props.route,
			(nextLocation) => {
				if (nextLocation.pathname === "/") {
					return "Are you sure?";
				}
			}
		);

		this.prepareForBook();
	}

	prepareForBook() {
		// Pusher
		const channel = window.pusher.subscribe(`book-${this.props.params.id}`);
		channel.bind("sentence-created", this.props.asyncReloadBook);
		channel.bind("sentence-deleted", this.props.asyncReloadBook);
		channel.bind("sentence-updated", this.props.asyncSentenceUpdated);
		channel.bind("queue-created", this.props.addedJob);
		channel.bind("queue-updated", this.props.updatedJob);
		channel.bind("note-created", this.props.noteCreated);
		channel.bind("note-deleted", this.props.noteDeleted);

		this.props.load(this.props.params.id);
	}

	cleanupAfterBook(id) {
		window.pusher.unsubscribe(`book-${id}`);
	}

	componentWillUnmount() {
		this.cleanupAfterBook(this.props.params.id);
	}

	componentDidUpdate(oldProps) {
		if (this.props.params.id !== oldProps.params.id) {
			this.cleanupAfterBook(oldProps.params.id);
			this.prepareForBook();
		}
	}

	render() {
		return <Editor {...this.props} />;
	}
}

const mapStateToProps = (state) => {
	return {
		book: state.editor.book,
		cluster: state.editor.cluster,
		paragraphs: state.editor.paragraphs,
		loading: state.editor.loading,
		error: state.editor.error,
		activeSentenceIds: state.editor.activeSentenceIds,
		noteIds: state.editor.noteIds,
		queueCount: queueCountSelector(state),
	}
};

const mapDispatchToProps = {
	load,
	asyncReloadBook,
	asyncSentenceUpdated,
	addedJob,
	updatedJob,
	noteCreated,
	noteDeleted,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EditorContainer));
