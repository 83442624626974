import axios from "axios";

export const SEARCH = "SEARCH";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";
export const SEARCH_RESET = "SEARCH_RESET";

const searchSuccess = data => ({ type: SEARCH_SUCCESS, payload: data });
const searchFail = e => ({ type: SEARCH_FAIL, payload: e });

export function search(token) {
	return dispatch => {
		dispatch({ type: SEARCH, payload: token });

		axios
			.get(`lexicon/search/?token=${encodeURIComponent(token)}`)
			.then(res => dispatch(searchSuccess(res.data)))
			.catch(e => dispatch(searchFail(e)));
	};
}

export const reset = () => ({ type: SEARCH_RESET });

const initialState = {
	token: "",
	data: null,
	error: null,
	loading: false
};

export default function searchReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_RESET:
			return initialState;
		case SEARCH:
			return { ...initialState, loading: true, token: action.payload };
		case SEARCH_SUCCESS:
			return { ...state, loading: false, data: action.payload };
		case SEARCH_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
}
