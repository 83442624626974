import axios from "axios";

export const G2P_LOAD_STATUS = "G2P_LOAD_STATUS";
export const G2P_LOAD_STATUS_SUCCESS = "G2P_LOAD_STATUS_SUCCESS";
export const G2P_LOAD_STATUS_FAIL = "G2P_LOAD_STATUS_FAIL";
export const G2P_LOAD_TRAINED_MODELS = "G2P_LOAD_TRAINED_MODELS";
export const G2P_LOAD_TRAINED_MODELS_SUCCESS =
	"G2P_LOAD_TRAINED_MODELS_SUCCESS";
export const G2P_LOAD_TRAINED_MODELS_FAIL = "G2P_LOAD_TRAINED_MODELS_FAIL";
export const G2P_START_TRAINING = "G2P_START_TRAINING";
export const G2P_START_TRAINING_SUCCESS = "G2P_START_TRAINING_SUCCESS";
export const G2P_START_TRAINING_FAIL = "G2P_START_TRAINING_FAIL";
export const G2P_PREDICT = "G2P_PREDICT";
export const G2P_PREDICT_SUCCESS = "G2P_PREDICT_SUCCESS";
export const G2P_PREDICT_FAIL = "G2P_PREDICT_FAIL";

const loadStatusSuccess = data => ({
	type: G2P_LOAD_STATUS_SUCCESS,
	payload: data
});

const loadStatusFail = e => ({ type: G2P_LOAD_STATUS_FAIL, payload: e });

export const loadStatus = (showLoadingIndicator = true) => dispatch => {
	dispatch({ type: G2P_LOAD_STATUS, payload: showLoadingIndicator });

	axios
		.get("lexicon/g2p/proxy/?endpoint=g2p_fetch_status")
		.then(res => dispatch(loadStatusSuccess(res.data.results)))
		.catch(e => dispatch(loadStatusFail(e)));
};

const loadTrainedModelsSuccess = data => ({
	type: G2P_LOAD_TRAINED_MODELS_SUCCESS,
	payload: data
});

const loadTrainedModelsFail = e => ({
	type: G2P_LOAD_TRAINED_MODELS_FAIL,
	payload: e
});

export const loadTrainedModels = () => dispatch => {
	dispatch({ type: G2P_LOAD_TRAINED_MODELS });

	axios
		.get("lexicon/g2p/proxy/?endpoint=g2p_list_models")
		.then(res => dispatch(loadTrainedModelsSuccess(res.data.results)))
		.catch(e => dispatch(loadTrainedModelsFail(e)));
};

const predictSuccess = data => ({
	type: G2P_PREDICT_SUCCESS,
	payload: data
});

const predictFail = e => ({
	type: G2P_PREDICT_FAIL,
	payload: e
});

export const predict = (word, model) => dispatch => {
	dispatch({ type: G2P_PREDICT });

	axios
		.get(
			`lexicon/g2p/proxy/?endpoint=g2p_predict&name=${model}&words=${word}`
		)
		.then(res => dispatch(predictSuccess(res.data.results)))
		.catch(e => dispatch(predictFail(e)));
};

const startTrainingSuccess = data => ({
	type: G2P_START_TRAINING_SUCCESS,
	payload: data
});

const startTrainingFail = e => ({
	type: G2P_START_TRAINING_FAIL,
	payload: e
});

export const startTraining = values => dispatch => {
	dispatch({ type: G2P_START_TRAINING });

	let data = new FormData();
	data.append("name", values.name);
	data.append("file", values.data);

	axios
		.post(`lexicon/g2p/proxy/`, data)
		.then(res => dispatch(startTrainingSuccess(res.data)))
		.catch(e => dispatch(startTrainingFail(e)));
};

const initialStatus = {
	data: null,
	loading: false,
	error: null
};

const initialTrainedModels = {
	data: null,
	loading: false,
	error: null
};

const initialTraining = {
	success: false,
	loading: false,
	error: null
};

const initialPrediction = {
	data: null,
	loading: false,
	error: null
};

const initialState = {
	status: initialStatus,
	trainedModels: initialTrainedModels,
	training: initialTraining,
	prediction: initialPrediction
};

export default function g2pReducer(state = initialState, action) {
	switch (action.type) {
		case G2P_LOAD_STATUS:
			return {
				...state,
				status: { ...state.status, loading: action.payload }
			};
		case G2P_LOAD_STATUS_SUCCESS:
			return {
				...state,
				status: { ...initialStatus, data: action.payload }
			};
		case G2P_LOAD_STATUS_FAIL:
			return {
				...state,
				status: { ...initialStatus, error: action.payload }
			};
		case G2P_LOAD_TRAINED_MODELS:
			return {
				...state,
				trainedModels: { ...initialTrainedModels, loading: true }
			};
		case G2P_LOAD_TRAINED_MODELS_SUCCESS:
			return {
				...state,
				trainedModels: { ...initialTrainedModels, data: action.payload }
			};
		case G2P_LOAD_TRAINED_MODELS_FAIL:
			return {
				...state,
				trainedModels: {
					...initialTrainedModels,
					error: action.payload
				}
			};
		case G2P_START_TRAINING:
			return {
				...state,
				training: { ...initialTraining, loading: true }
			};
		case G2P_START_TRAINING_SUCCESS:
			return {
				...state,
				training: { ...initialTraining, success: true }
			};
		case G2P_START_TRAINING_FAIL:
			return {
				...state,
				training: { ...initialTraining, error: action.payload }
			};
		case G2P_PREDICT:
			return {
				...state,
				prediction: { ...initialPrediction, loading: true }
			};
		case G2P_PREDICT_SUCCESS:
			return {
				...state,
				prediction: { ...initialPrediction, data: action.payload }
			};
		case G2P_PREDICT_FAIL:
			return {
				...state,
				prediction: { ...initialPrediction, error: action.payload }
			};
		default:
			return state;
	}
}
