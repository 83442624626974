import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ApiError from "../../../../components/ApiError";
import Card, { CardBody } from "../../../../components/Card";
import PortalProjectSummary from "../Overview/PortalProjectSummary";
import Form from "./Form";
import { browserHistory } from "react-router";

const PortalChapterGenerator = () => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(null);

	const cluster = useSelector((state) => state.clusters.detail.data);
	const project = useSelector((state) => state.clusters.detail.portalProject);

	if (!cluster || !project) return null;

	const handleSubmit = (values) => {
		setLoading(true);
		if(values.fixed_nlp_score){
			Object.keys(values.tts_nlp_type)
				.map((key) => {
					values.tts_nlp_type[key] = Number(values.tts_nlp_type[key]); 
				});
		}
		
		axios
			.post(`clusters/${cluster.id}/bulk/`, {
				...values, 
					tts_nlp_type: values.fixed_nlp_score 
						? `fixed:${JSON.stringify(values.tts_nlp_type)}` 
						: undefined })
			.then((res) => {
				message.success("Chapters are generated.");
				browserHistory.push(`/clusters/${cluster.id}`);
				setTimeout(() => window.location.reload(), 1000);
			})
			.catch((e) => {
				setLoading(false);
				setError(e);
			});
	};

	const defaultNlpTypes = {
		anger: 0.1, 
		disgust: 0, 
		fear: 0, 
		happy: 0.5, 
		love: 0.7, 
		sad: 0, 
		surprise: 0 
	};

	const initialValues = {
		tts_model: project.voice.tts_model,
		tts_voice: project.voice.tts_voice,
		tts_gst_factor: 0.4,
		text: project.text,
		tts_nlp_type: defaultNlpTypes
	};

	return (
		<div className="container">
			<div className="flex -mx-2.5">
				<div className="w-1/3 px-2.5">
					<PortalProjectSummary data={project} />
				</div>
				<div className="w-2/3 px-2.5">
					<Card title="Generate Chapters">
						<CardBody>
							{error && (
								<div className="mb-3">
									<ApiError error={error} />
								</div>
							)}

							<Form
								loading={loading}
								onSubmit={handleSubmit}
								defaultNlpTypes={defaultNlpTypes}
								initialValues={initialValues}
							/>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default PortalChapterGenerator;
