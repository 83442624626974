import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Popover, Select } from "antd";
import classNames from "classnames";
import Button from "./Button";
import { useDispatch } from "react-redux";
import { updateClusterStatus } from "../modules/statusUpdater";
import EditIcon  from "./Icons/Edit";

const statusTitles = {
	pending: "Pending",
	inprogress: "In progress",
	review: "In review",
	ready: "Ready",
};

const statusColors = {
	inprogress: "bg-blue-100 text-blue-800 border-none hover:bg-blue-100 hover:text-blue-800 active:bg-blue-100 active:text-blue-800",
	pending: "bg-red-100 text-red-800 border-none hover:bg-red-100 hover:text-red-800 active:bg-red-100 active:text-red-800",
	review: "bg-orange-100 text-orange-800 border-none hover:bg-orange-100 hover:text-orange-800 active:bg-orange-100 active:text-orange-800",
	ready: "bg-green-100 text-green-800 border-none hover:bg-green-100 hover:text-green-800 active:bg-green-100 active:text-green-800",
};

export function ClusterStatusUpdater({ cluster}) {

	const [popoverVisible, setPopoverVisible] = useState(false);
	const dispatch = useDispatch();

	const onStatusChange = (clusterId, newStatus) => {
		dispatch(updateClusterStatus(clusterId, newStatus));
    	setPopoverVisible(false);
  	};

	const content = (
		<div className="w-40">
			<Select
				style={{ width: "100%" }}
				value={cluster.status}
				onChange={(newStatus) =>
					onStatusChange(cluster.id, newStatus)
				}
			>
				{["pending", "inprogress", "ready"].map((status) => (
					<Select.Option key={status} value={status}>
						{statusTitles[status]}
					</Select.Option>
				))}
			</Select>
		</div>
	);

	return (
		<Popover
			placement="bottomRight" 
			trigger="click" 
			visible={popoverVisible}
			onVisibleChange={(e) => setPopoverVisible(e)}
			content={content}>
			<Button
				size="small"
				className={classNames(
					"rounded-full",
					statusColors[cluster.status],
				)}
			>
				{statusTitles[cluster.status]}
				<EditIcon size={3} className="ml-1"/>
				
			</Button>
		</Popover>
	);
}

ClusterStatusUpdater.propTypes = {
	cluster: PropTypes.object.isRequired,
};

export default ClusterStatusUpdater;
 