import axios from "axios";
import { message } from "antd";

export const CLONE = "CLONE";
export const CLONE_SUCCESS = "CLONE_SUCCESS";
export const CLONE_FAIL = "CLONE_FAIL";
export const CLONE_TOGGLE = "CLONE_TOGGLE";

const cloneSuccess = (data) => ({ type: CLONE_SUCCESS });
const cloneFail = (e) => ({ type: CLONE_FAIL, payload: e });

export function clone(bookId, values) {
	return (dispatch) => {
		dispatch({ type: CLONE, payload: bookId });

		axios
			.post(`books/${bookId}/clone/`, values)
			.then((res) => dispatch(cloneSuccess(res.data)))
			.catch((e) => {
				message.error("An error occured while duplicating the book.");
				dispatch(cloneFail(e));
			});
	};
}

export const toggle = () => ({ type: CLONE_TOGGLE });

const initialState = {
	open: false,
	loading: false,
};

export default function cloneReducer(state = initialState, action) {
	switch (action.type) {
		case CLONE:
			return { ...state, loading: true };
		case CLONE_SUCCESS:
			return { ...state, show: false, loading: false };
		case CLONE_FAIL:
			return { ...state, loading: false };
		case CLONE_TOGGLE:
			return { ...state, show: !state.show };
		default:
			return state;
	}
}
