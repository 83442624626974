import React, { useState } from "react";
import { Button, Alert } from "antd";
import Card from "../../../../../components/Card";
import Loading from "../../../../../components/Loading";
import ApiError from "../../../../../components/ApiError";
import Form from "./Form";
import BookResults from "./BookResults";
import ResynthesizeContainer from "../containers/ResynthesizeContainer";

export function Search({
	cluster,
	loading,
	search,
	results,
	resultsLoading,
	resultsError,
	checkedSentenceIds,
	toggleChecked,
	checkAll,
	uncheckAll,
}) {

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [playingAudioId, setPlayingAudioId] = useState(null);

	const onPlayAudio = (id) => {
    	if (id !== playingAudioId) {
      		let audioEl = document.getElementById("au-" + playingAudioId);
      		if (audioEl) {
        		audioEl.pause();
      		}
    	}
    	setPlayingAudioId(id);
  	};

	if (loading) return <Loading />;

	const onFormSubmit = (values) => {
		search(cluster.id, values.query);
		setFormSubmitted(true);
	}

	const form = (
		<Form
			onSubmit={(values) => onFormSubmit(values)}
			loading={resultsLoading}
		/>
	);

	return (
		<div className="search">
			<Card>
				<div className="bg-gray-50 p-5">{form}</div>

				{resultsError && <ApiError error={resultsError} />}
				{results.length > 0 && (
					<div className="pb-80">
						<div className="border-t border-gray-200 p-5">
							<Button.Group>
								<Button size="small" onClick={() => checkAll()}>
									Check all
								</Button>
								<Button
									size="small"
									onClick={() => uncheckAll()}
								>
									Uncheck all
								</Button>
							</Button.Group>
						</div>

						{results.map((result) => (
							<BookResults
								key={result.book.id}
								onToggleChecked={(id) => toggleChecked(id)}
								checkedSentenceIds={checkedSentenceIds}
								checkAll={() =>
									checkAll(result.sentences.map((s) => s.id))
								}
								uncheckAll={() =>
									uncheckAll(
										result.sentences.map((s) => s.id)
									)
								}
								onPlayAudio={onPlayAudio}
								{...result}
							/>
						))}
					</div>
				)}
				{!resultsLoading && !resultsError && formSubmitted && results.length === 0 && (
					<div className="api-error">
						<Alert
							message="Resource not found"
							type="info"
							showIcon
						/>
					</div>
				)}
			</Card>

			<ResynthesizeContainer />
		</div>
	);
}

export default Search;
