import React from "react";
import classNames from "classnames";

const StepTitle = ({ active, title, onClick }) => (
	<a
		onClick={onClick}
		className={classNames(
			"text-xl block",
			active ? "text-indigo-700" : "text-gray-700"
		)}
	>
		{title}
	</a>
);

export default StepTitle;
