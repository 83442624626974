import React from "react";

const StepChapter = ({ book, removedChapters, onUndo }) => (
	<>
		First we need you to choose which chapters will be included. Removing
		unnecessary chapters at once will make it easier. You can switch to any
		chapter using the preview on the left and remove if you want. Don't
		worry about images, they'll be removed completely.
		{/*existingLandmarks.length > 0 && (
			<div className="bg-blue-50 px-3 py-2 mt-3">
				<div className="font-semibold text-blue-800">Quick actions</div>

				{existingLandmarks
					.filter(({ href }) => removedXhtmls.indexOf(href) === -1)
					.map(({ href, label }) => (
						<a
							className="bg-white border rounded-full block mt-1 px-3"
							onClick={() =>
								setRemovedXhtmls([...removedXhtmls, href])
							}
							key={href}
						>
							Remove
							<span className="ml-1 bg-orange-100 text-xs italic px-1">
								{label}
							</span>
						</a>
					))}
			</div>
						)*/}
		{removedChapters.length > 0 && (
			<div className="bg-red-50 px-3 py-2 mt-3">
				<div className="font-semibold text-red-800">
					Chapters to be removed
				</div>

				{removedChapters.map((i) => (
					<div key={i} className="mt-1">
						{book.chapters[i].label}
						<a className="text-xs ml-2" onClick={() => onUndo(i)}>
							Undo
						</a>
					</div>
				))}
			</div>
		)}
	</>
);

export default StepChapter;
