import axios from "axios";
import { message } from "antd";

export const CLUSTER_STATUS_UPDATE = "CLUSTER_STATUS_UPDATE";
export const CLUSTER_STATUS_UPDATE_SUCCESS = "CLUSTER_STATUS_UPDATE_SUCCESS";
export const CLUSTER_STATUS_UPDATE_FAIL = "CLUSTER_STATUS_UPDATE_FAIL";
export const CLUSTER_DETAIL_UPDATE_SUCCESS = "CLUSTER_DETAIL_UPDATE_SUCCESS";

export const BOOK_DONE_UPDATE = "BOOK_DONE_UPDATE";
export const BOOK_DONE_UPDATE_SUCCESS = "BOOK_DONE_UPDATE_SUCCESS";
export const BOOK_DONE_UPDATE_FAIL = "BOOK_DONE_UPDATE_FAIL";

const updateClusterStatusSuccess = (data) => ({
	type: CLUSTER_STATUS_UPDATE_SUCCESS,
	payload: data,
});
const updateClusterStatusFail = (e) => ({
	type: CLUSTER_STATUS_UPDATE_FAIL,
	payload: e,
});
const updateClusterDetailSuccess = (data) => ({
	type: CLUSTER_DETAIL_UPDATE_SUCCESS,
	payload: data,
});


export function updateClusterStatus(clusterId, newStatus) {
	return (dispatch) => {
		dispatch({ type: CLUSTER_STATUS_UPDATE, payload: clusterId });

		axios
			.patch(`/clusters/${clusterId}/`, { status: newStatus })
			.then((res) => {
				dispatch(updateClusterStatusSuccess(res.data));
				dispatch(updateClusterDetailSuccess(res.data));
			})
			.catch((e) => {
				dispatch(updateClusterStatusFail(e));
				message.error("An error occured while updating status.");
			});
	};
}

const updateBookDoneSuccess = (field, value) => ({
	type: BOOK_DONE_UPDATE_SUCCESS,
	payload: { field, value },
});
const updateBookDoneFail = (e) => ({
	type: BOOK_DONE_UPDATE_FAIL,
	payload: e,
});

export function updateBookDone(bookId, field, value) {
	return (dispatch) => {
		dispatch({ type: BOOK_DONE_UPDATE, payload: bookId });

		axios
			.patch(`/books/${bookId}/`, { [field]: value })
			.then((res) =>
				dispatch(updateBookDoneSuccess(field, res.data[field]))
			)
			.catch((e) => {
				dispatch(updateBookDoneFail(e));
				message.error("An error occured while marking as done.");
			});
	};
}
