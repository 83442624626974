import React, { Component, useEffect, useState } from "react";
import { Modal, Input, Radio, Select, Spin, message, Icon, Alert } from "antd";
import axios from "axios";
import TextField from "../../../../components/Forms/TextField";
import CollectionIcon from "../../../../components/Icons/Collection";
import Button from "../../../../components/Button";
import TranscriptionInput, { getValidPosTagInfo } from "../../../../components/TranscriptionInput";
import { languageFromLexiconName } from "../../../../services/lexicons";
import { restrictedWords } from "../../Edit/modules/edit";
import LexiconAlert from "../../../../components/LexiconAlert";

const InitialEntry = ({
	title,
	value,
	onValueChange,
	onPredictionOptionChange,
	selected,
	language,
	isFormDisabled
}) => {
	
	const [validPosTagsInfo, setValidPosTagsInfo] = useState(null);

	useEffect(() => {
		setValidPosTagsInfo(
			getValidPosTagInfo(language)
		);
	}, [language]);

	return (
		<div className="missing-word-form__initial">
			{validPosTagsInfo && (
				<div className="flex pb-3 w-full">
					<Alert 
						className="w-full" 
						type="info" 
						message="Valid Pos Tags" 
						description={validPosTagsInfo} 
					/>
				</div>
			)}
			<div className="flex items-center">
				<div className="w-1/4">
					<label>
						<input
							type="radio"
							className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
							onChange={() => onPredictionOptionChange(title)}
							checked={selected}
						/>
						{title}:
					</label>
				</div>
				<div className="w-3/4">
					<TranscriptionInput
						language={language}
						input={{
							disabled: !selected,
							value,
							onChange: onValueChange,
						}}
						isFormDisabled={isFormDisabled}
					/>
				</div>
			</div>
		</div>
	);
};

class MissingWord extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOption: null,
			options: null,
			variantsLoading: false,
			variants: [],
			selectedLexicon: `addendum${
				props.language === "uk" ? "" : "-" + props.language
			}`.replace("_", "-"),
			isWordRestricted: false
		};
	}

	componentDidMount() {
		axios
			.get(
				`lexicon/predict/lisp/?missing_word_id=${this.props.id}&step=initial`
			)
			.then((res) => {
				const options =
					res.data.length > 0
						? res.data
						: {
								title: "No predictions",
								value: "",
						  };

				this.setState({
					selectedOption:
						this.props.defaultOption || options[0].title,
					options,
				});
			})
			.catch((e) => message.error("Could not load predictions."));

			const isWordRestricted = restrictedWords.find(w => w === this.props.token);
			this.setState({
				isWordRestricted: isWordRestricted
			});
	}

	handleValueChange = (title, value) => {
		this.setState({
			options: this.state.options.map((option) =>
				option.title === title ? { ...option, value } : option
			),
		});
	};

	handlePredictionOptionChange = (title) =>
		this.setState({ selectedOption: title });

	getSelectedValue = () => {
		const { options, selectedOption } = this.state;
		return options.filter((option) => option.title === selectedOption)[0]
			.value;
	};

	loadVariants = () => {
		this.setState({ variantsLoading: true });

		axios
			.get(
				`lexicon/predict/lisp/?missing_word_id=${
					this.props.id
				}&step=variants&value=${this.getSelectedValue()}`
			)
			.then((res) =>
				this.setState({
					variants: res.data.map((v) => ({
						token: v[0],
						value: `${v[1]} ${v[2]}`,
					})),
				})
			)
			.catch((e) => message.error("Could not load variants."))
			.finally(() => this.setState({ variantsLoading: false }));
	};

	handleVariantValueChange = (variant, value) => {
		this.setState({
			variants: this.state.variants.map((v) =>
				v.token === variant.token ? { ...v, value } : v
			),
		});
	};

	save = () => {
		const lexicon_id = this.state.selectedLexicon.replace("_", "-");
		const variantValues = this.state.variants.map((variant) => ({
			...variant,
			lexicon_id,
		}));
		const payload = {
			word: this.props.token,
			type: "create",
			values: [
				{
					lexicon_id,
					token: this.props.token,
					value: this.getSelectedValue(),
				},
				...variantValues,
			],
		};

		this.setState({ saving: true });
		axios
			.post("lexicon/update/", payload)
			.then((res) => {
				message.success("Updated lexicon successfully.");
				this.props.deploy([lexicon_id]);
				this.props.onCancel();

			})
			.catch((e) => {
				message.error(
					e.response.data?.error?.[0] ?? "An error occurred."
				);
				this.setState({ saving: false });
			});
	};

	removeVariant = (variant) =>
		this.setState({
			variants: this.state.variants.filter((v) => v !== variant),
		});

	handleLexiconChange = (value) => this.setState({ selectedLexicon: value });

	render() {
		const { options, selectedOption, variants, variantsLoading, saving } =
			this.state;
		const { onCancel, lexicons, hasUserFullEditingAccess, deployLoading } = this.props;
		
		return (
			<Modal
				title={`Missing word \`${this.props.token}\``}
				visible
				bodyStyle={{ padding: 0 }}
				className="missing-word-form"
				onCancel={onCancel}
				footer={(!this.state.isWordRestricted || hasUserFullEditingAccess) && ([
					<Select
						defaultValue={this.state.selectedLexicon}
						onChange={this.handleLexiconChange}
						key="lexicon"
					>
						{lexicons &&
							lexicons.map((lexicon) => (
								<Select.Option
									key={lexicon.id}
									value={lexicon.id}
									disabled={!hasUserFullEditingAccess && lexicon.id.indexOf("main") > -1}
								>
									{lexicon.id}
								</Select.Option>
							))}
					</Select>,
					<Button
						key="submit"
						type="primary"
						loading={saving || deployLoading}
						onClick={this.save}
					>
						Save & Deploy
					</Button>,
				])}
			>
				{this.state.isWordRestricted && !hasUserFullEditingAccess && (
					<LexiconAlert type="restrictedWord" />
				)}
				
				{options === null && <Spin size="small" />}
				{options && (!this.state.isWordRestricted || hasUserFullEditingAccess) && (
					<div>
						{options.map((option) => (
							<InitialEntry
								key={option.title}
								title={option.title}
								value={option.value}
								selected={option.title === selectedOption}
								language={languageFromLexiconName(
									this.state.selectedLexicon
								)}
								onPredictionOptionChange={
									this.handlePredictionOptionChange
								}
								onValueChange={(value) =>
									this.handleValueChange(option.title, value)
								}
								isFormDisabled={!hasUserFullEditingAccess && this.state.selectedLexicon.indexOf("main") > -1}
							/>
						))}

						<div className="missing-word-form__variants">
							<Button
								onClick={this.loadVariants}
								size="small"
								icon={CollectionIcon}
								className="m-2"
								loading={variantsLoading}
							>
								Generate variants
							</Button>

							{variants.map((variant, i) => (
								<div
									key={i}
									className="missing-word-form__variants__item"
								>
									<div className="flex items-center">
										<div className="w-1/4 font-medium">
											{variant.token}
										</div>
										<div className="flex-grow">
											<TextField
												input={{
													value: variant.value,
													onChange: (e) =>
														this.handleVariantValueChange(
															variant,
															e.target.value
														),
												}}
											/>
										</div>
										<div className="pl-2">
											<a
												onClick={() =>
													this.removeVariant(variant)
												}
											>
												<Icon type="close" />
											</a>
										</div>
									</div>
									{variant[0]}
								</div>
							))}
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

export default MissingWord;
