import React from "react";
import classNames from "classnames";

const PreviewNav = ({ onChange, activeIndex, items }) => {
	const handleChange = (e) => onChange(parseInt(e.target.value));
	const handlePrev = () => onChange(activeIndex - 1);
	const handleNext = () => onChange(activeIndex + 1);

	return (
		<>
			<div className="bg-cool-gray-900 flex p-2 shadow-md">
				<button
					disabled={activeIndex === 0}
					onClick={handlePrev}
					className={classNames(
						"bg-gray-300 rounded-md w-1/4 mr-2",
						activeIndex === 0 && "opacity-25"
					)}
				>
					Prev
				</button>
				<select
					value={activeIndex}
					onChange={handleChange}
					className="flex-grow block form-select transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				>
					{items.map(({ label, removed }, i) => (
						<option key={i} value={i}>
							{label} {removed > -1 && " (Deleted)"}
						</option>
					))}
				</select>
				<button
					disabled={activeIndex === items.length - 1}
					onClick={handleNext}
					className={classNames(
						"bg-gray-300 rounded-md w-1/4 ml-2",
						activeIndex === items.length - 1 && "opacity-25"
					)}
				>
					Next
				</button>
			</div>
		</>
	);
};

export default PreviewNav;
