import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import {
	loggedInUserSelector,
	setProtectRedirect,
} from "../routes/Auth/modules/auth";
import { PropTypes } from "prop-types";

export function authOnly(Comp) {
	class AuthOnly extends Component {
		constructor(props) {
			super(props);
			if (props.verified && !props.user) this.redirect();
		}

		componentDidUpdate() {
			if (this.props.verified && !this.props.user) this.redirect();
		}

		redirect() {
			this.props.setProtectRedirect(
				window.location.pathname.replace("/auth/login", "/")
			);
			browserHistory.push("/auth/login");
		}

		render() {
			if (!this.props.verified || !this.props.user) return null;

			return <Comp {...this.props} />;
		}
	}

	AuthOnly.propTypes = {
		verified: PropTypes.bool.isRequired,
		user: PropTypes.object,
		setProtectRedirect: PropTypes.func.isRequired,
	};

	const mapStateToProps = (state) => ({
		verified: state.auth.verified,
		user: loggedInUserSelector(state),
	});

	const mapDispatchToProps = { setProtectRedirect };

	return connect(mapStateToProps, mapDispatchToProps)(AuthOnly);
}

export default authOnly;
