import React from "react";
import Results from "./Results";
import Form from "./Form";

export function Search({ loading, error, data, search, token }) {
	return (
		<>
			<Form
				apiError={error}
				loading={loading}
				onSubmit={(values) => search(values.word.trim())}
			/>

			{data && <Results token={token} data={data} />}
		</>
	);
}

export default Search;
