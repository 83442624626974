export default  [
	{
		type: "consonant",
		ipa: "ɾ",
		deepzen: "r",
		combilex: "r",
		keywords: [{ text: "durum", highlight: [0, 1] }],
		sample: ["durum", 'd u r u m'],
	},
	{
		type: "consonant",
		ipa: "ɾ̝̊",
		deepzen: "R",
		combilex: "R",
		keywords: [{ text: "bir", highlight: [0, 1] }],
		sample: ["bir", 'b i r'],
	},
	{
		type: "consonant",
		ipa: "b",
		deepzen: "b",
		combilex: "b",
		keywords: [{ text: "bir", highlight: [0, 1] }],
		sample: ["bir", 'b i r'],
	},
	{
		type: "consonant",
		ipa: "c",
		deepzen: "c",
		combilex: "c",
		keywords: [{ text: "kedi", highlight: [0, 1] }],
		sample: ["kedi", 'c e d i'],
	},
	{
		type: "consonant",
		ipa: "d",
		deepzen: "d",
		combilex: "d",
		keywords: [{ text: "dede", highlight: [0, 1] }],
		sample: ["dede", 'd e d e'],
	},
	{
		type: "consonant",
		ipa: "d͡ʒ",
		deepzen: "dZ",
		combilex: "dZ",
		keywords: [{ text: "ricam", highlight: [0, 1] }],
		sample: ["ricam", 'r i dZ a m'],
	},
	{
		type: "consonant",
		ipa: "f",
		deepzen: "f",
		combilex: "f",
		keywords: [{ text: "fare", highlight: [0, 1] }],
		sample: ["fare", 'f a: r e'],
	},
	{
		type: "consonant",
		ipa: "g",
		deepzen: "g",
		combilex: "g",
		keywords: [{ text: "galibi", highlight: [0, 1] }],
		sample: ["galibi", 'g a: l i b i'],
	},
	{
		type: "consonant",
		ipa: "h",
		deepzen: "h",
		combilex: "h",
		keywords: [{ text: "hasta", highlight: [5, 7] }],
		sample: ["hasta", 'h a s t a'],
	},
	{
		type: "consonant",
		ipa: "j",
		deepzen: "j",
		combilex: "j",
		keywords: [{ text: "jat", highlight: [0, 1] }],
		sample: ["jat", 'j a t'],
	},
	{
		type: "consonant",
		ipa: "ɟ",
		deepzen: "gj",
		combilex: "gj",
		keywords: [{ text: "genç", highlight: [2, 3] }],
		sample: ["genç", 'gj e n tS'],
	},
	{
		type: "consonant",
		ipa: "k",
		deepzen: "k",
		combilex: "k",
		keywords: [{ text: "akıl", highlight: [0, 1] }],
		sample: ["akıl", 'a k I L'],
	},
	{
		type: "consonant",
		ipa: "l",
		deepzen: "l",
		combilex: "l",
		keywords: [{ text: "lale", highlight: [2, 3] }],
		sample: ["lale", 'l a: l e'],
	},
	{
		type: "consonant",
		ipa: "ɫ",
		deepzen: "L",
		combilex: "L",
		keywords: [{ text: "kulak", highlight: [0, 2] }],
		sample: ["kulak", 'k u L a k'],
	},
	{
		type: "consonant",
		ipa: "m",
		deepzen: "m",
		combilex: "m",
		keywords: [{ text: "maaş", highlight: [0, 1] }],
		sample: ["maaş", 'm a a S'],
	},
	{
		type: "consonant",
		ipa: "n",
		deepzen: "n",
		combilex: "n",
		keywords: [{ text: "anı", highlight: [0, 2] }],
		sample: ["anı", 'a: n i'],
	},
	{
		type: "consonant",
		ipa: "ŋ",
		deepzen: "N",
		combilex: "N",
		keywords: [{ text: "doping", highlight: [0, 2] }],
		sample: ["doping", 'd o p i N gj'],
	},
	{
		type: "consonant",
		ipa: "p",
		deepzen: "p",
		combilex: "p",
		keywords: [{ text: "ip", highlight: [0, 2] }],
		sample: ["ip", 'i p'],
	},
	{
		type: "consonant",
		ipa: "s",
		deepzen: "s",
		combilex: "s",
		keywords: [{ text: "ses", highlight: [0, 1] }],
		sample: ["ses", 's e s'],
	},
	{
		type: "consonant",
		ipa: "ʃ",
		deepzen: "S",
		combilex: "S",
		keywords: [{ text: "aşı", highlight: [4, 5] }],
		sample: ["aşı", 'a S I'],
	},
	{
		type: "consonant",
		ipa: "t",
		deepzen: "t",
		combilex: "t",
		keywords: [{ text: "ütü", highlight: [0, 1] }],
		sample: ["ütü", 'y t y'],
	},
	{
		type: "consonant",
		ipa: "t͡ʃ",
		deepzen: "tS",
		combilex: "tS",
		keywords: [{ text: "çaba", highlight: [0, 2] }],
		sample: ["çaba", 'tS a b a'],
	},
	{
		type: "consonant",
		ipa: "v",
		deepzen: "v",
		combilex: "v",
		keywords: [{ text: "ekvator", highlight: [0, 1] }],
		sample: ["ekvator", 'e c v a t o r'],
	},
	{
		type: "consonant",
		ipa: "z",
		deepzen: "z",
		combilex: "z",
		keywords: [{ text: "zarf", highlight: [0, 1] }],
		sample: ["zarf", 'z a r f'],
	},
	{
		type: "consonant",
		ipa: "ʒ",
		deepzen: "Z",
		combilex: "Z",
		keywords: [{ text: "jilet", highlight: [0, 1] }],
		sample: ["jilet", 'Z i l e t'],
	},
	{
		type: "consonant",
		ipa: "ɰ",
		deepzen: "G",
		combilex: "G",
		keywords: [{ text: "abanacağım", highlight: [0, 1] }],
		sample: ["abanacağım", 'a b a n a dZ a G I m'],
	},
	{
		type: "vowel",
		ipa: "ø",
		deepzen: "@/",
		combilex: "@/",
		keywords: [{ text: "göl", highlight: [1, 2] }],
		sample: ["göl", 'gj @/ l'],
	},
	{
		type: "vowel",
		ipa: "ø:",
		deepzen: "@/:",
		combilex: "@/:",
		keywords: [{ text: "öğretmen", highlight: [9, 10] }],
		sample: ["öğretmen", '@/: r e t m E n'],
	},
	{
		type: "vowel",
		ipa: "a",
		deepzen: "a",
		combilex: "a",
		keywords: [{ text: "kal", highlight: [1, 3] }],
		sample: ["kal", 'k a l'],
	},
	{
		type: "vowel",
		ipa: "a:",
		deepzen: "a:",
		combilex: "a:",
		keywords: [{ text: "davacı", highlight: [1, 2] }],
		sample: ["davacı", 'd a: v a: dZ I'],
	},
	{
		type: "vowel",
		ipa: "ɛ",
		deepzen: "E",
		combilex: "E",
		keywords: [{ text: "özlem", highlight: [1, 2] }],
		sample: ["özlem", '@/ z l E m'],
	},
	{
		type: "vowel",
		ipa: "e",
		deepzen: "e",
		combilex: "e",
		keywords: [{ text: "dede", highlight: [1, 2] }],
		sample: ["dede", 'd e d e'],
	},
	{
		type: "vowel",
		ipa: "e:",
		deepzen: "e:",
		combilex: "e:",
		keywords: [{ text: "eğlence", highlight: [1, 2] }],
		sample: ["eğlence", 'e j l E n dZ e'],
	},
	{
		type: "vowel",
		ipa: "i",
		deepzen: "i",
		combilex: "i",
		keywords: [{ text: "keçi", highlight: [1, 2] }],
		sample: ["keçi", 'c e tS i'],
	},
	{
		type: "vowel",
		ipa: "i:",
		deepzen: "i:",
		combilex: "i:",
		keywords: [{ text: "izah", highlight: [1, 2] }],
		sample: ["izah", 'i: z a h'],
	},
	{
		type: "vowel",
		ipa: "o",
		deepzen: "o",
		combilex: "o",
		keywords: [{ text: "kol", highlight: [1, 2] }],
		sample: ["kol", 'k o l'],
	},
	{
		type: "vowel",
		ipa: "o:",
		deepzen: "o:",
		combilex: "o:",
		keywords: [{ text: "yoğrulmak ", highlight: [1, 2] }],
		sample: ["yoğrulmak ", 'j o: r u L m a k'],
	},
	{
		type: "vowel",
		ipa: "ʊ",
		deepzen: "u",
		combilex: "u",
		keywords: [{ text: "dolu", highlight: [1, 2] }],
		sample: ["dolu", 'd o L u'],
	},
	{
		type: "vowel",
		ipa: "u",
		deepzen: "u",
		combilex: "u",
		keywords: [{ text: "durum", highlight: [1, 2] }],
		sample: ["durum", 'd u r u m'],
	},
	{
		type: "vowel",
		ipa: "u:",
		deepzen: "u:",
		combilex: "u:",
		keywords: [{ text: "ruhum", highlight: [1, 2] }],
		sample: ["ruhum", 'r u: h u m'],
	},
	{
		type: "vowel",
		ipa: "y",
		deepzen: "y",
		combilex: "y",
		keywords: [{ text: "güvenlik", highlight: [1, 2] }],
		sample: ["güvenlik", 'gj y v E n l i c'],
	},
	{
		type: "vowel",
		ipa: "y:",
		deepzen: "y:",
		combilex: "y:",
		keywords: [{ text: "üğrüm", highlight: [1, 2] }],
		sample: ["üğrüm", 'y: r y m'],
	},
	{
		type: "vowel",
		ipa: "ɯ",
		deepzen: "I",
		combilex: "I",
		keywords: [{ text: "aşı", highlight: [1, 2] }],
		sample: ["aşı", 'a S I'],
	},
	{
		type: "vowel",
		ipa: "ɯ:",
		deepzen: "I:",
		combilex: "I:",
		keywords: [{ text: "Iğdır", highlight: [1, 2] }],
		sample: ["Iğdır", 'I: d I r'],
	},
];