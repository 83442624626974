import React from "react";

const StepPages = ({ removedPages, removedXhtmls, setRemovedXhtmls }) => (
	<>
		If you want to remove a few pages here and there, you can do it now.
		Preview on the left has now all pages across the chapters that you
		didn't remove in the previous step. Don't worry about images, they'll be
		removed completely.
		{removedPages.length > 0 && (
			<div className="bg-red-50 px-3 py-2 mt-3">
				<div className="font-semibold text-red-800">
					Chapters to be removed
				</div>

				{removedPages.map((p) => (
					<div key={p.href} className="mt-1">
						{p.label}
						<a
							className="text-xs ml-2"
							onClick={() =>
								setRemovedXhtmls(
									removedXhtmls.filter((h) => h !== p.href)
								)
							}
						>
							Undo
						</a>
					</div>
				))}
			</div>
		)}
	</>
);

export default StepPages;
