import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
	construct,
	destruct,
	start,
	stop,
	tick,
	elapsedSelector
} from "../modules/timeTrackerLexicon";
import TimeTracker from "../components/TimeTracker";
import { secondsToHms } from "../services/time";

class TimeTrackerLexiconContainer extends PureComponent {
	timer = null;

	componentDidMount() {
		this.props.construct(this.props.lexiconSessionId);
	}

	componentDidUpdate(oldProps) {
		if (oldProps.activeLog === null && this.props.activeLog !== null) {
			this.tick();
		} else if (
			oldProps.activeLog !== null &&
			this.props.activeLog === null &&
			this.timer
		) {
			clearTimeout(this.timer);
		}
	}

	componentWillUnmount() {
		this.props.destruct();
		if (this.timer) clearTimeout(this.timer);
	}

	tick = () => {
		if (this.props.activeLog === null) return;
		this.props.tick(this.props.lexiconSessionId);
		this.timer = setTimeout(this.tick, 1000);
	};

	stop = () => {
		this.props.stop(this.props.lexiconSessionId);
	};

	start = () => {
		this.props.start(this.props.lexiconSessionId);
	};

	render() {
		return (
			<TimeTracker
				active={this.props.activeLog !== null}
				elapsed={this.props.elapsed}
				start={this.start}
				stop={this.stop}
                trackerButtonName={`Start working on this lexicon [${secondsToHms(
                    Math.floor(this.props.cumulativeElapsed)
                )}]`}
			/>
		);
	}
}

const mapStateToProps = state => ({
	...state.timeTrackerLexicon,
	elapsed: elapsedSelector(state)
});

const mapDispatchToProps = {
	construct,
	destruct,
	start,
	stop,
	tick
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimeTrackerLexiconContainer);
