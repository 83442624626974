import React from "react";
import { Field, reduxForm } from "redux-form";
import ApiError from "../../../../../components/ApiError";
import { TextField } from "redux-form-antd";
import { Button, Card } from "antd";
import { required } from "../../../../../services/validations";
import { useVoices } from "../../../../../services/voices";
import SelectField from "../../../../../components/Forms/SelectField";

function SynthesizeForm({ loading, handleSubmit, apiError }) {

	const voices = useVoices();

	return (
		<Card title="Synthesize missing words">
			<form onSubmit={handleSubmit}>
				{apiError && <ApiError error={apiError} />}

				<div className="row">
					<div className="col">
						<Field
							label="Model"
							component={TextField}
							name="tts_model"
							validate={[required]}
						/>
					</div>

					<div className="col">
						<Field
							label="Voice"
							name="tts_voice"
							component={SelectField}
							validate={required}
							disabled={voices === null}
							options={voices ?? []}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col">
						<Field
							label="NLP Type"
							component={TextField}
							name="nlp_type"
							validate={[required]}
						/>
					</div>
					<div className="col">
						<Field
							component={TextField}
							label="Smooth Factor"
							name="smooth_factor"
							validate={[required]}
						/>
					</div>
				</div>

				<br />
				<br />

				<Button
					size="large"
					type="primary"
					htmlType="submit"
					loading={loading}
				>
					Synthesize
				</Button>
			</form>
		</Card>
	);
}

export default reduxForm({
	form: "lexicon-analyze-synthesize",
	initialValues: {
		tts_model: "phoneme_rmaia_multi_v84",
		tts_voice: "laura",
		nlp_type: "deepzen_lexicon",
		smooth_factor: "0",
	},
})(SynthesizeForm);
