import React from "react";
import { Tooltip, Switch } from "antd";

export function HeaderSwitch({ shortTitle, longTitle, checked, onChange }) {
	return (
		<div className="text-center">
			<div className="-mt-0.5">
				<Tooltip title={longTitle}>
					<span className="text-xs font-medium">{shortTitle}</span>
				</Tooltip>
			</div>
			<div className="transform scale-75 -mt-1.5">
				<Switch size="small" checked={checked} onChange={onChange} />
			</div>
		</div>
	);
}

export default HeaderSwitch;
