import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import ApiError from "../../../components/ApiError";
import TextField from "../../../components/Forms/TextField";
import Button from "../../../components/Button";
import Card, { CardBody } from "../../../components/Card";
import TextAreaField from "../../../components/Forms/TextAreaField";
import { required, number } from "../../../services/validations";
import { browserHistory } from "react-router";
import PageHeader from "../../../components/PageHeader";
import Modal from "../../../components/Modal";
import { Icon } from "antd";

const Form = ({ handleSubmit, apiError, loading }) => {
	return (
		<>
			{apiError && (
				<div className="mb-4">
					<ApiError error={apiError} />
				</div>
			)}

			<form onSubmit={handleSubmit}>
				<div className="mb-4 flex -mx-2">
					<div className="px-2" style={{ flex: 3 }}>
						<Field
							name="title"
							label="Title"
							component={TextField}
							validate={required}
						/>
					</div>
					<div className="px-2 flex-1">
						<Field
							name="tts_nlp_type"
							label="NLP Type"
							component={TextField}
							validate={required}
						/>
					</div>
					<div className="px-2 flex-1">
						<Field
							name="tts_gst_factor"
							label="Narration Intensity"
							component={TextField}
							validate={number}
						/>
					</div>
					<div className="px-2 flex-1">
						<Field
							name="tts_gst_factor_quotes"
							label="Conversation Intensity"
							component={TextField}
							validate={number}
						/>
					</div>
				</div>

				<div className="mb-4">
					<Field
						name="tts_models"
						label="Models separated with comma"
						component={TextField}
						validate={required}
					/>
				</div>
				<div className="mb-4">
					<Field
						name="tts_voices"
						label="Speakers separated with comma"
						component={TextField}
						validate={required}
					/>
				</div>

				<div className="mb-4">
					<Field
						name="text"
						label="Text"
						component={TextAreaField}
						rows={20}
						validate={required}
					/>
				</div>

				<Button type="primary" loading={loading}>
					Send
				</Button>
			</form>
		</>
	);
};

const ConnectedForm = reduxForm({
	form: "cluster-benchmark",
	initialValues: {
		tts_gst_factor: 0.4,
		tts_gst_factor_quotes: 0.6,
		tts_nlp_type: "deepzen_lexicon",
	},
})(Form);

const Benchmark = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	const onSubmit = (values) => {
		setLoading(true);
		setError(null);

		const models = values.tts_models.split(",");
		const voices = values.tts_voices.split(",");
		const combinations = models.flatMap((model) =>
			voices.map((voice) => ({
				model,
				voice,
				title: `${model} / ${voice}`,
			}))
		);

		// First create a cluster
		axios
			.post(`clusters/`, {
				title: values.title,
				text: `{{ ${combinations[0].title} }} ${values.text}`,
				tts_gst_factor: values.tts_gst_factor,
				tts_gst_factor_quotes: values.tts_gst_factor_quotes,
				tts_nlp_type: values.tts_nlp_type,
				tts_voice: combinations[0].voice,
				tts_model: combinations[0].model,
				synthesize_now: "1",
			})
			.then(async (res) => {
				// Create chapters
				const promises = combinations.slice(1).map((combination) => {
					return axios.post(`clusters/${res.data.id}/books/`, {
						title: combination.title,
						text: values.text,
						tts_gst_factor: values.tts_gst_factor,
						tts_gst_factor_quotes: values.tts_gst_factor_quotes,
						tts_nlp_type: values.tts_nlp_type,
						tts_voice: combination.voice,
						tts_model: combination.model,
						synthesize_now: "1",
					});
				});

				Promise.all(promises).then(() => {
					setLoading(false);
					setSuccess(true);

					setTimeout(
						() => browserHistory.push(`/clusters/${res.data.id}`),
						2000
					);
				});
			})
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
	};

	return (
		<div className="container">
			{(success || error || loading) && (
				<Modal>
					<div className="p-4">
						{success && (
							<>
								<Icon
									type="check-circle"
									className="text-green-500"
								/>
								Done
							</>
						)}

						{error && (
							<>
								<Icon
									type="check-warning"
									className="text-red-500"
								/>
								An error occurred
							</>
						)}

						{loading && (
							<>
								<Icon
									className="text-blue-500 mr-2"
									type="loading"
									spin
								/>
								Creating book
							</>
						)}
					</div>
				</Modal>
			)}

			<PageHeader
				title="New benchmark book"
				subTitle="Create a new book and chapters with all model and speaker combinations."
			/>
			<Card>
				<CardBody>
					<ConnectedForm
						onSubmit={onSubmit}
						loading={loading}
						apiError={error}
					/>
				</CardBody>
			</Card>
		</div>
	);
};

export default Benchmark;
