import React from "react";
import classNames from "classnames";
import { Icon, Tooltip } from "antd";

const sizeMap = {
  small: "py-1 px-2",
  default: "",
};

const SelectField = ({
  label,
  input,
  meta,
  className,
  size,
  info,
  options,
  ...otherProps
}) => (
  <div className="relative focus-within:z-10 flex-grow">
    {label && (
      <label
        className={classNames(
          "text-gray-500 font-medium mb-0.5 block flex items-center",
          size === "small" ? "text-xs" : "text-sm"
        )}
      >
        {label}
        {info && (
          <Tooltip placement="top" title={info} trigger={["hover"]}>
            <Icon
              type="info-circle-o"
              style={{ paddingLeft: 5, cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </label>
    )}
    <select
      className={classNames(
        "block bg-white outline-none sm:text-sm shadow-sm form-select w-full sm:leading-5",
        meta && meta.touched && meta.error
          ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
          : "focus:shadow-outline-blue",
        sizeMap[size || "default"],
        input.disabled && "cursor-not-allowed bg-gray-100",
        className
      )}
      {...input}
      {...otherProps}
    >
      {options.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
    {meta &&
      meta.touched &&
      ((meta.error && (
        <span className="text-red-900 text-xs">{meta.error}</span>
      )) ||
        (meta.warning && (
          <span className="text-red-900 text-xs">{meta.warning}</span>
        )))}
  </div>
);

export default SelectField;
