import React, { Component } from "react";
import { connect } from "react-redux";
import MissingWord from "../components/MissingWord";
import { load, deploy } from "../../modules/lexicons";
import { hasUserFullEditingAccess } from "../../../Auth/modules/auth";

class MissingWordContainer extends Component {
	componentDidMount() {
		if (this.props.lexicons === null) {
			this.props.load();
		}
	}

	render() {
		return <MissingWord {...this.props} />;
	}
}

const mapStateToProps = state => ({ 
	lexicons: state.lexicon.lexicons.data,
	deployLoading: state.lexicon.lexicons.deployLoading,
	hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user) 
});

const mapDispatchToProps = {
	load,
	deploy,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MissingWordContainer);
