import React from "react";
import { PropTypes } from "prop-types";

export function Auth({ children }) {
	return <div className="auth">{children}</div>;
}

Auth.propTypes = {
	children: PropTypes.node.isRequired
};

export default Auth;
