import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router";

export const UserMenu = ({user}) => {
	
	const menuRef = useRef();
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const menuList = [
		{
			name: 'Reset password',
			link: '/password-reset'
		},
		{
			name: 'Sign out',
			link: '/auth/logout'
		}
	];

	useEffect(() => {
    	const handleClickOutsideMenu = (e) => {
      		if (menuRef && menuRef.current && !menuRef.current.contains(e.target)) {
        		setProfileMenuOpen(false);
      		}
    	};

    	document.addEventListener("click", handleClickOutsideMenu);
    	return () => document.removeEventListener("click", handleClickOutsideMenu);
  	}, []);

	if (!user) return null;

	return (
		<div className="flex items-center mb-10">
            <div className="hidden md:flex-shrink-0 md:flex md:items-center">
              	<div className="relative" ref={menuRef}>
                	<div>
                  		<button
                    		className="flex items-center text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    		id="user-menu"
                    		aria-label="User menu"
                    		aria-haspopup="true"
                    		onClick={() => setProfileMenuOpen(!profileMenuOpen)}>
                    		    
                                <span className="h-9 w-9 rounded-full bg-gray-400 text-white flex justify-center items-center">
                      				<svg
                        				className="w-6 h-6"
                        				fill="none"
                        				stroke="currentColor"
                        				viewBox="0 0 24 24"
                        				xmlns="http://www.w3.org/2000/svg"
                      				>
                        			    <path
                          					strokeLinecap="round"
                          					strokeLinejoin="round"
                          					strokeWidth="2"
                          					d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        				></path>
                      				</svg>
                    			</span>
                  		</button>
                	</div>
                   
				   {profileMenuOpen && (
						<div className="origin-top-right absolute block bottom-0 left-10 mt-2 ml-7 w-48 rounded-md shadow-lg z-10" >
                  		    <div
                    		    className="py-1 rounded-md bg-white shadow-xs"
                    		    role="menu"
                    		    aria-orientation="vertical"
                    		    aria-labelledby="user-menu">
								{menuList.map((menu, index) => (
									<Link
										key={index}
                      			    	to={menu.link}
										className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                      			    	role="menuitem"
										onClick={(e) => setProfileMenuOpen(false)}  
                    		    	>
                      		        	{menu.name}
                    		    	</Link>
								))}
                    		</div>
					    </div>
					)}
                	
				</div>
            </div>
		</div>
    );
}

export default UserMenu;