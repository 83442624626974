import React, { useState } from "react";
import Card, { CardBody } from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import axios from "axios";
import { message, Spin } from "antd";
import { useDispatch } from "react-redux";
import { load, loadStats } from "../../../modules/detail";

const FirstPassChapters = ({ id, onClose }) => {

    const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

    const onClickFirstPassAllChapters = () => {
		setLoading(true);
		axios
			.put(`clusters/${id}/first_pass_all_chapters/`)
			.then((res) => {
				setLoading(false);
				dispatch(load(id));
				dispatch(loadStats(id));
                onClose();
				message.success("Chapters saved successfully");
			})
			.catch((e) => {
				setLoading(false);
				message.error("An error occurred while saving chapters");
			});
		
	};

	return (
        <Card title="First pass all chapters">
		    <CardBody>
			    Are you sure you want to continue?
			    <Button 
                    className="mt-4" 
                    block type="primary"
					disabled={loading}
                    onClick={(e) => onClickFirstPassAllChapters()}>
				    Yes
			    </Button>
		    </CardBody>
	    </Card>
    );
};

export default FirstPassChapters;
