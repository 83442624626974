import React from "react";
import { Popconfirm } from "antd";
import Button from "../../../components/Button";

export function SentenceDetailsActions({
	sentence,
	toggleInsertSentence,
	deleteSentence,
	hasUserFullEditingAccess,
}) {
	return (
		<div className="py-2 px-1">
			<Button
				onClick={toggleInsertSentence}
				size="small"
				className="mr-1"
			>
				Split
			</Button>

			<Popconfirm
				placement="bottomRight"
				onConfirm={() => deleteSentence(sentence)}
				key="delete"
				title="Are you sure?"
			>
				<Button size="small" type="danger">
					Delete
				</Button>
			</Popconfirm>
		</div>
	);
}

export default SentenceDetailsActions;
