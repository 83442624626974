import React, { useState } from "react";
import { useSelector } from "react-redux";
import { loggedInUserSelector } from "../Auth/modules/auth";
import RecentBooks from "./RecentBooks";
import StatusGroup from "./StatusGroup";
import SearchResults from "./SearchResults";
import { useDebounce } from "use-debounce";

const Dashboard = () => {
	const user = useSelector(loggedInUserSelector);
	const [searchQuery, setSearchQuery] = useState("");
	const [debouncedSearchValue] = useDebounce(searchQuery, 300);

	return (
		<div className="h-screen -my-4 flex overflow-hidden">
			<div className="w-2/5 py-10 px-16 bg-cool-gray-800 overflow-y-auto">
				<h1 className="text-white text-5xl font-black">
					Hi {user && user.first_name}
				</h1>
				<h2 className="text-cool-gray-400 text-xl -mt-2">
					Welcome to DeepZen Studio
				</h2>

				<div className="mt-4">
					<input
						autoFocus
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						className="form-input rounded-lg p-3 text-lg bg-cool-gray-700 placeholder-cool-gray-400 border-cool-gray-400 w-full outline-none shadow-sm focus:shadow-outline-blue text-white"
						placeholder="Search books..."
					/>

					<div className="mt-16">
						{debouncedSearchValue && (
							<SearchResults query={debouncedSearchValue} />
						)}
						{!debouncedSearchValue && <RecentBooks />}
					</div>
				</div>
			</div>
			<div className="w-3/5 h-screen overflow-y-auto p-3">
				<div className="overflow-hidden">
					<StatusGroup status="pending" />
					<StatusGroup status="inprogress" />
					<StatusGroup status="ready" />
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
