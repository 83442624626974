import React, { Component } from "react";
import { Modal, message, Spin } from "antd";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import axios from "axios";
import ApiError from "../../../../components/ApiError";
import EpubEditorContainer from "../../EpubEditor/containers/EpubEditorContainer";

class ImportEpub extends Component {
	state = {
		error: null,
		loading: false,
	};

	onDrop = (acceptedFiles) => {
		this.setState({ error: false });

		if (acceptedFiles.length > 0) {
			this.setState({ loading: true });

			var data = new FormData();
			data.append("file", acceptedFiles[0]);

			axios
				.post("/clusters/parse/epub/manual/", data)
				.then((res) => {
					this.setState({
						showEditor: true,
						manual: res.data,
						loading: false,
					});
				})
				.catch((e) =>
					this.setState({
						error: e,
						loading: false,
					})
				);
		} else {
			message.error("Invalid file.");
		}
	};

	render() {
		const { onComplete } = this.props;
		const { loading, error, showEditor, manual } = this.state;

		return (
			<div>
				<Modal
					onCancel={this.props.onCancel}
					visible
					width={500}
					footer={null}
					title="Import EPUB"
				>
					{error && <ApiError error={error} />}

					<Dropzone
						disabled={loading}
						multiple={false}
						onDrop={this.onDrop}
					>
						{({ getRootProps, getInputProps, isDragActive }) => {
							return (
								<div
									{...getRootProps()}
									className={classNames("dropzone", {
										"dropzone--active": isDragActive,
									})}
								>
									<input {...getInputProps()} />
									{loading ? (
										<Spin size="small" />
									) : (
										<p>
											Try dropping some files here, or
											click to select files to upload.
										</p>
									)}
								</div>
							);
						}}
					</Dropzone>
				</Modal>
				{showEditor && (
					<EpubEditorContainer
						manual={manual}
						onComplete={onComplete}
					/>
				)}
			</div>
		);
	}
}

export default ImportEpub;
