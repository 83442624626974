import { connect } from "react-redux";
import Clone from "../../components/Clone/Clone";
import { clone, toggle } from "../../modules/clone";
import { hasUserFullEditingAccess } from "../../../Auth/modules/auth";

const mapStateToProps = (state) => {
	const hasFullAccess = hasUserFullEditingAccess(state.auth.user);

	return {
		loading: state.clone.loading,
		bookId: state.editor.book.id,
		hasUserFullEditingAccess: hasFullAccess,
		initialValues: {
			title: `${state.editor.book.title} - Duplicate`,
			tts_voice: state.editor.book.tts_voice,
			...(hasFullAccess
				? {
						tts_model: state.editor.book.tts_model,
						tts_gst_factor: state.editor.book.tts_gst_factor,
						tts_gst_factor_quotes:
							state.editor.book.tts_gst_factor_quotes,
						tts_nlp_type: state.editor.book.tts_nlp_type,
				  }
				: {}),
		},
	};
};

const mapDispatchToProps = {
	clone,
	toggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clone);
