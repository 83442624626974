import React, { Component } from "react";
import { load, save } from "../modules/edit";
import Edit from "../components/Edit";
import { connect } from "react-redux";
import { load as loadLexicons } from "../../modules/lexicons";
import { hasUserFullEditingAccess } from "../../../Auth/modules/auth";

export class EditContainer extends Component {
	componentDidMount() {
		this.props.load(this.props.token);

		if (!this.props.lexicons) {
			this.props.loadLexicons();
		}
	}

	render() {
		return <Edit {...this.props} />;
	}
}

const mapStateToProps = state => ({
	...state.lexicon.edit,
	lexicons: state.lexicon.lexicons.data,
	deployLoading: state.lexicon.lexicons.deployLoading,
	hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user)
});

const mapDispatchToProps = {
	load,
	loadLexicons,
	save
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
