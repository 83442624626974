import React from "react";

const StepClasses = ({ removedClasses, setRemovedClasses }) => (
	<>
		This is relatively harder if are not familiar with HTML or EPUB files.
		EPUB files don't mark repeated content like page numbers, image captions
		etc. differently. So we can't know what to remove and what to keep. But
		these sections usually have the same{" "}
		<i className="bg-orange-100 text-orange-900">class</i> attributes. You
		can go through pages using the preview on the left and move your mouse
		over those parts you want to remove and we will remove all repetitions
		having the same class. Go Make sure only the part you want to remove is
		highlighted and then click on it. After this step, images and anything
		other than texts will be removed and you will be able to modify the
		final text.
		{removedClasses.length > 0 && (
			<div className="bg-red-50 px-3 py-2 mt-3">
				<div className="font-semibold text-red-800">
					Classes to be removed
				</div>

				{removedClasses.map((c) => (
					<div key={c} className="mt-1">
						{c}
						<a
							className="text-xs ml-2"
							onClick={() =>
								setRemovedClasses(
									removedClasses.filter((h) => h !== c)
								)
							}
						>
							Undo
						</a>
					</div>
				))}
			</div>
		)}
	</>
);

export default StepClasses;
