import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import SentenceDetailsVersions from "./SentenceDetailsVersions";
import SentenceDetailsPlayer from "./SentenceDetailsPlayer";
import SentenceDetailsResynthesizeContainer from "../containers/SentenceDetailsResynthesizeContainer";
import NoteContainer from "../containers/Notes/NoteContainer";
import SentenceDetailsActionsContainer from "../containers/SentenceDetailsActionsContainer";
import WordSelector from "./WordLevel/WordSelector";
import InsertSentenceContainer from "../containers/InsertSentence/InsertSentenceContainer";
import classNames from "classnames";
import Tabs from "../../../components/Tabs";
import FormContainer from "../containers/Notes/FormContainer";
import Form from "./WordLevel/Form";
import { createPopper } from "@popperjs/core";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { change, formValueSelector } from "redux-form";

const selector = formValueSelector("resynthesize");

export function SentenceDetails({
	loading,
	error,
	sentence,
	versions,
	activate,
	selectNextSentence,
	autoPlay,
	autoContinue,
	copy,
	paste,
	copiedNlp,
	showInsertSentence,
	noteIds,
	canEdit,
	markSentence,
	hasUserFullEditingAccess,
}) {
	const popperRef = useRef();
	const wordLevelFormRef = useRef();

	const dispatch = useDispatch();
	const editedWords = useSelector((state) => selector(state, "words") || []);
	const [editingWordIndex, setEditingWordIndex] = useState(null);

	useEffect(() => {
		if (sentence) {
			setEditingWordIndex(null);
		}
	}, [sentence]);

	if (loading) return <Loading />;
	if (error) return <ApiError error={error} />;
	if (!sentence) return null;

	const tabs = [
		{
			key: "new-version",
			title: "New version",
			content: (
				<SentenceDetailsResynthesizeContainer
					sentence={sentence}
					copiedNlp={copiedNlp}
					paste={paste}
					hasUserFullEditingAccess={hasUserFullEditingAccess}
				/>
			),
		},
		{
			key: "history",
			title: "History",
			badge: versions.length,
			content: (
				<SentenceDetailsVersions
					activate={activate}
					copy={copy}
					sentence={sentence}
					versions={versions}
				/>
			),
		},
		{
			key: "notes",
			title: "Notes",
			badge: noteIds.length,
			content: (
				<div>
					<FormContainer sentenceId={sentence.id} />
					{noteIds.map((id) => (
						<NoteContainer key={id} id={id} showSentence={false} />
					))}
				</div>
			),
		},
	];

	const colorMenuBgColorMap = {
		default: "bg-gray-400",
		blue: "bg-blue-400",
		red: "bg-red-400",
		orange: "bg-orange-400",
		green: "bg-green-400",
		indigo: "bg-indigo-400",
		yellow: "bg-yellow-400",
		purple: "bg-purple-400",
	};

	const sentenceColorMap = {
		default: "bg-gray-300 text-gray-800",
		blue: "bg-blue-300 text-blue-800",
		red: "bg-red-300 text-red-800",
		orange: "bg-orange-300 text-orange-800",
		green: "bg-green-300 text-green-800",
		indigo: "bg-indigo-300 text-indigo-800",
		yellow: "bg-yellow-200 text-yellow-800",
		purple: "bg-purple-300 text-purple-800",
	};

	const handleSelectWord = (event, selectedWordIndex) => {
		if (popperRef.current) {
			popperRef.current.destroy();
		}

		popperRef.current = createPopper(
			event.currentTarget,
			wordLevelFormRef.current
		);

		setEditingWordIndex(selectedWordIndex);
	};

	const colorMenu = (
		<div className="flex items-center justify-between">
			{[null, "orange", "yellow", "purple"].map((color) => (
				<button
					key={color}
					onClick={() => markSentence(sentence.id, color)}
					className={classNames(
						"h-4 w-4 rounded-full mr-1 focus:outline-none border border-solid ",
						colorMenuBgColorMap[color || "default"],
						color === sentence.color
							? "border-gray-800"
							: "border-transparent"
					)}
				/>
			))}
		</div>
	);

	return (
		<div className={classNames("sentence-details", canEdit || "closed")}>
			<div className="border-b border-gray-200 shadow-sm flex h-10">
				<div className="flex items-center bg-gray-50 border-r border-gray-200">
					<span
						className={classNames(
							"inline-flex items-center rounded-lg text-xs font-medium px-2 h-5 mx-2",
							sentenceColorMap[sentence.color || "default"]
						)}
					>
						#{sentence.sentence_id}
					</span>
					{colorMenu}
				</div>

				<div className="flex-grow" />

				<SentenceDetailsActionsContainer sentence={sentence} />
			</div>

			<SentenceDetailsPlayer
				wav_url={sentence.active_version.wav_url}
				autoPlay={canEdit && autoPlay}
				onEndAudio={() => {
					if (autoContinue && !editingWordIndex) selectNextSentence();
				}}
			/>

			{sentence.active_version.words[0]?.token ===
				editedWords[0]?.token && (
				<WordSelector
					words={sentence.active_version.words}
					editedWords={editedWords}
					editingWordIndex={editingWordIndex}
					onSelectWord={handleSelectWord}
				/>
			)}

			<div
				ref={wordLevelFormRef}
				className="z-20 shadow rounded overflow-hidden"
			>
				{editingWordIndex !== null && (
					<OutsideClickHandler
						onOutsideClick={() => setEditingWordIndex(null)}
					>
						<Form
							sentence={sentence}
							word={editedWords[editingWordIndex]}
							wordIndex={editingWordIndex}
							onClose={() => setEditingWordIndex(null)}
							onChange={(updatedWord) => {
								const newWords = editedWords.map((w, i) =>
									i === editingWordIndex ? updatedWord : w
								);

								dispatch(
									change("resynthesize", "words", newWords)
								);
							}}
						/>
					</OutsideClickHandler>
				)}
			</div>

			<Tabs data={tabs} defaultTab="new-version" tabScope={sentence} />

			{showInsertSentence && (
				<InsertSentenceContainer sentenceId={sentence.id} />
			)}
		</div>
	);
}

export default SentenceDetails;
