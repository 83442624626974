import { message } from "antd";

export const NLP_COPY = "NLP_COPY";
export const NLP_PASTE = "NLP_PASTE";

export function copy(clipboard) {
	message.success("Copied!");

	return {
		type: NLP_COPY,
		payload: clipboard
	};
}

export function paste(sentence, clipboard) {
	message.success("Pasted!");

	return {
		type: NLP_PASTE,
		payload: { sentence, clipboard }
	};
}

const initialState = {
	clipboard: null
};

export default function nlpReducer(state = initialState, action) {
	switch (action.type) {
		case NLP_COPY:
			return { ...state, clipboard: action.payload };
		default:
			return state;
	}
}