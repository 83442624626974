import React from "react";
import classNames from "classnames";

export function WordTag({ word }) {
	const getPauseTag = (pause) => {
		let pauseTag = "";
		switch (pause) {
			case "/":
				pauseTag = "[P-S]";
				break;
			case "*":
				pauseTag = "[P-M]";
				break;
			case "%":
				pauseTag = "[P-L]";
				break;
			case ">":
				pauseTag = "[P-EL]";
				break;
			default:
				break;
		}
		return pauseTag;
	};

	const emphasisClass =
		word?.emphasis &&
		(word.emphasis == "1"
			? "underline"
			: word.emphasis == "2"
			? "underline-double"
			: "");

	return (
		<span className={classNames(emphasisClass)}>
			{word.token}
			<div className={classNames("inline-block", emphasisClass)}>
				{word?.pause && word.pause !== " " && (
					<small className="text-red-700" style={{ fontSize: "8px" }}>
						{getPauseTag(word.pause)}
					</small>
				)}
			</div>
		</span>
	);
}

export default WordTag;
