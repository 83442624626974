import React from "react";
import { Field, reduxForm } from "redux-form";
import { required } from "../../../../services/validations";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import Button from "../../../../components/Button";

export function Form({ handleSubmit, loading }) {
	return (
		<form onSubmit={handleSubmit} className="p-3">
			<Field
				name="note"
				validate={required}
				component={TextAreaField}
				size="small"
				rows="6"
			/>
			<Button
				block
				htmlType="submit"
				type="primary"
				size="large"
				loading={loading}
				className="mt-2"
			>
				Save
			</Button>
		</form>
	);
}

export default reduxForm({ form: "note" })(Form);
