import { connect } from "react-redux";
import {
	sentenceByIdSelector,
	isActiveSelector,
	selectSentence,
	multipleSelectedSelector
} from "../modules/editor";
import Sentence from "../components/Sentence";

const mapStateToProps = (state, { id }) => ({
	sentence: sentenceByIdSelector(state, id),
	isActive: isActiveSelector(state, id),
	multipleSelected: multipleSelectedSelector(state),
	highlightIssueSentence: state.editor.highlightIssueSentence
});

const mapDispatchToProps = { selectSentence };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Sentence);
