import React, { Component } from "react";
import { Button, message } from "antd";
import axios from "axios";
import * as moment from "moment";

class Row extends Component {
	state = {
		deploying: false
	};

	deploy = () => {
		message.info("Deployment started...");

		this.setState({ deploying: true });

		axios
			.post(`lexicons/${this.props.id}/deploy/`)
			.then(res => {
				this.setState({ deploying: false });
				message.success("Deployment successful");
				this.props.onChange(res.data);
			})
			.catch(e => {
				this.setState({ deploying: false });
				message.error("An error occurred while deploying lexicon.");
			});
	};

	render() {
		const { id, deployed_at } = this.props;
		const { deploying } = this.state;

		return (
			<tr>
				<td>
					<strong>{id}</strong>
				</td>
				<td>{deployed_at && moment(deployed_at).fromNow()}</td>
				<td>
					<Button
						size="small"
						icon="upload"
						onClick={this.deploy}
						loading={deploying}
						disabled={deploying}
					>
						Deploy
					</Button>
				</td>
			</tr>
		);
	}
}

export default Row;
