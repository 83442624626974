import React from "react";
import Card, { CardBody } from "../../../../components/Card";

const BundleSummary = ({ bundle }) => (
	<Card
		title={bundle.finished_at ? "Last Bundle" : "Bundle in progress"}
		className="mb-4"
	>
		<CardBody>
			{bundle.error && <div>Failed: {bundle.error}</div>}
			{bundle.download_url && (
				<div>
					Ready to download:{" "}
					<a
						href={bundle.download_url}
						target="_blank"
						className="text-blue-600 font-bold"
					>
						{bundle.download_url}
					</a>
				</div>
			)}

			{!bundle.error &&
				bundle.started_at &&
				bundle.total_books &&
				bundle.finished_books < bundle.total_books && (
					<div>
						{bundle.finished_books} / {bundle.total_books} chapters
						finished.
					</div>
				)}
		</CardBody>
	</Card>
);

export default BundleSummary;
