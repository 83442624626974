import axios from "axios";

export const CLUSTERS_NEW_RESET = "CLUSTERS_NEW_RESET";
export const CLUSTERS_NEW_UPDATE_TEXT_DATA = "CLUSTERS_NEW_UPDATE_TEXT_DATA";
export const CLUSTERS_NEW_SAVE = "CLUSTERS_NEW_SAVE";
export const CLUSTERS_NEW_SAVE_SUCCESS = "CLUSTERS_NEW_SAVE_SUCCESS";
export const CLUSTERS_NEW_SAVE_FAIL = "CLUSTERS_NEW_SAVE_FAIL";

export const reset = () => ({ type: CLUSTERS_NEW_RESET });

export const updateTextData = (data) => ({
	type: CLUSTERS_NEW_UPDATE_TEXT_DATA,
	payload: data,
});

const saveSuccess = (data) => ({
	type: CLUSTERS_NEW_SAVE_SUCCESS,
	payload: data,
});
const saveFail = (e) => ({ type: CLUSTERS_NEW_SAVE_FAIL, payload: e });

export function save(data) {
	return (dispatch, getState) => {
		const {
			clusters: {
				create: { textData },
			},
		} = getState(); 

		dispatch({ type: CLUSTERS_NEW_SAVE });
		dispatch(updateTextData(data));

		axios
			.post("clusters/", {
				...data,
				// Don't send `publisher_id` if it is "0"
				publisher_id:
					data.publisher_id !== "0"
						? data.publisher_id
						: undefined,
			})
			.then((res) => dispatch(saveSuccess(res.data)))
			.catch((e) => dispatch(saveFail(e))); 
	};
}

const initialState = {
	textData: null,
	loading: false,
	error: null,
	success: false,
};

export default function newReducer(state = initialState, action) {
	switch (action.type) {
		case CLUSTERS_NEW_RESET:
			return initialState;
		case CLUSTERS_NEW_UPDATE_TEXT_DATA:
			return { ...state, textData: action.payload };
		case CLUSTERS_NEW_SAVE:
			return { ...state, loading: true };
		case CLUSTERS_NEW_SAVE_SUCCESS:
			return { ...state, loading: false, success: true };
		case CLUSTERS_NEW_SAVE_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
}
