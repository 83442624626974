import React from "react";
import { PageHeader, Card } from "antd";
import Form from "./Form";

function Create({ loading, error, create, lexicons }) {
	return (
		<div className="container">
			<PageHeader title="Create Lexicon Session" />
			<Card>
				<Form
					lexicons={lexicons}
					apiError={error}
					loading={loading}
					onSubmit={create}
					initialValues={{ lexicons: ["main", "addendum"] }}
				/>
			</Card>
		</div>
	);
}

export default Create;
