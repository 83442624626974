import axios from "axios";
import { useEffect, useState } from "react";

export const useVoices = () => {
	const [voices, setVoices] = useState(null);

	useEffect(() => {
		(async () => setVoices(await getVoices()))();
	}, []);

	return voices;
};

export const getVoices = () =>
	axios.get(`portal/voices/`).then((res) =>
		res.data.map((v) => ({
			value: v.tts_voice,
			label: v.name_of_narrator,
		}))
	);
