import React, { Component } from "react";
import Detail from "../components/Detail";
import { connect } from "react-redux";
import { load, synthesize, reanalyze, archive } from "../modules/detail";
import { sessionByIdSelector } from "../../modules/sessions";
import { hasUserFullEditingAccess } from "../../../../Auth/modules/auth";

class DetailContainer extends Component {
	componentDidMount() {
		const { id } = this.props.params;

		this.props.load(id);

		const channel = window.pusher.subscribe(`lexicon-session-${id}`);
		channel.bind("session-updated", (data) => this.props.load(id));
	}

	componentWillUnmount() {
		window.pusher.unsubscribe(`lexicon-session-${this.props.params.id}`);
	}

	render() {
		return <Detail {...this.props} />;
	}
}

const mapStateToProps = (state, { params: { id } }) => {
	return {
		session: sessionByIdSelector(state, id),
		hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user),
	};
};

const mapDispatchToProps = {
	load,
	synthesize,
	reanalyze,
	archive,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailContainer);
