import { connect } from "react-redux";
import Reload from "../components/ReloadTTSSession/index";
import { reload, toggle } from "../modules/reload";

const mapStateToProps = (state) => {
	return {
		loading: state.reload.loading,
		bookId: state.editor.book.id,
		initialValues: {
			tts_session_id: state.editor.book.tts_session_id,
		},
	};
};

const mapDispatchToProps = {
	reload,
	toggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reload);
