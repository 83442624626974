import React from "react";
import { browserHistory, Router } from "react-router";
import { Provider } from "react-redux";

class App extends React.Component {
	shouldComponentUpdate() {
		return false;
	}

	render() {
		return (
			<Provider store={this.props.store}>
				<div className="h-screen flex overflow-hidden bg-cool-gray-100">
					<Router history={browserHistory}>
						{this.props.routes()}
					</Router>
				</div>
			</Provider>
		);
	}
}

export default App;
