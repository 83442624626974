import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Modal } from "antd";
import EditContainer from "../../Edit/containers/EditContainer";
import Button from "../../../../components/Button";
import Badge from "../../../../components/Badge";
import {
	convertTranscription,
	languageFromLexiconName,
	parseCombilex,
	parseTranscription,
} from "../../../../services/lexicons";

export class Results extends Component {
	state = {
		showEdit: false,
	};

	render() {
		const { data, token } = this.props;
		const { showEdit } = this.state;

		const valueCount = data.reduce(
			(cum, result) => cum + result.values.length,
			0
		);

		return (
			<>
				{showEdit && (
					<Modal
						footer={null}
						bodyStyle={{ padding: 0 }}
						visible
						width={750}
						onCancel={() => this.setState({ showEdit: false })}
					>
						<EditContainer token={token} />
					</Modal>
				)}
				<div className="bg-white rounded-lg shadow mt-3">
					<div className="flex items-center p-3 font-medium text-gray-900">
						<div className="flex-grow text-sm">
							Found {valueCount} entries.
						</div>

						<Button
							onClick={() => this.setState({ showEdit: true })}
						>
							Edit
						</Button>
					</div>

					{data.map((result) => (
						<>
							<div className="bg-gray-100 p-3 font-medium text-gray-900">
								{result.lexicon_id}
							</div>

							{result.values.map((value, j) => {
								const language = languageFromLexiconName(
									result.lexicon_id
								);

								const [pos, transcription] =
									parseCombilex(value);

								const parsed = parseTranscription(
									language,
									"combilex",
									transcription
								);

								return (
									<div
										className="border-b px-3 py-2 border-gray-200 font-monospace"
										key={j}
									>
										<Badge className="mr-1">{pos}</Badge>
										<Badge className="font-bold">
											<span
												style={{
													fontFamily: "monospace",
												}}
											>
												{convertTranscription(
													language,
													"combilex",
													"deepzen",
													parsed
												)}
											</span>
										</Badge>
									</div>
								);
							})}
						</>
					))}
				</div>
			</>
		);
	}
}

Results.propTypes = {
	data: PropTypes.array.isRequired,
	token: PropTypes.string.isRequired,
};

export default Results;
