import React, { useState } from "react";
import { bundle } from "../../../modules/detail";
import Card, { CardBody } from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import TextField from "../../../../../components/Forms/TextField";
import CheckboxField from "../../../../../components/Forms/CheckboxField";
import { Field, reduxForm } from "redux-form";
import { required } from "../../../../../services/validations";

const FormBody = ({ handleSubmit }) => (
	<form onSubmit={handleSubmit} className="mt-4">
		<Field
			component={TextField}
			name="filename_template"
			validate={[required]}
			className="h-9 mb-4"
			autoFocus
		/>
		<Field
			label="Include visemes"
			component={CheckboxField}
			name="include_visemes"
		/>
		<div className="mt-4">
			<Button block htmlType="submit" type="success" className="h-9">
				Start
			</Button>
		</div>
	</form>
);

const Form = reduxForm({ form: "bundle-cluster" })(FormBody);

const BundleForm = ({ id, onClose }) => {
	const DEFAULT_FILENAME_TEMPLATE =
		localStorage.getItem("BUNDLE_FILENAME_TEMPLATE") ||
		"{{isbn}}_{{index}}";

	const [showForm, setShowForm] = useState(false);

	return (
		<Card title="Bundle book">
			<CardBody>
				{showForm && (
					<>
						<div className="mb-4">
							Please enter your filename template.
						</div>
						<div>
							You can use <strong>{`{{book_title}}`}</strong>,{" "}
							<strong>{`{{chapter_title}}`}</strong>,{" "}
							<strong>{`{{index}}`}</strong> and{" "}
							<strong>{`{{isbn}}`}</strong> as variables.
						</div>
						<Form
							onSubmit={(values) => {
								bundle(
									id,
									values.filename_template,
									!!values.include_visemes
								);
								setShowForm(false);
								localStorage.setItem(
									"BUNDLE_FILENAME_TEMPLATE",
									values.filename_template
								);
								onClose();
							}}
							initialValues={{
								filename_template: DEFAULT_FILENAME_TEMPLATE,
							}}
						/>
					</>
				)}
				{!showForm && (
					<>
						<div>
							Finalize all <strong>done</strong> chapters and
							generate one zip file of wav files. This will take a
							while and download link will be sent to
							<strong> #productionhelp</strong> on Slack.
						</div>
						<Button
							onClick={() => setShowForm(true)}
							className="mt-4"
							block
							type="primary"
						>
							Bundle book
						</Button>
					</>
				)}
			</CardBody>
		</Card>
	);
};

export default BundleForm;
