import React from "react";
import { Icon, Popconfirm } from "antd";
import classNames from "classnames";

export function Note({
	note,
	sentence,
	showSentence,
	selectSentence,
	deleteNote,
}) {
	const borderColorMap = {
		default: "border-gray-300",
		blue: "border-blue-300",
		red: "border-red-300",
		orange: "border-orange-300",
		green: "border-green-300",
		indigo: "border-indigo-300",
		yellow: "border-yellow-200",
		purple: "border-purple-200",
	};

	return (
		<div className="border-b border-gray-200 overflow-hidden group">
			<div
				className={classNames(
					"pt-1 pb-2 px-2",
					showSentence !== false && (
						`border-l-4 border-l-transparent`, 
						borderColorMap[sentence.color || "default"]
					)	
				)}
			>
				{showSentence !== false && (
					<div
						onClick={() => selectSentence(sentence.id)}
						className="text-xs cursor-pointer block mb-1"
					>
						<span className="font-medium text-gray-900">
							#{sentence.sentence_id}:
						</span>{" "}
						{sentence.active_version.text.substring(0, 80)}
						{sentence.active_version.text.length > 100 && "..."}
						<Icon type="right" />
					</div>
				)}
				<div className="bg-cool-gray-200 text-gray-900 py-1 px-2 text-md rounded-lg rounded-tl-none mt-1">
					<div className="text-xs mb-0.5 font-medium text-gray-500 relative">
						{note.user.first_name} {note.user.last_name}
						<Popconfirm
							placement="bottomRight"
							onConfirm={() =>
								deleteNote(note.sentence_id, note.id)
							}
							title="Are you sure?"
						>
							<span className="absolute right-0 top-0 -mt-1.5 text-lg cursor-pointer group-hover:block hidden">
								&times;
							</span>
						</Popconfirm>
					</div>

					{note.note}
				</div>
			</div>
		</div>
	);
}

export default Note;
