import React from "react";
import classNames from "classnames";

const sizeMap = {
	large: "px-4 py-1.5 text-md font-medium leading-6",
	small: "px-2.5 py-1 text-xs leading-4 font-medium",
	default: "px-3 py-2 text-sm leading-4 font-medium",
};

const iconSizeMap = {
	large: 5,
	small: 3,
	default: 4,
};

const iconClassMap = {
	large: "mr-2 -ml-1",
	small: "mr-1",
	default: "mr-1.5",
};

const typeMap = {
	success:
		"bg-green-500 hover:bg-green-400 focus:bg-green-600 active:bg-green-600 text-white focus:shadow-outline-green",
	info:
		"bg-blue-600 hover:bg-blue-500 focus:bg-blue-700 active:bg-blue-700 text-white focus:shadow-outline-blue",
	warning:
		"bg-orange-400 hover:bg-orange-300 focus:bg-orange-500 active:bg-orange-500 text-white focus:shadow-outline-orange",
	danger:
		"bg-red-600 hover:bg-red-500 focus:bg-red-700 active:bg-red-700 text-white focus:shadow-outline-red",
	primary:
		"bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-700 active:bg-indigo-700 text-white focus:shadow-outline-indigo",
	default:
		"text-gray-700 bg-gray-50 border border-gray-300 hover:text-gray-500 hover:bg-white active:bg-gray-100 active:text-gray-700 focus:shadow-outline-blue",
};

const Button = ({
	children,
	size,
	type,
	block,
	disabled,
	loading,
	className,
	htmlType,
	icon,
	onClick,
	...otherProps
}) => {
	const Icon = icon;

	return (
		<button
			type={htmlType}
			disabled={disabled}
			className={classNames(
				"relative inline-flex items-center rounded-md focus:outline-none focus:border-blue-300 transition ease-in-out duration-150",
				className,
				sizeMap[size || "default"],
				typeMap[type || "default"],
				block && "w-full justify-center",
				(disabled || loading) && "cursor-not-allowed opacity-50 focus"
			)}
			onClick={!disabled && onClick}
			{...otherProps}
		>
			{Icon && (
				<Icon
					size={iconSizeMap[size || "default"]}
					className={children ? iconClassMap[size || "default"] : ""}
				/>
			)}
			{children}
		</button>
	);
};

export default Button;
