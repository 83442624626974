import React, { useState } from "react";
import Sentence from "./Sentence";
import { Button } from "antd";

const BookResults = ({
	book,
	sentences,
	checkedSentenceIds,
	onToggleChecked,
	checkAll,
	uncheckAll,
	onPlayAudio,
}) => {

	return (
		<div className=" mb-2">
			<div className="bg-gray-50 border-t border-b border-gray-100 py-2 px-5 text-gray-900 font-medium text-lg">
				{book.title}{" "}
				<span className="text-gray-500 text-sm">
					{sentences.length} results
				</span>
				<Button.Group style={{ marginLeft: 10 }}>
					<Button size="small" type="dashed" onClick={checkAll}>
						Check
					</Button>
					<Button size="small" type="dashed" onClick={uncheckAll}>
						Uncheck
					</Button>
				</Button.Group>
			</div>

			<table className="w-full">
				{sentences.map((sentence, index) => (
					<Sentence
						key={sentence.id}
						sentence={sentence}
						onToggleChecked={onToggleChecked}
						audioId={`${index}-${book.id}`}
						onPlayAudio={onPlayAudio}
						checked={checkedSentenceIds.indexOf(sentence.id) > -1}
					/>
				))}
			</table>
		</div>
	);
};

export default BookResults;
