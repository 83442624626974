import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";

export const TIME_TRACKER_LEXICON_CONSTRUCT = "TIME_TRACKER_LEXICON_CONSTRUCT";
export const TIME_TRACKER_LEXICON_CONSTRUCT_SUCCESS = "TIME_TRACKER_LEXICON_CONSTRUCT_SUCCESS";
export const TIME_TRACKER_LEXICON_DESTRUCT = "TIME_TRACKER_LEXICON_DESTRUCT";
export const TIME_TRACKER_LEXICON_PROGRESS = "TIME_TRACKER_LEXICON_PROGRESS";
export const TIME_TRACKER_LEXICON_START = "TIME_TRACKER_LEXICON_START";
export const TIME_TRACKER_LEXICON_STOP = "TIME_TRACKER_LEXICON_STOP";
export const TIME_TRACKER_LEXICON_START_SUCCESS = "TIME_TRACKER_LEXICON_START_SUCCESS";
export const TIME_TRACKER_LEXICON_START_FAIL = "TIME_TRACKER_LEXICON_START_FAIL";

export const elapsedSelector = createSelector(
	state => state.timeTrackerLexicon.activeLog,
	state => state.timeTrackerLexicon.ttl,
	(activeLog, ttl) =>
		activeLog
			? (new Date().getTime() -
					new Date(activeLog.created_at).getTime()) /
			  1000
			: 0
);

const constructSuccess = payload => ({
	type: TIME_TRACKER_LEXICON_CONSTRUCT_SUCCESS,
	payload
});

export function construct(lexiconSessionId) {
	return dispatch => {
		dispatch({
			type: TIME_TRACKER_LEXICON_CONSTRUCT,
			payload: { lexiconSessionId }
		});

		axios
			.get(
				`/accounting/work_log/?lexiconsession_id=${lexiconSessionId}`
			)
			.then(res => dispatch(constructSuccess(res.data)))
			.catch(e =>
				message.error("Time tracker could not be initialized.")
			);
	};
}

const progress = ttl => ({
	type: TIME_TRACKER_LEXICON_PROGRESS,
	payload: ttl
});

export const destruct = () => ({ type: TIME_TRACKER_LEXICON_DESTRUCT });

const startSuccess = log => ({
	type: TIME_TRACKER_LEXICON_START_SUCCESS,
	payload: log
});

const startFail = e => ({
	type: TIME_TRACKER_LEXICON_START_FAIL,
	payload: e
});

export function start(lexiconSessionId) {
	return (dispatch, getState) => {
		dispatch({
			type: TIME_TRACKER_LEXICON_START,
			payload: { lexiconSessionId }
		});

		axios
			.post(
				`/accounting/work_log/?lexiconsession_id=${lexiconSessionId}`,
				{}
			)
			.then(res => dispatch(startSuccess(res.data)))
			.catch(e => {
				dispatch(startFail(e));
				message.error("Time tracker failed.");
			});
	};
}

export function tick(lexiconSessionId) {
	return (dispatch, getState) => {
		const { timeTrackerLexicon } = getState();
		const { activeLog, activity } = timeTrackerLexicon;
		const now = new Date().getTime();
		const ttl = (new Date(activeLog.expires_at).getTime() - now) / 1000;

		if (ttl < 0 && !activity) {
			dispatch(stop(lexiconSessionId));
		} else if (ttl < 170 && activity) {
			dispatch(start(lexiconSessionId));
		} else {
			dispatch(progress(ttl));
		}
	};
}

export function stop(lexiconSessionId) {
	return dispatch => {
		axios
			.delete(
				`/accounting/work_log/?lexiconsession_id=${lexiconSessionId}`
			)
			.then(res => {
				message.info("Stopped");
				dispatch(construct(lexiconSessionId));
			})
			.catch(e =>
				message.info("Time tracker stopped due to inactivity.")
			);

		dispatch({ type: TIME_TRACKER_LEXICON_STOP });
		dispatch(construct(lexiconSessionId));
	};
}

const initialState = {
	activeLog: null,
	activity: false,
	ttl: 0,
	cumulativeElapsed: 0
};

export default function timeTrackerLexiconReducer(state = initialState, action) {
	switch (action.type) {
		case TIME_TRACKER_LEXICON_CONSTRUCT:
		case TIME_TRACKER_LEXICON_DESTRUCT:
			return {
				...initialState,
				cumulativeElapsed: state.cumulativeElapsed
			};
		case TIME_TRACKER_LEXICON_CONSTRUCT_SUCCESS:
			return {
				...state,
				activeLog: action.payload.active_log,
				cumulativeElapsed: action.payload.cumulative_elapsed,
				activity: false
			};
		case TIME_TRACKER_LEXICON_START_SUCCESS:
			return {
				...state,
				activeLog: action.payload,
				activity: false
			};
		case TIME_TRACKER_LEXICON_PROGRESS:
			return { ...state, ttl: action.payload };
		default:
			return state;
	}
};
