import React from "react";
import { PropTypes } from "prop-types";
import TextForm from "./TextForm";
import { Steps } from "antd";
import { Success } from "./Success";
import Loading from "../../../../components/Loading";
import { useVoices } from "../../../../services/voices";

const { Step } = Steps;

export function Create({ loading, error, success, textData, save, reset }) {
	const currentStep = 0 + (textData !== null) + success;
	const voices = useVoices();

	const handleOnSubmit = (e) => {
		if(e.fixed_nlp_score){
			Object.keys(e.tts_nlp_type)
				.map((key) => {
					e.tts_nlp_type[key] = Number(e.tts_nlp_type[key]); 
				});
		}
		
		save({
			...e, 
			tts_voice: !e.tts_voice ? voices[0]['value'] : e.tts_voice, 
			tts_nlp_type: e.fixed_nlp_score 
				? `fixed:${JSON.stringify(e.tts_nlp_type)}` 
				: undefined 
		});
	};

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-8 offset-md-2">
					<div className="cluster-form">
						<h1>Create a New Book</h1>
						<Steps current={currentStep}>
							<Step title="Enter text" />
							<Step title="Finish" />
						</Steps>

						{success && <Success reset={reset} />}

						{currentStep === 0 && (
							<TextForm 
								voices={voices} 
								onSubmit={(e) => handleOnSubmit(e)} 
							/>
						)}
						{currentStep === 1 && loading && <Loading />}
					</div>
				</div>
			</div>
		</div>
	);
}

Create.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	success: PropTypes.bool.isRequired,
	save: PropTypes.func.isRequired,
	reset: PropTypes.func.isRequired,
};

export default Create;
