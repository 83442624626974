import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, Select, message } from "antd";
import axios from "axios";
import { updateMissingWord } from "../modules/detail";

const languages = [
	"ltz",
	"en",
	"est",
	"afr",
	"ara",
	"dan",
	"isl",
	"sun",
	"swe",
	"mlg",
	"aze",
	"gle",
	"spa",
	"cym",
	"deu",
	"lav",
	"tam",
	"slk",
	"eus",
	"jpn",
	"lat",
	"sqi",
	"fry",
	"epo",
	"yor",
	"msa",
	"hat",
	"tur",
	"ces",
	"tgl",
	"cat",
	"uzb",
	"por",
	"kur",
	"hun",
	"ron",
	"nld",
	"ben",
	"hrv",
	"bul",
	"ind",
	"pol",
	"ibo",
	"eng",
	"slv",
	"lit",
	"fra",
	"fin",
	"bos",
	"ell",
	"swa",
	"som",
	"ita",
	"hin",
	"nor",
	"mlt",
	"vie",
].sort((a, b) => a.localeCompare(b));

class LanguagePredictionContainer extends Component {
	state = {
		loading: false,
		editMode: false,
	};

	save = (newLang) => {
		axios
			.put(
				`lexicon/sessions/${this.props.sessionId}/missing_words/${this.props.word.id}/`,
				{ language: newLang }
			)
			.then((res) => {
				this.props.updateMissingWord(res.data);
				this.setState({ editMode: false });
			})
			.catch((e) =>
				message.error("An error occurred while saving new language.")
			);
	};

	render() {
		const { word } = this.props;

		if (!word.language_prediction) return null;

		if (this.state.editMode) {
			return (
				<Select
					open
					autoFocus
					onChange={this.save}
					onBlur={() => this.setState({ editMode: false })}
					defaultValue={word.prediction_language}
				>
					{languages.map((lang) => (
						<Select.Option key={lang} value={lang}>
							{lang}
						</Select.Option>
					))}
				</Select>
			);
		}

		return (
			<a
				className="text-gray-500 font-normal text-xs inline-block w-8"
				onClick={() => this.setState({ editMode: true })}
			>
				{word.prediction_language.toUpperCase()}
			</a>
		);
	}
}

const mapDispatchToProps = {
	updateMissingWord,
};

export default connect(null, mapDispatchToProps)(LanguagePredictionContainer);
