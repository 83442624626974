import React from "react";
import logoIcon from "../assets/logo-icon.png";
import { IndexLink, Link } from "react-router";
import { useSelector } from "react-redux";
import { loggedInUserSelector } from "../routes/Auth/modules/auth";
import UserMenu from "./UserMenu";

export function Header() {
	const { pathname } = useSelector((state) => state.location);
	const user = useSelector(loggedInUserSelector);

	// Hide on chapter editor
	if (pathname.startsWith("/books/") 
		|| pathname.startsWith("/auth/") 
		|| pathname.startsWith("/maintenance")) {
		return null;
	}

	return (
		<div className="h-screen flex flex-col items-center w-24 bg-cool-gray-900">
			<IndexLink
				to="/"
				className="px-2 mt-3 bg-gray-100 rounded-full h-14 w-14 flex items-center"
			>
				<img className="w-14 h-auto" src={logoIcon} alt="Workflow" />
			</IndexLink>

			{user && (
				<>
					<nav className="flex-grow flex flex-col justify-center px-2">
						<Link
							to="/"
							className="group flex items-center px-3 py-3 text-sm leading-5 font-medium text-white rounded-md bg-cool-gray-900 focus:outline-none focus:bg-cool-gray-700 transition ease-in-out duration-150"
						>
							<svg
								className="h-8 w-8 text-cool-gray-400 group-focus:text-cool-gray-300 transition ease-in-out duration-150"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
							</svg>
						</Link>
						<Link
							to="/lexicon/sessions"
							className="my-2 group flex items-center px-3 py-3 text-sm leading-5 font-medium text-cool-gray-300 rounded-md hover:text-white hover:bg-cool-gray-700 focus:outline-none focus:text-white focus:bg-cool-gray-700 transition ease-in-out duration-150"
						>
							<svg
								className="h-8 w-8 text-cool-gray-400 group-hover:text-cool-gray-300"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									d="M7 2a1 1 0 011 1v1h3a1 1 0 110 2H9.578a18.87 18.87 0 01-1.724 4.78c.29.354.596.696.914 1.026a1 1 0 11-1.44 1.389c-.188-.196-.373-.396-.554-.6a19.098 19.098 0 01-3.107 3.567 1 1 0 01-1.334-1.49 17.087 17.087 0 003.13-3.733 18.992 18.992 0 01-1.487-2.494 1 1 0 111.79-.89c.234.47.489.928.764 1.372.417-.934.752-1.913.997-2.927H3a1 1 0 110-2h3V3a1 1 0 011-1zm6 6a1 1 0 01.894.553l2.991 5.982a.869.869 0 01.02.037l.99 1.98a1 1 0 11-1.79.895L15.383 16h-4.764l-.724 1.447a1 1 0 11-1.788-.894l.99-1.98.019-.038 2.99-5.982A1 1 0 0113 8zm-1.382 6h2.764L13 11.236 11.618 14z"
									clipRule="evenodd"
									fillRule="evenodd"
								></path>
							</svg>
						</Link>
						{user.is_superuser && (
							<Link
								to="/api-stats"
								className="my-2 group flex items-center px-3 py-3 text-sm leading-5 font-medium text-cool-gray-300 rounded-md hover:text-white hover:bg-cool-gray-700 focus:outline-none focus:text-white focus:bg-cool-gray-700 transition ease-in-out duration-150"
							>
								<svg
									className="h-8 w-8 text-cool-gray-400 group-hover:text-cool-gray-300 group-focus:text-cool-gray-300 transition ease-in-out duration-150"
									stroke="currentColor"
									fill="none"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
									/>
								</svg>
							</Link>
						)}
					</nav>
					<UserMenu user={user} />
				</>
			)}
		</div>
	);
}

export default Header;
