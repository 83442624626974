import React, { useState } from "react";
import Form from "./Form";
import PageHeader from "../../components/PageHeader";
import { resetPassword } from "../Auth/modules/auth";
import { message } from "antd";
import {reset} from 'redux-form';
import { useDispatch } from "react-redux";

export function PasswordReset() {
	
	const [loading, setLoading] = useState(false);
	const [apiError, setApiError] = useState(null);
	const dispatch = useDispatch();
	
	const onSubmit = (credentials) => {
		setLoading(true);
		resetPassword({
			password: credentials.password,
			new_password: credentials.new_password
		})
			.then((res) => {
				setLoading(false);
				setApiError(null);
				dispatch(reset("reset-password"));
				message.success("Your password has been set successfully.");
			})
			.catch((e) => {
				setLoading(false);
				message.error("An error occured while changing password.");
				setApiError(e);
			});
	}

	return (
		<div className="container p-3">
			<PageHeader
				title="Password Reset Confirm"
				subTitle="Please enter your new password"
			/>
			<div className="p-7 shadow-md rounded-md bg-white">
				<Form loading={loading} apiError={apiError} onSubmit={onSubmit} />
			</div>
		</div>
	);
}

export default PasswordReset;
