import React, { useState } from "react";
import Button from "../../../../components/Button";
import TextField from "../../../../components/Forms/TextField";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import { Field, reduxForm } from "redux-form";
import BundleSummary from "../Overview/BundleSummary";
import axios from "axios";
import ApiError from "../../../../components/ApiError";
import { required } from "../../../../services/validations";
import Card, { CardBody } from "../../../../components/Card";
import SelectField from "../../../../components/Forms/SelectField";

const fileFormatOptions = [
	{ value: "wav", label: "wav"},
	{ value: "mp3", label: "mp3"}
];

const FormBody = ({
	onSkipPostProcessing,
	handleSubmit,
	loading,
	hasPostProcessing,
}) => (
	<form onSubmit={handleSubmit}>
		<div className="mb-4">
			<Field
				component={TextField}
				validate={required}
				label="Customer's Filename Preference"
				name="post_processing_filename_template"
			/>
		</div>

		{hasPostProcessing && (
			<>
				<div className="mb-4">
					<Field
						component={TextField}
						validate={required}
						label="Post Processor E-Mail"
						name="post_processing_email"
					/>
				</div>
				<div className="mb-4">
					<Field
						component={TextAreaField}
						label="Post Processing Notes"
						name="post_processing_notes"
					/>
				</div>
				<div className="mb-4">
					<Field
						component={SelectField}
						label="Post Processing Format"
						name="post_processing_format"
						options={fileFormatOptions}
					/>
				</div>
			</>
		)}

		<Button
			block
			htmlType="submit"
			type="success"
			className="h-9"
			loading={loading}
		>
			{hasPostProcessing
				? "Bundle and send for post-processing"
				: "Bundle and deliver to customer"}
		</Button>

		{hasPostProcessing && (
			<div className="mt-4 text-center">
				<a onClick={onSkipPostProcessing}>
					Or, click here to skip post-processing.
				</a>
			</div>
		)}
	</form>
);

const Form = reduxForm({ form: "bundle-cluster" })(FormBody);

const PostProcessingForm = ({ cluster, project, onSuccess }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	if (
		cluster.bundle?.is_for_portal_postprocessing &&
		!cluster.bundle?.finished_at
	) {
		return <BundleSummary bundle={cluster.bundle} />;
	}

	const handleSkipPostProcessing = () => {
		setLoading(true);

		axios
			.post(`/portal/projects/${project.id}/start_post_processing/`, {
				post_processing_skip: "1",
			})
			.then(onSuccess)
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
	};

	const hasPostProcessing = true; // project.distribution || project.edit_mode === "managed";

	return (
		<Card
			title={
				hasPostProcessing
					? "Send for Post Processing"
					: "Bundle and Deliver"
			}
		>
			<CardBody>
				{error && (
					<div className="mb-4">
						<ApiError error={error} />
					</div>
				)}

				<Form
					hasPostProcessing={hasPostProcessing}
					onSkipPostProcessing={handleSkipPostProcessing}
					initialValues={{
						post_processing_email: project.post_processor_email,
						post_processing_notes: project.post_processor_notes,
						post_processing_filename_template:
							project.post_processing_filename_template,
						post_processing_format: project.post_processing_format	
					}}
					loading={loading}
					onSubmit={(values) => {
						
						setLoading(true);

						axios
							.post(
								`/portal/projects/${project.id}/start_post_processing/`,
								{...values, 
									post_processing_format: values.post_processing_format 
										? values.post_processing_format
										: fileFormatOptions[0].value
								}
							)
							.then(onSuccess)
							.catch((e) => {
								setError(e);
								setLoading(false);
							});
							
					}}
				/>
			</CardBody>
		</Card>
	);
};

export default PostProcessingForm;
