import React from "react";
import { reduxForm, Field } from "redux-form";
import * as validation from "../../../services/validations";
import ApiError from "../../../components/ApiError";
import TextField from "../../../components/Forms/TextField";
import TextAreaField from "../../../components/Forms/TextAreaField";
import SelectField from "../../../components/Forms/SelectField";
import SliderField from "../../../components/Forms/SliderField";
import Button from "../../../components/Button";
import classNames from "classnames";
import { useVoices } from "../../../services/voices";

export function Form({
	handleSubmit,
	loading,
	apiError,
	nlpTypes,
	hasUserFullEditingAccess,
	sentenceSpeaker,
}) {
	const voices = useVoices();

	const getVoiceOptions = () => {
		if(voices){
			return voices.filter((voice) => voice.value === sentenceSpeaker).length > 0 
				? voices
				: [...voices, { value: sentenceSpeaker, label: sentenceSpeaker}];
		}
		return [];
	}
	
	return (
		<form onSubmit={handleSubmit}>
			{apiError && <ApiError error={apiError} />}
			{/* TODO: DEPRECATED */}
			<div className="hidden">
				<Field
					label="Title"
					name="title"
					validate={validation.required}
					component={TextField}
					size="small"
				/>
			</div>

			<div
				className={classNames(
					hasUserFullEditingAccess ? "block" : "flex"
				)}
			>
				<div
					className={classNames(
						"mt-3",
						hasUserFullEditingAccess ? "flex" : "flex flex-1"
					)}
				>
					{hasUserFullEditingAccess && (
						<div className="w-1/2 pr-1.5">
							<Field
								label="Model ID"
								name="model_id"
								validate={validation.required}
								component={TextField}
								size="small"
							/>
						</div>
					)}

					<div
						className={classNames(
							"pr-1.5",
							hasUserFullEditingAccess ? "w-1/2" : "w-full"
						)}
					>
						<Field
							label="Speaker"
							name="speaker_id"
							size="small"
							component={SelectField}
							validate={validation.required}
							disabled={voices === null}
							options={getVoiceOptions()}
						/>
					</div>
				</div>

				<div
					className={classNames(
						"mt-3",
						hasUserFullEditingAccess ? "flex" : "flex flex-1"
					)}
				>
					{hasUserFullEditingAccess && (
						<div className="w-1/2 pr-1.5">
							<Field
								label="Character ID"
								name="character_id"
								component={TextField}
								size="small"
							/>
						</div>
					)}

					<div
						className={classNames(
							"pr-1.5",
							hasUserFullEditingAccess ? "w-1/2" : "w-full"
						)}
					>
						<Field
							label="Pause"
							name="pause"
							component={TextField}
							size="small"
						/>
					</div>
				</div>
			</div>

			<div className="mt-3">
				<Field
					label="Text"
					name="text"
					validate={validation.required}
					component={TextAreaField}
					size="small"
					rows="4"
				/>
			</div>

			<div className="my-3">
				<div className="flex">
					<div className="flex-grow">
						<Field
							label="Intensity"
							name="gst_factor"
							validate={validation.number}
							component={SliderField}
							min={0}
							max={1}
							step={0.01}
							className="w-full"
						/>
					</div>
					<div className="w-16 pl-2 pt-4">
						<Field
							name="gst_factor"
							validate={validation.number}
							type="number"
							component={TextField}
							min={0}
							max={1}
							step={0.01}
							className="w-full"
							size="small"
						/>
					</div>
				</div>
			</div>

			<div className="flex -ml-0.5">
				{nlpTypes.map((type, index) => (
					<div key={index} className="mx-1 text-center flex-1">
						<Field
							name={`nlp_${type}`}
							label={type === "love" ? "excitement" : type}
							type="number"
							min={0}
							max={1}
							step={0.01}
							component={TextField}
							size="small"
						/>
						<div className="flex mt-3">
							<div className="relative h-28">
								<Field
									name={`nlp_${type}`}
									min={0}
									max={1}
									step={0.01}
									className="absolute w-24 transform -rotate-90 left-0 top-1/3 mt-8 -ml-4"
									component={SliderField}
								/>
							</div>
						</div>
					</div>
				))}
			</div>

			<Button
				block
				htmlType="submit"
				type="primary"
				size="large"
				loading={loading}
			>
				Resynthesize
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "resynthesize",
	enableReinitialize: true,
})(Form);
