import axios from "axios";
import { message } from "antd";

export const API_STATS_LOAD_TOKENS_SUCCESS = "API_STATS_LOAD_TOKENS_SUCCESS";

export const API_STATS_LOAD = "API_STATS_LOAD";
export const API_STATS_LOAD_SUCCESS = "API_STATS_LOAD_SUCCESS";
export const API_STATS_LOAD_FAIL = "API_STATS_LOAD_FAIL";
export const API_STATS_UPDATE_DATE_RANGE = "API_STATS_UPDATE_DATE_RANGE";

// TODO: TEMPORARY
export const API_STATS_TOKEN =
	"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoyMDU1MzEzNjc2LCJqdGkiOiJkYTczNTQ2NzE0MTY0ODZkOGY3ZDlhNWVkMThjYzJkZCIsInVzZXJfaWQiOjF9.M2crjOorTd_lAAFIA7ZLX7KYyQbRPWXT3OoWvtBtx8o";

export const API_STATS_BASE_URL = "https://api.deepzen.io/";

export const loadTokens = () => (dispatch) =>
	axios
		.get(`${API_STATS_BASE_URL}v1/tokens/`, {
			headers: {
				Authorization: `Bearer ${API_STATS_TOKEN}`,
			},
		})
		.then((res) =>
			dispatch({ type: API_STATS_LOAD_TOKENS_SUCCESS, payload: res.data })
		)
		.catch((e) =>
			message.error("An error occcurred while fetching tokens.")
		);

const loadSuccess = (data) => ({ type: API_STATS_LOAD_SUCCESS, payload: data });
const loadFail = (e) => ({ type: API_STATS_LOAD_FAIL, payload: e });

export const formatDate = (date) => date.toISOString().split("T")[0];

export const load = (token, startDate, endDate) => (dispatch) => {
	dispatch({ type: API_STATS_LOAD, payload: { token, startDate, endDate } });

	axios
		.get(
			`${API_STATS_BASE_URL}v1/stats/?start_date=${formatDate(
				startDate
			)}&end_date=${formatDate(endDate)}&token_id=${token || ""}`,
			{
				headers: {
					Authorization: `Bearer ${API_STATS_TOKEN}`,
				},
			}
		)
		.then((res) => dispatch(loadSuccess(res.data)))
		.catch((e) => dispatch(loadFail(e)));
};

export const updateDateRange = (startDate, endDate) => ({
	type: API_STATS_UPDATE_DATE_RANGE,
	payload: { startDate, endDate },
});

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(new Date().getDate() - 30);

const today = new Date();

const initialState = {
	startDate: thirtyDaysAgo,
	endDate: today,
	loading: false,
	error: null,
	data: null,
	tokens: null,
	selectedToken: null,
};

export default function apiStatsReducer(state = initialState, action) {
	switch (action.type) {
		case API_STATS_LOAD:
			return {
				...state,
				selectedToken: action.payload.token,
				startDate: action.payload.startDate,
				endDate: action.payload.endDate,
				loading: true,
				error: null,
				data: null,
			};
		case API_STATS_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case API_STATS_LOAD_SUCCESS:
			return { ...state, loading: false, data: action.payload };
		case API_STATS_LOAD_TOKENS_SUCCESS:
			return { ...state, tokens: action.payload };
		default:
			return state;
	}
}
