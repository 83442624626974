import React from "react";
import { PropTypes } from "prop-types";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../components/ApiError";
import { Modal } from "antd";
import * as validation from "../../../../services/validations";
import TextField from "../../../../components/Forms/TextField";
import SelectField from "../../../../components/Forms/SelectField";
import { useVoices } from "../../../../services/voices";

export function Form({
	handleSubmit,
	loading,
	apiError,
	onCancel,
	hasUserFullEditingAccess,
}) {
	const voices = useVoices();

	return (
		<Modal
			title="Synthesize Chapter"
			visible
			onOk={handleSubmit}
			okText="Synthesize"
			confirmLoading={loading}
			onCancel={onCancel}
		>
			{apiError && <ApiError error={apiError} />}

			<div className="mb-4 flex space-x-4">
				{hasUserFullEditingAccess && (
					<div className="flex-1">
						<Field
							label="Model"
							name="tts_model"
							validate={validation.required}
							component={TextField}
						/>
					</div>
				)}

				<div className="flex-1">
					<Field
						label="Voice"
						name="tts_voice"
						component={SelectField}
						validate={validation.required}
						disabled={voices === null}
						options={voices ?? []}
					/>
				</div>
			</div>

			{hasUserFullEditingAccess && (
				<div className="mb-4 flex space-x-4">
					<div className="flex-1">
						<Field
							label="Narration Intensity"
							name="tts_gst_factor"
							validate={validation.number}
							component={TextField}
						/>
					</div>
					<div className="flex-1">
						<Field
							label="Conversation Intensity"
							name="tts_gst_factor_quotes"
							validate={validation.number}
							component={TextField}
						/>
					</div>
				</div>
			)}

			{hasUserFullEditingAccess && (
				<>
					<div className="mb-4 flex -mx-2">
						<div className="px-2 flex-1">
							<Field
								label="NLP Type"
								name="tts_nlp_type"
								validate={validation.required}
								component={TextField}
							/>
						</div>
					</div>
				</>
			)}
		</Modal>
	);
}

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	apiError: PropTypes.object,
	onCancel: PropTypes.func.isRequired,
};

export default reduxForm({
	form: "synthesize-book",
})(Form);
