import React from "react";
import { reduxForm, Field } from "redux-form";
import { SelectField } from "redux-form-antd";
import { Button } from "antd";
import TextField from "redux-form-antd/lib/components/TextField";

export function PhonesForm({ count, handleSubmit, phoneData, loading }) {
	return (
		<form onSubmit={handleSubmit}>
			<div className="float-left">
				<Field
					label={null}
					name="phones"
					component={SelectField}
					options={phoneData.map((opt) => ({
						label: opt,
						value: opt,
					}))}
				/>
			</div>

			<div className="float-left">
				<Field
					label={null}
					name="model_id"
					placeholder="Model (optional)"
					component={TextField}
				/>
			</div>

			<Button type="primary" htmlType="submit" loading={loading}>
				Resynthesize&nbsp;{count}&nbsp;sentences
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "cluster-search-resynthesize-phones",
})(PhonesForm);
