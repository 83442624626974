import React from "react";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../components/ApiError";
import { Modal } from "antd";
import * as validation from "../../../../services/validations";
import TextField from "../../../../components/Forms/TextField";
import SelectField from "../../../../components/Forms/SelectField";
import { useVoices } from "../../../../services/voices";

export function Form({
	handleSubmit,
	loading,
	apiError,
	onCancel,
	hasUserFullEditingAccess,
}) {
	const voices = useVoices();

	return (
		<Modal
			title="Duplicate chapter"
			visible={true}
			onOk={handleSubmit}
			okText="Duplicate"
			confirmLoading={loading}
			onCancel={onCancel}
		>
			{apiError && <ApiError error={apiError} />}

			<div className="mb-4">
				<Field
					label="Title"
					name="title"
					validate={validation.required}
					component={TextField}
				/>
			</div>

			<div className="mb-4 flex space-x-4">
				{hasUserFullEditingAccess && (
					<div className="flex-1">
						<Field
							label="Model"
							name="tts_model"
							validate={validation.required}
							component={TextField}
						/>
					</div>
				)}

				<div className="flex-1">
					<Field
						label="Voice"
						name="tts_voice"
						component={SelectField}
						validate={validation.required}
						disabled={voices === null}
						options={voices ?? []}
					/>
				</div>
			</div>

			{hasUserFullEditingAccess && (
				<div className="mb-4 flex space-x-4">
					{hasUserFullEditingAccess && (
						<div className="flex-1">
							<Field
								label="Narration Intensity"
								name="tts_gst_factor"
								validate={validation.number}
								type="number"
								component={TextField}
								step={0.01}
								min={0}
								max={1}
							/>
						</div>
					)}

					<div className="flex-1">
						<Field
							label="NLP Type"
							name="tts_nlp_type"
							validate={validation.required}
							component={TextField}
						/>
					</div>
				</div>
			)}
		</Modal>
	);
}

export default reduxForm({
	form: "clone-book",
})(Form);
