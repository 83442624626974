import React from "react";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../components/ApiError";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import { Modal } from "antd";
import * as validation from "../../../../services/validations";

export function Form({ handleSubmit, loading, apiError, onCancel }) {
	return (
		<Modal
			title="Split sentence"
			visible={true}
			onOk={handleSubmit}
			okText="Submit"
			confirmLoading={loading}
			onCancel={onCancel}
			width={750}
		>
			{apiError && <ApiError error={apiError} />}

			<div className="flex">
				<div className="flex-1 pr-2">
					<Field
						name="old_text"
						validate={validation.required}
						component={TextAreaField}
						rows={5}
						autoFocus
					/>
				</div>
				<div className="flex-1 pl-2">
					<Field
						name="new_text"
						validate={validation.required}
						component={TextAreaField}
						rows={5}
					/>
				</div>
			</div>
		</Modal>
	);
}

export default reduxForm({
	form: "split-sentence",
})(Form);
