export default  [
	{
		type: "consonant",
		ipa: "t",
		deepzen: "t",
		combilex: "t",
		keywords: [{ text: "taller", highlight: [0, 1] }],
		sample: ["tales", 't a l e s'],
	},
	{
		type: "consonant",
		ipa: "p",
		deepzen: "p",
		combilex: "p",
		keywords: [{ text: "pacheco", highlight: [0, 1] }],
		sample: ["pacheco", 'p a tS e k o'],
	},
	{
		type: "consonant",
		ipa: "b",
		deepzen: "b",
		combilex: "b",
		keywords: [{ text: "badajoz", highlight: [0, 1] }],
		sample: ["badajoz", 'b a D a x o s'],
	},
	{
		type: "consonant",
		ipa: "β",
		deepzen: "B",
		combilex: "B",
		keywords: [{ text: "bebés", highlight: [0, 1] }],
		sample: ["bebés", 'b e B e1 s'],
	},
	{
		type: "consonant",
		ipa: "d",
		deepzen: "d",
		combilex: "d",
		keywords: [{ text: "dactilar", highlight: [0, 1] }],
		sample: ["dactilar", 'd a k t i l a r'],
	},
	{
		type: "consonant",
		ipa: "k",
		deepzen: "k",
		combilex: "k",
		keywords: [{ text: "kandani", highlight: [0, 1] }],
		sample: ["kandani", 'k a n d a n i'],
	},
	{
		type: "consonant",
		ipa: "g",
		deepzen: "g",
		combilex: "g",
		keywords: [{ text: "kengo", highlight: [0, 1] }],
		sample: ["kengo", 'k e N g o'],
	},
	{
		type: "consonant",
		ipa: "m",
		deepzen: "m",
		combilex: "m",
		keywords: [{ text: "kilometro", highlight: [0, 1] }],
		sample: ["kilometro", 'k i l o m e t r! o'],
	},
	{
		type: "consonant",
		ipa: "ŋ",
		deepzen: "N",
		combilex: "N",
		keywords: [{ text: "abroncar", highlight: [5, 7] }],
		sample: ["abroncar", 'a B r! o N k a r!'],
	},
	{
		type: "consonant",
		ipa: "f",
		deepzen: "f",
		combilex: "f",
		keywords: [{ text: "acafelar", highlight: [0, 1] }],
		sample: ["acafelar", 'a k a f e l a r!'],
	},
	{
		type: "consonant",
		ipa: "ɣ",
		deepzen: "G",
		combilex: "G",
		keywords: [{ text: "acelga", highlight: [2, 3] }],
		sample: ["acelga", 'a s e l G a'],
	},
	{
		type: "consonant",
		ipa: "s",
		deepzen: "s",
		combilex: "s",
		keywords: [{ text: "acentuar", highlight: [0, 1] }],
		sample: ["acentuar", 'a s e n t w a r!'],
	},
	{
		type: "consonant",
		ipa: "z",
		deepzen: "z",
		combilex: "z",
		keywords: [{ text: "acrobatismo", highlight: [2, 3] }],
		sample: ["acrobatismo", 'a k r! o B a t i z m o'],
	},
	{
		type: "consonant",
		ipa: "ʃ",
		deepzen: "S",
		combilex: "S",
		keywords: [{ text: "flash", highlight: [0, 2] }],
		sample: ["flash", 'f l a S'],
	},
	{
		type: "consonant",
		ipa: "ʝ",
		deepzen: "j!",
		combilex: "j!",
		keywords: [{ text: "acuchillar", highlight: [0, 1] }],
		sample: ["acuchillar", 'a k u tS i j! a r!'],
	},
	{
		type: "consonant",
		ipa: "ɲ",
		deepzen: "n~",
		combilex: "n~",
		keywords: [{ text: "acuña", highlight: [0, 2] }],
		sample: ["acuña", 'a k u n~ a'],
	},
	{
		type: "consonant",
		ipa: "ð",
		deepzen: "D",
		combilex: "D",
		keywords: [{ text: "acuñada", highlight: [0, 2] }],
		sample: ["acuñada", 'a k u n~ a D a'],
	},
	{
		type: "consonant",
		ipa: "ɾ",
		deepzen: "r!",
		combilex: "r!",
		keywords: [{ text: "adaptar", highlight: [0, 1] }],
		sample: ["adaptar", 'a D a p t a r!'],
	},
	{
		type: "consonant",
		ipa: "l",
		deepzen: "l",
		combilex: "l",
		keywords: [{ text: "adela", highlight: [4, 5] }],
		sample: ["adela", 'a D e l a'],
	},
	{
		type: "consonant",
		ipa: "x",
		deepzen: "x",
		combilex: "x",
		keywords: [{ text: "ajusta", highlight: [0, 1] }],
		sample: ["ajusta", 'a x u s t a'],
	},
	{
		type: "consonant",
		ipa: "ʧ",
		deepzen: "tS",
		combilex: "tS",
		keywords: [{ text: "ancho", highlight: [0, 2] }],
		sample: ["ancho", 'a n tS o'],
	},
	{
		type: "consonant",
		ipa: "ʤ",
		deepzen: "dZ",
		combilex: "dZ",
		keywords: [{ text: "inyectable", highlight: [0, 1] }],
		sample: ["inyectable", 'i n dZ e k t a B l e'],
	},
	{
		type: "consonant",
		ipa: "j",
		deepzen: "j",
		combilex: "j",
		keywords: [{ text: "inícialo", highlight: [0, 1] }],
		sample: ["inícialo", 'i n i1 s j a l o'],
	},
	{
		type: "consonant",
		ipa: "w",
		deepzen: "w",
		combilex: "w",
		keywords: [{ text: "irapuato", highlight: [0, 1] }],
		sample: ["irapuato", 'i r! a p w a t o'],
	},
  {
		type: "consonant",
		ipa: "n",
		deepzen: "n",
		combilex: "n",
		keywords: [{ text: "ironman", highlight: [0, 1] }],
		sample: ["ironman", 'i r! o n m a n'],
	},
  {
		type: "consonant",
		ipa: "r",
		deepzen: "r",
		combilex: "r",
		keywords: [{ text: "irracional", highlight: [0, 1] }],
		sample: ["irracional", 'i r a s j o n a l'],
	},
	{
		type: "vowel",
		ipa: "a",
		deepzen: "a",
		combilex: "a",
		keywords: [{ text: "irrestricta", highlight: [1, 2] }],
		sample: ["irrestricta", 'i r e s t r! i k t a'],
	},
	{
		type: "vowel",
		ipa: "e",
		deepzen: "e",
		combilex: "e",
		keywords: [{ text: "irrestricto", highlight: [9, 10] }],
		sample: ["irrestricto", 'i r e s t r! i k t o'],
	},
	{
		type: "vowel",
		ipa: "o",
		deepzen: "o",
		combilex: "o",
		keywords: [{ text: "irresuelto", highlight: [1, 3] }],
		sample: ["irresuelto", 'i r e s w e l t o'],
	},
	{
		type: "vowel",
		ipa: "u",
		deepzen: "u",
		combilex: "u",
		keywords: [{ text: "irrumpe", highlight: [1, 2] }],
		sample: ["irrumpe", 'i r u m p e'],
	},
	{
		type: "vowel",
		ipa: "i",
		deepzen: "i",
		combilex: "i",
		keywords: [{ text: "irrumpir", highlight: [1, 2] }],
		sample: ["irrumpir", 'i r u m p i r!'],
	},
];