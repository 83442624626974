import { connect } from "react-redux";
import InsertSentence from "../../components/InsertSentence";
import { insert, toggle } from "../../modules/insertSentence";
import { sentenceByIdSelector } from "../../modules/editor";

const mapStateToProps = (state) => ({
	loading: state.insertSentence.loading,
	error: state.insertSentence.error,
	initialValues: {
		old_text: sentenceByIdSelector(state, state.editor.activeSentenceIds[0])
			.active_version.text,
	},
});

const mapDispatchToProps = {
	insert,
	toggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsertSentence);
