import axios from "axios";
import { message } from "antd";
import audioBufferer from "./audioBufferer";
import { hasUserFullEditingAccess } from "../../Auth/modules/auth";

export const SENTENCE_DETAILS_LOAD_SUCCESS = "SENTENCE_DETAILS_LOAD_SUCCESS";
export const SENTENCE_DETAILS_LOAD_FAIL = "SENTENCE_DETAILS_LOAD_FAIL";
export const SENTENCE_DETAILS_RESYNT_SENTENCE =
	"SENTENCE_DETAILS_RESYNT_SENTENCE";
export const SENTENCE_DETAILS_RESYNT_SENTENCE_FAIL =
	"SENTENCE_DETAILS_RESYNT_SENTENCE_FAIL";
export const SENTENCE_DETAILS_ACTIVATE = "SENTENCE_DETAILS_ACTIVATE";
export const SENTENCE_DETAILS_ACTIVATE_FAIL = "SENTENCE_DETAILS_ACTIVATE_FAIL";

const loadSuccess = (data) => {
	return (dispatch, getState) => {
		audioBufferer.buffer(getState().editor.sentencesById, data.sentence.id);

		dispatch({
			type: SENTENCE_DETAILS_LOAD_SUCCESS,
			payload: data,
		});
	};
};
const loadFail = (e) => ({ type: SENTENCE_DETAILS_LOAD_FAIL, payload: e });

export function load(id) {
	return (dispatch) => {
		axios
			.get(`sentences/${id}/editor/`)
			.then((res) => dispatch(loadSuccess(res.data)))
			.catch((e) => dispatch(loadFail(e)));
	};
}

const resynthesizeFail = (e) => ({
	type: SENTENCE_DETAILS_RESYNT_SENTENCE_FAIL,
	payload: e,
});

export function resynthesize(sentenceId, values) {
	return (dispatch, getState) => {
		dispatch({
			type: SENTENCE_DETAILS_RESYNT_SENTENCE,
			payload: sentenceId,
		});

		const {
			auth: { user },
		} = getState();

		let payload = { ...values };

		if (!hasUserFullEditingAccess(user)) {
			delete payload["model_id"];
		}

		axios
			.post(`sentences/${sentenceId}/resynthesize/`, payload)
			.catch((e) => dispatch(resynthesizeFail(e)));
	};
}

function activateFail(e) {
	return (dispatch) => {
		message.error("Could not activate version.");
		dispatch({ type: SENTENCE_DETAILS_ACTIVATE_FAIL, payload: e });
	};
}

export function activate(sentenceId, versionId) {
	return (dispatch) => {
		dispatch({ type: SENTENCE_DETAILS_ACTIVATE, payload: versionId });

		axios
			.post(`sentences/${sentenceId}/activate_version/`, {
				version_id: versionId,
			})
			.then((res) => dispatch(load(sentenceId)))
			.catch((e) => dispatch(activateFail(e)));
	};
}

const initialState = {
	loading: false,
	error: null,
	id: null,
	versions: [],
	resynthesizeLoading: false,
	resynthesizeError: null,
	activatingVersionId: null,
};

export default function sentenceDetailsReducer(state = initialState, action) {
	switch (action.type) {
		case SENTENCE_DETAILS_LOAD_SUCCESS:
			return {
				...initialState,
				id: action.payload.sentence.id,
				versions: action.payload.versions,
			};
		case SENTENCE_DETAILS_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case SENTENCE_DETAILS_RESYNT_SENTENCE:
			return {
				...state,
				resynthesizeLoading: true,
				resynthesizeError: null,
			};
		case SENTENCE_DETAILS_RESYNT_SENTENCE_FAIL:
			return {
				...state,
				resynthesizeLoading: false,
				resynthesizeError: action.payload,
			};
		case SENTENCE_DETAILS_ACTIVATE:
			return { ...state, activatingVersionId: action.payload };
		case SENTENCE_DETAILS_ACTIVATE_FAIL:
			return { ...state, activatingVersionId: null };
		default:
			return state;
	}
}
