export default [
	{
		type: "consonant",
		ipa: "?",
		combilex: "?",
		deepzen: "?",
		keywords: [{ text: "abalieniere", highlight: [1, 2] }],
		sample: ["abalieniere", "a2 b ? a: l i @ n i:1 r @"],
	},
	{
		type: "consonant",
		ipa: "b",
		combilex: "b",
		deepzen: "b",
		keywords: [{ text: "bier", highlight: [0, 1] }],
		sample: ["bier", "b i:1 a!"],
	},
	{
		type: "consonant",
		ipa: "d",
		combilex: "d",
		deepzen: "d",
		keywords: [{ text: "dach", highlight: [0, 1] }],
		sample: ["dach", "d a1 x"],
	},
	{
		type: "consonant",
		ipa: "ç",
		combilex: "C",
		deepzen: "C",
		keywords: [{ text: "ich", highlight: [1, 3] }],
		sample: ["ich", "i1 C"],
	},
	{
		type: "consonant",
		ipa: "f",
		combilex: "f",
		deepzen: "f",
		keywords: [{ text: "vogel", highlight: [0, 1] }],
		sample: ["vogel", "f o:1 g @ l"],
	},
	{
		type: "consonant",
		ipa: "g",
		combilex: "g",
		deepzen: "g",
		keywords: [{ text: "gabel", highlight: [0, 1] }],
		sample: ["gabel", "g a:1 b @ l"],
	},
	{
		type: "consonant",
		ipa: "h",
		combilex: "h",
		deepzen: "h",
		keywords: [{ text: "haus", highlight: [0, 1] }],
		sample: ["haus", "h aU1 z)"],
	},
	{
		type: "consonant",
		ipa: "j",
		combilex: "j",
		deepzen: "j",
		keywords: [{ text: "jemand", highlight: [0, 1] }],
		sample: ["jemand", "j e:1 m a n t"],
	},
	{
		type: "consonant",
		ipa: "k",
		combilex: "k",
		deepzen: "k",
		keywords: [{ text: "kleid", highlight: [0, 1] }],
		sample: ["kleid", "k l aI1 t"],
	},
	{
		type: "consonant",
		ipa: "l",
		combilex: "l",
		deepzen: "l",
		keywords: [{ text: "loch", highlight: [0, 1] }],
		sample: ["loch", "l O1 x"],
	},
	{
		type: "consonant",
		ipa: "m",
		combilex: "m",
		deepzen: "m",
		keywords: [{ text: "milch", highlight: [0, 1] }],
		sample: ["milch", "m i1 l C"],
	},
	{
		type: "consonant",
		ipa: "n",
		combilex: "n",
		deepzen: "n",
		keywords: [{ text: "natur", highlight: [0, 1] }],
		sample: ["natur", "n a t u:1 a!"],
	},
	{
		type: "consonant",
		ipa: "ŋ",
		combilex: "N",
		deepzen: "N",
		keywords: [{ text: "klingen", highlight: [3, 5] }],
		sample: ["klingen", "k l i1 N @ n"],
	},
	{
		type: "consonant",
		ipa: "p",
		combilex: "p",
		deepzen: "p",
		keywords: [{ text: "park", highlight: [0, 1] }],
		sample: ["park", "p a1 r k"],
	},
	{
		type: "consonant",
		ipa: "ʀ",
		combilex: "r",
		deepzen: "r",
		keywords: [{ text: "regen", highlight: [0, 1] }],
		sample: ["regen", "r e:1 g @ n"],
	},
	{
		type: "consonant",
		ipa: "s",
		combilex: "s",
		deepzen: "s",
		keywords: [{ text: "messer", highlight: [2, 4] }],
		sample: ["messer", "m E1 s a!"],
	},
	{
		type: "consonant",
		ipa: "ʃ",
		combilex: "S",
		deepzen: "S",
		keywords: [{ text: "fischer", highlight: [2, 5] }],
		sample: ["fischer", "f i1 S a!"],
	},
	{
		type: "consonant",
		ipa: "t",
		combilex: "t",
		deepzen: "t",
		keywords: [{ text: "topf", highlight: [0, 1] }],
		sample: ["topf", "t O1 p f"],
	},
	{
		type: "consonant",
		ipa: "t͡s",
		combilex: "ts",
		deepzen: "ts",
		keywords: [{ text: "zahl", highlight: [0, 1] }],
		sample: ["zahl", "ts a:1 l"],
	},
	{
		type: "consonant",
		ipa: "t͡ʃ",
		combilex: "tS",
		deepzen: "tS",
		keywords: [{ text: "deutsch", highlight: [3, 7] }],
		sample: ["deutsch", "d OY1 tS"],
	},
	{
		type: "consonant",
		ipa: "v",
		combilex: "v",
		deepzen: "v",
		keywords: [{ text: "wasser", highlight: [0, 1] }],
		sample: ["wasser", "v a1 s a!"],
	},
	{
		type: "consonant",
		ipa: "x",
		combilex: "x",
		deepzen: "x",
		keywords: [{ text: "kochen", highlight: [2, 4] }],
		sample: ["kochen", "k O1 x @ n"],
	},
	{
		type: "consonant",
		ipa: "z",
		combilex: "z",
		deepzen: "z",
		keywords: [{ text: "kochkurse", highlight: [7, 8] }],
		sample: ["kochkurse", "k O1 x k U2 r z @"],
	},
	{
		type: "consonant",
		ipa: "ʒ",
		combilex: "Z",
		deepzen: "Z",
		keywords: [{ text: "orange", highlight: [4, 5] }],
		sample: ["orange", "O r a~1 Z"],
	},
	{
		type: "vowel",
		optionalStress: true, // This vowel can be not stressed unlike others
		ipa: "ə",
		combilex: "@",
		deepzen: "@",
		keywords: [{ text: "rede", highlight: [1, 2] }],
		sample: ["rede", "r e:1 d @"],
	},
	{
		type: "vowel",
		ipa: "a",
		combilex: "a",
		deepzen: "a",
		keywords: [{ text: "salz", highlight: [1, 2] }],
		sample: ["salz", "z a1 l ts"],
	},
	{
		type: "vowel",
		ipa: "a:",
		combilex: "a:",
		deepzen: "a:",
		keywords: [{ text: "sahne", highlight: [1, 3] }],
		sample: ["sahne", "z a:1 n @"],
	},
	{
		type: "vowel",
		ipa: "aɪ",
		combilex: "aI",
		deepzen: "aI",
		keywords: [{ text: "nein", highlight: [1, 3] }],
		sample: ["nein", "n aI1 n"],
	},
	{
		type: "vowel",
		ipa: "aʊ",
		combilex: "aU",
		deepzen: "aU",
		keywords: [{ text: "augen", highlight: [0, 2] }],
		sample: ["augen", "aU1 g @ n"],
	},
	{
		type: "vowel",
		ipa: "œ",
		combilex: "O/",
		deepzen: "O/",
		keywords: [{ text: "können", highlight: [1, 2] }],
		sample: ["können", "k O/1 n @ n"],
	},
	{
		type: "vowel",
		ipa: "ɐ",
		combilex: "a!",
		deepzen: "a!",
		keywords: [{ text: "besser", highlight: [4, 6] }],
		sample: ["besser", "b E1 s a!"],
	},
	{
		type: "vowel",
		ipa: "øː",
		combilex: "o!",
		deepzen: "o!",
		keywords: [{ text: "böse", highlight: [1, 2] }],
		sample: ["böse", "b o!1 z @"],
	},
	{
		type: "vowel",
		ipa: "i:",
		combilex: "i:",
		deepzen: "i:",
		keywords: [{ text: "lied", highlight: [1, 3] }],
		sample: ["lied", "l i:1 t"],
	},
	{
		type: "vowel",
		ipa: "ɪ",
		combilex: "i",
		deepzen: "i",
		keywords: [{ text: "bitte", highlight: [1, 2] }],
		sample: ["bitte", "b i1 t @"],
	},
	{
		type: "vowel",
		ipa: "y",
		combilex: "y:",
		deepzen: "y:",
		keywords: [{ text: "kühl", highlight: [1, 3] }],
		sample: ["kühl", "k y:1 l"],
	},
	{
		type: "vowel",
		ipa: "ʏ",
		combilex: "y",
		deepzen: "y",
		keywords: [{ text: "mütter", highlight: [1, 2] }],
		sample: ["mütter", "m y1 t a!"],
	},
	{
		type: "vowel",
		ipa: "ɔʏ",
		combilex: "OY",
		deepzen: "OY",
		keywords: [{ text: "neu", highlight: [1, 3] }],
		sample: ["neu", "n OY1"],
	},
	{
		type: "vowel",
		ipa: "o:",
		combilex: "o:",
		deepzen: "o:",
		keywords: [{ text: "kohl", highlight: [1, 3] }],
		sample: ["kohl", "k o:1 l"],
	},
	{
		type: "vowel",
		ipa: "ɔ",
		combilex: "O",
		deepzen: "O",
		keywords: [{ text: "koffer", highlight: [1, 2] }],
		sample: ["koffer", "k O1 f a!"],
	},
	{
		type: "vowel",
		ipa: "u:",
		combilex: "u:",
		deepzen: "u:",
		keywords: [{ text: "bruder", highlight: [2, 3] }],
		sample: ["bruder", "b r u:1 d a!"],
	},
	{
		type: "vowel",
		ipa: "ʊ",
		combilex: "U",
		deepzen: "U",
		keywords: [{ text: "wunder", highlight: [1, 2] }],
		sample: ["wunder", "v U1 n d a!"],
	},
	{
		type: "vowel",
		ipa: "e:",
		combilex: "e:",
		deepzen: "e:",
		keywords: [{ text: "resümee", highlight: [5, 7] }],
		sample: ["resümee", "r E2 z y: m e:1"],
	},
	{
		type: "vowel",
		ipa: "ɛ",
		combilex: "E",
		deepzen: "E",
		keywords: [{ text: "keller", highlight: [1, 2] }],
		sample: ["keller", "k E1 l a!"],
	},
	{
		type: "vowel",
		ipa: "ɛ̃",
		combilex: "e~",
		deepzen: "e~",
		keywords: [{ text: "terrain", highlight: [4, 7] }],
		sample: ["terrain", "t E r e~1"],
	},
	{
		type: "vowel",
		ipa: "ɑ̃",
		combilex: "a~",
		deepzen: "a~",
		keywords: [{ text: "restaurant", highlight: [7, 9] }],
		sample: ["restaurant", "r E1 s t O r a~ t"],
	},
	{
		type: "vowel",
		ipa: "ɔ̃",
		combilex: "O~",
		deepzen: "O~",
		keywords: [{ text: "annonce", highlight: [3, 5] }],
		sample: ["annonce", "a n O~1 s @"],
	},
];
