import React from "react";
import { reduxForm, Field } from "redux-form";
import { required, email, passwordConfirmation, password } from "../../services/validations";
import ApiError from "../../components/ApiError";
import TextField from "../../components/Forms/TextField";
import Button from "../../components/Button";

export function Form({ handleSubmit, loading, apiError }) {

	const passwordRules = [
		{
			id: 1,
			description: "Your password can't be too similar to your other personal information."
		},
		{
			id: 2,
			description: "Your password must contain at least 8 characters."
		},
		{
			id: 3,
			description: "Your password can't be a commonly used password."
		},
		{
			id: 4,
			description: "Your password can't be entirely numeric"
		}
	];

	return (
		<form onSubmit={handleSubmit}>
			{apiError && (
				<div className="mb-6">
					<ApiError error={apiError}/>
				</div>
			)}

			<div className="mb-6">
				<Field
					label="Password*"
					name="password"
					component={TextField}
					type="password"
					validate={[required]}
					showError={true}
				/>
			</div>

			<div className="mb-6">
				<Field
					label="New password*"
					name="new_password"
					component={TextField}
					type="password"
					validate={[required, password]}
					showError={true}
				/>
			</div>
			
			<div className="mb-6">
				<ul className="list-disc ml-8">
					{passwordRules && passwordRules.map((rule) => (
						<li key={rule.id} className="text-sm text-gray-500">
							{rule.description}
						</li>
					))}
				</ul>
			</div>

            <div className="mb-6">
				<Field
					label="New password confirmation*"
					name="password_confirmation"
					component={TextField}
					type="password"
					validate={[required, passwordConfirmation]}
					showError={true}
				/>
			</div>

			<div className="flex justify-end">
				<Button
					htmlType="submit"
					type="primary"
					size="large"
					className="leading-7"
					loading={loading}
				>
					Reset password
				</Button>
			</div>
		</form>
	);
}

export default reduxForm({
	form: "reset-password",
})(Form);
