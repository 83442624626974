import React, { useState } from "react";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import ApiError from "../../../../components/ApiError";
import TextField from "../../../../components/Forms/TextField";
import Button from "../../../../components/Button";
import Card, { CardBody } from "../../../../components/Card";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import { required, number } from "../../../../services/validations";
import { browserHistory } from "react-router";
import SelectField from "../../../../components/Forms/SelectField";
import { useVoices } from "../../../../services/voices";

const Form = ({ handleSubmit, apiError, loading, voices }) => {

	return (
	<>
		{apiError && (
			<div className="mb-4">
				<ApiError error={apiError} />
			</div>
		)}

		<form onSubmit={handleSubmit}>
			<div className="mb-4">
				<Field
					name="title"
					label="Title"
					component={TextField}
					validate={required}
				/>
			</div>
			<div className="mb-4 flex -mx-2">
				<div className="px-2 flex-1">
					<Field
						name="tts_model"
						label="Model"
						component={TextField}
						validate={required}
					/>
				</div>
				<div className="px-2 flex-1">
					<Field
						label="Voice"
						name="tts_voice"
						component={SelectField}
						disabled={voices === null}
						options={voices ?? []}
					/>
				</div>
				<div className="px-2 flex-1">
					<Field
						name="tts_gst_factor"
						label="Narration Intensity"
						component={TextField}
						validate={number}
					/>
				</div>
				<div className="px-2 flex-1">
					<Field
						name="tts_gst_factor_quotes"
						label="Conversation Intensity"
						component={TextField}
						validate={number}
					/>
				</div>
			</div>
			<div className="mb-4">
				<Field
					name="text"
					label="Text"
					component={TextAreaField}
					rows={20}
					validate={required}
				/>
			</div>

			<Button type="primary" loading={loading}>
				Send
			</Button>
		</form>
	</>
  );
}

const ConnectedForm = reduxForm({
	form: "cluster-create-book",
	initialValues: {
		tts_gst_factor: 0.4
	}
})(Form);

const CreateBook = ({ params }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const voices = useVoices();

	const onSubmit = (values) => {
		setLoading(true);
		setError(null);
		const payload = { ...values, tts_voice: !values.tts_voice ? voices[0]['value'] : values.tts_voice };
		
		axios
			.post(`clusters/${params.id}/books/`, payload)
			.then((res) => browserHistory.push(`/books/${res.data.id}`))
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
	};

	if(!voices) return null;

	return (
		<Card title="New chapter">
			<CardBody>
				<ConnectedForm
					onSubmit={onSubmit}
					loading={loading}
					apiError={error}
					voices={voices}
				/>
			</CardBody>
		</Card>
	);
};

export default CreateBook;
