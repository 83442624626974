import React from "react";
import SentenceContainer from "../containers/SentenceContainer";

export function Paragraph({ sentenceIds }) {
	return (
		<div className="mb-6">
			{sentenceIds.map((id) => (
				<SentenceContainer key={id} id={id} />
			))}
		</div>
	);
}

export default Paragraph;
