import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField, TextAreaField, SelectField } from "redux-form-antd";
import { Button } from "antd";
import { required } from "../../../../../services/validations";
import ApiError from "../../../../../components/ApiError";

function Form({ handleSubmit, apiError, loading, lexicons }) {
	return (
		<form onSubmit={handleSubmit}>
			{apiError && <ApiError error={apiError} />}

			<Field
				label="Title"
				name="title"
				component={TextField}
				validate={[required]}
			/>

			<Field
				label="Lexicons"
				name="lexicons"
				component={SelectField}
				mode="multiple"
				loading={lexicons === null}
				options={
					lexicons &&
					lexicons.map((lex) => ({
						label: lex.id,
						value: lex.id,
					}))
				}
				validate={[required]}
			/>

			<Field
				label="Text"
				name="text"
				component={TextAreaField}
				validate={[required]}
				rows={20}
			/>

			<Button
				size="large"
				type="primary"
				htmlType="submit"
				loading={loading}
			>
				Create
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "lexicon-sessions-create",
	enableReinitialize: true,
})(Form);
