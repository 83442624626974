import { connect } from "react-redux";
import { loadText, reset } from "../modules/epubEditor";
import EpubEditor from "../components/EpubEditor";

const mapStateToProps = state => state.epubEditor;

const mapDispatchToProps = {
	loadText,
	reset
};

export default connect(mapStateToProps, mapDispatchToProps)(EpubEditor);
