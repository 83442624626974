import React, { Component } from "react";
import { extract } from "../modules/extract";
import { Button } from "antd";

const defaultStyles =
	"body { margin-top: 20px;font-family: monospace; outline: none !important; } div, p { margin-bottom: 15px; padding: 25px; border-left: 1px solid #ccc } * { outline: 1px dashed #ddd }";

const tooltipStyles = `content: "[tag] > " attr(class); /* print em-space with class text */
display: inline-block;
position: absolute; bottom: 100%;
font-size: 12px;
background: #000; color: #FFF; padding: 2px 5px; border-radius: 5px;
opacity:0; transition:0.1s; overflow:hidden;
max-width: auto; /* avoids very long sentences */
white-space: nowrap;
pointer-events: none;`;

class VisualEditor extends Component {
	state = {
		tags: [],
		classes: [],
		removedTags: [],
		removedClasses: [],
		chapter: 0,
	};

	componentDidMount() {
		this.setState(extract(this.props.manual));
	}

	componentDidUpdate(oldProps, oldState) {
		if (
			oldState.removedTags !== this.state.removedTags ||
			oldState.removedClasses !== this.state.removedClasses ||
			oldState.chapter !== this.state.chapter
		) {
			this.previewIframe.contentWindow.location.reload(true);
		}
	}

	handleIframeLoad = (e) => {
		const iframe = this.previewIframe;

		// EPub
		iframe.contentDocument.open();
		iframe.contentDocument.write(
			this.props.manual[this.state.chapter].text
		);
		iframe.contentDocument.close();

		// Styles
		const el = document.createElement("style");

		el.innerHTML =
			defaultStyles +
			`${this.state.removedTags.join(
				", "
			)} { text-decoration: line-through; opacity: 0.5; color: red }` +
			`${this.state.removedClasses
				.map((c) => `.${c}`)
				.join(
					", "
				)} { text-decoration: line-through; opacity: 0.5; color: red }` +
			this.state.tags
				.map(
					(tag) =>
						`${tag} { position: relative } ${tag}::before { ${tooltipStyles.replace(
							"[tag]",
							tag
						)} } ${tag}:hover { outline: 2px dashed gray; } ${tag}:hover::before { opacity: 1; }`
				)
				.join(" ");

		iframe.contentDocument.head.appendChild(el);
	};

	prevChapter = () => {
		if (this.state.chapter === 0) {
			return;
		}

		this.setState({ chapter: this.state.chapter - 1 });
	};

	nextChapter = () => {
		if (this.state.chapter + 1 === this.props.manual.length) {
			return;
		}

		this.setState({ chapter: this.state.chapter + 1 });
	};

	toggleTag = (tag) => (el) => {
		let updatedTags = this.state.removedTags.filter((t) => t !== tag);

		if (el.target.checked) {
			updatedTags.push(tag);
		}

		this.setState({
			removedTags: updatedTags,
		});
	};

	toggleClass = (className) => (el) => {
		let updatedClasses = this.state.removedClasses.filter(
			(t) => t !== className
		);

		if (el.target.checked) {
			updatedClasses.push(className);
		}

		this.setState({
			removedClasses: updatedClasses,
		});
	};

	render() {
		const { tags, classes, chapter } = this.state;

		return (
			<div className="epub-parser">
				<div className="epub-parser__preview">
					<h2>
						<Button.Group size="small">
							<Button icon="left" onClick={this.prevChapter} />
							<Button disabled>
								{chapter} / {this.props.manual.length}
							</Button>
							<Button icon="right" onClick={this.nextChapter} />
						</Button.Group>
						<span className="title">
							{this.props.manual[chapter].title}
						</span>
					</h2>
					<iframe
						ref={(el) => (this.previewIframe = el)}
						title="Preview"
						onLoad={this.handleIframeLoad}
					/>
				</div>

				<div className="epub-parser__side">
					<h2>Tags to remove</h2>
					<div>
						{tags.map(
							(tag) =>
								tag !== "div" && (
									<label key={tag}>
										<input
											onChange={this.toggleTag(tag)}
											type="checkbox"
										/>{" "}
										{tag}
									</label>
								)
						)}
					</div>

					<h2>Classes to remove</h2>
					<div>
						{classes.map((className) => (
							<label key={className}>
								<input
									onChange={this.toggleClass(className)}
									type="checkbox"
								/>{" "}
								{className}
							</label>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default VisualEditor;
