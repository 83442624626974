import Detail from "./components/Detail";
import CreateChapter from "./CreateChapter";
import SearchContainer from "./Search/containers/SearchContainer";
import Overview from "./Overview";
import PortalDeliveryZone from "./PortalDeliveryZone";
import PortalChapterGenerator from "./PortalChapterGenerator";
import PortalIssues from "./PortalIssues";

export default {
	path: ":id",
	component: Detail,
	indexRoute: { component: Overview },
	childRoutes: [
		{ path: "search", component: SearchContainer },
		{ path: "chapters/create", component: CreateChapter },
		{ path: "portal/delivery", component: PortalDeliveryZone },
		{ path: "portal/chapter-generator", component: PortalChapterGenerator },
		{ path: "portal/issues", component: PortalIssues },
	],
};
