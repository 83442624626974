export const extract = data => {
	let classes = [],
		tags = [];

	data.forEach(({ title, text }) => {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(text, "text/xml");

		xmlDoc.querySelectorAll("[class]").forEach(e => {
			e.getAttribute("class")
				.split(" ")
				.forEach(cls => {
					if (cls.length > 0 && classes.indexOf(cls) < 0)
						classes.push(cls);
				});
		});

		var recurse = function(el) {
			// if element node
			if (el.nodeType == 1) {
				if (!tags[el.tagName]) tags[el.tagName] = 0;
				tags[el.tagName]++;
			}
			// recurse through the children
			for (
				var i = 0, children = el.childNodes, len = children.length;
				i < len;
				i++
			) {
				recurse(children[i]);
			}
		};

		// if you want just what's in the body(included)
		var bodies = xmlDoc.getElementsByTagName("body");
		for (var i = 0; i < bodies.length; i++) recurse(bodies[i]);
	});

	return {
		classes: classes.sort(),
		tags: Object.keys(tags)
			.sort()
			.filter(t => ["body", "html", "br", "p"].indexOf(t) === -1)
	};
};
