import { connect } from "react-redux";
import Resynthesize from "../components/Resynthesize";
import { resynthesize } from "../modules/search";

const mapStateToProps = (state) => ({
	checkedSentenceIds: state.search.checkedSentenceIds,
	cluster: state.search.cluster,
	phoneData: state.search.phoneData,
	query: state.search.query,
	loading: state.search.resynthesizeLoading,
});

const mapDispatchToProps = {
	resynthesize,
};

export default connect(mapStateToProps, mapDispatchToProps)(Resynthesize);
