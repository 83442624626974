import axios from "axios";
import { message } from "antd";
import { CLUSTER_DETAIL_UPDATE_SUCCESS } from "../../../modules/statusUpdater";

export const CLUSTERS_DETAIL_LOAD = "CLUSTERS_DETAIL_LOAD";
export const CLUSTERS_DETAIL_LOAD_SUCCESS = "CLUSTERS_DETAIL_LOAD_SUCCESS";
export const CLUSTERS_DETAIL_LOAD_FAIL = "CLUSTERS_DETAIL_LOAD_FAIL";

export const CLUSTERS_STATS_LOAD = "CLUSTERS_STATS_LOAD";
export const CLUSTERS_STATS_LOAD_SUCCESS = "CLUSTERS_STATS_LOAD_SUCCESS";
export const CLUSTERS_STATS_LOAD_FAIL = "CLUSTERS_STATS_LOAD_FAIL";

export const CLUSTERS_PORTAL_PROJECT_LOAD = "CLUSTERS_PORTAL_PROJECT_LOAD";
export const CLUSTERS_PORTAL_PROJECT_LOAD_SUCCESS =
	"CLUSTERS_PORTAL_PROJECT_LOAD_SUCCESS";
export const CLUSTERS_PORTAL_PROJECT_LOAD_FAIL =
	"CLUSTERS_PORTAL_PROJECT_LOAD_FAIL";

export const bundle = (id, filenameTemplate, includeVisemes) => {
	message.info("Starting bundler...");

	axios
		.post(`/clusters/${id}/bundle/`, {
			filename_template: filenameTemplate,
			include_visemes: includeVisemes ? "1" : "0",
		})
		.then((res) =>
			message.success(
				"Book will be bundled and download URL will be sent on Slack."
			)
		)
		.catch((e) =>
			message.error(
				"An error occurred while starting bundling of the book."
			)
		);
};

const loadSuccess = (data) => ({
	type: CLUSTERS_DETAIL_LOAD_SUCCESS,
	payload: data,
});
const loadFail = (e) => ({ type: CLUSTERS_DETAIL_LOAD_FAIL, payload: e });

export function load(id) {
	return (dispatch) => {
		dispatch({ type: CLUSTERS_DETAIL_LOAD });

		axios
			.get(`/clusters/${id}/`)
			.then((res) => dispatch(loadSuccess(res.data)))
			.catch((e) => dispatch(loadFail(e)));
	};
}

const loadStatsSuccess = (data) => ({
	type: CLUSTERS_STATS_LOAD_SUCCESS,
	payload: data,
});
const loadStatsFail = (e) => ({ type: CLUSTERS_STATS_LOAD_FAIL, payload: e });

export function loadStats(id) {
	return (dispatch) => {
		dispatch({ type: CLUSTERS_STATS_LOAD });

		axios
			.get(`/clusters/${id}/stats/`)
			.then((res) => dispatch(loadStatsSuccess(res.data)))
			.catch((e) => dispatch(loadStatsFail(e)));
	};
}

const loadPortalProjectSuccess = (data) => ({
	type: CLUSTERS_PORTAL_PROJECT_LOAD_SUCCESS,
	payload: data,
});
const loadPortalProjectFail = (e) => ({
	type: CLUSTERS_PORTAL_PROJECT_LOAD_FAIL,
	payload: e,
});

export function loadPortalProject(id) {
	return (dispatch) => {
		dispatch({ type: CLUSTERS_PORTAL_PROJECT_LOAD });

		axios
			.get(`/clusters/${id}/portal/`)
			.then((res) => dispatch(loadPortalProjectSuccess(res.data)))
			.catch((e) => dispatch(loadPortalProjectFail(e)));
	};
}

const initialState = {
	loading: false,
	data: null,
	error: null,
	stats: null,
	portalProject: null,
	chapterTitles: null
};

export default function clustersReducer(state = initialState, action) {
	switch (action.type) {
		case CLUSTERS_DETAIL_LOAD:
			return { ...initialState, loading: true };
		case CLUSTERS_DETAIL_LOAD_SUCCESS:
			let chapterTitles = {};
			if(action.payload)
				action.payload.books.forEach(
					(chapter) => (chapterTitles[chapter.id] = chapter.title)
				);
			return { ...state, loading: false, data: action.payload, chapterTitles };
		case CLUSTERS_DETAIL_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case CLUSTERS_STATS_LOAD_FAIL:
		case CLUSTERS_STATS_LOAD:
			return { ...initialState, stats: null };
		case CLUSTERS_STATS_LOAD_SUCCESS:
			return { ...state, stats: action.payload };
		case CLUSTERS_PORTAL_PROJECT_LOAD_FAIL:
		case CLUSTERS_PORTAL_PROJECT_LOAD:
			return { ...state, portalProject: null };
		case CLUSTERS_PORTAL_PROJECT_LOAD_SUCCESS:
			return { ...state, portalProject: action.payload };
		case CLUSTER_DETAIL_UPDATE_SUCCESS:
			return { ...state, data: action.payload };
		default:
			return state;
	}
}
