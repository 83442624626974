import React from "react";
import RefreshIcon from "../../../../../components/Icons/Refresh";

export function Sentence({ sentence, checked, onToggleChecked, onPlayAudio, audioId }) {
	return (
		<tr
			onClick={() => onToggleChecked(sentence.id)}
			className="hover:bg-cool-gray-100 cursor-pointer"
		>
			<td
				className="w-64 pl-5 py-1.5 border-b border-cool-gray-200"
				valign="top"
			>
				<audio
					id={`au-${audioId}`}
					className="h-5 w-full mt-0.5"
					controls
					onPlay={(e) => onPlayAudio(audioId)}
					src={sentence.active_version.wav_url}
					controlsList="nodownload"
				/>
			</td>
			<td
				className="w-32 pl-5 py-1.5 border-b border-cool-gray-200"
				valign="top"
			>
				<label className="font-medium text-gray-900 mr-2 pointer-events-none">
					<input
						type="checkbox"
						className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2 pointer-events-none"
						checked={checked}
					/>
					#{sentence.sentence_id}:
				</label>
			</td>
			<td className="pr-5 py-1.5 border-b border-cool-gray-200">
				{sentence.has_active_task && (
					<RefreshIcon size={4} className="mr-1" />
				)}
				{sentence.active_version.text}
			</td>
		</tr>
	);
}

export default Sentence;
