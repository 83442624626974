import React from "react";
import { PropTypes } from "prop-types";
import Form from "./Form";

export function Synthesize({
	bookId,
	synthesize,
	loading,
	initialValues,
	toggle,
	hasUserFullEditingAccess,
}) {
	return (
		<Form
			initialValues={initialValues}
			onSubmit={(values) => synthesize(bookId, values)}
			onCancel={toggle}
			loading={loading}
			hasUserFullEditingAccess={hasUserFullEditingAccess}
		/>
	);
}

Synthesize.propTypes = {
	bookId: PropTypes.number.isRequired,
	initialValues: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	synthesize: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default Synthesize;
