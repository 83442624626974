import React, { useState, useEffect } from "react";
import { Card, Spin } from "antd";
import { PropTypes } from "prop-types";
import Form from "./Form";
import { restrictedWords } from "../modules/edit";
import LexiconAlert from "../../../../components/LexiconAlert";
import { parseCombilex } from "../../../../services/lexicons";

export function Edit({
	token,
	data,
	lexicons,
	loadLoading,
	loadError,
	save,
	deployLoading,
	saveLoading,
	saveError,
	hasUserFullEditingAccess
}) {
	const [lexiconErrorType, setLexiconErrorType] = useState(null);
	const [wordRestricted, setWordRestricted] = useState(false);
	
	useEffect(() => {
		const isWordRestricted = restrictedWords.find(w => w === token);
		setWordRestricted(isWordRestricted);
		
		if(isWordRestricted && !hasUserFullEditingAccess){
			setLexiconErrorType("restrictedWord");
		}

	}, [token]);

	let values =
		data !== null
			? data.reduce(
					(cum, result) =>
						cum.concat(
							result.values.map(value => ({
								token,
								lexicon_id: result.lexicon_id,
								value
							}))
						),
					[]
			  )
			: [];

	if(!hasUserFullEditingAccess){
		values = values.filter((v) => v.lexicon_id.indexOf("main") === -1)
	}
	
	const initialValues = {
		values: values.concat([
			{ lexicon_id: "addendum", value: "", token },
			{ lexicon_id: "addendum", value: "", token }
		])
	};

	const onSave = (token, form) => {
		
		//saved entries without nil pos tag in the main lexicons.
		const initialMainLexiconMap = {};
		initialValues.values.map((e) => {
			if(e.value){
				const [pos] = parseCombilex(e.value);
				if (pos !== "nil" &&  e.lexicon_id.indexOf("main") !== -1){
					initialMainLexiconMap[e.lexicon_id] = e;
				}
			}
		});
		
		//form entries with nil pos tag in the main lexicons.	
		const filteredFormValues = form.values.filter((e) => {
			if(e.value){
				const [pos] = parseCombilex(e.value);
				return pos === "nil" 
					&&  e.lexicon_id.indexOf("main") !== -1;
			}
		});

		const restrictedFormValues = filteredFormValues
			.filter((e) => initialMainLexiconMap[e.lexicon_id]);

		if(restrictedFormValues.length === 0){
			setLexiconErrorType(null);
			save(token, form);
		}else{
			setLexiconErrorType("invalidPosNil");
		}
	}

	return (
		<Card title="Edit Lexicon">
			{loadLoading && <Spin>Loading...</Spin>}
			{loadError && <Spin>Error...</Spin>}
			
			<LexiconAlert type={lexiconErrorType} />
			
			{data !== null && (!wordRestricted || hasUserFullEditingAccess) && (
				<Form
					initialValues={initialValues}
					onSubmit={values => onSave(token, values)}
					apiError={saveError}
					lexicons={lexicons}
					loading={saveLoading || deployLoading}
					hasUserFullEditingAccess={hasUserFullEditingAccess}
				/>
			)}
		</Card>
	);
}

Edit.propTypes = {
	token: PropTypes.string.isRequired,
	data: PropTypes.array,
	lexicons: PropTypes.array,
	loadLoading: PropTypes.bool.isRequired,
	loadError: PropTypes.object,
	save: PropTypes.func.isRequired,
	saveLoading: PropTypes.bool.isRequired,
	deployLoading: PropTypes.bool.isRequired,
	saveError: PropTypes.object
};

export default Edit;
