import React from "react";
import Header from "../components/Header";

const Layout = ({ children }) => (
	<>
		<div className="hidden md:flex md:flex-shrink-0">
			<Header />
		</div>
		<div className="flex flex-col w-0 flex-1 overflow-y-auto">
			{children}
		</div>
	</>
);

export default Layout;
