import { connect } from "react-redux";
import SentenceDetailsResynthesizeForm from "../components/SentenceDetailsResynthesizeForm";
import { resynthesize } from "../modules/sentenceDetails";
import { hasUserFullEditingAccess } from "../../Auth/modules/auth";

const mapStateToProps = (state, { sentence }) => {
	let initialValues = sentence.active_version;

	// NLP
	const json = JSON.parse(initialValues.nlp.replace(/'/g, '"'));
	for (let type in json) {
		initialValues["nlp_" + type] = json[type];
	}

	// Empty title
	initialValues.title =
		"Version " + (state.sentenceDetails.versions.length + 1);
	
	return {
		initialValues,
		apiError: state.sentenceDetails.resynthesizeError,
		loading: state.sentenceDetails.resynthesizeLoading,
		nlpTypes: Object.keys(json).sort(),
		hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user),
		sentenceSpeaker: initialValues.speaker_id,
	};
};

const mapDispatchToProps = { resynthesize };

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SentenceDetailsResynthesizeForm);
