import axios from "axios";
import { message } from "antd";

export const ASSIGNMENT_LOAD = "ASSIGNMENT_LOAD";
export const ASSIGNMENT_LOAD_SUCCESS = "ASSIGNMENT_LOAD_SUCCESS";
export const ASSIGNMENT_LOAD_FAIL = "ASSIGNMENT_LOAD_FAIL";
export const ASSIGNMENT_SAVE = "ASSIGNMENT_SAVE";
export const ASSIGNMENT_SAVE_SUCCESS = "ASSIGNMENT_SAVE_SUCCESS";
export const ASSIGNMENT_SAVE_FAIL = "ASSIGNMENT_SAVE_FAIL";

const loadSuccess = data => ({
	type: ASSIGNMENT_LOAD_SUCCESS,
	payload: data
});
const loadFail = e => ({ type: ASSIGNMENT_LOAD_FAIL, payload: e });

export function load(id) {
	return dispatch => {
		dispatch({ type: ASSIGNMENT_LOAD });
		axios
			.get(`/clusters/${id}/assignment/`)
			.then(res => dispatch(loadSuccess(res.data)))
			.catch(e => dispatch(loadFail(e)));
	};
}

const saveSuccess = data => ({
	type: ASSIGNMENT_SAVE_SUCCESS,
	payload: data
});
const saveFail = e => ({ type: ASSIGNMENT_SAVE_FAIL, payload: e });

export function save(id, userIds) {
	return dispatch => {
		dispatch({ type: ASSIGNMENT_SAVE });
		axios
			.put(`/clusters/${id}/assignment/`, { user_ids: userIds })
			.then(res => dispatch(saveSuccess(res.data)))
			.catch(e => {
				dispatch(saveFail(e));
				message.error("An error occured while assigning users");
			});
	};
}

const initialState = {
	loading: false,
	users: null,
	error: null,
	saving: false
};

export default function assignmentReducer(state = initialState, action) {
	switch (action.type) {
		case ASSIGNMENT_LOAD:
			return { ...initialState, loading: true };
		case ASSIGNMENT_LOAD_SUCCESS:
			return { ...state, loading: false, users: action.payload };
		case ASSIGNMENT_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case ASSIGNMENT_SAVE:
			return { ...state, saving: true };
		case ASSIGNMENT_SAVE_SUCCESS:
		case ASSIGNMENT_SAVE_FAIL:
			return { ...state, saving: false };
		default:
			return state;
	}
}
