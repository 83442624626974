import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import axios from "axios";
import Card from "../../../components/Card";
import ClusterStatusBadge from "../../../components/ClusterStatusBadge";
import {
	Table,
	TableBody,
	TableHead,
	TableHeadCell,
	TableRow,
	TableCell,
} from "../../../components/Table";
import RightArrow from "../../../components/Icons/RightArrow";
import { browserHistory } from "react-router";

const Detail = ({ params }) => {
	const [publisher, setPublisher] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setLoading(true);

		axios
			.get(`publishers/${params.id}/`)
			.then((res) => setPublisher(res.data))
			.catch((e) => setError(e))
			.finally(() => setLoading(false));
	}, [params.id]);

	if (error)
		return (
			<div className="container">
				<ApiError error={error} />
			</div>
		);

	if (!publisher || loading) return <Loading />;

	return (
		<div className="container">
			<PageHeader title={publisher.title} subTitle="Publisher" />

			<Card title="Books">
				<div className="overflow-x-auto">
					<Table>
						<TableHead>
							<TableRow>
								<TableHeadCell width="20" />
								<TableHeadCell width="40%">Title</TableHeadCell>
								<TableHeadCell></TableHeadCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{publisher.clusters.map((cluster) => (
								<TableRow
									key={cluster.id}
									className="cursor-pointer"
									onClick={() =>
										browserHistory.push(
											`/clusters/${cluster.id}`
										)
									}
								>
									<TableCell>
										<ClusterStatusBadge
											status={cluster.status}
										/>
									</TableCell>
									<TableCell className="font-medium pt-3">
										<span className="inline-block max-w-md truncate">
											{cluster.title}
										</span>
									</TableCell>
									<TableCell className="text-right">
										<RightArrow
											size={7}
											className="text-gray-500"
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</Card>
		</div>
	);
};

export default Detail;
