import React from "react";
import classNames from "classnames";

export const TableHead = ({ children }) => <thead>{children}</thead>;
export const TableBody = ({ children }) => <tbody>{children}</tbody>;
export const TableRow = ({ children, className, ...otherProps }) => (
	<tr className={classNames("group", className)} {...otherProps}>
		{children}
	</tr>
);

export const TableHeadCell = ({ children, className, ...otherProps }) => (
	<th
		{...otherProps}
		className={classNames(
			"px-6 py-3 border-b border-t border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider",
			className
		)}
	>
		{children}
	</th>
);

export const TableCell = ({ children, className, ...otherProps }) => (
	<td
		className={classNames(
			"group-hover:bg-gray-50 px-6 py-2 border-b border-gray-100 whitespace-no-wrap text-sm leading-5 text-gray-900",
			className
		)}
		{...otherProps}
	>
		{children}
	</td>
);

export const Table = ({ children, className }) => (
	<table className={classNames("min-w-full", className)}>{children}</table>
);
