import { connect } from "react-redux";
import Resynthesize from "../../components/MultiSentence/Resynthesize";
import { resynthesize } from "../../modules/sentenceDetails";
import { sentenceByIdSelector } from "../../modules/editor";
import { paste } from "../../modules/nlp";

const mapStateToProps = state => ({
	ids: state.editor.activeSentenceIds,
	sentenceForInitialValues: sentenceByIdSelector(state, state.editor.activeSentenceIds[0]),
	clipboard: state.nlp.clipboard,
});

const mapDispatchToProps = { resynthesize, paste };

export default connect(mapStateToProps, mapDispatchToProps)(Resynthesize);