import React, {useEffect, useState} from "react";
import PageHeader from "../../../components/PageHeader";
import ApiError from "../../../components/ApiError";
import axios from "axios";
import Card, {CardBody} from "../../../components/Card";
import ClusterStatusBadge from "../../../components/ClusterStatusBadge";
import {
    Table,
    TableBody,
    TableHead,
    TableHeadCell,
    TableRow,
    TableCell,
} from "../../../components/Table";
import RightArrow from "../../../components/Icons/RightArrow";
import {browserHistory} from "react-router";
import {statuses} from "../../Clusters/modules/portal";
import Badge from "../../../components/Badge";
import classNames from "classnames";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import {message} from "antd";
import Pagination from "../../../components/Pagination";
import SearchBox from "../../../components/SearchBox";
import Spinner from "../../../components/Spinner";
import SelectedListComponent from "./SelectedListComponent";

const TABS = [
    {name: "All", status: null},
    {name: "Pending", status: "pending"},
    {name: "In progress", status: "inprogress"},
    {name: "Ready", status: "ready"},
];

const defaultPageOptions = {
    page: 1,
    pageSize: 25
};

const Detail = ({params}) => {
    const [business, setBusiness] = useState(null);
    const [clusters, setClusters] = useState(null);
    const [loading, setLoading] = useState(2);
    const [error, setError] = useState(null);
    const [tab, setTab] = useState(null);
    const [showBundleModal, setShowBundleModal] = useState(false);
    const [bundleFilenameTemplate, setBundleFilenameTemplate] = useState(
        "{{language}}/{{book_title}}/{{isbn}}-{{chapter_title}}"
    );
    const [bundleOutputFormat, setBundleOutputFormat] = useState("mp3");
    const [bundleSpeed, setBundleSpeed] = useState("1");
    const [searchedValue, setSearchedValue] = useState("");
    const [page, setPage] = useState(defaultPageOptions);
    const [totalSize, setTotalSize] = useState(0);

    const [selectedItems, setSelectedItems] = useState(new Map());

    const handleRemoveItemFromBundleList = (itemId) => {
        setSelectedItems((prevSelectedItems) => {
            const newSelectedItems = new Map(prevSelectedItems);
            newSelectedItems.delete(itemId);
            return newSelectedItems;
        });
    };


    const handleCheckboxChange = (value) => {
        setSelectedItems((prevSelectedItems) => {
            const newSelectedItems = new Map(prevSelectedItems);
            if (newSelectedItems.has(value.id)) {
                newSelectedItems.delete(value.id);
            } else {
                newSelectedItems.set(value.id, value);
            }
            return newSelectedItems;
        });
    };

    const handleSendForPostProcessing = (e) => {
        console.log(e);
    };

    const handleBundle = (e) => {
        e.preventDefault();

        axios
            .post("clusters/bulk_bundle/", {
                ids: Array.from(selectedItems.keys()),
                filename_template: bundleFilenameTemplate,
                output_format: bundleOutputFormat,
                speed: bundleSpeed,
            })
            .then((res) => {
                message.success(
                    "Books will be bundled and download URL will be sent on Slack."
                );
                setSelectedItems(new Map());
                setShowBundleModal(false);
            })
            .catch((e) => console.error(e));
    };

    const fetchClusters = (url, pageParams) => {
        axios
            .get(url)
            .then((res) => {
                setClusters([...res.data?.results]);
                setTotalSize(res.data?.count);
            })
            .catch((e) => setError(e))
            .finally(() => {
                setLoading((loading) => loading - 1);
                setPage(pageParams);
            });
    };

    useEffect(() => {
        axios
            .get(`portal/businesses/${params.id}/`)
            .then((res) => setBusiness(res.data))
            .catch((e) => setError(e))
            .finally(() => setLoading((loading) => loading - 1));

        const statusParam = tab ? `&status=${tab}` : "";
        const clusterListEndpoint = `clusters/?portal_business=${params.id}${statusParam}&page=${defaultPageOptions.page}&page_size=${defaultPageOptions.pageSize}`;
        fetchClusters(clusterListEndpoint, defaultPageOptions);

    }, [params.id]);

    const handleChange = (_searchedValue, _page, tabStatus) => {
        //Reset pagination...
        let newPage = _page;
        if (_searchedValue !== searchedValue) {
            newPage = defaultPageOptions;
        }
        setLoading(true);
        setSearchedValue(_searchedValue);

        const statusParam = tabStatus ? `&status=${tabStatus}` : "";
        const clusterListEndpoint = `clusters/?portal_business=${params.id}${statusParam}&page=${newPage.page}&page_size=${newPage.pageSize}&search=${_searchedValue}`;
        fetchClusters(clusterListEndpoint, newPage);
    };

    if (error)
        return (
            <div className="container">
                <ApiError error={error}/>
            </div>
        );

    if (!business) return null;

    return (
        <div className="container">
            <PageHeader
                title={business?.publisher_name}
                subTitle="Portal Publisher"
            />
            {showBundleModal && (
                <Modal>
                    <Card title={`Bundle ${selectedItems.size} books`}>
                        <CardBody>
                            <form onSubmit={handleBundle}>
                                <input
                                    type="text"
                                    name="filename_template"
                                    value={bundleFilenameTemplate}
                                    onChange={(e) =>
                                        setBundleFilenameTemplate(
                                            e.target.value
                                        )
                                    }
                                    placeholder="Filename template"
                                    className="block bg-white outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5"
                                    autoFocus
                                />

                                <select
                                    value={bundleOutputFormat}
                                    onChange={(e) =>
                                        setBundleOutputFormat(e.target.value)
                                    }
                                    className="block bg-white outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5 mt-4"
                                >
                                    <option value="mp3">MP3</option>
                                    <option value="wav">WAV</option>
                                </select>

                                <select
                                    value={bundleSpeed}
                                    onChange={(e) =>
                                        setBundleSpeed(e.target.value)
                                    }
                                    className="block bg-white outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5 mt-4"
                                >
                                    <option value="0.8">0.8x</option>
                                    <option value="0.85">0.85x</option>
                                    <option value="0.9">0.9x</option>
                                    <option value="0.95">0.95x</option>
                                    <option value="1">1x</option>
                                    <option value="1.05">1.05x</option>
                                    <option value="1.1">1.1x</option>
                                    <option value="1.15">1.15x</option>
                                    <option value="1.2">1.2x</option>
                                </select>

                                <div className="mt-4">
                                    <Button
                                        block
                                        htmlType="submit"
                                        type="success"
                                        className="h-9"
                                    >
                                        Start bundle
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Modal>
            )}
            
            <SelectedListComponent 
                selectedItems={selectedItems}
                onRemoveItem={handleRemoveItemFromBundleList}
                onHandleBundle={setShowBundleModal}
                onHandleSendForPostProcessing={handleSendForPostProcessing}

            />
            
            <Card title="Books">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex" aria-label="Tabs">
                        {TABS.map((t) => (
                            <a
                                key={t.name}
                                onClick={() => {
                                    setTab(t.status);
                                    handleChange(searchedValue, defaultPageOptions, t.status);
                                }}
                                className={classNames(
                                    tab === t.status
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                    "whitespace-nowrap border-b-2 pb-4 pt-0 px-6 text-sm font-medium"
                                )}
                                aria-current={
                                    tab === t.status ? "page" : undefined
                                }
                            >
                                {t.name}
                                {tab === t.status && (
                                    loading ? <Spinner className="w-3 h-3"/> : `(${totalSize})`
                                )}

                            </a>
                        ))}
                    </nav>
                    <SearchBox
                        placeholder="Search by title"
                        className="py-1.5"
                        loading={loading}
                        clear={!searchedValue}
                        onChange={(e) => {
                            handleChange(e, page, tab)
                        }}
                    />
                </div>

                <div className="overflow-x-auto">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadCell width="20">
                                    <a
                                        onClick={() =>
                                            setSelectedItems(new Map(clusters.map(item => [item.id, item])))
                                        }
                                    >
                                        All
                                    </a>
                                    &nbsp;
                                    <a onClick={() => setSelectedItems(new Map())}>None</a>
                                </TableHeadCell>
                                <TableHeadCell width="40%">Title</TableHeadCell>
                                <TableHeadCell >
                                </TableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clusters?.map((cluster) => (
                                <TableRow
                                    key={cluster.id}

                                >
                                    <TableCell
                                        onClick={() => handleCheckboxChange(cluster)}
                                    >
                                        <input
                                            type="checkbox"
                                            value={cluster.id}
                                            checked={selectedItems.has(cluster.id)}
                                            className="pointer-events-none"
                                        />
                                    </TableCell>
                                    <TableCell className="font-medium pt-3">
										<span className="inline-block max-w-md truncate">
											{cluster.title}
										</span>
                                        <div className="flex space-x-2.5">
                                            <ClusterStatusBadge
                                                size="default"
                                                status={cluster.status}
                                            />
                                            <Badge size="default" color="blue">
                                                {statuses[cluster.state]}
                                            </Badge>
                                        </div>
                                    </TableCell>
                                    <TableCell className="text-right cursor-pointer"
                                               onClick={() =>
                                                   browserHistory.push(
                                                       `/clusters/${cluster.id}`
                                                   )
                                               }
                                    >
                                        <RightArrow
                                            size={7}
                                            className="text-gray-500"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {totalSize > 0 && (
                        <Pagination
                            page={page.page}
                            pageSize={page.pageSize}
                            totalSize={totalSize}
                            onChangePage={(e) => handleChange(searchedValue, e, tab)}
                        />
                    )}
                </div>
            </Card>
        </div>
    );
};

export default Detail;
