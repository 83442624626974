import { connect } from "react-redux";
import {
	search,
	load,
	toggleChecked,
	checkAll,
	uncheckAll,
	asyncSentenceUpdated
} from "../modules/search";
import Search from "../components/Search";
import React, { Component } from "react";

class SearchContainer extends Component {
	componentDidMount() {
		this.props.load(this.props.params.id);
	}

	componentDidUpdate(oldProps) {
		if (oldProps.cluster !== this.props.cluster) {
			if (oldProps.cluster !== null) {
				this.cleanupPusherListeners(oldProps.cluster);
			}

			if (this.props.cluster !== null) {
				this.setupPusherListeners();
			}
		}
	}

	cleanupPusherListeners(cluster) {
		cluster.books.forEach(book =>
			window.pusher.unsubscribe(`book-${book.id}`)
		);
	}

	setupPusherListeners() {
		this.props.cluster.books.forEach(book => {
			const channel = window.pusher.subscribe(`book-${book.id}`);
			channel.bind("sentence-updated", data =>
				this.props.asyncSentenceUpdated(data)
			);
		});
	}

	componentWillMount() {
		if (this.props.cluster !== null) {
			this.cleanupPusherListeners(this.props.cluster);
		}
	}

	render() {
		return <Search {...this.props} />;
	}
}

const mapStateToProps = state => state.search;

const mapDispatchToProps = {
	search,
	load,
	toggleChecked,
	checkAll,
	uncheckAll,
	asyncSentenceUpdated
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchContainer);
