import React from "react";
import classNames from "classnames";
import SearchIcon from "../Icons/Search";
import RefreshIcon from "../Icons/Refresh";

const TextField = ({ label, input, meta, className, ...otherProps }) => (
	<div className="relative">
		{label && <label>{label}</label>}

		<input
			className={classNames(
				"py-3 pl-11 pr-4 bg-white w-full rounded-lg outline-none sm:text-sm sm:leading-5 shadow-sm",
				meta.touched && meta.error
					? "border-red-300 text-red-900 focus:border-red-300 focus:shadow-outline-red"
					: "focus:shadow-outline-blue",
				className
			)}
			autoComplete="off"
			{...input}
			{...otherProps}
		/>

		<div class="absolute inset-y-0 left-1 pl-3 flex items-center pointer-events-none">
			{otherProps.loading ? (
				<RefreshIcon size={5} />
			) : (
				<SearchIcon size={5} />
			)}
		</div>
	</div>
);

export default TextField;
