import React from "react";
import classNames from "classnames";

const sizeMap = {
	small: "py-1 px-2",
	default: "",
};

const TextAreaField = ({
	label,
	input,
	meta,
	className,
	size,
	...otherProps
}) => (
	<div className="relative focus-within:z-10 flex-grow">
		{label && (
			<label
				className={classNames(
					"text-gray-500 font-medium mb-0.5 block",
					size === "small" ? "text-xs" : "text-sm"
				)}
			>
				{label}
			</label>
		)}
		<textarea
			className={classNames(
				"block bg-white outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5",
				meta.touched && meta.error
					? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
					: "focus:shadow-outline-blue",
				sizeMap[size || "default"],
				className
			)}
			{...input}
			{...otherProps}
		/>
	</div>
);

export default TextAreaField;
