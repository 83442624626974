import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import Auth from "../components/Auth";
import { loggedInUserSelector } from "../modules/auth";
import { PropTypes } from "prop-types";

export class AuthContainer extends Component {
	static propTypes = {
		user: PropTypes.object,
		protectRedirect: PropTypes.string.isRequired,
	};

	componentDidMount() {
		this.redirectIfLoggedIn();
	}

	componentDidUpdate() {
		this.redirectIfLoggedIn();
	}

	redirectIfLoggedIn() {
		if (
			this.props.user !== null &&
			this.props.activeRoute !== "/auth/logout"
		) {
			browserHistory.push(this.props.protectRedirect);
		}
	}

	render() {
		return <Auth {...this.props} />;
	}
}

export const mapStateToProps = (state) => ({
	user: loggedInUserSelector(state),
	activeRoute: state.location.pathname,
	protectRedirect: state.auth.protectRedirect,
});

export default connect(mapStateToProps, null)(AuthContainer);
