import React from "react";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import Filter from "./Filter";
import * as moment from "moment";
import PageHeader from "../../../components/PageHeader";
import {
	Descriptions,
	DescriptionsItem,
} from "../../../components/Descriptions";
import Badge from "../../../components/Badge";
import Card from "../../../components/Card";
import {
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableHeadCell,
	TableCell,
} from "../../../components/Table";
import { API_URL } from "../../../config";
import { API_STATS_BASE_URL, formatDate } from "../modules/apiStats";

const ApiStats = ({
	startDate,
	endDate,
	tokens,
	selectedToken,
	data,
	loading,
	error,
	load,
}) => {
	return (
		<div className="container p-3">
			<PageHeader
				title="API Stats"
				subTitle="API usage metrics."
				extra={
					<Filter
						tokens={tokens}
						selectedToken={selectedToken}
						startDate={startDate}
						endDate={endDate}
						onChange={(token, startDate, endDate) =>
							load(token, startDate, endDate)
						}
					/>
				}
			/>
			{loading && <Loading />}
			{error && <ApiError error={error} />}

			{data && (
				<>
					<Card>
						<Descriptions>
							{data.metrics.map((metric) => (
								<DescriptionsItem
									key={metric.label}
									label={metric.label}
								>
									{metric.value && metric.format === "integer"
										? metric.value
										: ""}
									{metric.value && metric.format === "float"
										? metric.value.toFixed(2)
										: ""}
									{metric.value &&
									metric.format === "percentage"
										? metric.value.toFixed(2) + "%"
										: ""}
									{metric.value &&
									metric.format === "duration"
										? metric.value.toFixed(2) + " seconds"
										: ""}
								</DescriptionsItem>
							))}
							{selectedToken && (
								<DescriptionsItem label="Billing Report">
									<a
										href={`${API_STATS_BASE_URL}/v1/billing/export?token_id=${selectedToken}&start_date=${formatDate(
											startDate
										)}&end_date=${formatDate(endDate)}`}
										target="_blank"
									>
										Download as excel file
									</a>
								</DescriptionsItem>
							)}
						</Descriptions>
					</Card>

					<br />

					<Card title="Recent jobs">
						<Table>
							<TableHead>
								<TableRow>
									<TableHeadCell>Token</TableHeadCell>
									<TableHeadCell>Creation Date</TableHeadCell>
									<TableHeadCell>Task</TableHeadCell>
									<TableHeadCell>Status</TableHeadCell>
									<TableHeadCell>Webhook</TableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.jobs.map((job) => (
									<TableRow key={job.id}>
										<TableCell>
											<Badge>
												{job.token && job.token.title}
											</Badge>
										</TableCell>
										<TableCell>
											{job.created_at &&
												moment(
													job.created_at
												).fromNow()}
										</TableCell>
										<TableCell>{job.task}</TableCell>
										<TableCell>{job.status}</TableCell>
										<TableCell>
											<span className="text-gray-500 text-xs">
												{job.webhook_url}
											</span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Card>
				</>
			)}
		</div>
	);
};

export default ApiStats;
