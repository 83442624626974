import React, { useState } from "react";
import { Popconfirm } from "antd";
import { secondsToHms } from "../../../services/time";
import Button from "../../../components/Button";
import ClockIcon from "../../../components/Icons/Clock";
import SearchIcon from "../../../components/Icons/Search";
import HeaderSwitch from "./HeaderSwitch";
import { highlightSentence } from "../modules/highlightSentence";
import Dropdown from "../../../components/Dropdown";
import DropdownMenu, {
	DropdownMenuItem,
} from "../../../components/DropdownMenu";
import DotsVertical from "../../../components/Icons/DotsVertical";
import SynthesizeContainer from "../containers/Synthesize/SynthesizeContainer";
import CloneContainer from "../containers/Clone/CloneContainer";
import ReloadTTSSessionContainer from "../containers/ReloadTTSSessionContainer";

export function Header({
	autoPlay,
	autoContinue,
	toggleAutoPlay,
	toggleAutoContinue,
	book,
	activeSentenceStartTime,
	canFinalise,
	finalise,
	showSynthesize,
	toggleSynthesize,
	showClone,
	toggleClone,
	showReload,
	toggleReload,
	hasUserFullEditingAccess,
	getSentenceByTimestamp,
}) {
	const duration = book.total_duration
		? secondsToHms(book.total_duration)
		: "";

	const [sentenceQuery, setSentenceQuery] = useState("");

	const handleSearch = (event) => {
		event.preventDefault();
		
		let searchValue = sentenceQuery;
		if(sentenceQuery.indexOf(":") !== -1){
			const sentence = getSentenceByTimestamp(sentenceQuery);
			searchValue = sentence?.sentence_id;
		}
		
		highlightSentence(searchValue);
	};

	const extraMenu = (
		<DropdownMenu>
			<DropdownMenuItem onClick={toggleSynthesize}>
				{!!book.tts_session_id ? "Start from scratch" : "Synthesize"}
			</DropdownMenuItem>
			{!!book.tts_session_id && (
				<>
					{hasUserFullEditingAccess && (
						<DropdownMenuItem onClick={() => toggleReload()}>
							Reload chapter
						</DropdownMenuItem>
					)}
					<DropdownMenuItem onClick={() => toggleClone()}>
						Duplicate chapter
					</DropdownMenuItem>
				</>
			)}
		</DropdownMenu>
	);

	return (
		<div className="border-b border-gray-200 shadow-sm flex h-10">
			{showSynthesize && (
				<SynthesizeContainer onCancel={toggleSynthesize} />
			)}

			{showClone && <CloneContainer onCancel={toggleClone} />}
			{showReload && <ReloadTTSSessionContainer onCancel={toggleClone} />}

			<div className="flex items-center px-2 bg-gray-50 border-r border-gray-200">
				<ClockIcon size="4" className="mr-1" />{" "}
				{activeSentenceStartTime > 0 &&
					secondsToHms(activeSentenceStartTime) + " / "}{" "}
				{duration}
			</div>

			<div className="flex items-center pl-2 bg-gray-50">
				<HeaderSwitch
					key="ac"
					shortTitle="AC"
					longTitle="Auto Continue"
					checked={autoContinue}
					onChange={toggleAutoContinue}
				/>
			</div>
			<div className="flex items-center pr-2 bg-gray-50 border-r border-gray-200">
				<HeaderSwitch
					key="ap"
					shortTitle="AP"
					longTitle="Auto Play"
					checked={autoPlay}
					onChange={toggleAutoPlay}
				/>
			</div>

			<form onSubmit={handleSearch} className="relative flex">
				<SearchIcon
					size="4"
					className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
				/>

				<input
					placeholder="Find sentence"
					className="bg-gray-100 pl-7 pr-3 py-0.5 w-8 border-r border-gray-200 outline-none focus:shadow-outline-blue focus:w-36 focus:pl-8 transition-all duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-100"
					value={sentenceQuery}
					onBlur={(e) => setSentenceQuery("")}
					onChange={(e) => setSentenceQuery(e.target.value)}
				/>
			</form>

			<div className="flex-grow" />

			<div className="py-2 px-1">
				{!!book.tts_session_id && (
					<Button
						onClick={() => finalise(book.id)}
						loading={!canFinalise}
						size="small"
						className="mr-1"
					>
						Download chapter
					</Button>
				)}

				<Dropdown content={extraMenu}>
					<Button size="small">
						<div>
							<DotsVertical size={3} />
						</div>
					</Button>
				</Dropdown>
			</div>
		</div>
	);
}

export default Header;
