import React, { useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import Button from "../../../../components/Button";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";

const pauseOptions = [
	{ label: "None", value: " " },
	{ label: "Short", value: "/" },
	{ label: "Medium", value: "*" },
	{ label: "Long", value: "%" },
	{ label: "Extra Long", value: ">" },
];

const emphasisOptions = [
	{ label: "No", value: "0" },
	{ label: "Yes (1)", value: "1" },
	{ label: "Yes (2)", value: "2" },
];

const Selector = ({ options, value, onChange }) =>
	options.map((p) => (
		<a
			key={p.value}
			onClick={() => onChange(p.value)}
			className={classNames(
				"rounded-full border px-1.5 inline-block mr-1.5 text-xs",
				p.value.trim() === value.trim()
					? "bg-blue-100 border-blue-600"
					: "bg-gray-100 hover:border-gray-500 hover:text-gray-800"
			)}
		>
			{p.label}
		</a>
	));

const Form = ({ sentence, word, wordIndex, onChange, onClose }) => {
	const dispatch = useDispatch();
	const [phonesOptions, setPhonesOptions] = useState(null);
	
	const handleResynthesize = () => {
		onClose();
		dispatch(submit("resynthesize"));
	};

	useEffect(() => {
		if (sentence && word) {
			axios
				.get(
					`sentences/${sentence.id}/phones?word=${encodeURIComponent(
						word.token.split('.')[0]
					)}`
				)
				.then((res) => {
					setPhonesOptions([...new Set(res.data)
						.add(sentence.active_version.words[wordIndex].phones)])
				});

		}
	}, [sentence, word]);

	return (
		<div id="popper" className="bg-white p-4">
			<label className="text-xs font-medium text-black">Pause</label>
			<div className="mb-3">
				<Selector
					value={word.pause}
					onChange={(pause) => onChange({ ...word, pause })}
					options={pauseOptions}
				/>
			</div>

			<label className="text-xs font-medium text-black">Emphasis</label>
			<div className="mb-3">
				<Selector
					value={word.emphasis}
					onChange={(emphasis) => onChange({ ...word, emphasis })}
					options={emphasisOptions}
				/>
			</div>

			<label className="text-xs font-medium text-black">Phones</label>
			<div>
				{phonesOptions !== null ? (
					<Selector
						value={word.phones}
						onChange={(phones) => onChange({ ...word, phones })}
						options={phonesOptions.map((p) => ({
							label: p,
							value: p,
						}))}
					/>
				) : (
					<em className="italic text-xs">Loading...</em>
				)}
			</div>

			<Button
				onClick={handleResynthesize}
				type="primary"
				size="small"
				className="mt-4"
				block
			>
				Resynthesize Sentence
			</Button>
		</div>
	);
};

export default Form;
