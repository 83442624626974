function exportToCsv(filename, rows) {
	var processRow = function (row) {
		var finalVal = "";
		for (var j = 0; j < row.length; j++) {
			var innerValue = row[j] === null ? "" : row[j].toString();
			if (row[j] instanceof Date) {
				innerValue = row[j].toLocaleString();
			}
			var result = innerValue.replace(/"/g, '""');
			if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
			if (j > 0) finalVal += ",";
			finalVal += result;
		}
		return finalVal + "\n";
	};

	var csvFile = "";
	for (var i = 0; i < rows.length; i++) {
		csvFile += processRow(rows[i]);
	}

	var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, filename);
	} else {
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", filename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
}

export const exportDistributionCsv = (distribution) => {
	const MAP = [
		["title", "Book Title"],
		["subtitle", "Subtitle"],
		["publisher", "Publisher"],
		["imprint", "Imprint"],
		["publication_date", "Publication Date"],
		["country_of_publication", "Country of Publication"],
		["isbn13", "ISBN13"],
		["main_description", "Main Description"],
		["short_description", "Short Description"],
		["author", "Author(s)"],
		["bic_master", "BIC Master/Other (UK)"],
		["bisac_master", "BISAC Master/Other (US)"],
		["thema_codes", "Thema Codes"],
		["keywords", "Keywords"],
		["copyright_statement", "Copyright Statement"],
		["audience_type", "Audience Type"],
		["rights_type", "Rights Type"],
		["territory", "Territory"],
	];

	const data = MAP.map(([key, title]) => [title, distribution[key]]);
	exportToCsv(`${distribution.title}.csv`, data);
};
