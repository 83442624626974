import React from "react";
import classNames from "classnames";

const sizeMap = {
	large: "px-3 py-0.5 leading-5",
	small: "px-1 py-0.5 leading-4",
	default: "px-2.5 py-0.5 leading-4",
};

const colorMap = {
	default: "bg-gray-100 text-gray-800",
	blue: "bg-blue-100 text-blue-800",
	red: "bg-red-100 text-red-800",
	orange: "bg-orange-100 text-orange-800",
	green: "bg-green-100 text-green-800",
	darkGreen:"bg-green-300 text-green-800",
	indigo: "bg-indigo-100 text-indigo-800",
	yellow: "bg-yellow-100 text-yellow-800",
	purple: "bg-purple-100 text-purple-800",
};

const Badge = ({ color, size, className, children, ...otherProps }) => (
	<span
		className={classNames(
			"inline-flex items-center rounded-full font-medium",
			colorMap[color || "default"],
			sizeMap[size || "default"],
			className
		)}
		{...otherProps}
	>
		{children}
	</span>
);

export default Badge;
