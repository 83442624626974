import React, { useRef } from "react";

const Upload = ({ onReady }) => {
	const inputRef = useRef();
	const handleChange = (e) => {
		var reader = new FileReader();
		reader.onload = (e) => onReady(e.target.result);
		reader.readAsArrayBuffer(e.target.files[0]);
	};

	return (
		<div>
			<input type="file" ref={inputRef} onChange={handleChange} />
		</div>
	);
};

export default Upload;
