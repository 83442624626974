export default [
	{
		type: "consonant",
		ipa: "b",
		deepzen: "b",
		combilex: "b",
		keywords: [{ text: "banque", highlight: [0, 1] }],
		sample: ["banque", "b a~ k"],
	},
	{
		type: "consonant",
		ipa: "d",
		deepzen: "d",
		combilex: "d",
		keywords: [{ text: "dentiste", highlight: [0, 1] }],
		sample: ["dentiste", "d a~ t i s t"],
	},
	{
		type: "consonant",
		ipa: "f",
		deepzen: "f",
		combilex: "f",
		keywords: [{ text: "fièvre", highlight: [0, 1] }],
		sample: ["fièvre", "f j E v R"],
	},
	{
		type: "consonant",
		ipa: "g",
		deepzen: "g",
		combilex: "g",
		keywords: [{ text: "gateau", highlight: [0, 1] }],
		sample: ["gateau", "g A t o"],
	},
	{
		type: "consonant",
		ipa: "j",
		deepzen: "j",
		combilex: "j",
		keywords: [{ text: "yeux", highlight: [0, 1] }],
		sample: ["yeux", "j @/"],
	},
	{
		type: "consonant",
		ipa: "k",
		deepzen: "k",
		combilex: "k",
		keywords: [{ text: "car", highlight: [0, 1] }],
		sample: ["car", "k A R"],
	},
	{
		type: "consonant",
		ipa: "l",
		deepzen: "l",
		combilex: "l",
		keywords: [{ text: "lampe", highlight: [0, 1] }],
		sample: ["lampe", "l a~ p"],
	},
	{
		type: "consonant",
		ipa: "m",
		deepzen: "m",
		combilex: "m",
		keywords: [{ text: "mais", highlight: [0, 1] }],
		sample: ["mais", "m E"],
	},
	{
		type: "consonant",
		ipa: "n",
		deepzen: "n",
		combilex: "n",
		keywords: [{ text: "non", highlight: [0, 1] }],
		sample: ["non", "n o~"],
	},
	{
		type: "consonant",
		ipa: "ŋ",
		deepzen: "N",
		combilex: "N",
		keywords: [{ text: "parking", highlight: [5, 7] }],
		sample: ["parking", "p A R k i N"],
	},
	{
		type: "consonant",
		ipa: "ɲ",
		deepzen: "J",
		combilex: "J",
		keywords: [{ text: "gnole", highlight: [0, 2] }],
		sample: ["gnole", "J o l"],
	},
	{
		type: "consonant",
		ipa: "p",
		deepzen: "p",
		combilex: "p",
		keywords: [{ text: "parc", highlight: [0, 1] }],
		sample: ["parc", "p A R k"],
	},
	{
		type: "consonant",
		ipa: "ʁ",
		deepzen: "R",
		combilex: "R",
		keywords: [{ text: "rouge", highlight: [0, 1] }],
		sample: ["rouge", "R u Z"],
	},
	{
		type: "consonant",
		ipa: "s",
		deepzen: "s",
		combilex: "s",
		keywords: [{ text: "stop", highlight: [0, 1] }],
		sample: ["stop", "s t O p"],
	},
	{
		type: "consonant",
		ipa: "ʃ",
		deepzen: "S",
		combilex: "S",
		keywords: [{ text: "chaud", highlight: [0, 2] }],
		sample: ["chaud", "S o"],
	},
	{
		type: "consonant",
		ipa: "t",
		deepzen: "t",
		combilex: "t",
		keywords: [{ text: "tiens", highlight: [0, 1] }],
		sample: ["tiens", "t j e~"],
	},
	{
		type: "consonant",
		ipa: "ɥ",
		deepzen: "H",
		combilex: "H",
		keywords: [{ text: "huit", highlight: [0, 2] }],
		sample: ["huit", "*H i t"],
	},
	{
		type: "consonant",
		ipa: "v",
		deepzen: "v",
		combilex: "v",
		keywords: [{ text: "vase", highlight: [0, 1] }],
		sample: ["vase", "v A z"],
	},
	{
		type: "consonant",
		ipa: "w",
		deepzen: "w",
		combilex: "w",
		keywords: [{ text: "ouate", highlight: [0, 2] }],
		sample: ["ouate", "*w A t"],
	},
	{
		type: "consonant",
		ipa: "z",
		deepzen: "z",
		combilex: "z",
		keywords: [{ text: "zéro", highlight: [0, 1] }],
		sample: ["zéro", "z e R o"],
	},
	{
		type: "consonant",
		ipa: "ʒ",
		deepzen: "Z",
		combilex: "Z",
		keywords: [{ text: "genre", highlight: [0, 1] }],
		sample: ["genre", "z e R o"],
	},
	{
		type: "vowel",
		ipa: "ə",
		deepzen: "@",
		combilex: "@",
		keywords: [{ text: "le", highlight: [1, 2] }],
		sample: ["le", "l @"],
	},
	{
		type: "vowel",
		ipa: "a:",
		deepzen: "A",
		combilex: "A",
		keywords: [{ text: "patte", highlight: [1, 3] }],
		sample: ["patte", "p A t"],
	},
	{
		type: "vowel",
		ipa: "œ",
		deepzen: "@@",
		combilex: "@@",
		keywords: [{ text: "oeuf", highlight: [1, 3] }],
		sample: ["oeuf", "@@ f"],
	},
	{
		type: "vowel",
		ipa: "ø",
		deepzen: "@/",
		combilex: "@/",
		keywords: [{ text: "eux", highlight: [0, 3] }],
		sample: ["eux", "@/"],
	},
	{
		type: "vowel",
		ipa: "i",
		deepzen: "i",
		combilex: "i",
		keywords: [{ text: "mine", highlight: [1, 2] }],
		sample: ["mine", "m i n"],
	},
	{
		type: "vowel",
		ipa: "ʏ",
		deepzen: "y",
		combilex: "y",
		keywords: [{ text: "lu", highlight: [1, 2] }],
		sample: ["lu", "l y"],
	},
	{
		type: "vowel",
		ipa: "ɔ",
		deepzen: "O",
		combilex: "O",
		keywords: [{ text: "éloge", highlight: [2, 3] }],
		sample: ["éloge", "e l O Z"],
	},
	{
		type: "vowel",
		ipa: "o",
		deepzen: "o",
		combilex: "o",
		keywords: [{ text: "mot", highlight: [1, 2] }],
		sample: ["mot", "m o"],
	},
	{
		type: "vowel",
		ipa: "u",
		deepzen: "u",
		combilex: "u",
		keywords: [{ text: "roux", highlight: [1, 4] }],
		sample: ["roux", "R u"],
	},
	{
		type: "vowel",
		ipa: "e",
		deepzen: "e",
		combilex: "e",
		keywords: [{ text: "étage", highlight: [0, 1] }],
		sample: ["étage", "e t A Z"],
	},
	{
		type: "vowel",
		ipa: "ɛ",
		deepzen: "E",
		combilex: "E",
		keywords: [{ text: "être", highlight: [0, 1] }],
		sample: ["être", "E t R"],
	},
	{
		type: "vowel",
		ipa: "ɛ̃",
		deepzen: "e~",
		combilex: "e~",
		keywords: [{ text: "cinq", highlight: [1, 3] }],
		sample: ["cinq", "s e~ k"],
	},
	{
		type: "vowel",
		ipa: "œ̃",
		deepzen: "u~",
		combilex: "u~",
		keywords: [{ text: "un", highlight: [0, 2] }],
		sample: ["un", "u~"],
	},
	{
		type: "vowel",
		ipa: "ɑ̃",
		deepzen: "a~",
		combilex: "a~",
		keywords: [{ text: "ange", highlight: [0, 2] }],
		sample: ["ange", "a~ Z"],
	},
	{
		type: "vowel",
		ipa: "ɔ̃",
		deepzen: "o~",
		combilex: "o~",
		keywords: [{ text: "savon", highlight: [3, 5] }],
		sample: ["savon", "s A v o~"],
	},
];
