import React from "react";
import logo from "../../assets/logo-icon.png";
export const Maintenance = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-cool-gray-800">
            <div className="flex flex-col items-center justify-center max-w-3xl">
                <div className="mb-3">
                    <img src={logo} alt="DeepZen Logo" className="h-24" />
                </div>
                <h1 className="mb-3 text-3xl font-bold text-center text-purple-100">
                    Opps! We're sprucing up our software right now.
                </h1>
                <p className="text-center text-gray-100">
                    Hang tight, and we'll be back before you can say "She sells seashells by the seashore" in a dozen different accents. Thanks for bearing with us—your patience is music to our AI's ears!
                </p>
            </div>
        </div>
    );
};

export default Maintenance;