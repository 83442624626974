import React from "react";
import RowContainer from "../containers/RowContainer";
import {
	Table,
	TableHead,
	TableHeadCell,
	TableRow,
	TableBody,
} from "../../../../../components/Table";
import Card from "../../../../../components/Card";

function Results({ ids }) {
	return (
		<Card>
			<div className="overflow-x-auto">
				<Table>
					<TableHead>
						<TableRow>
							<TableHeadCell width="20" />
							<TableHeadCell>Title</TableHeadCell>
							<TableHeadCell className="hidden xl:table-cell">
								Lexicons
							</TableHeadCell>
							<TableHeadCell width="100" />
						</TableRow>
					</TableHead>
					<TableBody>
						{ids.map((id) => (
							<RowContainer key={id} id={id} />
						))}
					</TableBody>
				</Table>
			</div>
		</Card>
	);
}

export default Results;
