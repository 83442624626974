import React from "react";
import { Alert } from "antd";
import { restrictedWords } from "../routes/Lexicon/Edit/modules/edit";

const typeMap = {
	restrictedWord: {
		description: "You are not allowed to edit the reserved words below",
	},
	invalidPosNil: {
		description: "nil cannot be used because there is already a word with a different POS in the lexicon"
	}
};

export const LexiconAlert = ({ type }) => {
    
	if(!type || !typeMap[type]) return null;

	return (
		<div>
			<Alert
				message="Warning!"
				description={typeMap[type].description}
				type="warning"
				showIcon
				className="mb-5"
			/>
        	{type === "restrictedWord" && (
            	<ul className="list-disc ml-8">
			    	{restrictedWords.map((rw) => (
				    	<li>{rw}</li>
			    	))}
		    	</ul>
        	)}
		</div>
	);
}

export default LexiconAlert;
