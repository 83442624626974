import React from "react";
import { browserHistory } from "react-router";
import { TableRow, TableCell } from "../../../../../components/Table";
import RightArrow from "../../../../../components/Icons/RightArrow";
import Badge from "../../../../../components/Badge";

function Row({ session }) {
	return (
		<TableRow
			className="cursor-pointer"
			onClick={() =>
				browserHistory.push(`/lexicon/sessions/${session.id}`)
			}
		>
			<TableCell>
				{!session.error && session.analyzing && (
					<Badge color="blue">Running</Badge>
				)}
				{session.error && <Badge color="red">Failed</Badge>}
			</TableCell>
			<TableCell>
				<strong>{session.title}</strong>
			</TableCell>
			<TableCell className="hidden xl:table-cell">
				{/* TODO: Get rid of this JSON.parse */}
				{JSON.parse(session.lexicons).map((lexicon) => (
					<Badge className="mr-1">{lexicon}</Badge>
				))}
			</TableCell>
			<TableCell>
				<RightArrow size={7} className="text-gray-500" />
			</TableCell>
		</TableRow>
	);
}

export default Row;
