import React from "react";
import { Card, Alert } from "antd";
import Form from "./Form";

const Prediction = ({ trainedModels, loading, error, data, predict }) => (
	<Card title="Prediction">
		<Form
			apiError={error}
			models={trainedModels.data}
			loading={loading}
			onSubmit={values => predict(values.word, values.model)}
		/>

		{data && data.length > 0 && (
			<Alert
				message={data[0]}
				type="success"
				style={{
					fontFamily: "monospace"
				}}
			/>
		)}
	</Card>
);

export default Prediction;
