import axios from "axios";
import { message } from "antd";
import { deploy } from "../../modules/lexicons";


export const restrictedWords = ["yer", "twas", "a", "an", "and", "are", "at", "because", "but",
"can", "could", "for", "from", "gonna", "had", "has", "have", "he", "he'd", "he'll",
"he's", "her", "herself", "him", "himself", "his", "hisself", "I", "I'd", "I'll", "I'm",
"I've", "into", "it", "must", "my", "myself", "nor", "of", "onto", "or", "our", "shall",
"she", "she'd", "she'll", "she's", "should", "some", "than", "that", "that'd", "that's",
"that'll", "the", "their", "them", "themself", "themselves", "there", "there'd", "there'll",
"there's", "there've", "to", "unto", "us", "was", "wast", "we", "we'd", "we'll", "we're",
"we've", "were", "what", "would", "ye", "you", "you'd", "you'll", "you're", "you've", "your",
"yourself", "yourselves"];

export const EDIT_LOAD = "EDIT_LOAD_LOAD";
export const EDIT_LOAD_SUCCESS = "EDIT_LOAD_SUCCESS";
export const EDIT_LOAD_FAIL = "EDIT_LOAD_FAIL";

export const EDIT_SAVE = "EDIT_SAVE_LOAD";
export const EDIT_SAVE_SUCCESS = "EDIT_SAVE_SUCCESS";
export const EDIT_SAVE_FAIL = "EDIT_SAVE_FAIL";

const loadSuccess = data => ({ type: EDIT_LOAD_SUCCESS, payload: data });
const loadFail = e => ({ type: EDIT_LOAD_FAIL, payload: e });

export function load(token) {
	return dispatch => {
		dispatch({ type: EDIT_LOAD, payload: token });

		axios
			.get(`lexicon/search/?token=${encodeURIComponent(token)}`)
			.then(res => dispatch(loadSuccess(res.data)))
			.catch(e => dispatch(loadFail(e)));
	};
}

const saveSuccess = data => {
	return dispatch => {
		message.success("Updated lexicon.");
		dispatch({ type: EDIT_SAVE_SUCCESS, payload: data });
	};
};
const saveFail = e => ({ type: EDIT_SAVE_FAIL, payload: e });

export function save(token, { values }, type) {
	return dispatch => {
		dispatch({ type: EDIT_SAVE, payload: { token, values } });
		
		const lexiconIds = values.map(v => v.lexicon_id);
		const uniqueLexicondIds = Array.from(new Set(lexiconIds));
		
		axios
			.post("lexicon/update/", { token, values, type })
			.then(res => {
				dispatch(deploy(uniqueLexicondIds));
				dispatch(saveSuccess(res.data))
			})
			.catch(e => dispatch(saveFail(e)));
	};
}

export function addSyllable(text) {
	const SCHEME_REGEX = /^\s*([a-zA-Z0-9]+)\s*\((.*)\)\s*$/i;
	const [_, pos, currentSyllables] = text.match(SCHEME_REGEX);

	return `${pos.trim()} (${currentSyllables.trim()} (() 0))`;
}

const initialState = {
	data: null,
	loadError: null,
	loadLoading: false,
	saveLoading: false,
	saveError: null
};

export default function editReducer(state = initialState, action) {
	switch (action.type) {
		case EDIT_LOAD:
			return { ...initialState, loadLoading: true };
		case EDIT_LOAD_SUCCESS:
			return { ...state, loadLoading: false, data: action.payload };
		case EDIT_LOAD_FAIL:
			return { ...state, loadLoading: false, loadError: action.payload };
		case EDIT_SAVE:
			return { ...state, saveError: null, saveLoading: true };
		case EDIT_SAVE_SUCCESS:
			return { ...state, saveLoading: false };
		case EDIT_SAVE_FAIL:
			return { ...state, saveLoading: false, saveError: action.payload };
		default:
			return state;
	}
}
