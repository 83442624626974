import axios from "axios";

export const AUTH_ACCESS_TOKEN_KEY = "AUTH_ACCESS_TOKEN";
export const AUTH_LOGIN_VERIFY = "AUTH_LOGIN_VERIFY";
export const AUTH_LOGIN_VERIFY_SUCCESS = "AUTH_LOGIN_VERIFY_SUCCESS";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_PROTECT_REDIRECT = "AUTH_PROTECT_REDIRECT";

export const loginFail = (e) => ({ type: AUTH_LOGIN_FAIL, payload: e });

export const loginVerifySuccess = (user) => ({
	type: AUTH_LOGIN_VERIFY_SUCCESS,
	payload: user,
});

export const setProtectRedirect = (path) => ({
	type: AUTH_PROTECT_REDIRECT,
	payload: path,
});

export function logout() {
	return (dispatch) => {
		localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
		axios.defaults.headers.common["Authorization"] = null;

		dispatch({ type: AUTH_LOGOUT });
	};
}

export function loginVerify(token) {
	return (dispatch) => {
		const authHeader = "Bearer " + token;
		axios
			.get("auth/check/", { headers: { Authorization: authHeader } })
			.then((res) => {
				// Side-effect...
				localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
				axios.defaults.headers.common["Authorization"] = authHeader;

				dispatch(loginVerifySuccess(res.data));
			})
			.catch((e) => {
				// We got a unauthorized, which means user has been deleted
				if (e.response && e.response.status === 401) {
					dispatch(logout());
				}
			});
	};
}

export function login(credentials) {
	return (dispatch) => {
		dispatch({ type: AUTH_LOGIN });
		axios
			.post("auth/token/", credentials)
			.then((res) => dispatch(loginVerify(res.data.access)))
			.catch((e) => dispatch(loginFail(e)));
	};
}

export const loggedInUserSelector = (state) => state.auth.user;

export const hasUserFullEditingAccess = (user) => user && user.has_full_editing_access;

export const resetPassword = (credentials) => axios.put("auth/password/", credentials);

const initialState = {
	verified: false,
	user: null,
	loginLoading: false,
	loginError: null,
	protectRedirect: "/",
};

export default function authReducer(state = initialState, action) {
	switch (action.type) {
		case AUTH_LOGIN:
			return { ...state, loginError: null, loginLoading: true };
		case AUTH_LOGIN_FAIL:
			return {
				...state,
				loginError: action.payload,
				loginLoading: false,
			};
		case AUTH_LOGIN_VERIFY_SUCCESS:
			return {
				...initialState,
				user: action.payload,
				verified: true,
				protectRedirect: state.protectRedirect,
			};
		case AUTH_LOGOUT:
			return { ...initialState, verified: true };
		case AUTH_PROTECT_REDIRECT:
			return { ...state, protectRedirect: action.payload };
		default:
			return state;
	}
}
