import { connect } from "react-redux";
import {
	toggleAutoPlay,
	toggleAutoContinue,
	finalise,
	canFinaliseSelector,
	getSentenceByTimestamp,
} from "../modules/editor";
import { toggle as toggleSynthesize } from "../modules/synthesize";
import { toggle as toggleClone } from "../modules/clone";
import { toggle as toggleReload } from "../modules/reload";
import Header from "../components/Header";
import { hasUserFullEditingAccess } from "../../Auth/modules/auth";

const mapStateToProps = (state) => ({
	autoPlay: state.editor.autoPlay,
	autoContinue: state.editor.autoContinue,
	book: state.editor.book,
	activeSentenceStartTime: state.editor.activeSentenceStartTime,
	canFinalise: canFinaliseSelector(state),
	showClone: state.clone.show,
	showReload: state.reload.show,
	showSynthesize: state.synthesize.show,
	hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user),
});

const mapDispatchToProps = {
	toggleAutoContinue,
	toggleAutoPlay,
	finalise,
	toggleSynthesize,
	toggleClone,
	toggleReload,
	getSentenceByTimestamp,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
