import React from "react";
import { Link } from "react-router";

const DropdownMenu = ({ children }) => (
	<div className="py-1 rounded-md bg-white shadow-xs">{children}</div>
);

export const DropdownMenuItem = ({ to, onClick, children }) => (
	<Link
		to={to}
		onClick={onClick}
		className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
	>
		{children}
	</Link>
);

export default DropdownMenu;
