import React, { useState } from "react";
import Card, { CardBody } from "../../../../../components/Card";
import { statuses } from "../../../../Clusters/modules/portal";
import { Link } from "react-router";
import Button from "../../../../../components/Button";
import PortalProjectFinishInitialLinguisticsReview from "./PortalProjectFinishInitialLinguisticsReview";
import axios from "axios";
import { message } from "antd";

const PortalProjectSummary = ({ session, data, onStatusChange }) => {
	const [
		showFinishIntiailLinguisticsReviewModal,
		setShowFinishIntiailLinguisticsReviewModal,
	] = useState(false);

	return (
		<Card
			title="Portal Project Details"
			className="mb-5 bg-blue-100"
			headerActions={[
				<Link
					className="inline-block -mt-1"
					to={`/clusters/${data.book.id}`}
				>
					<Button>View book</Button>
				</Link>,
			]}
		>
			{showFinishIntiailLinguisticsReviewModal && (
				<PortalProjectFinishInitialLinguisticsReview
					missingWordIds={session.missing_words}
					onSubmit={(tokens) => {
						axios
							.post(
								`portal/projects/${data.id}/finish_initial_linguistics_review/`,
								{ tokens }
							)
							.then(onStatusChange)
							.catch(() => message.error("An error occurred."));
					}}
					onClose={() =>
						setShowFinishIntiailLinguisticsReviewModal(false)
					}
				/>
			)}

			<div className="bg-white">
				<CardBody>
					{data.status === "initial_linguistics_review" && (
						<div className="flex">
							<div className="flex-grow">
								This project is at initial stage. DeepZen is
								expected to work on linguistics work and then
								whenever needed, remaining words will be passed
								to customer to get pronunciation information
								from them.
							</div>
							<Button
								onClick={() =>
									setShowFinishIntiailLinguisticsReviewModal(
										true
									)
								}
								size="small"
								type="info"
							>
								{session.missing_words.length === 0
									? "Continue to editing"
									: "Send remaining words to customer"}
							</Button>
						</div>
					)}

					{data.status === "customer_linguistics_input" && (
						<div>
							We are now waiting for input from customer about
							missing words. You will get a notification when they
							are done.
						</div>
					)}

					{data.status === "editing_sample" && (
						<div>
							You can see customer's inputs for each missing word
							below in the table. DeepZen is expected to deliver a
							sample now. Customer won't be involved in any
							linguistics work anymore.
						</div>
					)}

					{Object.keys(statuses).indexOf(data.status) > 2 && (
						<div>
							This book is in editing stage now. You can still
							make changes for sure but customer won't be involved
							anymore.
						</div>
					)}
				</CardBody>
			</div>
		</Card>
	);
};

export default PortalProjectSummary;
