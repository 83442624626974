import AuthContainer from "./containers/AuthContainer";
import TokenRoute from "./Token";
import LoginRoute from "./Login";
import LogoutRoute from "./Logout";

export default {
	path: "auth",
	component: AuthContainer,
	indexRoute: LoginRoute,
	childRoutes: [TokenRoute, LoginRoute, LogoutRoute],
};
