import { useSelector, useDispatch } from "react-redux";
import { listSelector, load } from "../Clusters/modules/list";
import { useEffect } from "react";

export const useList = (listKey, endpoint) => {
	const dispatch = useDispatch();
	const list = useSelector((state) => listSelector(state, listKey));

	useEffect(() => {
		if (!list || list.qs !== endpoint) {
			dispatch(load(listKey, endpoint));
		}
	}, [endpoint]);

	return list;
};
