import axios from "axios";
import React, { useEffect, useState } from "react";
import Preview from "./Preview";
import StepChapter from "./StepChapter";
import StepClasses from "./StepClasses";
import StepPages from "./StepPages";
import StepTitle from "./StepTitle";
import Upload from "./Upload";

export let globRemovedClasses = [];

const Epub = () => {
	const [step, setStep] = useState(0);

	// Data
	const [book, setBook] = useState(null);
	const [data, setData] = useState(null);

	// Edits
	const [removedChapters, setRemovedChapters] = useState([]);
	const [removedXhtmls, setRemovedXhtmls] = useState([]);
	const [removedClasses, setRemovedClasses] = useState([]);

	// Final text
	const [text, setText] = useState("");

	useEffect(() => {
		globRemovedClasses = removedClasses.map((a) => a);
	}, [removedClasses]);

	useEffect(() => {
		async function fetch() {
			const res = await axios.post("clusters/parse/epub/parser/", {
				chapters: await book.getContents(
					removedChapters,
					removedXhtmls
				),
				removed_classes: removedClasses,
			});

			let out = "";
			res.data.forEach(
				({ title, pages }) =>
					(out += `{{ ${title.trim()} }}\n\n ${pages.join(
						"\n\n"
					)}\n\n`)
			);

			setText(out);
		}

		if (step === 3) {
			fetch();
		}
	}, [step]);

	const handleRemoveChapter = (i) =>
		setRemovedChapters([...removedChapters, i]);

	const handleUndoChapter = (i) => {
		setRemovedChapters(removedChapters.filter((ind) => ind !== i));

		// Remove xhtmls of this chapters
		const chapterXhtmls = book.pages
			.filter((p) => p.chapter === i)
			.map((p) => p.href);

		setRemovedXhtmls(
			removedXhtmls.filter((h) => chapterXhtmls.indexOf(h) > -1)
		);
	};

	const handleAddClasses = (classes) => {
		const values = [...globRemovedClasses, ...classes];

		setRemovedClasses([...new Set(values)]);
	};

	if (!data) {
		return <Upload onReady={setData} />;
	}

	return (
		<div className="h-full flex">
			<div className="w-1/2">
				<div className="bg-white shadow-md h-full">
					{step === 3 && (
						<textarea
							className="w-full h-full form-input"
							value={text}
							onChange={(e) => setText(e.target.value)}
						/>
					)}
					<Preview
						step={step}
						book={book}
						data={data}
						onBookReady={setBook}
						removedXhtmls={removedXhtmls}
						setRemovedXhtmls={setRemovedXhtmls}
						removedChapters={removedChapters}
						onRemoveChapter={handleRemoveChapter}
						onUndoChapter={handleUndoChapter}
						removedClasses={removedClasses}
						onAddClasses={handleAddClasses}
					/>
				</div>
			</div>
			<div className="w-1/2 py-3 px-4">
				<h1 className="text-2xl font-bold">Cleanup EPub</h1>

				{book && (
					<>
						<div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
							<StepTitle
								title="1. Remove chapters"
								onClick={() => setStep(0)}
								active={step === 0}
							/>

							{step === 0 && (
								<>
									<StepChapter
										book={book}
										removedChapters={removedChapters}
										onUndo={handleUndoChapter}
									/>
									<button
										onClick={() => setStep(1)}
										className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
									>
										I am done, continue
									</button>
								</>
							)}
						</div>

						<div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
							<StepTitle
								title="2. Remove pages"
								onClick={() => setStep(1)}
								active={step === 1}
							/>

							{step === 1 && (
								<>
									<StepPages
										book={book}
										removedXhtmls={removedXhtmls}
										setRemovedXhtmls={setRemovedXhtmls}
										removedPages={book.pages.filter(
											(p) =>
												removedXhtmls.indexOf(p.href) >
													-1 &&
												removedChapters.indexOf(
													p.chapter
												) === -1
										)}
									/>
									<button
										onClick={() => setStep(2)}
										className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
									>
										I am done, continue
									</button>
								</>
							)}
						</div>

						<div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
							<StepTitle
								title="2. Remove specific parts"
								onClick={() => setStep(2)}
								active={step === 2}
							/>
							{step === 2 && (
								<>
									<StepClasses
										removedClasses={removedClasses}
										setRemovedClasses={setRemovedClasses}
									/>
									<button
										onClick={() => setStep(3)}
										className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
									>
										I am done, continue
									</button>
								</>
							)}
						</div>

						<div className="mt-3 bg-white rounded-md shadow-md py-3 px-4">
							<StepTitle
								title="4. Review final text"
								onClick={() => setStep(3)}
								active={step === 3}
							/>
							{step === 3 && (
								<>
									You can see and modify the raw text output
									on the left. Please make sure it seems
									correct.
									<button
										onClick={() => setStep(4)}
										className="rounded-md block mt-3 py-1 text-white text-base text-center bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-400 w-full"
									>
										Create book
									</button>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Epub;
