import React, { useEffect, useState } from "react";
import Transition from "./Transition";

const Modal = ({ children, onClose }) => {
	const [show, setShow] = useState(false);

	useEffect(() => setShow(true), []);

	return (
		<div className="fixed z-20 inset-0 overflow-y-auto" onClick={onClose}>
			<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
				<Transition
					show={show}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<>
						<div className="fixed inset-0 transition-opacity">
							<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
						</div>
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
						&#8203;
						<Transition
							show={show}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full"
								role="dialog"
								aria-modal="true"
								aria-labelledby="modal-headline"
								onClick={(e) => e.stopPropagation()}
							>
								{children}
							</div>
						</Transition>
					</>
				</Transition>
			</div>
		</div>
	);
};

export default Modal;
