import React, { Component } from "react";
import { connect } from "react-redux";
import { create } from "../modules/create";
import { load as loadLexicons } from "../../../modules/lexicons";
import Create from "../components/Create";

class CreateContainer extends Component {
	componentDidMount() {
		if (!this.props.lexicons) {
			this.props.loadLexicons();
		}
	}

	render() {
		return <Create {...this.props} />;
	}
}

const mapStateToProps = state => ({
	...state.lexicon.createSession,
	lexicons: state.lexicon.lexicons.data
});

const mapDispatchToProps = {
	create,
	loadLexicons
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContainer);
