import React from "react";
import { Spin, Card } from "antd";
import ApiError from "../../../../components/ApiError";

const Status = ({ data, loading, error }) => (
	<Card title="Latest training status">
		{loading && <Spin size="small" />}
		{error && <ApiError error={error} />}
		{data && (
			<div>
				{Object.keys(data).map((key, i) => (
					<div key={i}>
						<strong>{key}:</strong> {`${data[key]}`}
					</div>
				))}
			</div>
		)}
	</Card>
);

export default Status;
