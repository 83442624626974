import axios from "axios";
import { createSelector } from "reselect";

export const CLUSTERS_LOAD = "CLUSTERS_LOAD";
export const CLUSTERS_LOAD_SUCCESS = "CLUSTERS_LOAD_SUCCESS";
export const CLUSTERS_LOAD_FAIL = "CLUSTERS_LOAD_FAIL";

const loadSuccess = (list, data) => ({
	type: CLUSTERS_LOAD_SUCCESS,
	payload: { list, data },
});

const loadFail = (list, error) => ({
	type: CLUSTERS_LOAD_FAIL,
	payload: { list, error },
});

export const listSelector = createSelector(
	(state) => state.clusters.list,
	(_, key) => key,
	(lists, key) => lists[key]
);

export function load(list, endpoint) {
	return (dispatch) => {
		dispatch({ type: CLUSTERS_LOAD, payload: { list, endpoint } });

		axios
			.get(endpoint)
			.then((res) => dispatch(loadSuccess(list, res.data)))
			.catch((e) => dispatch(loadFail(list, e)));
	};
}

const initialState = {};

export default function clustersReducer(state = initialState, action) {
	switch (action.type) {
		case CLUSTERS_LOAD:
			return {
				...state,
				[action.payload.list]: {
					endpoint: action.payload.endpoint,
					loading: true,
					error: null,
					data: state[action.payload.list]
						? state[action.payload.list].data
						: [],
				},
			};
		case CLUSTERS_LOAD_SUCCESS:
			return {
				...state,
				[action.payload.list]: {
					...state[action.payload.list],
					loading: false,
					data: action.payload.data,
				},
			};
		case CLUSTERS_LOAD_FAIL:
			return {
				...state,
				[action.payload.list]: {
					...state[action.payload.list],
					error: action.payload.error,
				},
			};
		default:
			return state;
	}
}
