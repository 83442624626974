import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";
import {
	SENTENCE_DETAILS_LOAD_SUCCESS,
	load as loadSentence,
} from "./sentenceDetails";
import { NLP_PASTE } from "./nlp";
import { NOTE_CREATED, NOTE_DELETED } from "./notes";
import {
	BOOK_DONE_UPDATE_SUCCESS,
	CLUSTER_STATUS_UPDATE_SUCCESS,
} from "../../../modules/statusUpdater";
import { SYNTHESIZE_SUCCESS } from "./synthesize";
import { timestampToDuration } from "../../../services/time";

export const BOOK_EDITOR_LOAD = "BOOK_EDITOR_LOAD";
export const BOOK_EDITOR_ASYNC_SENTENCE_UPDATED =
	"BOOK_EDITOR_ASYNC_SENTENCE_UPDATED";
export const BOOK_EDITOR_LOAD_SUCCESS = "BOOK_EDITOR_LOAD_SUCCESS";
export const BOOK_EDITOR_LOAD_FAIL = "BOOK_EDITOR_LOAD_FAIL";
export const BOOK_EDITOR_SELECT_SENTENCE = "BOOK_EDITOR_SELECT_SENTENCE";
export const BOOK_EDITOR_MARK_SENTENCE = "BOOK_EDITOR_MARK_SENTENCE";
export const BOOK_EDITOR_MARK_SENTENCE_SUCCESS =
	"BOOK_EDITOR_MARK_SENTENCE_SUCCESS";
export const BOOK_EDITOR_MARK_SENTENCE_FAIL = "BOOK_EDITOR_MARK_SENTENCE_FAIL";
export const BOOK_EDITOR_TOGGLE_AUTO_PLAY = "BOOK_EDITOR_TOGGLE_AUTO_PLAY";
export const BOOK_EDITOR_TOGGLE_AUTO_CONTINUE =
	"BOOK_EDITOR_TOGGLE_AUTO_CONTINUE";

export const sentenceByIdSelector = createSelector(
	(state, id) => id,
	(state) => state.editor.sentencesById,
	(id, sentencesById) => sentencesById[id]
);

export const isActiveSelector = createSelector(
	(state, id) => id,
	(state) => state.editor.activeSentenceIds,
	(id, activeSentenceIds) => activeSentenceIds.indexOf(id) > -1
);

export const multipleSelectedSelector = createSelector(
	(state) => state.editor.activeSentenceIds,
	(activeSentenceIds) => activeSentenceIds.length > 1
);

export const canFinaliseSelector = createSelector(
	(state) => state.editor.book,
	(state) => state.queue.jobs,
	(book, jobs) =>
		jobs.filter(
			(job) =>
				job.task === "finalise" &&
				(job.status === "pending" || job.status === "running")
		).length === 0
);

const loadSuccess = (data) => ({
	type: BOOK_EDITOR_LOAD_SUCCESS,
	payload: data,
});
const loadFail = (e) => ({ type: BOOK_EDITOR_LOAD_FAIL, payload: e });

export function load(id) {
	return (dispatch, getState) => {
		const { editor } = getState();
		const { book, activeSentenceIds } = editor;
		const sameBookReloading = book !== null && book.id == id;

		dispatch({ type: BOOK_EDITOR_LOAD, payload: id });

		axios
			.get(`books/${id}/editor/`)
			.then((res) => {
				dispatch(loadSuccess(res.data));

				if (sameBookReloading && activeSentenceIds.length === 1) {
					dispatch(selectSentence(activeSentenceIds[0]));
				}
			})
			.catch((e) => dispatch(loadFail(e)));
	};
}

const markSentenceSuccess = (data) => ({
	type: BOOK_EDITOR_MARK_SENTENCE_SUCCESS,
	payload: data,
});
const markSentenceFail = (e) => ({
	type: BOOK_EDITOR_MARK_SENTENCE_FAIL,
	payload: e,
});

export function markSentence(sentenceId, color) {
	return (dispatch) => {
		axios
			.patch(`sentences/${sentenceId}/`, { color })
			.then((res) => dispatch(markSentenceSuccess(res.data)))
			.catch((e) => dispatch(markSentenceFail(e)));
	};
}

export const selectSentence = (id, multi) => ({
	type: BOOK_EDITOR_SELECT_SENTENCE,
	payload: { id, multi },
});

export function selectNextSentence() {
	return (dispatch, getState) => {
		// When the user is editing a textfield, don't move to the next sentence
		const focusedEl = document.querySelector(":focus");
		if (focusedEl && ["TEXTAREA", "INPUT"].indexOf(focusedEl.tagName) > -1)
			return;

		const { editor } = getState();
		const { sentenceIds, activeSentenceIds } = editor;

		// Only when there is only one sentence seleected and word level editor is not open
		if (activeSentenceIds.length > 1) return;

		const indexOfCurrentSentence = sentenceIds.indexOf(
			activeSentenceIds[0]
		);

		if (sentenceIds.length > indexOfCurrentSentence + 1) {
			dispatch(selectSentence(sentenceIds[indexOfCurrentSentence + 1]));
		}
	};
}

export const toggleAutoPlay = () => ({ type: BOOK_EDITOR_TOGGLE_AUTO_PLAY });
export const toggleAutoContinue = () => ({
	type: BOOK_EDITOR_TOGGLE_AUTO_CONTINUE,
});

// Pusher event creators
export function asyncSentenceUpdated(sentence) {
	return (dispatch, getState) => {
		// Get currently active sentence
		const {
			editor: { activeSentenceIds },
		} = getState();

		// If this resynthed sentence is still being edited
		if (activeSentenceIds.indexOf(sentence.id) > -1) {
			// Reload that
			dispatch(loadSentence(sentence.id));
		} else {
			axios
				.get(`sentences/${sentence.id}/`)
				.then((res) =>
					dispatch({
						type: BOOK_EDITOR_ASYNC_SENTENCE_UPDATED,
						payload: res.data,
					})
				)
				.catch(console.error);
		}
	};
}

export function asyncReloadBook() {
	return (dispatch, getState) => {
		// Get currently active sentence
		const {
			editor: { book },
		} = getState();

		// Reload book
		message.success("Reloading the book...");

		if (book) dispatch(load(book.id));
	};
}

export function finalise(bookId) {
	return () => {
		axios
			.post(`books/${bookId}/finalise/`)
			.catch((e) =>
				message.error("An error occured while exporting the book.")
			);
	};
}

export function getSentenceByTimestamp(timestamp) {
	const duration = timestampToDuration(timestamp);
	
	return ( dispatch, getState ) => {
		const { editor } = getState();
		const { sentencesById } = editor;

		const sentenceArr = Object.keys(sentencesById)
			.map(key => sentencesById[key]);

		if(duration <= sentenceArr[0].endTime) return sentenceArr[0];

		// get last item of filtered array
		return sentenceArr.filter((sentence) => Math.floor(sentence.endTime) <= duration)
			.slice(-1)[0];
	}
}

const initialState = {
	sentencesById: {},
	sentenceIds: [],
	paragraphs: [],
	book: null,
	error: null,
	loading: true,
	cluster: null,
	activeSentenceIds: [],
	activeSentenceStartTime: null,
	autoPlay: localStorage.getItem("autoPlay") !== "false",
	autoContinue: localStorage.getItem("autoContinue") !== "false",
	exportLoading: false,
	noteIds: [],
	notesById: {},
	highlightIssueSentence: null,
};

export default function bookListReducer(state = initialState, action) {
	switch (action.type) {
		case BOOK_EDITOR_LOAD:
			return {
				...initialState,
				autoPlay: localStorage.getItem("autoPlay") !== "false",
				autoContinue: localStorage.getItem("autoContinue") !== "false",
			};
		case BOOK_EDITOR_LOAD_SUCCESS:
			let sentenceIds = [],
				sentencesById = {},
				paragraphs = [],
				noteIds = [],
				notesById = {};

			let sentenceEndTime = 0;	
			// Loop throught each paragraph to agg ui data
			action.payload.paragraphs.forEach((paragraph) => {
				// Keep track of the sentence ids of this paragraph
				let sentencesIdsOfTheParagraph = [];
				
				// Loop through sentences of this paragraph
				paragraph.forEach((sentence) => {
					// Add simply to a global sentence id list
					// A map of books keyed by ids
					// A list of sentence ids of this specific paragraph
					sentenceEndTime += sentence.active_version.duration;
					sentence.endTime = sentenceEndTime;
					sentencesById[sentence.id] = sentence;
					sentencesIdsOfTheParagraph.push(sentence.id);
					sentenceIds.push(sentence.id);
				});

				// Save paragraph record
				paragraphs.push(sentencesIdsOfTheParagraph);
			});

			action.payload.notes.forEach((note) => {
				noteIds.push(note.id);
				notesById[note.id] = note;
			});

			const selectedIssueTimeStamp = localStorage.getItem("selectedIssueTimestamp");
			let highlightIssueSentence = null;
			if(selectedIssueTimeStamp && selectedIssueTimeStamp.indexOf(":") !== -1){
				const sentenceArr = Object.keys(sentencesById)
					.map(key => sentencesById[key]);

				const duration = timestampToDuration(selectedIssueTimeStamp);
				if(duration <= sentenceArr[0]?.endTime){
					highlightIssueSentence = sentenceArr[0];
				}else{
					highlightIssueSentence = sentenceArr.filter(
						(sentence) => Math.floor(sentence.endTime) >= duration)[0];
				}  
				localStorage.removeItem("selectedIssueTimestamp");
			}
			
			return {
				...state,
				loading: false,
				book: action.payload.book,
				sentenceIds,
				sentencesById,
				paragraphs,
				noteIds,
				notesById,
				cluster: action.payload.cluster,
				highlightIssueSentence
			};
		case BOOK_EDITOR_LOAD_FAIL:
			localStorage.removeItem("selectedIssueTimestamp");
			return { ...state, loading: false, error: action.payload };
		case BOOK_EDITOR_SELECT_SENTENCE:
			const sentenceId = action.payload.id;
			const multi = action.payload.multi;

			// If multi, we don't need to calculate paragraph etc
			if (multi) {
				const alreadyActive =
					state.activeSentenceIds.indexOf(sentenceId) > -1;
				return {
					...state,
					activeSentenceIds: alreadyActive
						? state.activeSentenceIds.filter(
								(id) => id !== sentenceId
						  )
						: [...state.activeSentenceIds, sentenceId],
				};
			}

			// Get active paragraph
			const activeParagraph = state.paragraphs.filter(
				(sentenceIds) => sentenceIds.indexOf(sentenceId) > -1
			)[0];

			// Paragraph not found || sentence not found
			if (!activeParagraph) return state;

			// Calculate start time of this sentence
			const indexOfActiveSentence = state.sentenceIds.indexOf(sentenceId);
			let startTime = 0;

			for (let i = 0; i < indexOfActiveSentence; i++) {
				startTime +=
					state.sentencesById[state.sentenceIds[i]].active_version
						.duration;
			}

			return {
				...state,
				activeSentenceStartTime: startTime,
				activeSentenceIds: [sentenceId],
			};
		case BOOK_EDITOR_MARK_SENTENCE_SUCCESS:
			return {
				...state,
				sentencesById: {
					...state.sentencesById,
					[action.payload.id]: action.payload,
				},
			};
		case BOOK_EDITOR_TOGGLE_AUTO_PLAY:
			localStorage.setItem("autoPlay", state.autoPlay ? "false" : "true");
			return { ...state, autoPlay: !state.autoPlay };
		case BOOK_EDITOR_TOGGLE_AUTO_CONTINUE:
			localStorage.setItem(
				"autoContinue",
				state.autoContinue ? "false" : "true"
			);
			return { ...state, autoContinue: !state.autoContinue };
		case BOOK_EDITOR_ASYNC_SENTENCE_UPDATED:
			return {
				...state,
				sentencesById: {
					...state.sentencesById,
					[action.payload.id]: action.payload,
				},
			};
		case SENTENCE_DETAILS_LOAD_SUCCESS:
			return {
				...state,
				sentencesById: {
					...state.sentencesById,
					[action.payload.sentence.id]: action.payload.sentence,
				},
			};
		case NLP_PASTE:
			const { sentence } = action.payload;
			const { active_version } = sentence;
			const { nlp, gst_factor } = action.payload.clipboard;
			const updatedSentence = {
				...action.payload.sentence,
				active_version: {
					...active_version,
					nlp: nlp,
					gst_factor: gst_factor,
				},
			};
			return {
				...state,
				sentencesById: {
					...state.sentencesById,
					[action.payload.sentence.id]: updatedSentence,
				},
			};
		case NOTE_CREATED:
			return {
				...state,
				noteIds: [action.payload.id, ...state.noteIds],
				notesById: {
					...state.notesById,
					[action.payload.id]: action.payload,
				},
			};
		case NOTE_DELETED:
			return {
				...state,
				noteIds: state.noteIds.filter((id) => id !== action.payload),
			};
		case CLUSTER_STATUS_UPDATE_SUCCESS:
			return { ...state, cluster: action.payload };
		case SYNTHESIZE_SUCCESS:
			return { ...state, book: action.payload };
		case BOOK_DONE_UPDATE_SUCCESS:
			const updatedBook = {
				...state.book,
				[action.payload.field]: action.payload.value,
			};

			return {
				...state,
				book: updatedBook,
				cluster: {
					...state.cluster,
					books: state.cluster.books.map((book) =>
						book.id === action.payload.id ? updatedBook : book
					),
				},
			};
		default:
			return state;
	}
}
