import React, { useEffect } from "react";
import _ from "lodash";
import LanguagePredictionContainer from "../containers/LanguagePredictionContainer";
import { TableRow, TableCell } from "../../../../../components/Table";
import Badge from "../../../../../components/Badge";
import Button from "../../../../../components/Button";
import MiniPlayer from "../../../../../components/MiniPlayer";
import columns from "./columns";
import {
	convertTranscription,
	parseTranscription,
} from "../../../../../services/lexicons";

function LexiconPrediction({ prediction, onClick, color = "green" }) {
	return (
		<Badge onClick={onClick} color={color}>
			<span
				className="font-mono truncate"
				style={{ maxWidth: 270, fontFamily: "monospace" }}
				title={prediction}
			>
				{prediction}
			</span>
		</Badge>
	);
}

function parseOxfordPredictions(predictions) {
	try {
		return _.uniq(JSON.parse(predictions).map((l) => l[1]));
	} catch (e) {
		return [];
	}
}

function MissingWordRow({
	sessionId,
	word,
	onEdit,
	editText,
	selectedColumns,
	portalProjectLexiconEntry,
	id,
	playingAudioId,
	onPlayAudio,
	language,
	wordCount
}) {
	const token =
		word.token !== word.token_display ? (
			<abbr title={word.token}>{word.token_display}</abbr>
		) : (
			word.token_display
		);

	return (
		<TableRow>
			<TableCell className="font-medium">
				{word.wav_url && (
					<MiniPlayer
						playerId={id}
						src={word.wav_url}
						onPlayAudio={onPlayAudio}
						playingAudioId={playingAudioId}
					/>
				)}
				<LanguagePredictionContainer
					sessionId={sessionId}
					word={word}
				/>
				{token}{" "}{`(${wordCount})`}
			</TableCell>
			{portalProjectLexiconEntry && (
				<TableCell>
					{portalProjectLexiconEntry.recording && (
						<MiniPlayer
							playerId={`pp-${id}`}
							onPlayAudio={onPlayAudio}
							playingAudioId={playingAudioId}
							src={portalProjectLexiconEntry.recording}
						/>
					)}
					{portalProjectLexiconEntry.ipa}
				</TableCell>
			)}
			{selectedColumns.map((col) => {
				const key = `prediction_${col}`;

				if (Object.keys(columns).indexOf(col) === -1) {
					return null;
				}

				return (
					<TableCell key={col}>
						{col === "oxford" ? (
							parseOxfordPredictions(word.prediction_oxford).map(
								(w) => (
									<LexiconPrediction key={w} prediction={w} />
								)
							)
						) : col === "nlp_api" ? (
							<LexiconPrediction
								color={
									word[key] === word.token ? "green" : "red"
								}
								prediction={
									word[key] === word.token ? "✓" : word[key]
								}
							/>
						) : (
							<LexiconPrediction
								onClick={() =>
									onEdit(
										word,
										col === "g2p_mono_lingual"
											? "G2P"
											: "Textflow"
									)
								}
								prediction={
									col === "festival"
										? convertTranscription(
												language,
												"combilex",
												"deepzen",
												parseTranscription(
													language,
													"combilex",
													word[key]
												)
										  )
										: word[key]
								}
							/>
						)}
					</TableCell>
				);
			})}
			<TableCell style={{ textAlign: "right" }}>
				<Button size="small" onClick={() => onEdit(word)}>
					{editText || "Edit"}
				</Button>
			</TableCell>
		</TableRow>
	);
}

export default MissingWordRow;
