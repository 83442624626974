import React, { useState, useEffect } from "react";
import { useList } from "./useList";
import axios from "axios";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { Table, TableRow, TableCell, TableBody } from "../../components/Table";
import RightArrow from "../../components/Icons/RightArrow";
import { browserHistory } from "react-router";
import { statusLabels } from "../../components/ClusterStatusBadge";
import Badge from "../../components/Badge";
import { statuses } from "../Clusters/modules/portal";
import SearchBox from "../../components/SearchBox";
import SortBox from "../../components/SortBox";
import FilterBox from "../../components/FilterBox";
import Pagination from "../../components/Pagination";

const defaultPageOptions = {
	page: 1,
	pageSize: 10
};

const defaultSortOptions = {
	sort: "title", 
	sortDir: "asc"
};

const StatusGroupData = ({ status }) => {
	const initialList = useList(status, `/clusters/?status=${status}&ordering=${defaultSortOptions.sort}&page=${defaultPageOptions.page}&page_size=${defaultPageOptions.pageSize}`);
	const [list, setList] = useState(null);
	const [searchedValue, setSearchedValue] = useState("");
	const [filterData, setFilterData] = useState("");
	const [totalSize, setTotalSize] = useState(0);
	const [loading, setLoading] = useState(false);
	const [clearFilter, setClearFilter] = useState(false);
	const [sortData, setSortData] = useState(defaultSortOptions);
	const [page, setPage] = useState(defaultPageOptions);

    useEffect(() => {
		if(initialList){
			setTotalSize(initialList.data.count);
			setList(initialList);
		}
	}, [initialList]);

	const handleChange = (_sortData, _searchedValue, _filterData, _page) => {
		//Reset pagination...
		let newPage = _page;
        if(_searchedValue !== searchedValue 
            || _sortData.sort !== sortData.sort
            || _sortData.sortDir !== sortData.sortDir
			|| _filterData !== filterData
        ){
            newPage = defaultPageOptions;
        }
        setLoading(true);
        setSortData(_sortData);
        setSearchedValue(_searchedValue);
		setFilterData(_filterData);
        setClearFilter(false);

        const ordering = _sortData.sortDir === "desc" ?  `-${_sortData.sort}` : `${_sortData.sort}`;
		const clusterListEndpoint = `/clusters/?status=${status}&ordering=${ordering}&page=${newPage.page}&page_size=${newPage.pageSize}&search=${_searchedValue}&portal_project__status=${_filterData}`;
		axios
            .get(clusterListEndpoint)
            .then((res) => {
				setList({...list, data: res.data, endpoint: clusterListEndpoint});
				setTotalSize(res.data.count);
            })
            .catch((e) => alert("Error occurred while fetching data."))
            .finally(() => {
				setLoading(false);
				setPage(newPage);
			});
	}; 
	
	if (!list || !(list?.data?.results)) return null;
    
	return (
		<div className="w-full">
			{initialList && initialList.data?.results.length >= 5 && (
				<div>
					<div className="flex w-full">
						<div className="w-full">
							<SearchBox 
								placeholder="Search by title"
            					className="py-1.5"
								loading={loading}
								clear={!searchedValue}
								onChange={(e) => {handleChange(sortData, e, filterData, page)}}
							/>
						</div>
						<div className="flex pr-0">
							<FilterBox 
            					className="mr-0"
								title="Filter by state"
								clearFilter={clearFilter}
            					data={Object.keys(statuses).map((key) => ({ title: statuses[key], field: key}))}
            					onChange={(e) => handleChange(sortData, searchedValue, e, page)}
          					/>
						
							<SortBox 
            					className="mr-0"
								containerWidth={32}
            					data={[
	              					{title: "Title", field: "title", type: "text"},
              						{title: "State", field: "portal_project__status", type: "text"}
            					]}
            					defaultFieldIndex={0}
            					defaultSort={defaultSortOptions.sortDir}
            					onChange={(e) => handleChange(e, searchedValue, filterData, page)}
          					/>	
						</div>
					</div>
					{filterData && (
						<div className="flex justify-between pl-3 pr-1 pb-2">
							<div className="w-5/6">
								<div className="w-full">
									<span className="mr-2">State:</span>
									{filterData.split(",").map((v, index) => (
										<Badge key={index} color="blue" size="default" className="mt-2">
											{statuses[v]}
										</Badge>
									))}	
								</div>
							</div>
							<div className="w-1/6 pt-1.5">
								<button
									className="inline rounded-full float-right border border-gray-200 focus:outline-none focus:border-gray-300 px-2.5 py-0.5 leading-4 bg-gray-100 text-gray-800 font-medium ml-2.5"
									onClick={(e) => {
										setSearchedValue("");
										setClearFilter(!clearFilter);
									}}>
									Clear filters
								</button>
							</div>
						</div>	
					)}
					
				</div>
			)}
			
			<Table className="border-t border-gray-200 table-fixed w-full">
				<TableBody>
					{!(list?.data?.results?.length > 0) && (
						<TableRow>
							<TableCell className="flex justify-center items-center py-3">
								<span>No Data</span>
							</TableCell>
						</TableRow>
					)}
					{list?.data?.results.map((cluster) => (
						<TableRow
							key={cluster.id}
							className="cursor-pointer"
							onClick={() =>
								browserHistory.push(`/clusters/${cluster.id}`)
							}
						>
							<TableCell className="font-medium pt-3">
								<span className="inline-block max-w-sm truncate">
									{cluster.title}
								</span>
							</TableCell>
						
							<TableCell className="text-right">
								{cluster.state && (
									<Badge color="blue" size="default">
										{statuses[cluster.state]}
									</Badge>
								)}
								<RightArrow size={7} className="text-gray-500" />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			{totalSize > 0 && (						
				<Pagination 
          			page={page.page} 
          			pageSize={page.pageSize} 
          			totalSize={totalSize}
          			onChangePage={(e) => handleChange(sortData, searchedValue, filterData, e)}
        		/>
			)}	
		</div>
	);
};

const StatusGroup = ({ status }) => {
	const [open, setOpen] = useState(false);

	const toggleButton = (
		<Button size="small" onClick={() => setOpen(!open)}>
			{open ? "Close" : "Open"}
		</Button>
	);

	return (
		<Card
			className="mb-4"
			title={statusLabels[status]}
			headerActions={toggleButton}
		>
			{open && <StatusGroupData status={status} />}
		</Card>
	);
};

export default StatusGroup;
