import axios from "axios";
import { message } from "antd";

export const SESSION_LOAD = "SESSION_LOAD";
export const SESSION_LOAD_SUCCESS = "SESSION_LOAD_SUCCESS";
export const SESSION_UPDATE_SUCCESS = "SESSION_UPDATE_SUCCESS";

export const SYNTHESIZE = "LEXICON_SESSION_SYNTHESIZE";
export const SYNTHESIZE_SUCCESS = "LEXICON_SESSION_SYNTHESIZE_SUCCESS";
export const SYNTHESIZE_FAIL = "LEXICON_SESSION_SYNTHESIZE_FAIL";

export const REANALYZE = "LEXICON_SESSION_REANALYZE";
export const REANALYZE_SUCCESS = "LEXICON_SESSION_REANALYZE_SUCCESS";
export const REANALYZE_FAIL = "LEXICON_SESSION_REANALYZE_FAIL";

export const UPDATE_MISSING_WORD = "LEXICON_SESSION_UPDATE_MISSING_WORD";

export const updateMissingWord = (word) => ({
	type: UPDATE_MISSING_WORD,
	payload: word,
});

const loadSuccess = (session) => ({
	type: SESSION_LOAD_SUCCESS,
	payload: session,
});

export function load(id) {
	return (dispatch) => {
		axios
			.get(`/lexicon/sessions/${id}/`)
			.then((res) => dispatch(loadSuccess(res.data)))
			.catch((e) =>
				message.error("An error occurred while loading session.")
			);
	};
}

export function archive(id) {
	return (dispatch) => {
		axios
			.delete(`/lexicon/sessions/${id}/`)
			.then((res) => message.success("Session archived successfully."))
			.catch((e) =>
				message.error("An error occurred while loading session.")
			);
	};
}

const synthesizeFail = (e) => ({
	type: SYNTHESIZE_FAIL,
	payload: e,
});

const synthesizeSuccess = (data) => ({
	type: SYNTHESIZE_SUCCESS,
	payload: data,
});

export function synthesize(id, values) {
	return (dispatch) => {
		dispatch({ type: SYNTHESIZE, payload: id });

		axios
			.post(`lexicon/sessions/${id}/synthesize/`, values)
			.then((res) => dispatch(synthesizeSuccess(res.data)))
			.catch((e) => {
				message.error("An error occurred while starting synthesis.");

				dispatch(synthesizeFail(e));
			});
	};
}

const reanalyzeFail = (e) => ({
	type: REANALYZE_FAIL,
	payload: e,
});

const reanalyzeSuccess = (data) => ({
	type: REANALYZE_SUCCESS,
	payload: data,
});

export const isColumnActive = (lang, col) => !(lang !== "uk" && lang !== "us" && col === "oxford");

export function reanalyze(id, values) {
	return (dispatch) => {
		dispatch({ type: REANALYZE, payload: id });

		axios
			.post(`lexicon/sessions/${id}/reanalyze/`, values)
			.then((res) => dispatch(reanalyzeSuccess(res.data)))
			.catch((e) => {
				message.error("An error occurred while restarting analysis.");

				dispatch(reanalyzeFail(e));
			});
	};
}
