import axios from "axios";
import { message } from "antd";

export const RELOAD = "RELOAD";
export const RELOAD_SUCCESS = "RELOAD_SUCCESS";
export const RELOAD_FAIL = "RELOAD_FAIL";
export const RELOAD_TOGGLE = "RELOAD_TOGGLE";

const reloadSuccess = (data) => ({ type: RELOAD_SUCCESS });
const reloadFail = (e) => ({ type: RELOAD_FAIL, payload: e });

export function reload(bookId, values) {
	return (dispatch) => {
		dispatch({ type: RELOAD, payload: bookId });

		axios
			.post(`books/${bookId}/reload_tts_session/`, values)
			.then((res) => dispatch(reloadSuccess(res.data)))
			.catch((e) => {
				message.error("An error occured while reloading tts session.");
				dispatch(reloadFail(e));
			});
	};
}

export const toggle = () => ({ type: RELOAD_TOGGLE });

const initialState = {
	open: false,
	loading: false,
};

export default function reloadReducer(state = initialState, action) {
	switch (action.type) {
		case RELOAD:
			return { ...state, loading: true };
		case RELOAD_SUCCESS:
			return { ...state, show: false, loading: false };
		case RELOAD_FAIL:
			return { ...state, loading: false };
		case RELOAD_TOGGLE:
			return { ...state, show: !state.show };
		default:
			return state;
	}
}
