import React, {useState} from "react";
import Descriptions, {
	DescriptionsLinkItem,
} from "../../../../../components/Descriptions";
import BundleForm from "./BundleForm";
import WorkLogEditorForm from "./WorkLogEditorForm";
import CalculateNlpScoreForm from "./CalculateNlpScoreForm";
import Card from "../../../../../components/Card";
import Modal from "../../../../../components/Modal";
import FirstPassChapters from "./FirstPassChapters";
import CorrectionReportForm from "./CorrectionReportForm";

const Actions = ({cluster, portalProject}) => {
	const [content, setContent] = useState(null);
	const handleClose = () => setContent(null);

	return (
		<Card title="Actions" className="mt-5">
			{content && <Modal onClose={handleClose}>{content}</Modal>}

			<Descriptions>
				<DescriptionsLinkItem
					label="First pass all chapters"
					onClick={() =>
						setContent(
							<FirstPassChapters id={cluster.id} onClose={handleClose} />
						)
					}
				/>
				<DescriptionsLinkItem
					label="Bundle book"
					onClick={() =>
						setContent(
							<BundleForm id={cluster.id} onClose={handleClose} />
						)
					}
				/>

				<DescriptionsLinkItem
					label="Export work logs"
					onClick={() =>
						setContent(
							<WorkLogEditorForm
								id={cluster.id}
								onClose={handleClose}
							/>
						)
					}
				/>
				{portalProject && (
					<DescriptionsLinkItem
						label="Export correction reports"
						onClick={() =>
							setContent(
								<CorrectionReportForm
									id={portalProject.id}
									onClose={handleClose}
								/>
							)
						}
					/>)}

				{/*<DescriptionsLinkItem
					label="Calculate Nlp Score"
					onClick={() =>
						setContent(
							<CalculateNlpScoreForm
								id={cluster.id}
								onClose={handleClose}
							/>
						)
					}
				/>*/}
			</Descriptions>
		</Card>
	);
};

export default Actions;
