import React from "react";
import { reduxForm, Field } from "redux-form";
import { TextField } from "redux-form-antd";
import { Button } from "antd";

export function TokenForm({ count, handleSubmit, loading }) {
	return (
		<form onSubmit={handleSubmit}>
			<div className="float-left">
				<Field label={null} name="new_token" component={TextField} />
			</div>

			<Button type="primary" htmlType="submit" loading={loading}>
				Update tokens for &nbsp;{count}&nbsp;sentences
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "cluster-search-resynthesize-token"
})(TokenForm);
