import React, { Component } from "react";
import { reduxForm, Field, change } from "redux-form";
import * as validation from "../../../../services/validations";
import ApiError from "../../../../components/ApiError";
import { Button, Switch } from "antd";
import { TextField, SliderField, NumberField } from "redux-form-antd";

export class Form extends Component {
	toggleAllowedKey = (key) =>
		this.props.dispatch(
			change(
				"resynthesize-multi",
				"allowedKeys",

				this.props.allowedKeys.indexOf(key) > -1
					? this.props.allowedKeys.filter((k) => k !== key)
					: [...this.props.allowedKeys, key]
			)
		);

	isKeyAllowed = (key) => this.props.allowedKeys.indexOf(key) > -1;

	render() {
		const { handleSubmit, loading, apiError, nlpTypes } = this.props;

		return (
			<form onSubmit={handleSubmit}>
				{apiError && <ApiError error={apiError} />}
				<Field
					label="Title"
					name="title"
					validate={validation.required}
					component={TextField}
					size="small"
				/>
				<div className="row">
					<div className="col-md-6">
						<Field
							label={
								<span>
									<Switch
										style={{ marginRight: 5 }}
										size="small"
										onChange={() =>
											this.toggleAllowedKey("model_id")
										}
									/>
									Model ID
								</span>
							}
							name="model_id"
							validate={validation.required}
							component={TextField}
							disabled={!this.isKeyAllowed("model_id")}
							size="small"
						/>
					</div>
					<div className="col-md-3">
						<Field
							label={
								<span>
									<Switch
										style={{ marginRight: 5 }}
										size="small"
										onChange={() =>
											this.toggleAllowedKey("speaker_id")
										}
									/>
									Speaker
								</span>
							}
							name="speaker_id"
							validate={validation.required}
							component={TextField}
							disabled={!this.isKeyAllowed("speaker_id")}
							size="small"
						/>
					</div>
					<div className="col-md-3">
						<Field
							label={
								<span>
									<Switch
										style={{ marginRight: 5 }}
										size="small"
										onChange={() =>
											this.toggleAllowedKey("pause")
										}
									/>
									Pause
								</span>
							}
							disabled={!this.isKeyAllowed("pause")}
							name="pause"
							component={TextField}
							size="small"
						/>
					</div>
				</div>

				<Field
					label={
						<span>
							<Switch
								style={{ marginRight: 5 }}
								size="small"
								onChange={() =>
									this.toggleAllowedKey("gst_factor")
								}
							/>
							Intensity
						</span>
					}
					disabled={!this.isKeyAllowed("gst_factor")}
					name="gst_factor"
					validate={validation.number}
					component={SliderField}
					min={0}
					max={1}
					step={0.01}
				/>

				<label style={{ fontSize: 13 }}>
					<Switch
						style={{ marginRight: 5 }}
						size="small"
						onChange={() => this.toggleAllowedKey("nlp")}
					/>
					NLP Scores
				</label>
				<div className="row">
					{nlpTypes.map((type) => (
						<div className="col">
							<Field
								label={type}
								name={`nlp_${type}`}
								min={0}
								max={1}
								step={0.01}
								vertical
								style={{ height: 100 }}
								component={SliderField}
								disabled={!this.isKeyAllowed("nlp")}
							/>
							<Field
								name={`nlp_${type}`}
								min={0}
								max={1}
								step={0.01}
								style={{ width: "100%" }}
								component={NumberField}
								size="small"
								disabled={!this.isKeyAllowed("nlp")}
							/>
						</div>
					))}
				</div>

				<Button
					block
					htmlType="submit"
					type="primary"
					size="large"
					loading={loading}
				>
					Resynthesize
				</Button>
			</form>
		);
	}
}

export default reduxForm({
	form: "resynthesize-multi",
	enableReinitialize: true,
})(Form);
