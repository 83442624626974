import React, { useEffect, useState } from "react";
import Card, { CardBody } from "../../../../../components/Card";
import Modal from "../../../../../components/Modal";
import Descriptions, {
	DescriptionsItem,
} from "../../../../../components/Descriptions";
import { useSelector } from "react-redux";
import { wordByIdSelector } from "../../modules/sessions";
import Button from "../../../../../components/Button";
import { Popconfirm } from "antd";

const PortalProjectFinishInitialLinguisticsReview = ({
	onClose,
	onSubmit,
	missingWordIds,
}) => {
	const [tokens, setTokens] = useState([]);
	const missingWords = useSelector((state) =>
		missingWordIds.map((id) => wordByIdSelector(state, id))
	);

	useEffect(() => setTokens(missingWords.map((w) => w.token)), []);

	const handleChange = (word) => (e) => {
		const tokensWithout = tokens.filter((t) => word.token !== t);

		setTokens(
			e.target.checked
				? [...tokensWithout, e.target.value]
				: tokensWithout
		);
	};

	return (
		<Modal onClose={onClose}>
			<Card title="Customer Pronunciation Guidance">
				<div className="max-h-2/3">
					<Descriptions>
						{missingWords.map((word) => (
							<DescriptionsItem
								label={
									<label key={word.token}>
										<input
											type="checkbox"
											className="form-checkbox mr-2"
											value={word.token}
											checked={
												tokens.indexOf(word.token) > -1
											}
											onChange={handleChange(word)}
										/>
										{word.token}
									</label>
								}
							/>
						))}
					</Descriptions>
				</div>
				<CardBody>
					<Popconfirm
						title="Are you sure? You won't be able to add words later."
						okText={
							tokens.length === 0
								? "Continue"
								: "Yes, send to customer"
						}
						onConfirm={() => onSubmit(tokens)}
					>
						<Button block type="success" className="mt-2">
							{tokens.length === 0
								? "Continue to editing"
								: "Send to customer"}
						</Button>
					</Popconfirm>
				</CardBody>
			</Card>
		</Modal>
	);
};

export default PortalProjectFinishInitialLinguisticsReview;
