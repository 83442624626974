class Bufferer {
	static WINDOW = 5;

	readyIds = [];

	buffer(sentencesById, sentenceId) {
		for (let i = sentenceId + 1; i < sentenceId + Bufferer.WINDOW; i++) {
			if (
				sentencesById[i] &&
				sentencesById[i].active_version &&
				this.readyIds.indexOf(i) === -1
			) {
				this.load(i, sentencesById[i].active_version.wav_url);
			}
		}
	}

	load(id, url) {
		const audio = new Audio();
		audio.addEventListener(
			"canplaythrough",
			() => this.readyIds.push(id),
			false
		);
		audio.src = url;
	}
}

const instance = new Bufferer();

export default instance;
