import React from "react";
import ReactDOM from "react-dom";
import "./styles/vendor.scss";
import "./styles/main.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {
	API_URL,
	SENTRY_DSN,
	ENVIRONMENT,
	SENTRY_SAMPLING_RATE,
	PUSHER_TOKEN,
} from "./config";
import axios from "axios";
import Pusher from "pusher-js";
import {
	AUTH_ACCESS_TOKEN_KEY,
	loginVerify,
	loginVerifySuccess,
} from "./routes/Auth/modules/auth";
import routes from "./routes";
import createStore from "./store/createStore";
import "./tailwind.generated.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Axios
axios.defaults.baseURL = API_URL;

if (typeof SENTRY_DSN != "undefined") {
	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: SENTRY_SAMPLING_RATE,
		environment: ENVIRONMENT,
	});
}

window.pusher = new Pusher(PUSHER_TOKEN, {
	cluster: "eu",
	forceTLS: true,
});
Pusher.logToConsole = true;

// Store Initialization
const store = createStore();

// Login
const storedJwt = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
if (storedJwt) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + storedJwt;
	store.dispatch(loginVerify(storedJwt));
} else {
	store.dispatch(loginVerifySuccess(null));
}

ReactDOM.render(
	<DndProvider backend={HTML5Backend}>
		<App store={store} routes={routes} />
	</DndProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
