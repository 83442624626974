import React from "react";
import { connect } from "react-redux";
import { updateBookDone } from "../../../modules/statusUpdater";

const DoneStatus = ({ book, updateBookDone }) => {
	return (
		<div className="bg-white rounded-md font-medium">
			<div className="border-b border-gray-300 px-2 py-1">
				<label>
					<input
						type="checkbox"
						className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2 -mt-0.5"
						onChange={(event) =>
							updateBookDone(
								book.id,
								"done",
								event.target.checked
							)
						}
						disabled={book.done_2nd_pass}
						checked={book.done}
					/>
					1st pass
				</label>
			</div>
			<div className="px-2 py-1">
				<label>
					<input
						type="checkbox"
						className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2 -mt-0.5"
						onChange={(event) =>
							updateBookDone(
								book.id,
								"done_2nd_pass",
								event.target.checked
							)
						}
						disabled={!book.done}
						checked={book.done_2nd_pass}
					/>
					2nd pass
				</label>
			</div>
		</div>
	);
};

const mapDispatchToProps = {
	updateBookDone,
};

export default connect(null, mapDispatchToProps)(DoneStatus);
