import React from "react";
import { Timeline } from "antd";
import SentenceDetailsVersionsItem from "./SentenceDetailsVersionsItem";

export function SentenceDetailsVersions({
	sentence,
	versions,
	activate,
	copy,
}) {
	return (
		<div className="p-4">
			<Timeline>
				{versions.map((version) => (
					<Timeline.Item key={version.id}>
						<SentenceDetailsVersionsItem
							activate={activate}
							active={version.id === sentence.active_version.id}
							version={version}
							sentence={sentence}
							copy={copy}
						/>
					</Timeline.Item>
				))}
			</Timeline>
		</div>
	);
}

export default SentenceDetailsVersions;
