import React from "react";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../components/ApiError";
import { Modal } from "antd";
import * as validation from "../../../../services/validations";
import TextField from "../../../../components/Forms/TextField";

export function Form({ handleSubmit, loading, apiError, onCancel }) {
	return (
		<Modal
			title="Reload TTS session"
			visible={true}
			onOk={handleSubmit}
			okText="Reload"
			confirmLoading={loading}
			onCancel={onCancel}
		>
			{apiError && <ApiError error={apiError} />}

			<Field
				label="TTS Session ID"
				name="tts_session_id"
				validate={validation.required}
				component={TextField}
			/>
		</Modal>
	);
}

export default reduxForm({
	form: "reload-tts-session",
})(Form);
