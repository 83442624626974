import axios from "axios";
import { message } from "antd";

export const SEARCH_CLUSTER_LOAD = "SEARCH_CLUSTER_LOAD";
export const SEARCH_CLUSTER_LOAD_SUCCESS = "SEARCH_CLUSTER_LOAD_SUCCESS";
export const SEARCH_CLUSTER_LOAD_FAIL = "SEARCH_CLUSTER_LOAD_FAIL";

export const SEARCH_LOAD = "SEARCH_LOAD";
export const SEARCH_LOAD_SUCCESS = "SEARCH_LOAD_SUCCESS";
export const SEARCH_LOAD_FAIL = "SEARCH_LOAD_FAIL";

export const SEARCH_TOGGLE_SENTENCE = "SEARCH_TOGGLE_SENTENCE";
export const SEARCH_CHECK_ALL_SENTENCES = "SEARCH_CHECK_ALL_SENTENCES";
export const SEARCH_UNCHECK_ALL_SENTENCES = "SEARCH_UNCHECK_ALL_SENTENCES";

export const SEARCH_RESYNTHESIZE = "SEARCH_RESYNTHESIZE";
export const SEARCH_RESYNTHESIZE_SUCCESS = "SEARCH_RESYNTHESIZE_SUCCESS";
export const SEARCH_RESYNTHESIZE_FAIL = "SEARCH_RESYNTHESIZE_FAIL";

export const SEARCH_ASYNC_SENTENCE_UPDATED = "SEARCH_ASYNC_SENTENCE_UPDATED";

const loadSuccess = (data) => ({
	type: SEARCH_CLUSTER_LOAD_SUCCESS,
	payload: data,
});
const loadFail = (e) => ({ type: SEARCH_CLUSTER_LOAD_FAIL, payload: e });

export function load(id) {
	return (dispatch) => {
		dispatch({ type: SEARCH_CLUSTER_LOAD, payload: { id } });

		axios
			.get(`clusters/${id}/`)
			.then((res) => dispatch(loadSuccess(res.data)))
			.catch((e) => dispatch(loadFail(e)));
	};
}

const searchSuccess = (data) => ({ type: SEARCH_LOAD_SUCCESS, payload: data });
const searchFail = (e) => ({ type: SEARCH_LOAD_FAIL, payload: e });

export function search(id, query) {
	return (dispatch) => {
		dispatch({ type: SEARCH_LOAD, payload: { id, query } });

		axios
			.get(`clusters/${id}/search?q=${query}`)
			.then((res) => dispatch(searchSuccess(res.data)))
			.catch((e) => dispatch(searchFail(e)));
	};
}

export const toggleChecked = (id) => ({
	type: SEARCH_TOGGLE_SENTENCE,
	payload: id,
});
export const checkAll = (ids) => ({
	type: SEARCH_CHECK_ALL_SENTENCES,
	payload: ids,
});
export const uncheckAll = (ids) => ({
	type: SEARCH_UNCHECK_ALL_SENTENCES,
	payload: ids,
});

const resynthesizeSuccess = (data) => ({
	type: SEARCH_RESYNTHESIZE_SUCCESS,
	payload: data,
});
const resynthesizeFail = (e) => ({
	type: SEARCH_RESYNTHESIZE_FAIL,
	payload: e,
});

export function resynthesize(clusterId, sentenceIds, query, phones, newToken) {
	return (dispatch) => {
		dispatch({ type: SEARCH_RESYNTHESIZE, payload: { sentenceIds } });

		axios
			.post(`clusters/${clusterId}/resynt_words/`, {
				ids: sentenceIds.join(","),
				phones,
				new_token: newToken,
				q: query,
				model_id: phones && newToken,
			})
			.then((res) => {
				dispatch(resynthesizeSuccess(res.data));
				message.success("Resynthesis added to queue.");
			})
			.catch((e) => {
				dispatch(resynthesizeFail(e));
				message.error("An error occured while resynthesizing words.");
			});
	};
}

export const asyncSentenceUpdated = (sentence) => (dispatch) => {
	axios
		.get(`sentences/${sentence.id}/`)
		.then((res) =>
			dispatch({
				type: SEARCH_ASYNC_SENTENCE_UPDATED,
				payload: res.data,
			})
		)
		.catch(console.error);
};

const initialState = {
	query: "",
	loading: true,
	cluster: null,
	error: null,
	phoneData: [],
	results: [],
	resultsLoading: false,
	resultsError: null,
	checkedSentenceIds: [],
	resynthesizeLoading: false,
};

export default function SearchReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_CLUSTER_LOAD:
			return { ...initialState };
		case SEARCH_CLUSTER_LOAD_SUCCESS:
			return { ...state, loading: false, cluster: action.payload };
		case SEARCH_CLUSTER_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case SEARCH_TOGGLE_SENTENCE:
			const id = action.payload;
			const checked = state.checkedSentenceIds.indexOf(id) > -1;
			const updatedCheckedSentenceIds = checked
				? state.checkedSentenceIds.filter(
						(sentenceId) => sentenceId !== id
				  )
				: [...state.checkedSentenceIds, id];

			return { ...state, checkedSentenceIds: updatedCheckedSentenceIds };
		case SEARCH_CHECK_ALL_SENTENCES:
			return {
				...state,
				checkedSentenceIds: state.results
					.reduce(
						(ids, result) =>
							result.sentences
								.map((result) => result.id)
								.concat(ids),
						[]
					)
					.filter((id) =>
						action.payload === undefined
							? true
							: action.payload.indexOf(id) > -1
					)
					.concat(state.checkedSentenceIds),
			};
		case SEARCH_UNCHECK_ALL_SENTENCES:
			return {
				...state,
				checkedSentenceIds:
					action.payload === undefined
						? []
						: state.checkedSentenceIds.filter(
								(id) => action.payload.indexOf(id) === -1
						  ),
			};
		case SEARCH_LOAD:
			return {
				...state,
				query: action.payload.query,
				resultsLoading: true,
				results: [],
				resultsError: null,
				checkedSentenceIds: [],
				phoneData: [],
			};
		case SEARCH_LOAD_SUCCESS:
			return {
				...state,
				resultsLoading: false,
				results: action.payload.results,
				phoneData: action.payload.phones,
			};
		case SEARCH_LOAD_FAIL:
			return {
				...state,
				resultsLoading: false,
				resultsError: action.payload,
			};
		case SEARCH_RESYNTHESIZE:
			return { ...state, resynthesizeLoading: true };
		case SEARCH_RESYNTHESIZE_SUCCESS:
		case SEARCH_RESYNTHESIZE_FAIL:
			return { ...state, resynthesizeLoading: false };
		case SEARCH_ASYNC_SENTENCE_UPDATED:
			if (state.results === null) return state;

			const newResults = state.results.map((result) => ({
				...result,
				sentences: result.sentences.map((sentence) =>
					sentence.id === action.payload.id
						? action.payload
						: sentence
				),
			}));

			return { ...state, results: newResults };
		default:
			return state;
	}
}
