import React from "react";
import { PageHeader, Card } from "antd";
import Status from "./Status";
import Prediction from "./Prediction";
import Form from "./Form";

const G2P = ({
	status,
	trainedModels,
	training,
	startTraining,
	prediction,
	predict
}) => (
	<div className="container">
		<PageHeader
			title="G2P"
			subTitle="Monitor current status of trainings and start a new training."
		/>

		<div className="row">
			<div className="col-md-7">
				<Card title="Start a new training">
					<Form
						apiError={training.error}
						loading={training.loading}
						onSubmit={values => startTraining(values)}
					/>
				</Card>
			</div>

			<div className="col-md-5">
				<Status {...status} />
				<br />
				<Prediction
					trainedModels={trainedModels}
					{...prediction}
					predict={predict}
				/>
			</div>
		</div>
	</div>
);

export default G2P;
