import React, { useState, useEffect } from "react";
import Badge from "./Badge";
import classNames from "classnames";

const Tabs = ({ data, defaultTab, tabScope }) => {
	const [activeTab, setActiveTab] = useState(defaultTab);
	
	//Assigns defaultTab to activeTab when tabs scope change
    useEffect(() => {
        setActiveTab(defaultTab);
	}, [tabScope]);
   
	return (
		<>
			<nav className="-mb-px flex">
				{data.map((t) => (
					<button
						onClick={() => setActiveTab(t.key)}
						key={t.key}
						className={classNames(
							"whitespace-no-wrap py-2 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none border-transparent text-gray-500 focus:text-gray-700 focus:border-gray-300 w-1/3 text-center",
							t.key === activeTab
								? "border-cool-gray-500 text-cool-gray-600 focus:text-cool-gray-800 focus:border-cool-gray-700"
								: "border-gray-200 hover:border-gray-300 hover:text-gray-700"
						)}
					>
						{t.title}
						{t.badge > 0 && (
							<Badge size="small" color="indigo" className="ml-1">
								{t.badge}
							</Badge>
						)}
					</button>
				))}
			</nav>

			{data.map((t) => (
				<div key={t.key} className={classNames("", t.key !== activeTab && "hidden")}>
					{t.content}
				</div>
			))}
		</>
	);
};

export default Tabs;
