import React from "react";
import PropTypes from "prop-types";
import { PageHeader, Spin } from "antd";
import ApiError from "../../../../components/ApiError";
import Results from "./Results";

function List({ loading, error, data, update }) {
	return (
		<div className="container">
			<PageHeader title="Lexicons" />

			{loading && <Spin />}
			{error && <ApiError error={error} />}
			{data && <Results update={update} data={data} />}
		</div>
	);
}

List.propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	data: PropTypes.array
};

export default List;
