import React from "react";
import PhonesForm from "./PhonesForm";
import TokenForm from "./TokenForm";
import { Tabs } from "antd";

export function Resynthesize({
	resynthesize,
	checkedSentenceIds,
	phoneData,
	cluster,
	query,
	loading,
}) {
	if (checkedSentenceIds.length === 0) return null;

	return (
		<div className="search__resynthesize">
			<div className="shadow-lg m-3 bg-white overflow-hidden rounded-lg">
				<Tabs
					defaultActiveKey="phones"
					tabPosition="left"
					style={{ height: 150 }}
				>
					<Tabs.TabPane tab="Change Phones" key="phones">
						<PhonesForm
							initialValues={{
								phones:
									phoneData &&
									(phoneData.length > 0
										? phoneData[0]
										: undefined),
							}}
							onSubmit={(values) =>
								resynthesize(
									cluster.id,
									checkedSentenceIds,
									query,
									values.phones,
									values.model_id
								)
							}
							count={checkedSentenceIds.length}
							phoneData={phoneData}
							loading={loading}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Change Token" key="token">
						<TokenForm
							initialValues={{ new_token: query }}
							onSubmit={(values) =>
								resynthesize(
									cluster.id,
									checkedSentenceIds,
									query,
									undefined,
									values.new_token
								)
							}
							count={checkedSentenceIds.length}
							loading={loading}
						/>
					</Tabs.TabPane>
				</Tabs>
			</div>
		</div>
	);
}

export default Resynthesize;
