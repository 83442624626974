import React from "react";
import wrap from "./wrap";

const Pause = () => (
	<path
		fillRule="evenodd"
		d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
		clipRule="evenodd"
	/>
);

export default wrap(Pause);
