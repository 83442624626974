import { createSelector } from "reselect";
import axios from "axios";
import {
	SESSION_LOAD_SUCCESS,
	UPDATE_MISSING_WORD,
} from "../Detail/modules/detail";

export const SESSIONS_LOAD = "SESSIONS_LOAD";
export const SESSIONS_LOAD_SUCCESS = "SESSIONS_LOAD_SUCCESS";
export const SESSIONS_LOAD_FAIL = "SESSIONS_LOAD_FAIL";

export const sessionByIdSelector = createSelector(
	(state) => state.lexicon.sessions.byId,
	(state, id) => id,
	(byId, id) => byId[id]
);

export const wordByIdSelector = createSelector(
	(state) => state.lexicon.sessions.wordsById,
	(state, id) => id,
	(byId, id) => byId[id]
);

const loadSuccess = (byId, ids) => ({
	type: SESSIONS_LOAD_SUCCESS,
	payload: { byId, ids },
});

const loadFail = (e) => ({ type: SESSIONS_LOAD_FAIL, payload: e });

export function load() {
	return (dispatch) => {
		dispatch({ type: SESSIONS_LOAD });
		axios
			.get("/lexicon/sessions")
			.then((res) => {
				const { data } = res;
				let ids = [];
				let byId = {};

				data.forEach((session) => {
					ids.push(session.id);
					byId[session.id] = session;
				});

				dispatch(loadSuccess(byId, ids));
			})
			.catch((e) => dispatch(loadFail(e)));
	};
}

export const getPunctuationLessString = (s) => {
	const punctuationless = s.replace(/[.,\/#!$%?\^&\*;:{}=\-_`~()]/g,"");
	return punctuationless.replace(/\s{2,}/g," ");
}
	
const initialState = {
	byId: {},
	wordsById: {},
	ids: [],
	loading: false,
};

export default function sessionsReducer(state = initialState, action) {
	switch (action.type) {
		case SESSIONS_LOAD:
			return { ...initialState, loading: true };
		case SESSION_LOAD_SUCCESS:
			let existing = state.byId[action.payload.id] || {};
			let session = { ...existing, ...action.payload };
			
			let newWordsById = { ...state.wordsById };
			
			const sortedMissingWords = (session.missing_words || []).sort(
				(a, b) => a.token.toLocaleLowerCase() > b.token.toLocaleLowerCase() ? 1 : -1);

			const sortedResolvedWords = (session.resolved_words || []).sort(
					(a, b) => a.token.toLocaleLowerCase() > b.token.toLocaleLowerCase() ? 1 : -1);

			[...sortedMissingWords, ...sortedResolvedWords].forEach(
				(word) => (newWordsById[word.id] = word)
			);

			session.missing_words = sortedMissingWords.map((w) => w.id || w);
			session.resolved_words = sortedResolvedWords.map((w) => w.id || w);
			
			const words = getPunctuationLessString(session.text)
				.toLocaleLowerCase()
				.split(/\s/);
				
			const wordsFreq = {};
			words.forEach((w) => {
				if(!wordsFreq[w]){
					wordsFreq[w] = 0;
				}
				wordsFreq[w] += 1;
			});

			session.wordsFreq = wordsFreq;
			
			return {
				...state,
				wordsById: newWordsById,
				byId: { ...state.byId, [action.payload.id]: session },
				ids:
					state.ids.indexOf(session.id) > -1
						? state.ids
						: [...state.ids, session.id],
			};
		case SESSIONS_LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				byId: action.payload.byId,
				ids: action.payload.ids,
			};
		case SESSIONS_LOAD_FAIL:
			return { ...state, loading: false, error: action.payload };
		case UPDATE_MISSING_WORD:
			return {
				...state,
				wordsById: {
					...state.wordsById,
					[action.payload.id]: action.payload,
				},
			};
		default:
			return state;
	}
}
