import React, { Component } from "react";
import { Link } from "react-router";
import { Input, Icon } from "antd";
import { PropTypes } from "prop-types";
import classNames from "classnames";
import { browserHistory } from "react-router";

export class BookSwitcher extends Component {
	static propTypes = {
		data: PropTypes.arrayOf(PropTypes.object),
	};

	constructor(props) {
		super(props);

		this.state = {
			filter: "",
		};
	}

	render() {
		const { data, selected } = this.props;
		const { filter } = this.state;
		const filteredResults =
			filter.trim().length === 0
				? data
				: data.filter((book) =>
						book.title.toLowerCase().includes(filter.toLowerCase())
				  );

		let selectedIndex = data.findIndex((book, index) => {
			return book.id === selected;
		});
		const prevChapter =
			selectedIndex === -1 || selectedIndex === 0 || data.length === 1
				? null
				: data[selectedIndex - 1];
		const nextChapter =
			selectedIndex === -1 ||
			selectedIndex === data.length ||
			data.length === 1
				? null
				: data[selectedIndex + 1];

		return (
			<div>
				{/*data.length > 1 && (
					<div className="flex px-2 py-4">
					    <Link
						    key={book.id}
						    to={`/books/${book.id}`}
						    className="flex items-start"
					    >
					    </Link>
						
					</div>
				)*/}

				{data.length > 5 && (
					<div className="p-2 border-b border-gray-200">
						<input
							className="block bg-white outline-none sm:text-sm shadow-sm form-input w-full sm:leading-5 focus:shadow-outline-blue py-1 px-2"
							onChange={(event) =>
								this.setState({ filter: event.target.value })
							}
							value={filter}
							placeholder="Filter..."
							size="small"
						/>
					</div>
				)}

				{data.length > 1 && (
					<div className="flex justify-between py-2 border-b border-gray-200">
						<button
							disabled={!prevChapter}
							className={classNames(
								"py-1 px-2 focus:outline-none focus:border-none",
								!prevChapter &&
									"cursor-not-allowed opacity-50 focus"
							)}
							onClick={() =>
								browserHistory.push(`/books/${prevChapter.id}`)
							}
						>
							<Icon className="text-black-500 mr-1" type="left" />
							Previous
						</button>

						<button
							disabled={!nextChapter}
							className={classNames(
								"py-1 px-2 ml-auto focus:outline-none focus:border-none",
								!nextChapter &&
									"cursor-not-allowed opacity-50 focus"
							)}
							onClick={() =>
								browserHistory.push(`/books/${nextChapter.id}`)
							}
						>
							Next
							<Icon
								className="text-black-500 ml-1"
								type="right"
							/>
						</button>
					</div>
				)}

				{filteredResults.map((book) => (
					<Link
						key={book.id}
						to={`/books/${book.id}`}
						className={classNames(
							"flex py-1 px-2 border-b border-gray-200",
							selected && book.id === selected
								? "bg-cool-gray-100 text-cool-gray-800 font-medium"
								: "hover:bg-gray-100 hover:text-gray-900"
						)}
					>
						<div className="flex-none w-12 text-md">
							{book.done && (
								<>
									<Icon
										className="text-green-500"
										type="check-circle"
									/>
									<span
										className="ml-1 text-xs inline-block text-gray-500"
										style={{
											transform: "translateY(-1px)",
										}}
									>
										{book.done_2nd_pass ? "2nd" : "1st"}
									</span>
								</>
							)}
						</div>
						<div className="flex-shrink">{book.title}</div>
					</Link>
				))}
			</div>
		);
	}
}

export default BookSwitcher;
