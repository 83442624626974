import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import Button from "../../../../components/Button";
import TextAreaField from "../../../../components/Forms/TextAreaField";
import TextField from "../../../../components/Forms/TextField";
import SelectField from "../../../../components/Forms/SelectField";
import { useVoices } from "../../../../services/voices";
import SliderField from "../../../../components/Forms/SliderField";

const Form = ({ handleSubmit, loading, defaultNlpTypes }) => {

	const voices = useVoices();
	const [fixedNlpScore, setFixedNlpScore] = useState(false);

	return (
		<form onSubmit={handleSubmit}>
			<div className="flex -mt-3 -mx-2 mb-4">
				<div className="flex-1 p-2">
					<Field
						label="Model"
						name="tts_model"
						component={TextField}
					/>
				</div>
				<div className="flex-1 p-2">
					<Field
						label="Voice"
						name="tts_voice"
						component={SelectField}
						disabled={voices === null}
						options={voices ?? []}
					/>
				</div>
				<div className="flex-1 p-2">
					<Field
						label="Narration Intensity"
						name="tts_gst_factor"
						component={TextField}
					/>
				</div>
				<div className="flex-1 p-2">
					<Field
						label="Conversation Intensity"
						name="tts_gst_factor_quotes"
						component={TextField}
					/>
				</div>
			</div>

			<Field
				label="Text"
				name="text"
				component={TextAreaField}
				style={{ height: 400 }}
			/>

			<div className="mt-3">
				<Field
					name="include_titles_in_body"
					id="include_titles_in_the_body"
					label="Include titles in the body"
					type="checkbox"
					component="input"
					className="form-checkbox mr-1"
				/>
				<label htmlFor="include_titles_in_the_body">
					Include titles in the body
				</label>
			</div>

			<div className="flex items-center mt-5">
				<Field
					name="fixed_nlp_score"
					id="fixed_nlp_score"
					label="Fixed nlp score"
					type="checkbox"
					component="input"
					className="form-checkbox mr-1"
					onChange={(e) => setFixedNlpScore(e.target.checked)}
				/>
    			<label htmlFor="fixed_nlp_score">
					Fixed nlp score
				</label>
			</div>

			<div className="mt-3">
				<Field
					name="synthesize"
					id="synthesize"
					label="Synthesize"
					type="checkbox"
					component="input"
					className="form-checkbox mr-1"
				/>
				<label htmlFor="synthesize">
					Synthesize
				</label>
			</div>

			{fixedNlpScore && (
					<div className="flex items-center mt-5 ml-5">
						{Object.keys(defaultNlpTypes).map((type) => (
							<div>
								<div className="mr-5" key={type}>
									<label
										htmlFor={`tts_nlp_type.${type}`}
										className="block text-md font-medium text-gray-900">
											{type === "love" ? "excitement" : type}
									</label>
									<Field
										key={type}
										name={`tts_nlp_type.${type}`}
										type="number"
										min={0}
										max={1}
										step={0.01}
										component={TextField}
										size="small"
									/>
								</div>
								<div className="flex mt-3">
									<div className="relative h-28">
										<Field
											name={`tts_nlp_type.${type}`}
											min={0}
											max={1}
											step={0.01}
											className="absolute w-24 transform -rotate-90 left-0 top-1/3 mt-8 -ml-4"
											component={SliderField}
										/>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

			<Button
				loading={loading}
				className="mt-4"
				size="large"
				block
				type="success"
			>
				Create Chapters
			</Button>
		</form>
	);
};

export default reduxForm({
	form: "portal-chapter-generator",
})(Form);
