import React, { Component } from "react";
import { Modal } from "antd";
import VisualEditor from "./VisualEditor";

class EpubEditor extends Component {
	componentDidMount() {
		this.props.reset();
	}

	componentDidUpdate(oldProps) {
		if (this.props.data && !oldProps.data) {
			this.props.onComplete(this.props.data.text);
		}
	}

	render() {
		const { loading, loadText, manual } = this.props;

		return (
			<Modal
				title="Edit E-Pub"
				visible={true}
				onOk={() =>
					loadText(
						manual,
						this.visualEditorEl.state.removedTags.join("|"),
						this.visualEditorEl.state.removedClasses.join("|")
					)
				}
				confirmLoading={loading}
				okText="Next"
				onCancel={() => alert("cancel")}
				width="100%"
				style={{ top: 0, bottom: 0, paddingBottom: 0 }}
			>
				<VisualEditor
					manual={manual}
					ref={el => (this.visualEditorEl = el)}
				/>
			</Modal>
		);
	}
}

export default EpubEditor;
