import React from "react";
import MissingWordRowContainer from "../containers/MissingWordRowContainer";
import {
	Table,
	TableHead,
	TableHeadCell,
	TableBody,
	TableRow,
} from "../../../../../components/Table";
import columns from "./columns";

function WordResults({
	sessionId,
	data,
	onEdit,
	editText,
	selectedColumns,
	portalProject,
	onPlayAudio,
	playingAudioId,
	language,
}) {
	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableHeadCell>Token</TableHeadCell>
					{portalProject && (
						<TableHeadCell>Customer Input</TableHeadCell>
					)}
					{selectedColumns.map((col) =>
						Object.keys(columns).indexOf(col) === -1 ? null : (
							<TableHeadCell key={col}>
								{columns[col]}
							</TableHeadCell>
						)
					)}
					<TableHeadCell></TableHeadCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((id) => (
					<MissingWordRowContainer
						key={id}
						language={language}
						selectedColumns={selectedColumns}
						sessionId={sessionId}
						editText={editText}
						onEdit={onEdit}
						id={id}
						portalProject={portalProject}
						onPlayAudio={onPlayAudio}
						playingAudioId={playingAudioId}
					/>
				))}
			</TableBody>
		</Table>
	);
}

export default WordResults;
