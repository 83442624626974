import { connect } from "react-redux";
import { 
	wordByIdSelector, 
	sessionByIdSelector, 
	getPunctuationLessString 
} from "../../modules/sessions";
import MissingWordRow from "../components/MissingWordRow";

const mapStateToProps = (state, { id, portalProject, sessionId }) => {
	const word = wordByIdSelector(state, id);
	const portalProjectLexiconEntries =
		portalProject &&
		portalProject.lexicon_entries.filter(
			(entry) => entry.token === word.token
		);
	
	const session = sessionByIdSelector(state, sessionId);	
	
	const wordFreq = session.wordsFreq[
		getPunctuationLessString(word.token_display).toLocaleLowerCase()
	];
	const wordCount = wordFreq ? wordFreq : 0; 
	
	return {
		word,
		wordCount,
		portalProjectLexiconEntry:
			portalProjectLexiconEntries && portalProjectLexiconEntries[0],
	};
};

export default connect(mapStateToProps)(MissingWordRow);
