import React from "react";
import { PropTypes } from "prop-types";
import { secondsToHms } from "../services/time";
import { Button } from "antd";

function TimeTracker({ active, start, stop, elapsed, trackerButtonName }) {
	return (
		<div className="time-tracker">
			{active && (
				<Button type="danger" onClick={stop} disabled>
					{secondsToHms(Math.floor(elapsed))}
				</Button>
			)}

			{!active && (
				<Button type="primary" onClick={start} icon="clock-circle">
					{trackerButtonName}
				</Button>
			)}
		</div>
	);
}

TimeTracker.propTypes = {
	active: PropTypes.bool.isRequired,
	start: PropTypes.func.isRequired,
	stop: PropTypes.func.isRequired,
	elapsed: PropTypes.number,
	trackerButtonName: PropTypes.string.isRequired,
};

export default TimeTracker;
