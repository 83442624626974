import axios from "axios";
import { message } from "antd";

export const RESET = "EPUB_EDITOR_RESET";
export const LOAD_TEXT = "EPUB_EDITOR_LOAD_TEXT";
export const LOAD_TEXT_FAIL = "EPUB_EDITOR_LOAD_TEXT_FAIL";
export const LOAD_TEXT_SUCCESS = "EPUB_EDITOR_LOAD_TEXT_SUCCESS";

export const reset = () => ({ type: RESET });

const loadTextFail = e => ({ type: LOAD_TEXT_FAIL, payload: e });
const loadTextSuccess = text => ({ type: LOAD_TEXT_SUCCESS, payload: text });

export const loadText = (text, rm_tag, rm_class) => dispatch => {
	axios
		.post("/clusters/parse/epub/text/", { text, rm_tag, rm_class })
		.then(res => dispatch(loadTextSuccess(res.data)))
		.catch(e => {
			message.error("E-pub parsing failed.");
			dispatch(loadTextFail(e));
		});
};

const initialState = {
	loading: false,
	error: null,
	data: null
};

export default function epubEditor(state = initialState, action) {
	switch (action.type) {
		case RESET:
			return initialState;
		case LOAD_TEXT:
			return { ...initialState, loading: true };
		case LOAD_TEXT_SUCCESS:
			return { ...state, loading: false, data: action.payload };
		case LOAD_TEXT_FAIL:
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
}
