import React from "react";
import classNames from "classnames";
import WordTag from "./WordTag";

export function Word({ word, isDirty, isActive, onClick }) {
	return (
		<>
			<a
				onClick={onClick}
				onDoubleClick={(event) => event.preventDefault()}
				className={classNames(
					"hover:text-gray-900",
					!isDirty && isActive && "bg-gray-300",
					isDirty && !isActive && "bg-red-200",
					isDirty && isActive && "bg-red-300"
				)}
			>
				{word.prepunc}
				<WordTag word={word} />
				{word.punc}
			</a>{" "}
		</>
	);
}

export default Word;
