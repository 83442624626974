import React from "react";
import classNames from "classnames";

const CheckboxField = ({ label, input, meta, className, ...otherProps }) => (
	<div className="relative focus-within:z-10 flex-grow">
		<label className="text-gray-500 font-medium mb-0.5 block text-sm">
			<input
				type="checkbox"
				className={classNames(
					"form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2 -mt-1",
					meta && meta.touched && meta.error
						? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
						: "focus:shadow-outline-blue",
					input.disabled && "cursor-not-allowed bg-gray-100",
					className
				)}
				{...input}
				{...otherProps}
			/>
			{label}
		</label>
	</div>
);

export default CheckboxField;
