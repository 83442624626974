import axios from "axios";

class Book {
	constructor(epub) {
		this.epub = epub;

		this.chapters = epub.navigation.toc;
		this.chapterBeginningHrefs = {};
		this.chapters.forEach(
			(c, i) =>
				(this.chapterBeginningHrefs[
					c.href.replace(/\.\.\//g, "").split("#")[0]
				] = i)
		);

		const hrefById = Object.assign(
			{},
			...Object.entries(epub.spine.spineByHref).map(([a, b]) => ({
				[b]: a,
			}))
		);

		let currentChapter = 0;
		this.pages = epub.spine.items.map((p) => {
			const href = hrefById[p.index] && hrefById[p.index].split("#")[0];
			if (this.chapterBeginningHrefs.hasOwnProperty(href)) {
				currentChapter = this.chapterBeginningHrefs[href];
			}

			return {
				href,
				label: `Page ${p.index + 1}`,
				chapter: currentChapter,
				url: p.url,
			};
		});

		this.landmarks = epub.navigation.landmarks.filter(
			(l) => l.type !== "bodymatter"
		);
	}

	async getContents(removedChapters, removedXhtmls) {
		return await Promise.all(
			this.chapters
				.map((c, i) => ({ ...c, i }))
				.filter((c) => removedChapters.indexOf(c.i) === -1)
				.map(async (chapter) => {
					return {
						title: chapter.label,
						contents: await Promise.all(
							this.pages
								.filter((p) => {
									return (
										removedXhtmls.indexOf(p.href) === -1 &&
										p.chapter !== null &&
										p.chapter === chapter.i
									);
								})
								.map(
									async (p) =>
										(await this.epub.load(p.url))
											.documentElement.outerHTML
								)
						),
					};
				})
		);
	}

	getNavItems(type, removedChapters, removedXhtmls) {
		if (type === "chapter") {
			return this.chapters;
		}

		return this.pages.filter(
			(p) =>
				removedChapters.indexOf(p.chapter) === -1 &&
				removedXhtmls.indexOf(p.href) === -1
		);
	}
}

export default Book;
