import React from "react";
import { Spin, Icon, Tooltip } from "antd";
import classnames from "classnames";
import WordTag from "./WordLevel/WordTag";

const spinIcon = <Icon type="loading" style={{ fontSize: 15 }} spin />;

export function Sentence({
	sentence,
	selectSentence,
	isActive,
	multipleSelected,
	highlightIssueSentence
}) {
	// This is mendatory to optimize UI
	// This component is our potential bottleneck
	// console.log("Render sentence:", sentence.id);
	
	const bgColorMap = {
		default: "bg-gray-200",
		blue: "bg-blue-200",
		red: "bg-red-200",
		orange: "bg-orange-200",
		green: "bg-green-200",
		indigo: "bg-indigo-200",
		yellow: "bg-yellow-200",
		purple: "bg-purple-200",
	};

	return (
		<Tooltip title={sentence.sentence_id}>
			{multipleSelected && isActive && (
				<input
					checked
					onChange={() => selectSentence(sentence.id, true)}
					type="checkbox"
				/>
			)}
			<a
				id={`sentence-${sentence.sentence_id}`}
				className={classnames(
					"mr-1 transition-none",
					isActive
						? "bg-blue-500 text-white"
						: [
								"text-gray-900",
								sentence.active_version &&
									!sentence.active_version.original &&
									"bg-red-200",
								sentence.color &&
									bgColorMap[sentence.color || "default"],
						  ],
					highlightIssueSentence?.sentence_id === sentence.sentence_id 
						&& "border-dashed border-2 border-blue-600"	  
				)}
				onClick={(event) => selectSentence(sentence.id, event.altKey)}
			>
				{sentence.has_active_task && (
					<Spin
						className="mr-1 text-orange-500"
						indicator={spinIcon}
					/>
				)}

				{sentence.active_version.words.map((word, i) => (
					<React.Fragment key={i}>
						{word.prepunc}
						<WordTag word={word} />
						{word.punc}
						&nbsp;
					</React.Fragment>
				))}
			</a>
		</Tooltip>
	);
}

export default Sentence;
