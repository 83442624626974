import React, { useState } from "react";
import Card, { CardBody } from "../../../../components/Card";
import Descriptions, {
	DescriptionsItem,
} from "../../../../components/Descriptions";
import { statuses } from "../../modules/portal";
import { Link } from "react-router";
import * as moment from "moment";
import Modal from "../../../../components/Modal";
import Badge from "../../../../components/Badge";
import Button from "../../../../components/Button";
import Download from "../../../../components/Icons/Download";
import { exportDistributionCsv } from "../../modules/portalDistribution";
import classNames from "classnames";

const Step = ({ label, complete, active, description }) => (
	<li>
		<div className="flex items-start space-x-3">
			<div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center">
				{complete ? (
					<svg
						className="h-full w-full text-indigo-600"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
							clipRule="evenodd"
						/>
					</svg>
				) : active ? (
					<>
						<span className="absolute h-4 w-4 rounded-full bg-indigo-200" />
						<span className="relative block w-2 h-2 bg-indigo-600 rounded-full" />
					</>
				) : (
					<span className="block h-2 w-2 bg-gray-300 rounded-full" />
				)}
			</div>
			<div className="text-sm leading-5 font-medium text-gray-500">
				{label}
				{active && description && (
					<div className="text-xs">{description}</div>
				)}
			</div>
		</div>
	</li>
);

const PortalProjectSummary = ({ data }) => {
	const [showCustomerSampleReview, setShowCustomerSampleReview] =
		useState(false);

	const steps =
		data.edit_mode === "managed"
			? Object.keys(statuses)
			: [
					"initial_linguistics_review",
					"customer_linguistics_input",
					"editing_book",
					"finalizing_book_post",
					"finalizing_book_post_review",
					"book_delivered",
			  ];

	const activeStepIndex = steps.indexOf(data.status);
	const descriptions = {
		initial_linguistics_review: (
			<span>
				A lexicon session has been automatically generated. DeepZen is
				expected to review, add words to lexicon and leave the rest to
				customer.
				<br />
				<Link
					to={`/lexicon/sessions/${data.lexicon_session?.id}`}
					className="text-blue-600 hover:underline"
				>
					View lexicon session &rarr;
				</Link>
			</span>
		),
		customer_linguistics_input:
			"Now we are waiting for customer to send pronunciation details for missing words.",
		editing_sample: (
			<span>
				Customer has provided pronunciation information of the words.
				DeepZen is expected to complete linguistics work and get back to
				customer with a sample.
				<br />
				<Link
					to={`/lexicon/sessions/${data.lexicon_session?.id}`}
					className="text-blue-600 hover:underline"
				>
					View lexicon session &rarr;
				</Link>
				<Link
					to={`/clusters/${data.book.id}/portal/delivery/`}
					className="ml-3 text-blue-600 hover:underline"
				>
					Deliver sample &rarr;
				</Link>
			</span>
		),
		customer_sample_review:
			"Customer has received the sample. Book generation will begin after feedbacks about sample is provided.",
		editing_book: (
			<span>
				DeepZen is expected to work on the book and deliver the draft.
				<br />
				{/* TODO: Conditional sample review
				<a
					onClick={() => setShowCustomerSampleReview(true)}
					className="text-blue-600 hover:underline"
				>
					View sample review &rarr;
				</a>
				*/}
				<Link
					to={`/lexicon/sessions/${data.lexicon_session?.id}`}
					className="text-blue-600 hover:underline"
				>
					View lexicon session &rarr;
				</Link>
				<Link
					to={`/clusters/${data.book.id}/portal/chapter-generator/`}
					className="block text-blue-600 hover:underline"
				>
					Generate all chapters &rarr;
				</Link>
				<Link
					to={`/clusters/${data.book.id}/portal/delivery/`}
					className="block text-blue-600 hover:underline"
				>
					Send for post-processing &rarr;
				</Link>
			</span>
		),
		customer_proofing:
			"Customer has received the first version of the book. Final touches will start once customer sends their findings.",
		making_corrections: (
			<span>
				Customer has reviewed the first version and now DeepZen is
				expected to work on the fixes for a final review round.
				<br />
				<Link
					to={`/clusters/${data.book.id}/portal/issues/`}
					className="block text-blue-600 hover:underline"
				>
					View issues &rarr;
				</Link>
				<Link
					to={`/clusters/${data.book.id}/portal/delivery/`}
					className="block text-blue-600 hover:underline"
				>
					Send for post-processing &rarr;
				</Link>
			</span>
		),
		customer_proofing_review:
			"Customer has received the corrected version of the book. Customer is expected to leave final notes before finishing the book.",
		finalizing_book: (
			<span>
				Customer has left final notes and now DeepZen is expected to
				finalize the book.
				<br />
				<Link
					to={`/clusters/${data.book.id}/portal/issues/`}
					className="block text-blue-600 hover:underline"
				>
					View issues &rarr;
				</Link>
				<Link
					to={`/clusters/${data.book.id}/portal/delivery/`}
					className="block text-blue-600 hover:underline"
				>
					Send for post-processing &rarr;
				</Link>
			</span>
		),
		book_delivered:
			data.edit_mode === "managed"
				? "Well done! Customer approval is awaiting."
				: "Well done!",
	};

	return (
		<Card title="Portal Project Details" className="mb-5 bg-blue-100">
			{data.needs_payment && (
				<div className="bg-white p-4 font-medium">
					This project hasn't been paid yet. No further action can be
					taken at this stage.
				</div>
			)}

			<div className="bg-white">
				<div
					className={classNames("", data.needs_payment && "pointer-events-none opacity-50")}
				>
					{showCustomerSampleReview && (
						<Modal
							onClose={() => setShowCustomerSampleReview(false)}
						>
							<Card title="Customer Sample Review">
								<CardBody>
									{data.customer_sample_review}
								</CardBody>
							</Card>
						</Modal>
					)}

					<Descriptions>
						<DescriptionsItem label="Customer">
							{data.user.email}
						</DescriptionsItem>
						<DescriptionsItem label="Edit Mode">
							<Badge color="blue">{data.edit_mode}</Badge>
							{data.edit_mode === "automated" &&
								data.distribution && (
									<Badge color="orange">distribution</Badge>
								)}
						</DescriptionsItem>
						{data.edit_mode === "automated" && data.distribution && (
							<DescriptionsItem label="Distribution Data">
								{data.project_distribution ? (
									<Button
										onClick={() =>
											exportDistributionCsv(
												data.project_distribution
											)
										}
										icon={Download}
										size="small"
									>
										Download
									</Button>
								) : (
									<em>Not submitted yet</em>
								)}
							</DescriptionsItem>
						)}
						<DescriptionsItem label="Creation">
							{moment(data.created_at).format("YYYY-MM-DD H:m:s")}
						</DescriptionsItem>
						<DescriptionsItem label="Voice">
							{data.voice?.title}
						</DescriptionsItem>
					</Descriptions>

					<nav className="flex p-4">
						<ul className="space-y-3">
							{steps.map((step, i) => (
								<Step
									key={step}
									complete={activeStepIndex > i}
									active={activeStepIndex === i}
									label={statuses[step]}
									description={descriptions[step]}
								/>
							))}
						</ul>
					</nav>
				</div>
			</div>
		</Card>
	);
};

export default PortalProjectSummary;
