import React from "react";
import axios from "axios";
import { message, Modal } from "antd";
import { createSelector } from "reselect";
import { BOOK_EDITOR_LOAD_SUCCESS } from "./editor";

export const queueCountSelector = createSelector(
	(state) => state.queue.jobs,
	(jobs) => jobs.filter((job) => job).length
);

export const QUEUE_ADDED = "QUEUE_ADDED";
export const QUEUE_UPDATED = "QUEUE_UPDATED";
export const QUEUE_MARK_ALL_AS_READ = "QUEUE_MARK_ALL_AS_READ";

export const addedJob = (job) => ({ type: QUEUE_ADDED, payload: job });
export const updatedJob = (job) => ({ type: QUEUE_UPDATED, payload: job });

export function markAllAsRead(bookId) {
	return (dispatch) => {
		axios
			.post(`books/${bookId}/mark_jobs_as_read/`)
			.then((res) => dispatch({ type: QUEUE_MARK_ALL_AS_READ }))
			.catch((e) =>
				message.error("An error occured while marking jobs as read.")
			);
	};
}

export function showErrorMessage(bookId, jobId) {
	return (dispatch) => {
		axios
			.get(`books/${bookId}/jobs/${jobId}`)
			.then((res) =>
				Modal.error({
					title: `Job #${jobId}`,
					content: (
						<div className="max-h-48 overflow-y-auto break-all">
							{res.data.error}
						</div>
					),
					width: 700,
				})
			)
			.catch((e) => message.error("Could not load error message."));
	};
}

const initialState = {
	jobs: [],
};

export default function nlpReducer(state = initialState, action) {
	switch (action.type) {
		case BOOK_EDITOR_LOAD_SUCCESS:
		    return { ...initialState, jobs: action.payload.jobs };
		case QUEUE_ADDED:
			return { ...state, jobs: [action.payload, ...state.jobs] };
		case QUEUE_UPDATED:
			return {
				...state,
				jobs: state.jobs.map((job) =>
					job.id === action.payload.id ? action.payload : job
				),
			};
		case QUEUE_MARK_ALL_AS_READ:
			return {
				...state,
				jobs: state.jobs.map((job) => ({ ...job, read: true })),
			};
		default:
			return state;
	}
}
