import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Card, { CardBody } from "../../../../components/Card";
import { loadPortalProject } from "../../modules/detail";
import PortalProjectSummary from "../Overview/PortalProjectSummary";
import SampleForm from "./SampleForm";
import BookForm from "./BookForm";
import PostProcessingForm from "./PostProcessingForm";

const PortalDeliveryZone = () => {
	const cluster = useSelector((state) => state.clusters.detail.data);
	const project = useSelector((state) => state.clusters.detail.portalProject);
	const dispatch = useDispatch();

	if (!cluster || !project) return null;

	return (
		<div className="container">
			<div className="flex -mx-2.5">
				<div className="w-1/3 px-2.5">
					<PortalProjectSummary data={project} />
				</div>
				<div className="w-2/3 px-2.5">
					{project.status === "book_delivered" && (
						<Card title="Book Delivered">
							<CardBody>
								Book has been delivered succesfully. Nothing can
								be delivered at this step.
							</CardBody>
						</Card>
					)}

					{project.status === "customer_sample_review" && (
						<Card title="Customer Sample Review">
							<CardBody>
								Sample has been sent succesfully. Nothing can be
								delivered at this step.
							</CardBody>
						</Card>
					)}

					{project.status === "editing_sample" && (
						<Card title="Deliver Sample">
							<CardBody>
								<SampleForm
									project={project}
									onSuccess={() =>
										dispatch(loadPortalProject(cluster.id))
									}
								/>
							</CardBody>
						</Card>
					)}

					{(project.status === "customer_proofing" ||
						project.status === "customer_proofing_review") && (
						<Card title="Customer Proofing">
							<CardBody>
								Draft has been sent succesfully. Nothing can be
								delivered until customer sends their feedbacks.
							</CardBody>
						</Card>
					)}

					{(project.status === "editing_book" ||
						project.status === "making_corrections" ||
						project.status === "finalizing_book") && (
						<PostProcessingForm
							project={project}
							onSuccess={() => window.location.reload()}
							cluster={cluster}
						/>
					)}

					{(project.status === "editing_book_post" ||
						project.status === "making_corrections_post" ||
						project.status === "finalizing_book_post") && (
						<Card title="Post Processing">
							<CardBody>
								Post processor is expected to upload the ZIP
								file containing processed audio files now.
							</CardBody>
						</Card>
					)}

					{project.status.endsWith("post_review") && (
						<Card title="Review and Deliver">
							<div className="bg-blue-100 text-blue-700 py-3 px-5">
								Review texts and audio files to deliver.
							</div>

							<CardBody>
								<BookForm
									cluster={cluster}
									project={project}
									onSuccess={() =>
										dispatch(loadPortalProject(cluster.id))
									}
								/>
							</CardBody>
						</Card>
					)}
				</div>
			</div>
		</div>
	);
};

export default PortalDeliveryZone;
