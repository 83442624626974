import React, { useState, useEffect } from "react";
import SearchIcon from "./Icons/Search";
import { useDebounce } from 'use-debounce';
import classNames from "classnames";
import Spinner from "./Spinner";


const SearchBox = ({ placeholder, clear, className, onChange, loading}) => {
    const [text, setText] = useState("");
    const [value] = useDebounce(text, 750);
    const [searchActive, setSearchActive] = useState(false);

    useEffect(() => {
        if(searchActive){
            onChange(value);        
        }
    }, [value]);

    useEffect(() => {
        if(clear){
            setText("");
        }
    }, [clear]);

    return (
        <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                {loading ? (
                    <Spinner className="w-4 h-4" />
                ): (
                    <SearchIcon size={5} />
                )}
                
            </div>
            <input 
                type="text"
                value={text} 
                className={classNames(
                    "bg-white border border-gray-200 text-gray-900 text-sm block w-full pl-10 p-2.5 focus:outline-none focus:border-gray-300" ,
                    className
                )}
                placeholder={placeholder || "Search"} 
                onChange={(e) => {
                    setSearchActive(true);
                    setText(e.target.value);
                }}
            />
        </div>
    );
};  

export default SearchBox;