import axios from "axios";
import { message } from "antd";

export const SYNTHESIZE = "SYNTHESIZE";
export const SYNTHESIZE_SUCCESS = "SYNTHESIZE_SUCCESS";
export const SYNTHESIZE_FAIL = "SYNTHESIZE_FAIL";
export const SYNTHESIZE_TOGGLE = "SYNTHESIZE_TOGGLE";

const synthesizeSuccess = (data) => ({
	type: SYNTHESIZE_SUCCESS,
	payload: data,
});
const synthesizeFail = (e) => ({ type: SYNTHESIZE_FAIL, payload: e });

export function synthesize(bookId, values) {
	return (dispatch) => {
		dispatch({ type: SYNTHESIZE, payload: bookId });

		axios
			.patch(`books/${bookId}/synthesize/`, values)
			.then((res) => dispatch(synthesizeSuccess(res.data)))
			.catch((e) => {
				message.error(
					"An error occured while starting the synthesis the book."
				);
				dispatch(synthesizeFail(e));
			});
	};
}

export const toggle = () => ({ type: SYNTHESIZE_TOGGLE });

const initialState = {
	open: false,
	loading: false,
};

export default function synthesizeReducer(state = initialState, action) {
	switch (action.type) {
		case SYNTHESIZE:
			return { ...state, loading: true };
		case SYNTHESIZE_SUCCESS:
			return { ...state, show: false, loading: false };
		case SYNTHESIZE_FAIL:
			return { ...state, loading: false };
		case SYNTHESIZE_TOGGLE:
			return { ...state, show: !state.show };
		default:
			return state;
	}
}
