import axios from "axios";
import { message } from "antd";
import { browserHistory } from "react-router";

export const CREATE = "LEXICON_SESSION_CREATE";
export const CREATE_SUCCESS = "LEXICON_SESSION_CREATE_SUCCESS";
export const CREATE_FAIL = "LEXICON_SESSION_CREATE_FAIL";

const createSuccess = session => ({ type: CREATE_SUCCESS, payload: session });
const createFail = e => ({ type: CREATE_FAIL, payload: e });

export function create(values) {
	return dispatch => {
		dispatch({ type: CREATE });

		axios
			.post("/lexicon/sessions/", values)
			.then(res => {
				message.success("Session created successfully.");
				browserHistory.push(`/lexicon/sessions/${res.data.id}`);
				dispatch(createSuccess(res.data));
			})
			.catch(e => dispatch(createFail(e)));
	};
}

const initialState = {
	lexicons: null,
	loading: false,
	error: null
};

export default function createReducer(state = initialState, action) {
	switch (action.type) {
		case CREATE:
			return { ...initialState, loading: true };
		case CREATE_SUCCESS:
		case CREATE_FAIL:
			return { ...state, loading: false };
		default:
			return state;
	}
}
