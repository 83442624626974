import React, { useState, useCallback } from "react";
import { Link, browserHistory } from "react-router";
import Card from "../../../../components/Card";
import Badge from "../../../../components/Badge";
import Button from "../../../../components/Button";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableHeadCell,
	TableCell,
} from "../../../../components/Table";
import RightArrow from "../../../../components/Icons/RightArrow";
import { useSelector } from "react-redux";
import {
	Descriptions,
	DescriptionsItem,
} from "../../../../components/Descriptions";
import { secondsToHms } from "../../../../services/time";
import PlusIcon from "../../../../components/Icons/Plus";
import Actions from "./Actions/index";
import PortalProjectSummary from "./PortalProjectSummary";
import BundleSummary from "./BundleSummary";
import { hasUserFullEditingAccess } from "../../../Auth/modules/auth";
import ClusterStatusUpdater from "../../../../components/ClusterStatusUpdater";
import update from "immutability-helper";
import DndBox from "../../../../components/DndBox";
import DragOutlined from "../../../../components/Icons/DragOutlined";
import classNames from "classnames";
import axios from "axios";
import { message } from "antd";

function Overview() {
	const { data: cluster, stats, portalProject } = useSelector(
		(state) => state.clusters.detail
	);
	const user  = useSelector((state) => state.auth.user);
	const [books, setBooks] = useState(cluster ? cluster.books : []);
	const [savedBooks, setSavedBooks] = useState(cluster ? cluster.books : []);
	const [chapterOrderActive, setChapterOrderActive] = useState(false);
	const [loading, setLoading] = useState(false);

	const moveCard = useCallback(
      (dragIndex, hoverIndex) => {
        const dragCard = books[dragIndex];
        setBooks(
          update(books, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard]
            ]
          })
        );
      },
      [books]
    );

	const saveChapterOrder = () => {
		setLoading(true);
		const orderedChapters = books.map((book, i) => ({ id: book.id, order: i+1 }));
		axios
			.put(`books/update_order/`, orderedChapters)
			.then((res) => {
				setLoading(false);
				setChapterOrderActive(false);
				setSavedBooks([...books]);
				message.success("Chapters saved successfully");
			})
			.catch((e) => {
				setLoading(false);
				message.error("An error occurred while saving chapters");
			});
	};

	const cancelChapterOrder = () => {
		setChapterOrderActive(false);
		setBooks([...savedBooks]);
	};

	const tableCellWrapper = (book) => (
		<>
			<TableCell>
				{book.done && (
					<Badge color={book.done_2nd_pass ? "darkGreen": "green"}>
						{book.done_2nd_pass
							? "2nd pass"
							: "1st pass"}
					</Badge>
				)}
				{!book.done &&
					book.tts_session_id && (
						<Badge color="orange">
							Generated
						</Badge>
				)}
			</TableCell>
			<TableCell className="font-medium pt-3">
				<span className="inline-block max-w-md truncate">
					{book.title}
				</span>
			</TableCell>
			<TableCell className="text-right">
				{chapterOrderActive && (
					<DragOutlined
						size={5}
						className="text-gray-500 inline"
					/>
				)}
				{!chapterOrderActive && (
					<RightArrow
						size={7}
						className="text-gray-500"
					/>
				)}
			</TableCell>
		</>
	)

	return (
		<div className="container">
			<div className="flex -mx-2.5">
				<div className="w-1/3 px-2.5">
					{portalProject && (
						<PortalProjectSummary data={portalProject} />
					)}

					<Card title="Basics">
						<Descriptions>
							<DescriptionsItem label="Status">
								<ClusterStatusUpdater cluster={cluster} />
							</DescriptionsItem>
							<DescriptionsItem label="Lexicon">
								<Badge>
									{cluster.lexicon_main
										? cluster.lexicon_main.id
										: "default"}
								</Badge>
							</DescriptionsItem>
							<DescriptionsItem label="Addendum">
								{cluster.lexicon_addendum &&
									cluster.lexicon_addendum.dependencies
										.length === 0 && (
										<Badge>
											{cluster.lexicon_addendum.id}
										</Badge>
									)}
								{cluster.lexicon_addendum &&
									cluster.lexicon_addendum.dependencies
										.length > 0 &&
									cluster.lexicon_addendum.dependencies.map(
										(lexicon) => (
											<Badge key={lexicon.id}>
												{lexicon.id}
											</Badge>
										)
									)}
								{!cluster.lexicon_addendum && (
									<Badge>default</Badge>
								)}
							</DescriptionsItem>
						</Descriptions>
					</Card>

					{stats && (
						<Card title="Stats" className="mt-5">
							<Descriptions>
								<DescriptionsItem
									label={stats.metrics[0].label}
								>
									<Badge color="blue">{`${stats.metrics[0].value.toFixed(
										1
									)}%`}</Badge>
								</DescriptionsItem>
								<DescriptionsItem
									label={stats.metrics[1].label}
								>
									<Badge color="blue">
										{stats.metrics[1].value.toFixed(1)}
									</Badge>
								</DescriptionsItem>
								<DescriptionsItem
									label={stats.metrics[2].label}
								>
									<Badge color="blue">
										{stats.metrics[2].value.toFixed(1)}%
									</Badge>
								</DescriptionsItem>
								<DescriptionsItem
									label={stats.metrics[3].label}
								>
									<Badge color="blue">
										{stats.metrics[3].value.toFixed(1)}%
									</Badge>
								</DescriptionsItem>
								<DescriptionsItem
									label={stats.metrics[4].label}
								>
									<Badge color="blue">{`1:${stats.metrics[4].value.toFixed(
										1
									)}`}</Badge>
								</DescriptionsItem>
								<DescriptionsItem
									label={stats.metrics[5].label}
								>
									<Badge color="blue">
										{secondsToHms(stats.metrics[5].value)}
									</Badge>
								</DescriptionsItem>
							</Descriptions>
						</Card>
					)}

					{hasUserFullEditingAccess(user) && (
						<Actions cluster={cluster} portalProject={portalProject} />
					)}

				</div>

				<div className="w-2/3 px-2.5">
					{cluster.bundle && (
						<BundleSummary bundle={cluster.bundle} />
					)}

					<Card
						title="Chapters"
						headerActions={
							hasUserFullEditingAccess(user) && (
								<>
								<Link
									to={`/clusters/${cluster.id}/chapters/create`}
								>
									<Button size="small" icon={PlusIcon}>
										Add chapter
									</Button>
								</Link>

								<Button
									className={classNames("ml-2", chapterOrderActive && "bg-gray-300")}
									size="small"
									icon={DragOutlined}
									onClick={(e) => setChapterOrderActive(true)}
								>
									Reorder chapters
								</Button>
								</>
							)
						}
					>
						<div className="overflow-x-auto">
							<Table>
								<TableHead>
									<TableRow>
										<TableHeadCell width="20" />
										<TableHeadCell width="40%">
											Title
										</TableHeadCell>
										<TableHeadCell></TableHeadCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{chapterOrderActive && (
										books.map((book, i) => (
											<DndBox
												key={book.id}
												index={i}
												id={book.id}
												type="tableRow"
												className="cursor-pointer"
												moveCard={moveCard}
											>
												{tableCellWrapper(book)}
											</DndBox>
										))
									)}

									{!chapterOrderActive && (
										books.map((book, i) => (
											<TableRow
												key={book.id}
												className="cursor-pointer"
												onClick={() =>
													browserHistory.push(
														`/books/${book.id}`
													)
												}
											>
												{tableCellWrapper(book)}
											</TableRow>
										))
									)}

								</TableBody>

							</Table>
						</div>
						{chapterOrderActive && (
							<div className="flex justify-end py-5 px-5 border-t border-gray-200">
								<Button
									className="mr-3"
									onClick={cancelChapterOrder}
								>
									Cancel
								</Button>
								<Button
									type="primary"
									className="bg-blue-300 text-white"
									onClick={saveChapterOrder}
								>
									{loading ? "Loading..": "Save"}
								</Button>
							</div>
						)}
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Overview;
