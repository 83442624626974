import React from "react";
import Card, {CardBody} from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import {API_URL} from "../../../../../config";
import {AUTH_ACCESS_TOKEN_KEY} from "../../../../Auth/modules/auth";

const CorrectionReportForm = ({id, onClose}) => (
	<Card title="Corrections">
		<CardBody>
			Download all correction logs for this book. Only super users can do this.
			<a
				href={`${API_URL}/portal/projects/${id}/export_issues/?token=${localStorage.getItem(
					AUTH_ACCESS_TOKEN_KEY
				)}`}
				onClick={onClose}
				target="_blank"
			>
				<Button className="mt-4" block type="primary">
					Export Project Correction Report
				</Button>
			</a>
		</CardBody>
	</Card>
);

export default CorrectionReportForm;


