import axios from "axios";
import { createSelector } from "reselect";
import { message } from "antd";
import { BOOK_EDITOR_SELECT_SENTENCE } from "../routes/Editor/modules/editor";
import { SENTENCE_DETAILS_LOAD_SUCCESS } from "../routes/Editor/modules/sentenceDetails";
import { QUEUE_ADDED } from "../routes/Editor/modules/queue";

export const TIME_TRACKER_EDITOR_CONSTRUCT = "TIME_TRACKER_EDITOR_CONSTRUCT";
export const TIME_TRACKER_EDITOR_CONSTRUCT_SUCCESS = "TIME_TRACKER_EDITOR_CONSTRUCT_SUCCESS";
export const TIME_TRACKER_EDITOR_DESTRUCT = "TIME_TRACKER_EDITOR_DESTRUCT";
export const TIME_TRACKER_EDITOR_PROGRESS = "TIME_TRACKER_EDITOR_PROGRESS";
export const TIME_TRACKER_EDITOR_START = "TIME_TRACKER_EDITOR_START";
export const TIME_TRACKER_EDITOR_STOP = "TIME_TRACKER_EDITOR_STOP";
export const TIME_TRACKER_EDITOR_START_SUCCESS = "TIME_TRACKER_EDITOR_START_SUCCESS";
export const TIME_TRACKER_EDITOR_START_FAIL = "TIME_TRACKER_EDITOR_START_FAIL";

export const elapsedSelector = createSelector(
	state => state.timeTrackerEditor.activeLog,
	state => state.timeTrackerEditor.ttl,
	(activeLog, ttl) =>
		activeLog
			? (new Date().getTime() -
					new Date(activeLog.created_at).getTime()) /
			  1000
			: 0
);

const constructSuccess = payload => ({
	type: TIME_TRACKER_EDITOR_CONSTRUCT_SUCCESS,
	payload
});

export function construct(clusterId, bookId) {
	return dispatch => {
		dispatch({
			type: TIME_TRACKER_EDITOR_CONSTRUCT,
			payload: { clusterId, bookId }
		});

		axios
			.get(
				`/accounting/work_log/?cluster_id=${clusterId}&book_id=${bookId}`
			)
			.then(res => dispatch(constructSuccess(res.data)))
			.catch(e =>
				message.error("Time tracker could not be initialized.")
			);
	};
}

const progress = ttl => ({
	type: TIME_TRACKER_EDITOR_PROGRESS,
	payload: ttl
});

export const destruct = () => ({ type: TIME_TRACKER_EDITOR_DESTRUCT });

const startSuccess = log => ({
	type: TIME_TRACKER_EDITOR_START_SUCCESS,
	payload: log
});

const startFail = e => ({
	type: TIME_TRACKER_EDITOR_START_FAIL,
	payload: e
});

export function start(clusterId, bookId) {
	return (dispatch, getState) => {
		dispatch({
			type: TIME_TRACKER_EDITOR_START,
			payload: { clusterId, bookId }
		});

		axios
			.post(
				`/accounting/work_log/?cluster_id=${clusterId}&book_id=${bookId}`,
				{}
			)
			.then(res => dispatch(startSuccess(res.data)))
			.catch(e => {
				dispatch(startFail(e));
				message.error("Time tracker failed.");
			});
	};
}

export function tick(clusterId, bookId) {
	return (dispatch, getState) => {
		const { timeTrackerEditor } = getState();
		const { activeLog, activity } = timeTrackerEditor;
		const now = new Date().getTime();
		const ttl = (new Date(activeLog.expires_at).getTime() - now) / 1000;

		if (ttl < 0 && !activity) {
			dispatch(stop(clusterId, bookId));
		} else if (ttl < 170 && activity) {
			dispatch(start(clusterId, bookId));
		} else {
			dispatch(progress(ttl));
		}
	};
}

export function stop(clusterId, bookId) {
	return dispatch => {
		axios
			.delete(
				`/accounting/work_log/?cluster_id=${clusterId}&book_id=${bookId}`
			)
			.then(res => {
				message.info("Stopped");
				dispatch(construct(clusterId, bookId));
			})
			.catch(e =>
				message.info("Time tracker stopped due to inactivity.")
			);

		dispatch({ type: TIME_TRACKER_EDITOR_STOP });
		dispatch(construct(clusterId, bookId));
	};
}

const initialState = {
	activeLog: null,
	activity: false,
	ttl: 0,
	cumulativeElapsed: 0
};

export default function timeTrackerEditorReducer(state = initialState, action) {
	switch (action.type) {
		case TIME_TRACKER_EDITOR_CONSTRUCT:
		case TIME_TRACKER_EDITOR_DESTRUCT:
			return {
				...initialState,
				cumulativeElapsed: state.cumulativeElapsed
			};
		case TIME_TRACKER_EDITOR_CONSTRUCT_SUCCESS:
			return {
				...state,
				activeLog: action.payload.active_log,
				cumulativeElapsed: action.payload.cumulative_elapsed,
				activity: false
			};
		case TIME_TRACKER_EDITOR_START_SUCCESS:
			return {
				...state,
				activeLog: action.payload,
				activity: false
			};
		case TIME_TRACKER_EDITOR_PROGRESS:
			return { ...state, ttl: action.payload };
		case BOOK_EDITOR_SELECT_SENTENCE:
		case SENTENCE_DETAILS_LOAD_SUCCESS:
		case QUEUE_ADDED:
			return { ...state, activity: true };
		default:
			return state;
	}
}
