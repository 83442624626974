import { connect } from "react-redux";
import ResynthesizeForm from "../../components/MultiSentence/ResynthesizeForm";
import { resynthesize } from "../../modules/sentenceDetails";
import { formValueSelector } from "redux-form";

const mapStateToProps = (state, { sentence }) => {
	let initialValues = {
		title: "Batch edit",
		speaker_id: sentence.active_version.speaker_id,
		pause: sentence.active_version.pause,
		model_id: sentence.active_version.model_id,
		nlp: sentence.active_version.nlp,
		gst_factor: sentence.active_version.gst_factor,
		allowedKeys: []
	};

	// NLP
	const json = JSON.parse(initialValues.nlp.replace(/'/g, '"'));
	for (let type in json) {
		initialValues["nlp_" + type] = json[type];
	}

	return {
		initialValues,
		apiError: state.sentenceDetails.resynthesizeError,
		loading: state.sentenceDetails.resynthesizeLoading,
		allowedKeys:
			formValueSelector("resynthesize-multi")(state, "allowedKeys") || [],
		nlpTypes: Object.keys(json).sort()
	};
};

const mapDispatchToProps = { resynthesize };

export default connect(mapStateToProps, mapDispatchToProps)(ResynthesizeForm);
