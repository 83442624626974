import React, { Component } from "react";
import { connect } from "react-redux";
import { load, update } from "../../modules/lexicons";
import List from "../components/List";

class ListContainer extends Component {
	componentDidMount() {
		if (!this.props.lexicons) {
			this.props.load();
		}
	}

	render() {
		return <List {...this.props} />;
	}
}

const mapStateToProps = state => state.lexicon.lexicons;

const mapDispatchToProps = {
	load,
	update
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer);
