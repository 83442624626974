import { connect } from "react-redux";
import { toggle as toggleInsertSentence } from "../modules/insertSentence";
import { deleteSentence } from "../modules/delete";
import SentenceDetailsActions from "../components/SentenceDetailsActions";
import { hasUserFullEditingAccess } from "../../Auth/modules/auth";

const mapStateToProps = (state) => ({
	hasUserFullEditingAccess: hasUserFullEditingAccess(state.auth.user)
});

const mapDispatchToProps = {
	toggleInsertSentence,
	deleteSentence,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SentenceDetailsActions);
