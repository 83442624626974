import React, { useState } from 'react';
import './selectedList.css';
import Button from "../../../components/Button";
import { Icon } from "antd";

const SelectedListComponent = ({ 
    selectedItems, 
    onRemoveItem, 
    onHandleBundle, 
    onHandleSendForPostProcessing 
}) => {
    const [isHovered, setIsHovered] = useState(false);
    
    return (
        <div 
            onMouseEnter={(e) => setIsHovered(true)}
            onMouseLeave={(e) => setIsHovered(false)}
            className={`hovering-div ${isHovered ? 'expanded' : ''} shadow-md rounded-md z-50 mr-5`}
        >
            <div className="header">
                {isHovered ? (
                    <div>
                        <p>Selected Items: {selectedItems.size}</p>
                        <hr/>
                        <div className="scrollable-list">
                            <ul>
                                {Array.from(selectedItems).map((item) => (
                                    <li className="pt-2" key={item[0]}>
                                        {item[1].title}
                                            <Icon
                                                type="close-circle"
                                                onClick={(e) => onRemoveItem(item[0])}
                                                style={{ paddingLeft: 5, cursor: "pointer", color: "red" }}
                                            />
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {selectedItems.size > 0 &&
                            <div className="text-center flex justify-end py-3 border-t-2 border-gray-200"> 
                                <Button  
                                    type="primary"
                                    size="small"
                                    onClick={onHandleSendForPostProcessing}>
                                    Send for Post Process
                                </Button> 
                                <Button  
                                    type="info" 
                                    size="small"
                                    className="ml-2"
                                    onClick={onHandleBundle}>
                                    Bundle
                                </Button>
                            </div>
                        }
                    </div>
                ) : (
                    <p> Selected list: &nbsp; {selectedItems.size}</p>
                )}
            </div>
        </div>
    );
};

export default SelectedListComponent