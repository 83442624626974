import React, { Component } from "react";
import { connect } from "react-redux";
import { load, loadTokens } from "../modules/apiStats";
import ApiStats from "../components/ApiStats";

class ApiStatsContainer extends Component {
	componentDidMount() {
		if (!this.props.tokens) {
			this.props.loadTokens();
		}

		this.props.load(null, this.props.startDate, this.props.endDate);
	}

	render() {
		return <ApiStats {...this.props} />;
	}
}

const mapStateToProps = (state) => state.apiStats;

const mapDispatchToProps = {
	load,
	loadTokens,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiStatsContainer);
