import React from "react";
import { Alert } from "antd";

export default function ApiError({ error }) {
	let message = "Hata";
	let errors = ["Oops, an error occured."];

	if (error.response) {
		switch (error.response.status) {
			case 404:
				message = "Resource not found";
				errors = [];
				break;
			case 400:
				message = "Bad request";
				errors = [];
				for (let key in error.response.data) {
					const keyErrors = error.response.data[key];
					const list = Array.isArray(keyErrors)
						? keyErrors
						: [keyErrors];

					errors = errors.concat(
						list.map((e) => {
							return key === "non_field_errors" ? (
								e
							) : (
								<span>{e}</span>
							);
						})
					);
				}
				break;
		}
	}

	const description = (
		<ul>
			{errors.map((error, i) => (
				<li key={i}>{error}</li>
			))}
		</ul>
	);

	return (
		<div className="api-error">
			<Alert
				message={message}
				description={description}
				type="error"
				showIcon
			/>
		</div>
	);
}
