import React from "react";
import { Field, reduxForm } from "redux-form";
import { TextField } from "redux-form-antd";
import { Button } from "antd";
import { required } from "../../../../services/validations";
import ApiError from "../../../../components/ApiError";

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileField = ({
	input: { value: omitValue, onChange, onBlur },
	meta: omitMeta,
	label,
	...props
}) => {
	return (
		<div className="ant-row ant-form-item">
			<div className="ant-col ant-form-item-label">
				<label>{label}</label>
			</div>
			<div className="ant-col ant-form-item-control-wrapper">
				<div className="ant-form-item-control">
					<span className="ant-form-item-children">
						<input
							onChange={adaptFileEventToValue(onChange)}
							onBlur={adaptFileEventToValue(onBlur)}
							type="file"
							className="ant-input"
							{...props.input}
							{...props}
						/>
					</span>
				</div>
			</div>
		</div>
	);
};

function Form({ handleSubmit, apiError, loading }) {
	return (
		<form onSubmit={handleSubmit}>
			{apiError && <ApiError error={apiError} />}

			<Field
				label="Name"
				name="name"
				component={TextField}
				validate={[required]}
			/>

			<Field
				label="Data"
				name="data"
				component={FileField}
				validate={[required]}
			/>

			<br />

			<Button
				size="large"
				type="primary"
				htmlType="submit"
				loading={loading}
			>
				Start a new training
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "lexicon-g2p-training",
	enableReinitialize: true
})(Form);
