import React from "react";
import RightArrow from "./Icons/RightArrow";
import classNames from "classnames";

export const Descriptions = ({ children }) => (
	<dl className="border-t border-gray-200">{children}</dl>
);

export const DescriptionsItem = ({ label, children, style }) => (
	<div className={classNames(
			"odd:bg-gray-50 px-4 py-3",
			style !== "row" && "flex"
		)}
	>
		<dt className="text-sm leading-5 font-medium text-gray-500 flex-grow">
			{label}
		</dt>
		<dd className="text-sm leading-5 text-gray-900">{children}</dd>
	</div>
);

export const DescriptionsLinkItem = ({ label, children, ...props }) => (
	<a
		{...props}
		className="text-gray-500 hover:text-gray-900 odd:bg-gray-50 px-4 py-2 flex items-center"
	>
		<div className="text-md leading-5 font-medium  flex-grow">{label}</div>
		<dd className="text-sm leading-5 text-gray-900">
			{children || <RightArrow size={7} className="text-gray-500" />}
		</dd>
	</a>
);

export default Descriptions;
