import React from "react";
import Loading from "../../components/Loading";
import ApiError from "../../components/ApiError";
import { useList } from "./useList";
import BookWithStatus from "./BookWithStatus";

const RecentBooks = () => {
	const list = useList("recent", "clusters/recent/");

	if (!list || list.loading) return <Loading />;
	if (list.error) return <ApiError error={list.error} />;

	return (
		<>
			<h3 className="text-lg text-cool-gray-400">
				Recently edited books
			</h3>
			{list.data.map((cluster) => (
				<BookWithStatus key={cluster.id} {...cluster} />
			))}
		</>
	);
};

export default RecentBooks;
