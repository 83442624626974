import loggedIn from "../../containers/loggedIn";
import Dashboard from "./Dashboard";
import ClustersRoute from "../Clusters";
import PortalPublishersRoute from "../PortalPublishers";
import PublishersRoute from "../Publishers";
import React from "react";

const Layout = ({ children }) => (
	<div className="overflow-y-auto py-4">{children}</div>
);

export default {
	path: "",
	component: loggedIn(Layout),
	indexRoute: { component: Dashboard },
	childRoutes: [ClustersRoute, PublishersRoute, PortalPublishersRoute],
};
