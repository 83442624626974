import React from "react";
import Card, { CardBody } from "../../../../../components/Card";
import Button from "../../../../../components/Button";
import { API_URL } from "../../../../../config";
import { AUTH_ACCESS_TOKEN_KEY } from "../../../../Auth/modules/auth";

const WorkLogLexiconSessionForm = ({ id, onClose }) => (
	<Card title="Work Logs">
		<CardBody>
			Download all work logs for this lexicon session. Only super users can do this.
			<a
				href={`${API_URL}/accounting/work_log/export/?lexiconsession_id=${id}&token=${localStorage.getItem(
					AUTH_ACCESS_TOKEN_KEY
				)}`}
				onClick={onClose}
				target="_blank"
			>
				<Button className="mt-4" block type="primary">
					Export Spread Sheet
				</Button>
			</a>
		</CardBody>
	</Card>
);

export default WorkLogLexiconSessionForm;
