import React, { Component } from "react";
import { reset, search } from "../modules/search";
import { connect } from "react-redux";
import Search from "../components/Search";

class SearchContainer extends Component {
	componentDidMount() {
		this.props.reset();
	}

	render() {
		return <Search {...this.props} />;
	}
}

const mapStateToProps = (state) => ({
	...state.lexicon.search,
});

const mapDispatchToProps = {
	reset,
	search,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
