import React from "react";
import { Button, Icon } from "antd";

export function SentenceDetailsVersionsItem({
	sentence,
	version,
	active,
	activate,
	copy,
}) {
	const nlpData = JSON.parse(version.nlp.replace(/'/g, '"'));

	return (
		<div>
			<span className="font-medium">
				{version.title}
				{!active && (
					<Button
						size="small"
						className="float-right"
						onClick={() => activate(sentence.id, version.id)}
					>
						<Icon type="check" />
					</Button>
				)}
				{
					<Button
						size="small"
						className="float-right"
						onClick={() =>
							copy({
								nlp: version.nlp,
								gst_factor: version.gst_factor,
							})
						}
					>
						<Icon type="copy" />
					</Button>
				}
			</span>

			<audio
				className="w-full h-6 my-1"
				preload="none"
				src={version.wav_url}
				controls
			/>

			<div className="grid grid-cols-3">
				<div>
					<span className="font-medium">pause:</span> {version.pause}
				</div>
				<div>
					<span className="font-medium">intensity:</span>{" "}
					{version.gst_factor}
				</div>
				{Object.keys(nlpData).map((k) => (
					<div key={k}>
						<span className="font-medium">{k}:</span> {nlpData[k]}
					</div>
				))}
			</div>
		</div>
	);
}

export default SentenceDetailsVersionsItem;
