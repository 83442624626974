import { combineReducers } from "redux";
import searchReducer from "../Search/modules/search";
import editReducer from "../Edit/modules/edit";
import sessionsReducer from "../Sessions/modules/sessions";
import createSessionReducer from "../Sessions/Create/modules/create";
import g2pReducer from "../G2P/modules/g2p";
import lexiconsReducer from "./lexicons";

export default combineReducers({
	lexicons: lexiconsReducer,
	search: searchReducer,
	edit: editReducer,
	sessions: sessionsReducer,
	createSession: createSessionReducer,
	g2p: g2pReducer
});
