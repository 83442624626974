import React from "react";

const PreviewRemoveBar = ({ type, removed, onRemove, onUndo }) =>
	removed ? (
		<div className="bg-red-100 text-red-900 text-center py-1">
			This {type} won't be synthesized at all.{" "}
			<a
				className="border border-blue-700 bg-white px-2 inline-block ml-3 rounded-full text-xs text-blue-700"
				onClick={onUndo}
			>
				Undo
			</a>
		</div>
	) : (
		<div className="bg-blue-100 text-blue-900 text-center py-1">
			This {type} will be synthesized.{" "}
			<a
				className="border border-red-700 bg-white px-2 inline-block ml-3 rounded-full text-xs text-red-700"
				onClick={onRemove}
			>
				Don't synthesize this {type} at all
			</a>
		</div>
	);

export default PreviewRemoveBar;
