import React from "react";
import { reduxForm, Field } from "redux-form";
import { required } from "../../../../../services/validations";
import TextField from "../../../../../components/Forms/TextField";
import Button from "../../../../../components/Button";
import SearchIcon from "../../../../../components/Icons/Search";

function Form({ handleSubmit, loading }) {
	return (
		<form className="flex" onSubmit={handleSubmit}>
			<Field
				name="query"
				validate={required}
				component={TextField}
				className="rounded-r-none border-r-0"
				autoComplete="off"
			/>
			<Button
				className="rounded-l-none"
				htmlType="submit"
				type="primary"
				loading={loading}
				icon={SearchIcon}
			>
				Search
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "cluster-search",
})(Form);
