import React from "react";
import SentenceDetailsResynthesizeFormContainer from "../containers/SentenceDetailsResynthesizeFormContainer";
import Button from "../../../components/Button";

export function SentenceDetailsResynthesize({
	sentence,
	resynthesize,
	copiedNlp,
	paste,
}) {
	return (
		<div className="p-4">
			{copiedNlp !== null && (
				<Button
					block
					className="mb-2"
					size="small"
					type="info"
					onClick={() => paste(sentence, copiedNlp)}
				>
					Paste copied NLP scores
				</Button>
			)}

			<SentenceDetailsResynthesizeFormContainer
				sentence={sentence}
				onSubmit={(values) => resynthesize(sentence.id, values)}
			/>
		</div>
	);
}

export default SentenceDetailsResynthesize;
