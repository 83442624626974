import React, { useEffect, useState } from "react";
import { load, loadPortalProject, loadStats } from "../../modules/detail";
import PageHeader from "../../../../components/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router";
import Loading from "../../../../components/Loading";
import ApiError from "../../../../components/ApiError";

const defaultTabs = [
	{ path: "", title: "Overview" },
	{ path: "search", title: "Search & Bulk Replace" },
];

const Detail = ({ params, children }) => {
	const [tabs, setTabs] = useState(defaultTabs);
	const dispatch = useDispatch();
	const state = useSelector((state) => state.clusters.detail);
	const { loading, data: cluster, error, portalProject } = state;

	useEffect(() => {
		if (portalProject) {
			setTabs([
				...defaultTabs,
				{ path: "portal/delivery/", title: "Portal Delivery Zone" },
			]);
		} else {
			setTabs(defaultTabs);
		}
	}, [portalProject]);

	useEffect(() => {
		dispatch(load(params.id));
		dispatch(loadStats(params.id));
		dispatch(loadPortalProject(params.id));
	}, [params.id]);

	if (!cluster || loading) return <Loading />;
	if (error) return <ApiError error={error} />;

	return (
		<div className="container">
			<PageHeader
				title={cluster.title}
				subTitle={
					cluster.publisher ? (
						<Link
							className="text-blue-500 font-medium hover:text-blue-800"
							to={`/publishers/${cluster.publisher.id}/`}
						>
							{cluster.publisher.title}
						</Link>
					) : portalProject ? (
						<Link
							className="text-blue-500 font-medium hover:text-blue-800"
							to={`/portal-publishers/${portalProject.user.id}/`}
						>
							{portalProject.user.business.publisher_name}
						</Link>
					) : (
						"Not assigned to a publisher"
					)
				}
				footer={
					<nav className="-mb-px flex">
						{tabs.map((tab) => (
							<Link
								to={`/clusters/${cluster.id}/${tab.path}`}
								key={tab.path}
								className="whitespace-no-wrap mr-6 py-4 px-1 border-b-2 font-medium text-sm leading-5 focus:outline-none border-transparent  text-gray-500 hover:text-gray-700 hover:border-gray-300  focus:text-gray-700 focus:border-gray-300"
								activeClassName="border-indigo-500 text-indigo-600 focus:text-indigo-800 focus:border-indigo-700"
							>
								{tab.title}
							</Link>
						))}
					</nav>
				}
			/>

			{children}
		</div>
	);
};

export default Detail;
