import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Icon, Popover, Radio, Tooltip } from "antd";
import axios from "axios";
import { Keywords, Sample } from "./Help";
import classNames from "classnames";
import {
	alphabets,
	convertTranscription,
	parseCombilex,
	parseTranscription,
} from "../../services/lexicons";

const Match = ({ match, onChangeStressLevel }) => {
	const content = match.entry ? (
		<div style={{ maxWidth: 400 }}>
			<table className="w-full">
				<tr>
					<th className="w-1/2">DeepZen</th>
					<th className="w-1/2 text-right">IPA</th>
				</tr>
				<tr className="text-lg" style={{ fontFamily: "monospace" }}>
					<td>{match.entry.deepzen}</td>
					<td className="text-right">{match.entry.ipa}</td>
				</tr>
				{match.entry.keywords.length > 0 && (
					<>
						<tr>
							<th colSpan={2}>Keywords</th>
						</tr>
						<tr>
							<td colSpan={2}>
								<Keywords data={match.entry.keywords} />
							</td>
						</tr>
						<tr>
							<th colSpan={2}>Example</th>
						</tr>
						<tr>
							<td colSpan={2}>
								<Sample
									phoneme={match.entry.deepzen}
									data={match.entry.sample}
								/>
							</td>
						</tr>
					</>
				)}
			</table>

			{match.entry?.type === "vowel" && (
				<div className="mt-4">
					<div className="font-bold">Stress:</div>

					<Radio.Group
						size="small"
						defaultValue={match.stressLevel ?? "no"}
						buttonStyle="solid"
						onChange={(e) => {
							onChangeStressLevel(
								e.target.value === "no" ? null : e.target.value
							);
						}}
					>
						<Radio.Button value="no">No stress</Radio.Button>
						<Radio.Button value="primary">Primary</Radio.Button>
						<Radio.Button value="secondary">Secondary</Radio.Button>
					</Radio.Group>
				</div>
			)}
		</div>
	) : null;

	const visual = (
		<span
			style={{ fontFamily: "monospace" }}
			className={classNames(
				"mr-1 rounded inline-block py-0.5 px-1.5 font-bold cursor-pointer",
				match.valid
					? match.stressLevel === "primary"
						? "bg-green-300"
						: match.stressLevel === "secondary"
						? "bg-blue-300"
						: "bg-gray-300"
					: "bg-red-300"
			)}
		>
			{match.phoneme}
		</span>
	);

	return match.valid ? (
		<Popover
			content={content}
			title={match.entry.type}
			trigger="hover"
			placement="bottom"
			zIndex={999999}
		>
			{visual}
		</Popover>
	) : (
		visual
	);
};

const Matches = ({ data, onChangeStressLevel }) => {
	return (
		<div>
			{data.map((p, i) => (
				<Match
					key={i}
					match={p}
					onChangeStressLevel={(value) =>
						onChangeStressLevel(i, value)
					}
				/>
			))}
		</div>
	);
};

const Form = ({
	pos,
	onPosChange,
	value,
	onChange,
	matches,
	alphabet,
	onChangeAlphabet,
	language,
	isValid,
	validationErrors,
	isFormDisabled,
}) => {
	const [synthesizing, setSynthesizing] = useState(false);
	const [playing, setPlaying] = useState(false);
	const inputRef = useRef();

	const synthesize = useCallback(() => {
		const transcription = convertTranscription(
			language,
			alphabet,
			"combilex",
			matches
		);

		setSynthesizing(true);

		axios
			.post(
				"/lexicons/synthesize/",
				{ language, transcription, pos }
			)
			.then((res) => {
				const player = new Audio();
				player.src = res.data.wav_url;
				player.onplay = () => setPlaying(true);
				player.onended = () => setPlaying(false);
				player.play();
			})
			.finally(() => setSynthesizing(false));
	}, [matches, language, pos]);

	const handleStressLevelChange = useCallback((i, stressLevel) => {
		const newMatches = [...matches];
		newMatches[i].stressLevel = stressLevel;

		// Generate transcription with updated stress level
		const newTranscription = convertTranscription(
			language,
			alphabet,
			alphabet,
			newMatches
		);

		onChange(newTranscription);
	});

	return (
		<div className="bg-white rounded-lg relative">
			<div className="flex mt-1">
				{value.length > 0 && !isValid && (
					<Tooltip
						defaultVisible
						zIndex={99999}
						title={validationErrors.join(", ")}
					>
						<div className="cursor-pointer absolute right-2 top-1.5 text-blue-500 z-50">
							<Icon
								type="exclamation-circle"
								className="text-xl z-50 text-red-600"
							/>
						</div>
					</Tooltip>
				)}

				{isValid && (
					<a
						onClick={synthesize}
						className="cursor-pointer absolute right-2 top-1.5 text-blue-500"
					>
						<Icon
							type={
								synthesizing
									? "loading"
									: playing
									? "pause-circle"
									: "play-circle"
							}
							spin={synthesizing}
							className="text-xl z-50"
						/>
					</a>
				)}

				{isValid && (
					<a
						onClick={synthesize}
						className="cursor-pointer absolute right-2 top-1.5 text-blue-500"
					>
						<Icon
							type={
								synthesizing
									? "loading"
									: playing
									? "pause-circle"
									: "play-circle"
							}
							spin={synthesizing}
							className="text-xl z-50"
						/>
					</a>
				)}

				<input
					value={pos}
					placeholder="POS"
					onChange={(e) => onPosChange(e.target.value)}
					className="w-16 border-t border-b border-l rounded-l px-2 h-8 text-base"
					disabled={isFormDisabled}
				/>

				<Popover
					zIndex={99999}
					trigger="focus"
					content={
						<div className="">
							<div className="flex text-xs">
								<label className="flex items-center mr-4">
									<input
										ref={inputRef}
										type="radio"
										checked={alphabet === "deepzen"}
										value="deepzen"
										className="mr-1"
										onChange={(e) =>
											onChangeAlphabet(e.target.value)
										}
									/>
									DeepZen
								</label>
								<label className="flex items-center">
									<input
										type="radio"
										checked={alphabet === "ipa"}
										value="ipa"
										className="mr-1"
										onChange={(e) =>
											onChangeAlphabet(e.target.value)
										}
									/>
									IPA
								</label>
							</div>

							{matches.length > 0 && (
								<div className="h-6 mt-4 flex items-center">
									<Matches
										data={matches}
										onChangeStressLevel={
											handleStressLevelChange
										}
									/>
								</div>
							)}
						</div>
					}
					placement="bottomLeft"
				>
					<input
						value={value}
						onChange={(e) => onChange(e.target.value)}
						className="flex-grow border rounded-r px-2 py-0.5 text-base"
						style={{ fontFamily: "monospace" }}
						placeholder="Pronunciation"
						disabled={isFormDisabled}
					/>
				</Popover>
			</div>

			<div className="hidden">
				<label className="flex items-center">
					<input
						type="radio"
						checked={alphabet === "deepzen"}
						value="deepzen"
						className="mr-1"
						onChange={(e) => onChangeAlphabet(e.target.value)}
					/>
					DeepZen
				</label>
				<label className="flex items-center">
					<input
						type="radio"
						checked={alphabet === "ipa"}
						value="ipa"
						className="mr-1"
						onChange={(e) => onChangeAlphabet(e.target.value)}
					/>
					IPA
				</label>
			</div>
		</div>
	);
};

export const validPosTagsByLanguage = {
	"es_mx": ["nil", "adj", "adv", "noun", "nounp", "num", "pron", "prep", "verb"],
	"es_es": ["nil", "adj", "adv", "noun", "nounp", "num", "pron", "prep", "verb"],
	"tr": ["adj", "adv", "adverb", "anum", "conj", "demons", "det", "dup", "interj", "nadj", "adp", "neg", "nnum", "noun", "pcabl", "pcacc", "pcdat", "pcins", "pcnom", "pers", "prop", "quant", "ques", "reflex", "rel", "verb", "without"]
};

export const getValidPosTagInfo = (language) => {
	let posTagsInfo = "";
	if(language && validPosTagsByLanguage[language]){
		const posTagLength = validPosTagsByLanguage[language].length;
		validPosTagsByLanguage[language].map((posTag, index) => {
			posTagsInfo += posTag;
			if(index < (posTagLength - 1))
				posTagsInfo += ", ";
		});
	};
	return posTagsInfo;
};

const TranscriptionInput = ({
	input: { value, onChange },
	language,
	isFormDisabled,
}) => {
	const [pos, setPos] = useState("");
	const [transcription, setTranscription] = useState("");
	const [matches, setMatches] = useState([]);
	const [alphabet, setAlphabet] = useState("deepzen"); // deepzen, ipa, xsampa, combilex
	const [isPosTagEditedManually, setIsPosTagEditedManually] = useState(false);

	const mounted = useRef(false);

	useEffect(() => {
		if (mounted.current || !value) {
			return;
		}

		const [pos, trans] = parseCombilex(value);

		setPos(pos);
		handleTranscriptionChange(
			trans
				? convertTranscription(
						language,
						"combilex",
						"deepzen",
						parseTranscription(language, "combilex", trans)
				  )
				: ""
		);

		mounted.current = true;
	}, [value]);

	const checkTranscriptionRules = (language) => {
		let errors = [];
		const primaryStressCount = matches.filter(
			(m) => m.stressLevel === "primary"
		).length;
		const consonantStress = matches.filter(
			(m) => m.stressLevel && m.entry?.type === "consonant"
		);
		const vowels = matches.filter((m) => m.entry?.type === "vowel");
		const schwaStress = vowels.filter(
			(m) => m.entry.optionalStress && m.stressLevel
		);

		const invalidEntries = matches.filter((m) => !m.valid);
		const alphabetConfig = alphabets[alphabet];
		const stressSymbolsAsInvalidEntry = invalidEntries.filter(
			(m) =>
				m.phoneme.trim() === alphabetConfig.stress.primary ||
				m.phoneme.trim() === alphabetConfig.stress.secondary
		);

		switch (language) {
			case "es_mx" || "es_es": {
				 const validPosTags = validPosTagsByLanguage[language];
				 const filteredPosTags = validPosTags.filter((vp) => vp === pos);
				 if (filteredPosTags.length === 0 && isPosTagEditedManually){
					errors.push("Invalid POS tag");
				 }else if(invalidEntries.length > 0){
					errors.push("Please fix invalid phonemes.");
				 
				}else if (consonantStress.length > 0) {
					errors.push("Consonants can not have stress modifiers.");
				}
				break;
			}
			case "tr":{
				const validPosTags = validPosTagsByLanguage[language];
				const filteredPosTags = validPosTags.filter((vp) => vp === pos);
				if (filteredPosTags.length === 0 && isPosTagEditedManually){
					errors.push("Invalid POS tag");
				}else if(invalidEntries.length > 0){
					errors.push("Please fix invalid phonemes.");
				 
				}else if (consonantStress.length > 0) {
					errors.push("Consonants can not have stress modifiers.");
				}
				break;
			}	
			default:{
				if (primaryStressCount > 1) {
					errors.push("There can't be more than one primary stress");
				} else if (stressSymbolsAsInvalidEntry.length > 0) {
					errors.push(
						"The stress should be added to right of the stressed vowel."
					);
				} else if (invalidEntries.length > 0) {
					errors.push("Please fix invalid phonemes.");
				} else if (consonantStress.length > 0) {
					errors.push("Consonants can not have stress modifiers.");
				} else if (schwaStress.length > 0) {
					errors.push("Schwa (@) can not have stress modifiers.");
				} else if (
					language !== "fr" &&
					vowels.length > 1 &&
					primaryStressCount === 0
				) {
					errors.push("There must be one primary stress.");
				}
		
				// US-specific rhotic vowel rule
				if (
					language === "us" &&
					matches.filter((m, i) => {
						if (
							m.entry?.deepzen === "@" &&
							matches[i + 1]?.entry?.deepzen === "@" &&
							matches[i + 2]?.entry?.deepzen === "r"
						) {
							return true;
						} else if (
							m.entry?.deepzen === "@" &&
							matches[i + 1]?.entry?.deepzen === "r"
						) {
							return true;
						} else if (
							m.entry?.deepzen === "aw" &&
							matches[i + 1]?.entry?.deepzen === "r"
						) {
							return true;
						} else {
							return false;
						}
					}).length > 0
				) {
					errors.push(
						"This is not a rhotic vowel. Please add an r attached to the right of @ @@ aw."
					);
				}
				break;
			}
		}
		return errors;
	}

	const validationErrors = useMemo(() => {
		return checkTranscriptionRules(language);
	}, [matches, pos]);

	const handleAlphabetChange = (newAlphabet) => {
		const newTranscription = convertTranscription(
			language,
			alphabet,
			newAlphabet,
			matches
		);
		const newMatches = parseTranscription(
			language,
			newAlphabet,
			newTranscription
		);

		setTranscription(newTranscription);
		setMatches(newMatches);
		setAlphabet(newAlphabet);
	};

	const handleTranscriptionChange = (value) => {
		const matches = parseTranscription(language, alphabet, value);

		setTranscription(value);
		setMatches(matches);
	};

	useEffect(() => emitChange(pos, matches), [pos, matches]);

	const emitChange = (pos, matches) => {
		const updatedValue = `${pos} (${convertTranscription(
			language,
			alphabet,
			"combilex",
			matches
		)})`;

		onChange(matches?.length > 0 ? updatedValue : "");
	};

	return (
		<Form
			pos={pos}
			onPosChange={(e) => {
				setIsPosTagEditedManually(true);
				setPos(e);
			}}
			value={transcription}
			onChange={handleTranscriptionChange}
			language={language}
			alphabet={alphabet}
			onChangeAlphabet={handleAlphabetChange}
			matches={matches}
			isValid={transcription.length > 0 && validationErrors.length === 0}
			validationErrors={validationErrors}
			isFormDisabled={isFormDisabled}
		/>
	);
};

export default TranscriptionInput;
