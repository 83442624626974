import axios from "axios";
import { message } from "antd";
import { createSelector } from "reselect";
import {reset} from 'redux-form';

export const NOTE_CREATED = "NOTE_CREATED";
export const NOTE_DELETED = "NOTE_DELETED";

// Selectors
export const noteIdsBySentenceIdSelector = createSelector(
	(state, id) => id,
	state => state.editor.noteIds,
	state => state.editor.notesById,
	(id, noteIds, notesById) => {
		return noteIds.filter(noteId => notesById[noteId].sentence_id === id);
	}
);

export const noteByIdSelector = createSelector(
	(state, id) => id,
	state => state.editor.notesById,
	(id, notesById) => notesById[id]
);

// Pusher action creators
export const noteCreated = note => ({ type: NOTE_CREATED, payload: note });
export const noteDeleted = noteId => ({ type: NOTE_DELETED, payload: noteId });

export function saveNote(sentenceId, note) {
	return dispatch => {
		axios.post(`sentences/${sentenceId}/notes/`, { note })
			.then((res) => dispatch(reset('note')))
			.catch(e => message.error('An error occured while saving note.'));
	};	
}

export function deleteNote(sentenceId, noteId) {
	return dispatch => {
		axios.delete(`sentences/${sentenceId}/notes/${noteId}/`)
			.catch(e => message.error('An error occured while deleting note.'));
	};	
}