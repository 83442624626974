import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ApiError from "../../../../components/ApiError";
import Button from "../../../../components/Button";

const SampleForm = ({ project, onSuccess }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [text, setText] = useState(null);

	const handleFileChange = (e) => {
		if (e.target.files.length > 0) {
			setFile(e.target.files[0]);
		} else {
			message.error("Invalid file.");
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		let data = new FormData();
		data.append("sample_wav", file);
		data.append("sample_text", text);

		axios
			.post(`/portal/projects/${project.id}/finish_editing_sample/`, data)
			.then(onSuccess)
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
	};

	return (
		<form onSubmit={handleSubmit}>
			{error && (
				<div className="mb-4">
					<ApiError error={error} />
				</div>
			)}

			<input
				required
				type="file"
				onChange={handleFileChange}
				className="mb-4"
			/>
			<textarea
				reuired
				className="form-textarea block w-full mb-4 h-48"
				value={text}
				onChange={(e) => setText(e.target.value)}
			/>

			<Button block htmlType="submit" type="success" loading={loading}>
				Send sample to customer
			</Button>
		</form>
	);
};

export default SampleForm;
