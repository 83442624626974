import { message } from "antd";

export function highlightSentence(value) {
	const el = document.getElementById(`sentence-${value}`);

	if (el === null) {
		message.error("Sentence not found.");
	} else {
		el.style.outline = "3px dotted blue";
		el.scrollIntoView({ block: "center" });
		setTimeout(() => (el.style.outline = null), 1500);
	}
}
