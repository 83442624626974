import React from "react";
import { connect } from "react-redux";
import Form from "../../components/Notes/Form";
import { saveNote } from "../../modules/notes";

export function FormContainer({ saveNote, sentenceId, ...otherProps }) {
	return (
		<Form
			onSubmit={({ note }) => saveNote(sentenceId, note)}
			{...otherProps}
		/>
	);
}

const mapStateToProps = (state) => ({
	loading: state.editor.noteLoading,
});

const mapDispatchToProps = { saveNote };

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
