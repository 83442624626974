import React from "react";
import { Icon } from "antd";
import { Link } from "react-router";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { selectSentence } from "../modules/editor";
import {
	markAllAsRead,
	showErrorMessage,
	queueCountSelector,
} from "../modules/queue";

const iconMap = {
	pending: "database",
	running: "loading",
	success: "check-circle",
	fail: "close-circle",
};

const titleMap = {
	sentence_resynt: "Sentence Resynthesis",
	sentence_pause: "Sentence Pause Update",
	sentence_insert: "Insert Sentence",
	sentence_delete: "Delete Sentence",
	synt_using_text: "Synthesize Chapter",
	finalise: "Export Chapter",
	sentence_resynt_word: "Word Resynthesis",
	clone: "Duplicate Chapter",
	reload_session: "Reload Chapter",
};

export function Queue({ book }) {
	const dispatch = useDispatch();
	const queueCount = useSelector(queueCountSelector);
	const jobs = useSelector((state) => state.queue.jobs);

	return (
		<div
			className="queue"
			onClick={() => {
				if (queueCount > 0) {
					dispatch(markAllAsRead(book.id));
				}
			}}
		>
			{jobs.map((job) => (
				<div
					className={classNames(
						"flex border-b border-gray-200",
						!job.read && "bg-gray-100"
					)}
					key={job.id}
				>
					<div className="w-8 pl-2 pt-2 text-md">
						<Icon
							className={
								job.status === "success"
									? "text-green-500"
									: "text-red-500"
							}
							type={iconMap[job.status]}
						/>
					</div>

					<div className="flex-grow py-1">
						<div className="font-medium text-gray-800">
							{titleMap[job.task] ?? job.task}
						</div>
						<div className="flex justify-between">
							{job.sentence_id && (
								<div className="text-sm text-gray-600">
									<a
										onClick={() =>
											dispatch(
												selectSentence(job.sentence_id)
											)
										}
									>
										#{job.tts_sentence_id}
										<Icon type="right" />
									</a>
								</div>
							)}
							{!job.airflow_execution_date &&
								job.status === "fail" && (
									<div className="text-sm text-gray-600">
										<a
											onClick={() =>
												dispatch(
													showErrorMessage(
														book.id,
														job.id
													)
												)
											}
										>
											Show error message
											<Icon type="right" />
										</a>
									</div>
								)}
							{job.task === "finalise" &&
								job.status === "success" && (
									<div className="text-sm text-gray-600">
										<a target="_blank" href={job.output}>
											Download
											<Icon type="right" />
										</a>
									</div>
								)}
							{job.task === "clone" && job.status === "success" && (
								<div className="text-sm text-gray-600">
									<Link href={`/books/${job.output}`}>
										Open created chapter
										<Icon type="right" />
									</Link>
								</div>
							)}
							{job.airflow_execution_date && (
								<div className="text-xs text-gray-600">
									<a
										target="_blank"
										href={`http://158.101.169.4:8080/graph?dag_id=${
											job.airflow_dag_id
										}&execution_date=${encodeURIComponent(
											job.airflow_execution_date
										)}`}
									>
										Airflow
										<Icon type="right" />
									</a>
								</div>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

export default Queue;
