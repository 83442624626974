import axios from "axios";
import React, { useState } from "react";
import ApiError from "../../../../components/ApiError";
import Button from "../../../../components/Button";
import { API_URL } from "../../../../config";

const SampleForm = ({ cluster, project, onSuccess }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState(project.delivered_chapters);

	const handleTextChange = (index) => (e) => {
		setData(
			data.map((c, i) =>
				i === index ? { ...c, text: e.target.value } : c
			)
		);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		let formData = new FormData();

		formData.append(
			"chapters",
			JSON.stringify(data.map((c) => ({ id: c.id, text: c.text })))
		);

		axios
			.post(`/portal/projects/${project.id}/deliver/`, formData)
			.then(onSuccess)
			.catch((e) => {
				setError(e);
				setLoading(false);
			});
	};

	return (
		<form onSubmit={handleSubmit}>
			{error && (
				<div className="mb-4">
					<ApiError error={error} />
				</div>
			)}

			{data.map((c, i) => (
				<div className="bg-gray-100 rounded-md mb-4 p-3">
					<label className="block font-medium text-base">
						{
							cluster.books.filter((b) => c.chapter === b.id)[0]
								?.title
						}
					</label>

					{c.wav && (
						<audio
							src={`${c.wav}`}
							controls
							className="my-4 w-full"
						/>
					)}
					<textarea
						reuired
						className="form-textarea block w-full mb-4 h-48"
						value={c.text}
						onChange={handleTextChange(i)}
					/>
				</div>
			))}

			<Button block htmlType="submit" type="success" loading={loading}>
				Send book to customer
				
			</Button>
		</form>
	);
};

export default SampleForm;
