import { combineReducers } from "redux";
import createReducer from "../Create/modules/create";
import listReducer from "./list";
import detailReducer from "./detail";

export default combineReducers({
	create: createReducer,
	list: listReducer,
	detail: detailReducer
});
