import React from "react";
import NoteContainer from "../../containers/Notes/NoteContainer";

export function Notes({ noteIds }) {
	return (
		<div>
			{noteIds.map((id) => (
				<NoteContainer key={id} id={id} />
			))}
		</div>
	);
}

export default Notes;
