import { connect } from "react-redux";
import React, { Component } from "react";
import {
	loadStatus,
	loadTrainedModels,
	startTraining,
	predict
} from "../modules/g2p";
import G2P from "../components/G2P";

class G2PContainer extends Component {
	timer = null;

	componentDidMount() {
		this.props.loadStatus();
		this.props.loadTrainedModels();

		this.timer = setInterval(() => this.props.loadStatus(false), 2000);
	}

	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
		}
	}

	render() {
		return <G2P {...this.props} />;
	}
}

const mapStateToProps = state => state.lexicon.g2p;

const mapDispatchToProps = {
	loadStatus,
	loadTrainedModels,
	startTraining,
	predict
};

export default connect(mapStateToProps, mapDispatchToProps)(G2PContainer);
