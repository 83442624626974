import React, { Component } from "react";
import { connect } from "react-redux";
import {
	sentenceByIdSelector,
	selectNextSentence,
	markSentence,
} from "../modules/editor";
import { noteIdsBySentenceIdSelector } from "../modules/notes";
import { copy, paste } from "../modules/nlp";
import { load, activate, resynthesize } from "../modules/sentenceDetails";
import SentenceDetails from "../components/SentenceDetails";

export class SentenceDetailsContainer extends Component {
	componentDidMount() {
		this.props.load(this.props.id);
	}

	componentDidUpdate(oldProps) {
		if (oldProps.id !== this.props.id) {
			this.props.load(this.props.id);
		}
	}

	render() {
		return <SentenceDetails {...this.props} />;
	}
}

const mapStateToProps = (state, { id }) => ({
	sentence: sentenceByIdSelector(state, id),
	loading: state.sentenceDetails.loading,
	error: state.sentenceDetails.error,
	versions: state.sentenceDetails.versions,
	autoPlay: state.editor.autoPlay,
	autoContinue: state.editor.autoContinue,
	copiedNlp: state.nlp.clipboard,
	showInsertSentence: state.insertSentence.show,
	noteIds: noteIdsBySentenceIdSelector(state, id),
	canEdit: state.timeTrackerEditor.activeLog !== null,
});

const mapDispatchToProps = {
	load,
	activate,
	selectNextSentence,
	copy,
	paste,
	resynthesize,
	markSentence,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SentenceDetailsContainer);
