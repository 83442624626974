import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from 'antd';
import classNames from "classnames";
import FilterIcon from "../components/Icons/Filter";

const FilterBox = ({
    className,
    title,
    containerWidth, 
    data, 
    clearFilter,
    onChange
}) => {
    
    const [selectedOptionIndexMap, setSelectedOptionIndexMap] = useState({});

    useEffect(() => {
        if(clearFilter){
            setSelectedOptionIndexMap({});
            onChange("");
        }
    }, [clearFilter]);
    
    const handleOnClickOption = (e, index) => {
        if(e.target.checked){
            selectedOptionIndexMap[index]=index;
        }else{
            delete selectedOptionIndexMap[index];
        }
        setSelectedOptionIndexMap({...selectedOptionIndexMap});

        const selectedOptions = data.filter((value, index) => index === selectedOptionIndexMap[index]);
        let values = "";
        if(selectedOptions?.length > 0){
            let index = 0;
            selectedOptions.forEach(element => {
                values += element.field;
                if(index++ < (selectedOptions.length - 1)){
                    values += ",";
                }
            });
        }
        onChange(values);
    };

    if(!data) return null;

    const menu = (
        <Menu className={classNames(containerWidth ? `w-${containerWidth}` : "")}>
            <Menu.Item key="title" disabled className="cursor-default">
                <div className="text-sm font-medium text-gray-900">
                    {title}
                </div>
            </Menu.Item>
            {data && data.map((option, i) => (
                <Menu.Item key={i}>
                    <div 
                        className="flex items-center cursor-pointer" 
                        role="button"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <input 
                            className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            id={`checkbox-${i}`} 
                            type="checkbox" 
                            name="checkbox"
                            checked={selectedOptionIndexMap[i] === i}
                            onChange={(e) => handleOnClickOption(e, i)}
                        />
                        <label htmlFor={`checkbox-${i}`} className="ml-2 cursor-pointer text-sm font-medium text-gray-900">
                             {option.title}
                        </label>
                 </div>
             </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <div role="button"
                className={classNames(
                    "flex items-center border border-gray-200 focus:outline-none focus:border-gray-300 px-3",
                    className
                )} 
                onClick={e => e.preventDefault()}
            >
                <FilterIcon size={5} />
            </div>
        </Dropdown>
    );
}  
  export default FilterBox;