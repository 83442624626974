import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Card, { CardBody } from "../../components/Card";
import classNames from "classnames";
import { message, Spin } from "antd";
import ApiError from "../../components/ApiError";

const PortalPostProcess = ({ params: { id } }) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleDrop = (acceptedFiles) => {
		setError(null);

		if (acceptedFiles.length > 0) {
			setLoading(true);

			var data = new FormData();
			data.append("file", acceptedFiles[0]);

			axios
				.post(`portal/projects/${id}/finish_post_processing/`, data)
				.then((res) => setSuccess(true))
				.catch((e) => {
					setLoading(false);
					setError(null);
				});
		} else {
			message.error("Invalid file.");
		}
	};

	useEffect(() => {
		axios
			.get(`portal/projects/${id}/post_processing_details/`)
			.then((res) => setData(res.data))
			.catch((e) => setError(e));
	}, [id, setData, setError]);

	if (!data) return null;

	return (
		<div className="h-full w-full flex justify-center items-center">
			<div className="w-1/3">
				<Card title="Post Processing">
					{error && <ApiError error={error} />}

					<CardBody>
						You can download and upload processed versions. Make
						sure you upload a ZIP file with the same file names. If
						file names are changed, audio files won't be mapped to
						correct chapters.
						<a
							href={data.bundle.download_url}
							className="block my-4 font-bold text-blue-600"
						>
							Download
						</a>
						{data.notes && (
							<div className="bg-gray-200 rounded shadow-sm px-4 py-2 text-sm">
								<strong>Notes:</strong> {data.notes}
							</div>
						)}
						<div className="h-4" />
						{success ? (
							<div className="px-4 py-2 bg-green-100 text-green-800">
								File has been submitted successfully.
							</div>
						) : (
							<Dropzone
								disabled={loading}
								multiple={false}
								onDrop={handleDrop}
							>
								{({
									getRootProps,
									getInputProps,
									isDragActive,
								}) => {
									return (
										<div
											{...getRootProps()}
											className={classNames(
												"dropzone cursor-pointer",
												{
													"dropzone--active":
														isDragActive,
												}
											)}
										>
											<input {...getInputProps()} />
											{loading ? (
												<>
													<Spin size="small" />{" "}
													<strong>
														Do not refresh the page
													</strong>{" "}
													until file is uploaded.
												</>
											) : (
												<p>
													Try dropping some files
													here, or click to select
													files to upload.
												</p>
											)}
										</div>
									);
								}}
							</Dropzone>
						)}
					</CardBody>
				</Card>
			</div>
		</div>
	);
};

export default PortalPostProcess;
