import { connect } from "react-redux";
import Note from "../../components/Notes/Note";
import { sentenceByIdSelector, selectSentence } from "../../modules/editor";
import { noteByIdSelector } from "../../modules/notes";
import { deleteNote } from "../../modules/notes";

const mapStateToProps = (state, { id }) => {
	const note = noteByIdSelector(state, id);

	return {
		note,
		sentence: sentenceByIdSelector(state, note.sentence_id),
	};
};

const mapDispatchToProps = { selectSentence, deleteNote };

export default connect(mapStateToProps, mapDispatchToProps)(Note);