import React from "react";
import Loading from "../../../components/Loading";
import ApiError from "../../../components/ApiError";
import Paragraph from "./Paragraph";
import SentenceDetailsContainer from "../containers/SentenceDetailsContainer";
import MultiSentenceDetails from "../components/MultiSentence/MultiSentenceDetails";
import Notes from "../components/Notes/Notes";
import HeaderContainer from "../containers/HeaderContainer";
import TimeTrackerEditorContainer from "../../../containers/TimeTrackerEditorContainer";
import { Link } from "react-router";
import Queue from "./Queue";
import Tabs from "../../../components/Tabs";
import BookSwitcher from "../../../components/BookSwitcher";
import DoneStatus from "./DoneStatus";

export function Editor({
	loading,
	error,
	cluster,
	book,
	paragraphs,
	activeSentenceIds,
	noteIds,
	queueCount,
}) {
	
	if (loading) return <Loading />;
	if (error) return <ApiError error={error} />;

	const tabs = [
		{
			key: "chapters",
			title: "Chapters",
			content: <BookSwitcher data={cluster.books} selected={book.id} />,
		},
		{
			key: "queue",
			title: "Queue",
			badge: queueCount,
			content: <Queue book={book} />,
		},
		{
			key: "notes",
			title: "Notes",
			badge: noteIds.length,
			content: <Notes noteIds={noteIds} />,
		},
	];

	return (
		<div className="editor flex bg-gray-50">
			<div className="w-1/5 flex flex-col p-4 h-screen">
				<div className="bg-cool-gray-700 shadow-md rounded-lg mb-4">
					<Link
						to={`/clusters/${cluster.id}`}
						className="block text-white font-medium pt-2 px-4 font-medium text-base truncate"
					>
						{cluster.title}
					</Link>
					<h2 className="text-cool-gray-100 font-medium pb-2 px-4 font-medium text-md truncate">
						{book.title}
					</h2>
					<div className="px-4 pb-4">
						<DoneStatus book={book} />
					</div>
				</div>
				<div className="flex-grow bg-cool-gray-50 shadow-md rounded-lg overflow-y-auto">
					<Tabs
						data={tabs}
						defaultTab={noteIds.length > 0 ? "notes" : "chapters"}
					/>
				</div>
			</div>

			<div className="w-4/5 h-screen">
				<div className="h-full bg-white shadow-md flex">
					<div className="w-3/5 h-full flex flex-col">
						<HeaderContainer />
						<div className="flex-grow overflow-y-auto p-3 mb-13">
							{book.tts_session_id && (
								<div>
									{paragraphs.map((sentenceIds, i) => (
										<Paragraph
											key={i}
											sentenceIds={sentenceIds}
										/>
									))}
								</div>
							)}
						</div>
					</div>

					<div className="w-2/5 h-full bg-gray-50 border-l border-gray-200 overflow-y-auto">
						{activeSentenceIds.length === 1 && (
							<SentenceDetailsContainer
								id={activeSentenceIds[0]}
							/>
						)}
						{activeSentenceIds.length > 1 && (
							<MultiSentenceDetails ids={activeSentenceIds} />
						)}
					</div>
				</div>
			</div>

			<TimeTrackerEditorContainer clusterId={cluster.id} bookId={book.id} />
		</div>
	);
}

export default Editor;
