import React from "react";
import ResynthesizeFormContainer from "../../containers/MultiSentence/ResynthesizeFormContainer";
import { Button, Icon } from "antd";

export function Resynthesize({
	ids,
	sentenceForInitialValues,
	resynthesize,
	clipboard,
	paste
}) {
	return (
		<div className="sentence-details__box sentence-details__form">
			<h2>
				Resynthesize sentences
				{clipboard !== null && (
					<Button
						size="small"
						className="float-right"
						onClick={() =>
							paste(sentenceForInitialValues, clipboard)
						}
					>
						<Icon type="copy" />
					</Button>
				)}
			</h2>

			<ResynthesizeFormContainer
				sentence={sentenceForInitialValues}
				onSubmit={values =>
					ids.map(id => {
						let strippedValues = {};
						const allowedKeys = ["title", ...values.allowedKeys];

						for (let key in values) {
							const allowedLookupKey =
								key.indexOf("nlp_") > -1 ? "nlp" : key;

							if (allowedKeys.indexOf(allowedLookupKey) > -1) {
								strippedValues[key] = values[key];
							}
						}

						resynthesize(id, strippedValues);
					})
				}
			/>
		</div>
	);
}

export default Resynthesize;
