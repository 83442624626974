import React from "react";
import { reduxForm, Field } from "redux-form";
import ApiError from "../../../../components/ApiError";
import SearchField from "../../../../components/Forms/SearchField";
import * as validation from "../../../../services/validations";

export function Form({ loading, handleSubmit, apiError }) {
	return (
		<form onSubmit={handleSubmit}>
			{apiError && <ApiError error={apiError} />}

			<Field
				name="word"
				loading={loading}
				placeholder="Search entries in the lexicon..."
				component={SearchField}
				validate={validation.required}
			/>
		</form>
	);
}

export default reduxForm({
	form: "lexicon-search",
})(Form);
