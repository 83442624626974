import React from "react";
import { PropTypes } from "prop-types";
import { Result, Button } from "antd";
import { Link } from "react-router";

export function Success({ reset }) {
	return (
		<Result
			status="success"
			title="Successfully Started Your Project!"
			subTitle="Your text-to-speech project is in progress now. You will be notified via email as progress is made."
			extra={[
				<Link key="dashboard" to="/">
					<Button type="primary">Go back to dashboard</Button>
				</Link>,
				<Button key="reset" onClick={reset}>
					Create another one
				</Button>
			]}
		/>
	);
}

Success.propTypes = {
	reset: PropTypes.func.isRequired
};

export default Success;
