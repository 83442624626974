import React from "react";
import { reduxForm, Field } from "redux-form";
import { required, email } from "../../../../services/validations";
import ApiError from "../../../../components/ApiError";
import TextField from "../../../../components/Forms/TextField";
import Button from "../../../../components/Button";

export function Form({ handleSubmit, loading, apiError }) {
	return (
		<form onSubmit={handleSubmit}>
			{apiError && <ApiError error={apiError} />}

			<div className="mb-4">
				<Field
					label="E-mail address"
					name="email"
					component={TextField}
					type="email"
					validate={[required, email]}
				/>
			</div>

			<div className="mb-6">
				<Field
					label="Password"
					name="password"
					component={TextField}
					type="password"
					validate={[required]}
				/>
			</div>

			<Button
				block
				htmlType="submit"
				type="primary"
				size="large"
				className="leading-7"
				loading={loading}
			>
				Login
			</Button>
		</form>
	);
}

export default reduxForm({
	form: "login",
})(Form);
