import React, { useState } from "react";
import { Menu, Dropdown, Icon } from 'antd';
import classNames from "classnames";
import SortIcon from "../components/Icons/Sort";

const SortBox = ({
    className,
    containerWidth, 
    data, 
    defaultFieldIndex, 
    defaultSort,
    onChange
}) => {
    
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(defaultFieldIndex || 0);
    const [selectedSortType, setSelectedSortType] = useState(defaultSort || "desc");

    const handleOnClickOption = (index) => {
        setSelectedOptionIndex(index);
        onChange({
            sort: data[index]["field"], 
            sortDir: selectedSortType
        });
    };

    const handleOnClickSort = (e, value) => {
        e.stopPropagation();
        setSelectedSortType(value);
        onChange({
            sort: data[selectedOptionIndex]["field"], 
            sortDir: value
        });
    }

    if(!data) return null;

    const menu = (
        <Menu className={classNames(containerWidth ? `w-${containerWidth}` : "")}>
            <Menu.Item key="title" disabled className="cursor-default">
                <div className="text-sm font-medium text-gray-900">
                    Sort by
                </div>
            </Menu.Item>
            {data && data.map((option, i) => (
                <Menu.Item key={i}>
                    <div 
                        className="flex items-center cursor-pointer" 
                        role="button"
                        onClick={(e) => e.stopPropagation()}>
                        <input 
                            className="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            id={`radio-${i}`} 
                            type="radio" 
                            name="radio"
                            checked={selectedOptionIndex === i}
                            onChange={(e) => handleOnClickOption(i)}
                        />
                        <label htmlFor={`radio-${i}`} className="ml-2 cursor-pointer text-sm font-medium text-gray-900">
                             {option.title}
                        </label>
                 </div>
             </Menu.Item>
            ))}
            <Menu.Divider  className="my-3"/>
            <Menu.Item key="sort-asc" className={classNames(
                selectedSortType === "asc" ? "bg-blue-50 text-blue-500": "text-gray-900"
            )}>
                <div 
                    className="flex items-center cursor-pointer text-sm font-medium"
                    role="button"
                    onClick={(e) => handleOnClickSort(e, "asc")}
                >
                    <Icon type="arrow-up" className="mr-2" />
                    {
                        data[selectedOptionIndex]["type"] === "date"
                        ? "Oldest first" 
                        : data[selectedOptionIndex]["type"] === "text"
                        ? "A-Z"
                        : data[selectedOptionIndex]["type"] === "number"
                        ? "Low " + data[selectedOptionIndex]["title"]
                        : ""
                    }
                </div>
            </Menu.Item>
            <Menu.Item key="sort-desc" className={classNames(
                selectedSortType === "desc" ? "bg-blue-50 text-blue-500": "text-gray-900"
            )}>
                <div 
                    className="flex items-center cursor-pointer text-sm font-medium"
                    role="button"
                    onClick={(e) => handleOnClickSort(e, "desc")}
                >
                    <Icon type="arrow-down" className="mr-2" />
                    {
                        data[selectedOptionIndex]["type"] === "date"
                        ? "Newest first" 
                        : data[selectedOptionIndex]["type"] === "text"
                        ? "Z-A"
                        : data[selectedOptionIndex]["type"] === "number"
                        ? "High " + data[selectedOptionIndex]["title"]
                        : ""
                    }
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']}>
            <div role="button"
                className={classNames(
                    "flex items-center border border-gray-200 focus:outline-none focus:border-gray-300 px-3",
                    className
                )} 
                onClick={e => e.preventDefault()}
            >
                <SortIcon size={5} />
            </div>
        </Dropdown>
    );
}  
  export default SortBox;