import React from "react";
import Row from "./Row";

const Results = ({ data, update }) => (
	<div className="table-card">
		<table>
			<thead>
				<tr>
					<th>ID</th>
					<th>Last deployment</th>
					<th width="100"></th>
				</tr>
			</thead>
			<tbody>
				{data.map((row, i) => (
					<Row key={i} {...row} onChange={update} />
				))}
			</tbody>
		</table>
	</div>
);

export default Results;
