import React from "react";
import Word from "./Word";

export function WordSelector({
	words,
	editedWords,
	editingWordIndex,
	onSelectWord,
}) {
	return (
		<div className="p-3 border-b border-gray-200 bg-cool-gray-50 select-none">
			{editedWords.map((word, i) => (
				<Word
					key={i}
					word={word}
					isDirty={
						words[i]?.pause !== word.pause ||
						words[i]?.emphasis !== word.emphasis ||
						words[i]?.phones !== word.phones
					}
					isActive={editingWordIndex === i}
					onClick={(event) =>
						onSelectWord(event, i === editingWordIndex ? null : i)
					}
				/>
			))}
		</div>
	);
}

export default WordSelector;
