export const statuses = {
	initial_linguistics_review: "Initial Linguistics Review",
	customer_linguistics_input: "Customer Pronunciation Guidance",
	// TODO: Conditional sample state
	// editing_sample: "Editing Sample",
	// customer_sample_review: "Customer Sample Review",
	editing_book: "Editing Book",
	editing_book_post: "Post Processing",
	editing_book_post_review: "Review and Deliver",
	customer_proofing: "Corrections",
	making_corrections: "Working on Corrections",
	making_corrections_post: "Post Processing",
	making_corrections_post_review: "Review and Deliver",
	customer_proofing_review: "Customer Proofing Review",
	finalizing_book: "Finalizing Book",
	finalizing_book_post: "Post Processing",
	finalizing_book_post_review: "Review and Deliver",
	book_delivered: "Book Delivered",
};
