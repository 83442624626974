import React, { useState } from "react";
import { formatDate } from "../modules/apiStats";
import Button from "../../../components/Button";
import { Select } from 'antd';
const { Option } = Select;

const Filter = ({ tokens, selectedToken, startDate, endDate, onChange }) => {
	const [token, setToken] = useState(selectedToken);
	const [start, setStart] = useState(formatDate(startDate));
	const [end, setEnd] = useState(formatDate(endDate));
	

	if (tokens === null) return null;

	return (
		<div className="flex relative items-center" style={{ width: 700 }}>
			<div className="flex-1">
				<Select
					className="text-sm"
    				showSearch
    				style={{ width: 200 }}
					size="large"
					dropdownMatchSelectWidth={false}
    				placeholder="Search customers"
    				optionFilterProp="children"
					onChange={(e) => setToken(e)}
					filterOption={(input, option) =>
      					option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						  
    				}
    				filterSort={(optionA, optionB) =>
      					optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
    				}
  				>
				  	<Option value="">All tokens</Option>
					{tokens.map((token, index) => (
						<Option key={index} value={token.id}>
							{token.title}
						</Option>
					))}  
    				
  				</Select>

				<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-200">
					<svg
						className="fill-current h-4 w-4"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
					>
						<path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
					</svg>
				</div>
			</div>
			<div className="flex-1 pl-2">
				<input
					className="form-input text-sm w-full"
					type="date"
					value={start}
					onChange={(e) => setStart(e.target.value)}
				/>
			</div>
			<div className="flex-1 pl-2">
				<input
					className="form-input text-sm w-full"
					type="date"
					value={end}
					onChange={(e) => setEnd(e.target.value)}
				/>
			</div>
			<div className="pl-2">
				<Button
					onClick={() =>
						onChange(token, new Date(start), new Date(end))
					}
					className="h-10"
				>
					Refresh
				</Button>
			</div>
		</div>
	);
};

export default Filter;
