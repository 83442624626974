import SearchRoute from "./Search";
import SessionsRoute from "./Sessions";
import G2PRoute from "./G2P";
import ListRoute from "./List";
import LexiconLayout from "../../components/LexiconLayout";
import loggedIn from "../../containers/loggedIn";

export default {
	path: "lexicon",
	component: loggedIn(LexiconLayout),
	indexRoute: ListRoute,
	childRoutes: [SearchRoute, SessionsRoute, G2PRoute],
};
